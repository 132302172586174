import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import SwissICHBottom from './SwissICHBottom';
import SwissICHAccess from './SwissICHAccess';
import { SubscribeDockerApp } from 'graphql/Subscriptions';
import { ServerErrorsString } from 'helpers';
import SwissICHUpload from './SwissICHUpload';
import SwissICHResults from './SwissICHResults';

class SwissICHComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isNifti: false,
      isCropped: false,
      folder_content: null,
      access: null,
      active: 0,
      // threshold: 0,
      files: [],
      disabled_all: true,
      running: false,
      enabled: false,
      prev_results: null,
    };
    this.holder = {
      reset: null,
      setTotal: null,
      startUpload: null,
      startRun: null,
      setStateResults: null,
    };
  }
  componentDidUpdate() {
    // const { disabled_all, threshold } = this.state;
    // if (disabled_all && threshold !== 0 && threshold !== 50) {
    //   this.setState({ threshold: 50 });
    // } else if (!disabled_all && threshold === 0) {
    //   this.setState({ threshold: 50 });
    // }
  }
  handleStart = () => {
    this.setState({ running: true, active: 1 });
    this.holder.startUpload();
  };
  handleRun = folder_content => {
    console.log('handleRun', { folder_content });
    this.setState({ active: 2, folder_content }, () => {
      this.holder.startRun();
    });
  };
  handleResetToStartAgain = () => {
    this.setState({ disabled_all: false, active: 1, running: false });
    this.holder.reset();
  };
  handleDockerResult = data => {
    const { prev_results } = this.state;
    let new_results = null;
    if (data) {
      new_results = data.results;
    }
    console.log('handleDockerResult', { new_results });
    if (prev_results === new_results) return;
    try {
      const { ok, results, errors } = data;
      if (ok) {
        const SwissICH = JSON.parse(results);
        console.log({ SwissICH });
        this.setState(
          {
            running: false,
            active: 0,
            prev_results: results,
          },
          () => {
            // const { option_left, option_center, option_right } = this.state;
            this.holder.setStateResults({
              running: false,
              finished: true,
              // option_left: { ...option_left, series: [AAOCA.anomaly] },
              // option_center: { ...option_center, series: [AAOCA.origin] },
              // option_right: { ...option_right, series: [AAOCA.risk] },
              diagnosis: 'Download the results to analize them',
              // AAOCA,
              SwissICH
            });
          }
        );
      } else {
        throw errors;
      }
    } catch (error) {
      this.holder.setStateResults({ running: false });
      this.setState({ running: false, active: 0 });
      this.props.handleAddError(ServerErrorsString(error));
    }
  };
  render() {
    let { /*threshold,*/ files, disabled_all, active, access } = this.state;
    // if (!files.length) threshold = 50;
    let idUserTemp = null;
    let idDocker = null;
    if (access) {
      idUserTemp = access.id;
      idDocker = access.idDocker;
    }
    return (
      <React.Fragment>
        <SubscribeDockerApp
          handleDockerResult={this.handleDockerResult}
          idDocker={idDocker}
          idUserTemp={idUserTemp}
        />
        <Grid
          container
          direction="column"
          spacing={2}
          sx={{
            justifyContent: 'center',
            alignItems: 'stretch',
            paddingBottom: 5,
          }}>
          <Grid item xs={12}>
            <SwissICHAccess
              active={0 === active}
              disabled_all={disabled_all}
              enabled={this.state.enabled}
              full_run={this.state.running}
              handleAddError={this.props.handleAddError}
              handleAddSuccess={this.props.handleAddSuccess}
              holder={this.holder}
              setState={state => this.setState(state)}
            />
          </Grid>
          <Grid item xs={12}>
            <SwissICHUpload
              access={this.state.access}
              active={1 === active}
              disabled_all={disabled_all}
              enabled={this.state.enabled}
              full_run={this.state.running}
              handleAddError={this.props.handleAddError}
              handleFinshed={this.handleRun}
              handleResetToStartAgain={this.handleResetToStartAgain}
              holder={this.holder}
              setState={state => this.setState(state)}
            />
          </Grid>
          <Grid item xs={12}>
            <SwissICHResults
              access={this.state.access}
              active={2 === active}
              disabled_all={disabled_all}
              enabled={this.state.enabled}
              files={files}
              folder_content={this.state.folder_content}
              full_run={this.state.running}
              handleAddError={this.props.handleAddError}
              handleStart={this.handleStart}
              holder={this.holder}
              // isCropped={this.state.isCropped}
              // isNifti={this.state.isNifti}
              setState={state => this.setState(state)}
              // threshold={threshold}
            />
          </Grid>
          <Grid item xs={12}>
            <SwissICHBottom
              active={active > 0 ? true : false}
              disabled_all={disabled_all}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

SwissICHComponent.propTypes = {
  classes: PropTypes.object,
};

export default SwissICHComponent;
