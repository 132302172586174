import React from 'react';
import PropTypes from 'prop-types';

class LelfSectionHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    const { patient } = this.props.state;
    let total_series = 0;
    let total_studies = 0;
    if (patient) {
      total_studies = patient.NumberOfPatientRelatedStudies;
      total_series = patient.NumberOfPatientRelatedSeries;
    }
    return (
      <div className={classes.sectionHeader}>
        <div>
          History{' '}
          <span className={classes.badge}>{`${total_studies} ${
            total_studies > 1 ? 'Studies' : 'Study'
          }, ${total_series} Series`}</span>
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <span className={classes.icon}>🔍</span>
          <span>Filter</span>
        </div>
      </div>
    );
  }
}

LelfSectionHeader.propTypes = {
  classes: PropTypes.object,
};

export default LelfSectionHeader;
