import { Enums, getRenderingEngine } from '@cornerstonejs/core';
import { ToolGroupManager } from '@cornerstonejs/tools';
import { IsInvalid } from 'helpers';
const { Events } = Enums;

// Check for selected/highlighted annotations
export const checkForSelectedAnnotation = (annotationManager, THIS) => {
  try {
    const allAnnotations = annotationManager.state.getAllAnnotations();

    // Look for highlighted annotations
    Object.keys(allAnnotations).forEach((uid) => {
      const annotation = allAnnotations[uid];

      // Check if this annotation is highlighted
      if (annotation.highlighted) {
        THIS.selectAnnotation(annotation);
      }
    });
  } catch (error) {
    console.error('Error checking for selected annotation:', error);
  }
};
export const deleteSelectedAnnotation = (
  annotationManager,
  renderingEngineId,
  viewportId,
  toolGroupId,
  THIS
) => {
  const { selectedAnnotationUID } = THIS.state;
  if (IsInvalid(selectedAnnotationUID)) {
    return;
  }
  try {
    // Get the rendering engine and viewport
    const renderingEngine = getRenderingEngine(renderingEngineId);
    const viewport = renderingEngine.getViewport(viewportId);

    // Get the toolGroup
    const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);

    // Get annotation data before deleting it to know which tool it belongs to
    const annotationData = annotationManager.state.getAnnotation(
      selectedAnnotationUID
    );
    if (!annotationData) {
      console.warn('Annotation not found in state:', selectedAnnotationUID);
      return;
    }
    const toolName = annotationData?.metadata?.toolName;
    const annotationUID = selectedAnnotationUID;

    // IMPORTANT: In Cornerstone Tools, some tool-specific handling is needed
    // Get the specific tool instance that created this annotation
    if (toolName && toolGroup) {
      const annotationInstance = toolGroup.getToolInstance(toolName);

      // Try to use tool-specific removal if available
      if (
        annotationInstance &&
        typeof annotationInstance.removeAnnotation === 'function'
      ) {
        annotationInstance.removeAnnotation(selectedAnnotationUID);
      }
    }

    // Remove from state manager
    annotationManager.state.removeAnnotation(selectedAnnotationUID);

    // Force removal from cache and all related data structures
    // This explicitly clears the render cache for the annotation
    if (
      toolName &&
      annotationManager.tools &&
      annotationManager.tools[toolName] &&
      typeof annotationManager.tools[toolName].clearAnnotation === 'function'
    ) {
      annotationManager.tools[toolName].clearAnnotation(selectedAnnotationUID);
    }

    // Make sure all viewports know about this change
    const element = THIS.elementRef.current;
    if (element) {
      // Dispatch a custom event that tools can listen for
      const customEvent = new CustomEvent(Events.ANNOTATION_REMOVED, {
        detail: {
          annotationUID: selectedAnnotationUID,
          toolName,
          viewportId,
          renderingEngineId,
        },
      });
      element.dispatchEvent(customEvent);
    }

    // Ensure viewport is refreshed
    viewport.render();

    // Update React state
    THIS.setState({
      selectedAnnotationUID: null,
    });

    // Force a re-check of annotations after a short delay
    // setTimeout(this.checkAnnotations, 100);

    console.log('Annotation deleted successfully:', annotationUID);
  } catch (error) {
    console.error('Error deleting annotation:', error);
  }
};
