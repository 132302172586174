/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable quotes */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { ExplorerViewGallery, ExplorerViewList } from './components';
import { GetData, getSorting, ServerErrorsString, stableSort } from 'helpers';
import { FormatedFolder, ToolBarShared } from '../index';
import { setSubFolders } from 'redux-store/actions';
import { FolderQuery } from 'graphql/Folders';
import { handleDownload } from '../ToolBarShared/components/ToolBarSharedBottom/components/ButtonDownloadAll/components';
import { IsInvalid } from 'helpers';
import { CircularProgressWaiting } from 'components';

class SharedViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      view_type: 0,
      subfolder: null,
      loading: false,
      downloading: false,
      total_downloaded: 0,
      current_index: -1,
      downloads: [],
      ready: [],
      download_all: false,
    };
    this.holder = {
      setProgress: null,
      setDownload: null,
      setFinished: null,
      setInit: null,
      setClean: null,
    };
    if (props.holder) {
      props.holder.getContents = () => {
        return this.getContents();
      };
    }
  }

  IsReady = () => {
    const { subfolder } = this.state;
    if (!subfolder) return false;
    const { idFolder } = this.props;
    if (subfolder.idFolder !== idFolder) return false;
    const { Files, SubFolders, total_files, total_sub_folders } = subfolder;
    if (!total_files && !total_sub_folders) {
      return true;
    }
    if (Files === undefined || SubFolders === undefined) {
      return false;
    }
    if (
      Files.length === total_files &&
      SubFolders.length === total_sub_folders
    ) {
      return true;
    }
    return false;
  };

  componentDidMount() {
    const { idFolder, subfolders } = this.props;
    if (!idFolder) {
      if (subfolders.length) {
        this.props.setSubFolders([]);
      }
    } else if (!this.IsReady()) {
      this.loadSubFolder(idFolder);
    }
  }

  componentDidUpdate() {
    const { idFolder, subfolders } = this.props;
    if (!idFolder) {
      if (subfolders.length) {
        this.props.setSubFolders([]);
      }
    } else if (!this.IsReady()) {
      this.loadSubFolder(idFolder);
    }
  }

  loadSubFolder = idFolder => {
    console.log('loadSubFolder', { idFolder });
    const { loading } = this.state;
    if (loading) return;
    const { code } = this.props;
    this.setState({ loading: true });
    (async () => {
      FolderQuery(idFolder, code)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, Folder } = data.folder;
          if (ok) {
            this.setState({ loading: false, subfolder: Folder });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          let text_error = ServerErrorsString(error);
          if (text_error.indexOf('UNAUTHENTICATED') !== -1) {
            text_error =
              "To access this level you need to be logged in.\nRequest an account if you don't have one";
          }
          this.props.handleAddError(text_error);
          this.setState({ loading: false, subfolder: null });
          this.props.setSubFolders([]);
          this.props.history.push(`/shared/folder/${code}`);
        });
    })();
  };
  handleSort = orderBy => {
    const { order } = this.state;
    this.setState({ orderBy, order: order === 'asc' ? 'desc' : 'asc' });
  };
  handleDoubleClick = content => {
    let { code, subfolders } = this.props;
    if (!content || !code) return;
    const { folder } = content;
    if (typeof folder === 'undefined' || !folder) return;
    const { idFolder } = folder;
    const index = subfolders.map(x => x.idFolder).indexOf(idFolder);
    if (index === -1) {
      subfolders.push(folder);
    } else {
      subfolders = subfolders.splice(0, index);
    }
    this.props.setSubFolders(subfolders);
    this.setState({ subfolder: folder });
    this.props.history.push(`/shared/folder/${code}/${idFolder}`);
  };
  getContents = () => {
    const { idFolder, home } = this.props;
    if (!idFolder) return FormatedFolder(home);
    const { subfolder } = this.state;
    if (subfolder) {
      return FormatedFolder(subfolder);
    } else {
      return [];
    }
  };
  getViewer = () => {
    const { order, orderBy, view_type, loading } = this.state;
    if (loading) {
      return (
        <CircularProgressWaiting style={{ height: '100%', width: '100%' }} />
      );
    }
    const { selected, handleSelected, handleSelectAll, height } = this.props;
    const contents = this.getContents();
    const rows = stableSort(contents, getSorting(order, orderBy));
    switch (view_type) {
      case 0:
        return (
          <ExplorerViewList
            contents={rows}
            handleDoubleClick={this.handleDoubleClick}
            handleSelectAll={handleSelectAll}
            handleSelected={handleSelected}
            handleSort={this.handleSort}
            height={height - 200}
            holder={this.holder}
            order={order}
            orderBy={orderBy}
            selected={selected}
          />
        );
      default:
        return (
          <ExplorerViewGallery
            contents={rows}
            handleDoubleClick={this.handleDoubleClick}
            handleSelectAll={handleSelectAll}
            handleSelected={handleSelected}
            handleSort={this.handleSort}
            holder={this.holder}
            order={order}
            orderBy={orderBy}
            selected={selected}
          />
        );
    }
  };
  handleViewType = view_type => {
    this.setState({ view_type });
  };
  handleDownloadOneAtaTime = () => {
    // console.log('handleDownloadOneAtaTime');
    const { orderBy, order } = this.state;
    const { selected } = this.props;
    this.state.total_downloaded = 0;
    this.state.download_all = true;
    this.state.ready = [];
    this.state.current_index = -1;
    this.state.downloading = true;
    console.log('handleDownloadOneAtaTime', { selected });
    if (selected.length) {
      this.state.downloads = selected;
    } else {
      const contents = this.getContents();
      const rows = stableSort(contents, getSorting(order, orderBy));
      if (rows === undefined || !rows || !rows.length) {
        return;
      }
      this.state.downloads = rows;
    }
    this.holder.setInit(this.state.downloads);
    handleDownload(0, this);
  };
  handleDownloadItem = (index, errors, item_prev) => {
    const { download_all, current_index } = this.state;
    // console.log({ index, errors, download_all, current_index });
    this.handleDownloadFinished(errors, item_prev);
    if (index - 1 >= 0) {
      let { folder, content } = this.state.downloads[index - 1];
      let selected = [];
      if (folder) {
        selected.push({ idFolder: folder.idFolder });
      } else if (content) {
        selected.push({ idFolderContent: content.idFolderContent });
      } else {
        console.log('error - handleDownloadItem');
      }
      this.holder.setFinished(selected, errors);
    }
    if (index < 0 || index >= this.state.downloads.length) {
      this.setState({
        downloading: false,
        current_index: -1,
        progress_all: 0,
        download_all: false,
        ready: [],
      });
    } else if (download_all || current_index === -1) {
      handleDownload(index, this);
    }
  };
  handleProgress = (event, item_downlading) => {
    const { downloads } = this.state;
    let { loaded, total } = event;
    const index = this.getIndexSelected(item_downlading);
    let total_downloaded = loaded + loaded * 0.1;
    if (index !== -1) {
      const { folder, content } = downloads[index];
      if (folder) total_downloaded = folder.Size;
      if (content) total_downloaded = content.Size;
    }
    if (isNaN(total) || total === 0 || total === undefined) {
      total = total_downloaded;
    }
    if (this.holder.setProgress) {
      let item = null;
      if (item_downlading.length) item = item_downlading[0];
      this.holder.setProgress({ loaded, total }, true, item);
    }
  };
  handleDownloadFinished = (error, item_downlading) => {
    if (error) {
      this.props.handleAddError(ServerErrorsString(error));
    }
    if (IsInvalid(item_downlading)) return;
    if (this.holder.setProgress) {
      let item = null;
      if (Array.isArray(item_downlading)) {
        if (item_downlading.length) item = item_downlading[0];
      } else {
        item = item_downlading;
      }
      this.holder.setFinished(item, error);
    }
  };
  getIndexSelected = in_selected => {
    const { downloads } = this.state;
    let find = `${in_selected[0].idFolder}-${in_selected[0].idFolderContent}`;
    const index = downloads
      .map(x => {
        let idFolder = undefined;
        let idFolderContent = undefined;
        if (x.folder) idFolder = x.folder.idFolder;
        if (x.content) idFolderContent = x.content.idFolderContent;
        return `${idFolder}-${idFolderContent}`;
      })
      .indexOf(find);
    return index;
  };
  render() {
    const {
      home,
      idFolder,
      selected,
      code,
      isMobile,
      handleReload,
      link,
      height,
    } = this.props;
    const { orderBy, order, subfolder, loading } = this.state;
    let folder = home;
    if (idFolder > 0 && !loading) {
      folder = subfolder;
    }

    return (
      <React.Fragment>
        <ToolBarShared
          code={code}
          folder={folder}
          handleDownloadOneAtaTime={this.handleDownloadOneAtaTime}
          handleReload={handleReload}
          handleSort={this.handleSort}
          handleViewType={this.handleViewType}
          holder={this.holder}
          home={home}
          idFolder={idFolder}
          isMobile={isMobile}
          link={link}
          loading={loading}
          onDownloadFinished={this.handleDownloadFinished}
          order={order}
          orderBy={orderBy}
          selected={selected}
        />
        <div style={{ height: height - 190, overflow: 'auto' }}>
          {this.getViewer()}
        </div>
      </React.Fragment>
    );
  }
}

SharedViewer.propTypes = {
  classes: PropTypes.object,
};
SharedViewer.defaultProps = {
  contents: [],
  view_type: 0,
  handleSelectAll: () => '',
  handleSelected: () => '',
};
const mapStateToProps = state => ({
  subfolders: state.page_shared.subfolders,
});

export default connect(mapStateToProps, {
  setSubFolders,
})(withRouter(SharedViewer));
