/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import {
  GetData,
  GetThumbnailFile,
  IsInvalid,
  ServerErrorsString,
} from 'helpers';
import { QueryStudiesSeries } from 'graphql/Study';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CardMedia, IconButton, ImageListItemBar } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { getStyles } from './style';
import { CircularLoading } from 'components';

class LeftSectionStudySeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ListSeriesInStudy: [],
      study: null,
      error: null,
      loading: false,
      selected: [],
      page: 0,
      limit: 100,
      isGridOpen: true,
      containerWidth: 0,
    };
    this.containerRef = React.createRef(); // Ref for container
    const { holder } = props;
    if (holder) {
      holder.getStudySeries = idSeries => {
        const { ListSeriesInStudy } = this.state;
        const index = ListSeriesInStudy.findIndex(x => x.idSeries === idSeries);
        if (index !== -1) {
          return ListSeriesInStudy[index];
        }
        return null;
      };
      holder.cleanSeriesInStudy = () =>
        this.setState({ ListSeriesInStudy: [], selected: [], study: null });
    }
  }
  componentDidMount() {
    this.updateContainerWidth();
    window.addEventListener('resize', this.updateContainerWidth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateContainerWidth);
  }
  updateContainerWidth = () => {
    if (this.containerRef.current) {
      this.setState({
        containerWidth: this.containerRef.current.offsetWidth,
      });
    }
  };
  componentDidUpdate() {
    const { study } = this.props.state;
    if (IsInvalid(study)) {
      if (this.state.study)
        this.setState({
          ListSeriesInStudy: [],
          study: null,
        });
      return;
    }
    if (!this.state.study && study) {
      this.handleLoadStudySeries(study);
    } else if (
      this.state.study &&
      study &&
      this.state.study.idStudy !== study.idStudy
    ) {
      this.handleLoadStudySeries(study);
    }
  }
  handleLoadStudySeries = study => {
    const { loading, error } = this.state;
    if (loading || error) return;
    this.setState({ loading: true });
    (async () => {
      QueryStudiesSeries([study.idStudy], 0, 100)
        .then(res => {
          const data = GetData(res);
          const { ok, studies_series, errors } = data.StudiesSeries;
          if (ok) {
            this.setState({
              study,
              loading: false,
              ListSeriesInStudy: studies_series.length
                ? studies_series[0].series
                : [],
              error: null,
            });
            // this.props.setState({ studies });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, error });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  toggleGrid = () => {
    this.setState(prevState => ({
      isGridOpen: !prevState.isGridOpen,
    }));
  };
  handleDragEnd = e => {
    e.target.style.opacity = '1';
  };
  handleDragStart = (e, item) => {
    e.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        html: `<div class="image-item" draggable="true" data-idSeries="${item.idSeries}" style="background-image: url('${item.url}')"></div>`,
        idSeries: item.idSeries,
      })
    );
    e.target.style.opacity = '0.8';
  };
  render() {
    const { classes, ListViewerSeries } = this.props;
    const {
      ListSeriesInStudy,
      isGridOpen,
      containerWidth,
      loading,
    } = this.state;
    const itemWidth = 200;
    const gap = 10;
    const padding = 15 * 2;
    const occupiedWidth = ListSeriesInStudy.length * (itemWidth + gap) - gap;
    const remainingSpace = containerWidth - occupiedWidth - padding;
    const justifyContent = remainingSpace < itemWidth ? 'center' : 'start';
    const styles = getStyles([], isGridOpen, justifyContent);
    console.log('LeftSectionStudySeries', {
      ListViewerSeries,
      ListSeriesInStudy,
    });
    return (
      <div style={{ padding: 10 }}>
        <div style={styles.leftSection}>
          <div ref={this.containerRef} style={styles.imagesSection}>
            <div
              className={classes.sectionHeader}
              onClick={this.toggleGrid}
              style={styles.sectionHeader}>
              <div>Images in Study</div>
              <span style={styles.icon}>▼</span>
            </div>
            <div style={styles.imageGrid}>
              {loading ? (
                <CircularLoading />
              ) : (
                ListSeriesInStudy.map((series_item, index) => {
                  const {
                    SeriesDescription,
                    Thumbnail,
                    Modality,
                    SeriesTime,
                    NumberOfFrames,
                    NumberOfSeriesRelatedInstances,
                  } = series_item;
                  let total_files = NumberOfSeriesRelatedInstances;
                  if (NumberOfFrames > 1) {
                    total_files = `${NumberOfFrames} - MF`;
                  }
                  let thumbnail = GetThumbnailFile('file.dcm');
                  if (Thumbnail && Thumbnail !== '') {
                    thumbnail = `data:image/jpeg;base64,${Thumbnail}`;
                  } else if (Modality === 'SR') {
                    thumbnail = '/images/icons/icon_sr.png';
                  } else if (Modality === 'PR') {
                    thumbnail = '/images/icons/icon_pr.png';
                  }
                  // console.log({ thumbnail });
                  return (
                    <div
                      draggable
                      key={index}
                      onDragEnd={this.handleDragEnd}
                      onDragStart={e => this.handleDragStart(e, series_item)}
                      style={{
                        ...styles.imageItem,
                        backgroundImage: `url(${series_item.url})`,
                        ...(ListViewerSeries.some(
                          d => d.idSeries === series_item.idSeries
                        )
                          ? styles.sourceDropped
                          : {}),
                      }}>
                      {ListViewerSeries.some(
                        d => d.idSeries === series_item.idSeries
                      ) && (
                        <div style={styles.sourceDroppedAfter}>
                          <CheckCircleIcon
                            style={{ color: '#F44336', width: 20, height: 20 }}
                          />
                        </div>
                      )}
                      <CardMedia
                        className={classes.imageItem}
                        image={thumbnail}
                        title="Image title"
                      />
                      <ImageListItemBar
                        actionIcon={
                          <IconButton
                            aria-label={`info about ${SeriesDescription}`}
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}>
                            <InfoIcon />
                          </IconButton>
                        }
                        subtitle={`${SeriesTime}\t(${total_files})`}
                        sx={{
                          '.MuiImageListItemBar-titleWrap': {
                            paddingTop: 0,
                            paddingBottom: 0,
                          },
                          '.MuiImageListItemBar-title': {
                            fontSize: '10px',
                          },
                          '.MuiImageListItemBar-subtitle': {
                            fontSize: '9px',
                          },
                        }}
                        title={SeriesDescription}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LeftSectionStudySeries.propTypes = {
  classes: PropTypes.object,
};
LeftSectionStudySeries.defaultProps = {
  droppedItems: [],
};

export default LeftSectionStudySeries;
