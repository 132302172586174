import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CardContent, Typography, Grid, IconButton } from '@mui/material';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Skeleton from '@mui/lab/Skeleton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { SeriesTagValue } from 'helpers/Series';
import { setDisplayDicomSeries } from 'redux-store/actions';
import { FormatBytes, IsUserViewer } from 'helpers';
class BottonMenu extends Component {
  handleOpenSeries = () => {
    const { Series } = this.props;
    this.props.setDisplayDicomSeries(Series);
    this.props.history.push(`/dicom_viewer/series/${Series.idSeries}`);
  };
  render() {
    const { loading, Series, user } = this.props;
    if (loading) {
      return (
        <CardContent>
          <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
          <Skeleton animation="wave" height={10} width="80%" />
        </CardContent>
      );
    }
    const { idSeries } = Series;
    const ImageSize = SeriesTagValue(Series, 'Size');
    const ImageSpacing = SeriesTagValue(Series, 'Spacing');
    let ComponentOpenDicomViewer = null;
    if (IsUserViewer(user)) {
      ComponentOpenDicomViewer = (
        <Grid item>
          <IconButton
            aria-label="delete"
            onClick={this.handleOpenSeries}
            size="small">
            <OpenInNewIcon fontSize="inherit" />
          </IconButton>
        </Grid>
      );
    }
    return (
      <CardContent style={{ padding: 5 }}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-start">
          {ComponentOpenDicomViewer}
          <Grid item>
            <Typography color="textSecondary" component="p" variant="body2">
              {`ID: ${idSeries}, Image Size: ${FormatBytes(
                ImageSize
              )},  Spacing: ${ImageSpacing}`}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    );
  }
}

BottonMenu.defaultProps = {
  loading: false,
};
BottonMenu.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { series } = state.page_dicom_viewer;
  const { user } = state.info_user;
  return { view_series: series, user };
};

export default connect(mapStateToProps, { setDisplayDicomSeries })(
  withRouter(BottonMenu)
);
