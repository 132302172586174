import { Enums } from '@cornerstonejs/core';
import { IsInvalid } from 'helpers';
import { orientationLettersImOriPat } from './utils';
export const isSeriesAxisOriented = series => {
  if (IsInvalid(series)) return false;
  const {
    ImagePlane: { ImageOrientationPatient },
  } = series;
  return isAxisOriented(ImageOrientationPatient);
};
export const isAxisOriented = imageOrientationPatient => {
  // Validate input
  if (
    !Array.isArray(imageOrientationPatient) ||
    imageOrientationPatient.length !== 6
  ) {
    console.error('ImageOrientationPatient must be an array of 6 numbers');
    return false;
  }
  // console.log({ imageOrientationPatient });
  // Extract row and column vectors
  const rowVector = [
    imageOrientationPatient[0],
    imageOrientationPatient[1],
    imageOrientationPatient[2],
  ];
  const colVector = [
    imageOrientationPatient[3],
    imageOrientationPatient[4],
    imageOrientationPatient[5],
  ];

  // Function to check if vector is aligned with an axis
  const isAxisAligned = vector => {
    // Count non-zero components (absolute value > small epsilon for floating-point comparison)
    const epsilon = 0.0001;
    const significantComponents = vector.filter(v => Math.abs(v) > epsilon)
      .length;

    // Vector is axis-aligned if:
    // 1. It has exactly one significant component (others near zero)
    // 2. That component is approximately 1 or -1
    if (significantComponents !== 1) return false;

    const maxComponent = Math.max(...vector.map(Math.abs));
    return Math.abs(maxComponent - 1) < epsilon;
  };

  // Check both vectors
  const rowAligned = isAxisAligned(rowVector);
  const colAligned = isAxisAligned(colVector);

  // Both vectors must be axis-aligned and orthogonal to each other
  if (rowAligned && colAligned) {
    // Check orthogonality via dot product
    const dotProduct =
      rowVector[0] * colVector[0] +
      rowVector[1] * colVector[1] +
      rowVector[2] * colVector[2];
    return Math.abs(dotProduct) < 0.0001; // Should be close to 0 for orthogonal vectors
  }

  return false;
};

// Example usage:
// const axial = [1, 0, 0, 0, 1, 0]; // Axial orientation (X, Y)
// const coronal = [1, 0, 0, 0, 0, 1]; // Coronal (X, Z)
// const sagittal = [0, 1, 0, 0, 0, 1]; // Sagittal (Y, Z)
// const oblique1 = [0.707, 0.707, 0, 0, 0, 1]; // Mostly axial
// const oblique2 = [0.2, 0.8, 0.1, -0.5, 0.1, 0.9]; // Complex oblique

// console.log('Axial:', isAxisOriented(axial)); // true
// console.log('Coronal:', isAxisOriented(coronal)); // true
// console.log('Sagittal:', isAxisOriented(sagittal)); // true
// console.log('Oblique:', isAxisOriented(oblique1)); // false
export const getOrientationFromString = imageOrientationPatient => {
  if (typeof imageOrientationPatient === 'string') {
    if (imageOrientationPatient === 'Empty') return [1, 0, 0, 0, 1, 0];
    else {
      let option_one = imageOrientationPatient.split('\\\\');
      if (option_one.length !== 6) {
        option_one = imageOrientationPatient.split('\\');
      }
      if (option_one.length !== 6) {
        return [1, 0, 0, 0, 1, 0];
      }
      imageOrientationPatient = option_one.map(x => parseFloat(x));
      console.log_dev('detectMainView - string', { imageOrientationPatient });
    }
  }
  return imageOrientationPatient;
};
export const detectMainView = imageOrientationPatient => {
  imageOrientationPatient = getOrientationFromString(imageOrientationPatient);
  // Validate input
  if (
    !Array.isArray(imageOrientationPatient) ||
    imageOrientationPatient.length !== 6
  ) {
    console.error('ImageOrientationPatient must be an array of 6 numbers');
    return false;
  }

  // Extract row and column vectors
  const rowVector = [
    imageOrientationPatient[0],
    imageOrientationPatient[1],
    imageOrientationPatient[2],
  ];
  const colVector = [
    imageOrientationPatient[3],
    imageOrientationPatient[4],
    imageOrientationPatient[5],
  ];

  // Calculate normal vector (cross product of row and column vectors)
  const normalVector = [
    rowVector[1] * colVector[2] - rowVector[2] * colVector[1], // x
    rowVector[2] * colVector[0] - rowVector[0] * colVector[2], // y
    rowVector[0] * colVector[1] - rowVector[1] * colVector[0], // z
  ];

  // Standard basis vectors for anatomical planes
  const sagittalNormal = [1, 0, 0]; // Normal to sagittal (X)
  const coronalNormal = [0, 1, 0]; // Normal to coronal (Y)
  const axialNormal = [0, 0, 1]; // Normal to axial (Z)

  // Function to compute absolute dot product
  const dotProduct = (v1, v2) =>
    Math.abs(v1[0] * v2[0] + v1[1] * v2[1] + v1[2] * v2[2]);

  // Calculate similarity to each standard plane
  const sagittalScore = dotProduct(normalVector, sagittalNormal);
  const coronalScore = dotProduct(normalVector, coronalNormal);
  const axialScore = dotProduct(normalVector, axialNormal);

  // Find the maximum score
  const maxScore = Math.max(sagittalScore, coronalScore, axialScore);

  // Determine predominant view
  if (maxScore === sagittalScore) {
    return 'sagittal';
  } else if (maxScore === coronalScore) {
    return 'coronal';
  } else {
    return 'axial';
  }
};

// Example usage:

// console.log('Axial:', detectMainView(axial)); // "axial"
// console.log('Coronal:', detectMainView(coronal)); // "coronal"
// console.log('Sagittal:', detectMainView(sagittal)); // "sagittal"
// console.log('Oblique1:', detectMainView(oblique1)); // "axial"
// console.log('Oblique2:', detectMainView(oblique2)); // "coronal"

export const getOrientedVectors = imageOrientationPatient => {
  if (typeof imageOrientationPatient === 'string') {
    imageOrientationPatient = imageOrientationPatient.split('\\\\');
    imageOrientationPatient.map(x => parseFloat(x));
    console.log('getOrientedVectors - string', { imageOrientationPatient });
  }
  // Validate input
  if (
    !Array.isArray(imageOrientationPatient) ||
    imageOrientationPatient.length !== 6
  ) {
    throw new Error('ImageOrientationPatient must be an array of 6 numbers');
  }

  // Extract row and column vectors
  const rowVector = [
    imageOrientationPatient[0],
    imageOrientationPatient[1],
    imageOrientationPatient[2],
  ];
  const colVector = [
    imageOrientationPatient[3],
    imageOrientationPatient[4],
    imageOrientationPatient[5],
  ];

  // Calculate normal vector (cross product)
  const normalVector = [
    rowVector[1] * colVector[2] - rowVector[2] * colVector[1], // x
    rowVector[2] * colVector[0] - rowVector[0] * colVector[2], // y
    rowVector[0] * colVector[1] - rowVector[1] * colVector[0], // z
  ];

  // Standard basis vectors
  const xAxis = [1, 0, 0]; // Sagittal direction (left-right)
  const yAxis = [0, 1, 0]; // Coronal direction (anterior-posterior)
  const zAxis = [0, 0, 1]; // Axial direction (superior-inferior)

  // Function to compute absolute dot product
  const dotProduct = (v1, v2) =>
    Math.abs(v1[0] * v2[0] + v1[1] * v2[1] + v1[2] * v2[2]);

  // Function to assign anatomical label
  const assignAxisLabel = vector => {
    const sagittalScore = dotProduct(vector, xAxis);
    const coronalScore = dotProduct(vector, yAxis);
    const axialScore = dotProduct(vector, zAxis);

    const maxScore = Math.max(sagittalScore, coronalScore, axialScore);

    if (maxScore === sagittalScore) return 'sagittal';
    if (maxScore === coronalScore) return 'coronal';
    return 'axial';
  };

  // Assign labels to all three vectors
  const result = {
    row: {
      vector: rowVector,
      label: assignAxisLabel(rowVector),
    },
    column: {
      vector: colVector,
      label: assignAxisLabel(colVector),
    },
    normal: {
      vector: normalVector,
      label: assignAxisLabel(normalVector),
    },
  };

  return result;
};

// Example usage with your provided data
// const imageOrientationPatient = [
//   0.99484138764375, 0.09810580799869, -0.0258043381613, -0.0383480166606,
//   0.12820800574666, -0.9910056189955,
// ];
// const vectors = getOrientedVectors(imageOrientationPatient);

// console.log('Row Vector:', vectors.row.vector, '->', vectors.row.label);
// console.log(
//   'Column Vector:',
//   vectors.column.vector,
//   '->',
//   vectors.column.label
// );
// console.log(
//   'Normal Vector:',
//   vectors.normal.vector,
//   '->',
//   vectors.normal.label
// );
export const getVectorForOrientation = (
  orientation,
  imageOrientationPatient
) => {
  imageOrientationPatient = getOrientationFromString(imageOrientationPatient);

  // Validate input
  if (
    !Array.isArray(imageOrientationPatient) ||
    imageOrientationPatient.length !== 6
  ) {
    console.error('ImageOrientationPatient must be an array of 6 numbers');
    return [];
  }
  const validOrientations = ['axial', 'sagittal', 'coronal'];
  if (!validOrientations.includes(orientation.toLowerCase())) {
    console.error('Orientation must be "axial", "sagittal", or "coronal"');
    return [];
  }

  // Extract row and column vectors
  const rowVector = [
    imageOrientationPatient[0],
    imageOrientationPatient[1],
    imageOrientationPatient[2],
  ];
  const colVector = [
    imageOrientationPatient[3],
    imageOrientationPatient[4],
    imageOrientationPatient[5],
  ];

  // Calculate normal vector (cross product)
  const normalVector = [
    rowVector[1] * colVector[2] - rowVector[2] * colVector[1], // x
    rowVector[2] * colVector[0] - rowVector[0] * colVector[2], // y
    rowVector[0] * colVector[1] - rowVector[1] * colVector[0], // z
  ];

  // Standard basis vectors
  const xAxis = [1, 0, 0]; // Sagittal direction (left-right)
  const yAxis = [0, 1, 0]; // Coronal direction (anterior-posterior)
  const zAxis = [0, 0, 1]; // Axial direction (superior-inferior)

  // Function to compute absolute dot product
  const dotProduct = (v1, v2) =>
    Math.abs(v1[0] * v2[0] + v1[1] * v2[1] + v1[2] * v2[2]);

  // Map orientations to their standard basis vectors
  const orientationMap = {
    sagittal: xAxis,
    coronal: yAxis,
    axial: zAxis,
  };

  const targetAxis = orientationMap[orientation.toLowerCase()];

  // Calculate dot products with target axis
  const rowScore = dotProduct(rowVector, targetAxis);
  const colScore = dotProduct(colVector, targetAxis);
  const normalScore = dotProduct(normalVector, targetAxis);

  // Find the vector with the highest similarity to the requested orientation
  const scores = [
    { vector: rowVector, score: rowScore },
    { vector: colVector, score: colScore },
    { vector: normalVector, score: normalScore },
  ];

  const bestMatch = scores.reduce((max, current) =>
    current.score > max.score ? current : max
  );

  return bestMatch.vector;
};

// Example usage with your provided data

// const sagittalVector = getVectorForOrientation(
//   'sagittal',
//   imageOrientationPatient
// );
// const coronalVector = getVectorForOrientation(
//   'coronal',
//   imageOrientationPatient
// );
// const axialVector = getVectorForOrientation('axial', imageOrientationPatient);

// console.log('Sagittal Vector:', sagittalVector);
// console.log('Coronal Vector:', coronalVector);
// console.log('Axial Vector:', axialVector);

export const getPlaneVectorsForOrientation = (
  orientation,
  imageOrientationPatient
) => {
  imageOrientationPatient = getOrientationFromString(imageOrientationPatient);

  // Validate input
  if (
    !Array.isArray(imageOrientationPatient) ||
    imageOrientationPatient.length !== 6
  ) {
    console.error('ImageOrientationPatient must be an array of 6 numbers');
    return {
      u: [],
      v: [],
    };
  }
  const validOrientations = ['axial', 'sagittal', 'coronal'];
  if (!validOrientations.includes(orientation.toLowerCase())) {
    console.error('Orientation must be "axial", "sagittal", or "coronal"');
    return {
      u: [],
      v: [],
    };
  }

  // Extract row and column vectors
  const rowVector = [
    imageOrientationPatient[0],
    imageOrientationPatient[1],
    imageOrientationPatient[2],
  ];
  const colVector = [
    imageOrientationPatient[3],
    imageOrientationPatient[4],
    imageOrientationPatient[5],
  ];

  // Calculate normal vector (cross product)
  const normalVector = [
    rowVector[1] * colVector[2] - rowVector[2] * colVector[1], // x
    rowVector[2] * colVector[0] - rowVector[0] * colVector[2], // y
    rowVector[0] * colVector[1] - rowVector[1] * colVector[0], // z
  ];

  // Standard basis vectors
  const xAxis = [1, 0, 0]; // Sagittal normal (left-right)
  const yAxis = [0, 1, 0]; // Coronal normal (anterior-posterior)
  const zAxis = [0, 0, 1]; // Axial normal (superior-inferior)

  // Function to compute absolute dot product
  const dotProduct = (v1, v2) =>
    Math.abs(v1[0] * v2[0] + v1[1] * v2[1] + v1[2] * v2[2]);

  // Map orientations to their standard normal vectors
  const orientationMap = {
    sagittal: xAxis, // Plane perpendicular to X-axis
    coronal: yAxis, // Plane perpendicular to Y-axis
    axial: zAxis, // Plane perpendicular to Z-axis
  };

  const targetNormal = orientationMap[orientation.toLowerCase()];

  // Calculate dot products with target normal
  const rowScore = dotProduct(rowVector, targetNormal);
  const colScore = dotProduct(colVector, targetNormal);
  const normalScore = dotProduct(normalVector, targetNormal);

  // Find the vector most aligned with the requested orientation's normal
  const scores = [
    { vector: rowVector, score: rowScore },
    { vector: colVector, score: colScore },
    { vector: normalVector, score: normalScore },
  ];

  const bestMatch = scores.reduce((max, current) =>
    current.score > max.score ? current : max
  );

  // The two vectors that are NOT the normal are the plane vectors (u, v)
  const allVectors = [rowVector, colVector, normalVector];
  const planeVectors = allVectors.filter(
    vec =>
      vec[0] !== bestMatch.vector[0] ||
      vec[1] !== bestMatch.vector[1] ||
      vec[2] !== bestMatch.vector[2]
  );

  return {
    u: planeVectors[0], // First in-plane vector
    v: planeVectors[1], // Second in-plane vector
  };
};

// Example usage with your provided data

// const axialPlane = getPlaneVectorsForOrientation(
//   'axial',
//   imageOrientationPatient
// );
// const sagittalPlane = getPlaneVectorsForOrientation(
//   'sagittal',
//   imageOrientationPatient
// );
// const coronalPlane = getPlaneVectorsForOrientation(
//   'coronal',
//   imageOrientationPatient
// );

// console.log('Axial Plane Vectors:', axialPlane);
// console.log('Sagittal Plane Vectors:', sagittalPlane);
// console.log('Coronal Plane Vectors:', coronalPlane);

export const getPlaneVectors = imageOrientationPatient => {
  imageOrientationPatient = getOrientationFromString(imageOrientationPatient);

  const rowVector = [
    imageOrientationPatient[0],
    imageOrientationPatient[1],
    imageOrientationPatient[2],
  ];
  const colVector = [
    imageOrientationPatient[3],
    imageOrientationPatient[4],
    imageOrientationPatient[5],
  ];
  return {
    u: rowVector, // First in-plane vector
    v: colVector, // Second in-plane vector
  };
};

export const computeCameraVectors = (imageOrientationPatient, orientation) => {
  imageOrientationPatient = getOrientationFromString(imageOrientationPatient);
  if (
    !Array.isArray(imageOrientationPatient) ||
    imageOrientationPatient.length !== 6
  ) {
    console.error('ImageOrientationPatient must be an array of 6 numbers', {
      imageOrientationPatient,
    });
    return null;
  }
  const validOrientations = ['axial', 'sagittal', 'coronal'];
  if (!validOrientations.includes(orientation.toLowerCase())) {
    console.error('Orientation must be "axial", "sagittal", or "coronal"', {
      orientation,
    });
    return null;
  }

  const originalOrientation = detectMainView(imageOrientationPatient);
  if (!originalOrientation) return null;

  const u = [
    imageOrientationPatient[0],
    imageOrientationPatient[1],
    imageOrientationPatient[2],
  ];
  const v = [
    imageOrientationPatient[3],
    imageOrientationPatient[4],
    imageOrientationPatient[5],
  ];
  const w = [
    u[1] * v[2] - u[2] * v[1],
    u[2] * v[0] - u[0] * v[2],
    u[0] * v[1] - u[1] * v[0],
  ];

  const normalize = vec => {
    const mag = Math.sqrt(vec[0] * vec[0] + vec[1] * vec[1] + vec[2] * vec[2]);
    return mag > 0 ? [vec[0] / mag, vec[1] / mag, vec[2] / mag] : vec;
  };
  const negate = vec => [-vec[0], -vec[1], -vec[2]];
  const dotProduct = (v1, v2) => v1[0] * v2[0] + v1[1] * v2[1] + v1[2] * v2[2];

  const right = [1, 0, 0];
  const anterior = [0, 1, 0];
  const superior = [0, 0, 1];

  let originalRight = normalize(u);
  let originalDown = normalize(v);
  let originalNormal = normalize(w);

  switch (originalOrientation) {
    case 'axial':
      originalRight = normalize(u); // Right
      originalDown = normalize(v); // Anterior
      originalNormal = normalize(w); // Superior
      break;
    case 'coronal':
      originalRight = normalize(u); // Right
      originalDown = normalize(v); // Inferior
      originalNormal = normalize(w); // Anterior
      break;
    case 'sagittal':
      originalRight = normalize(u); // Anterior
      originalDown = normalize(v); // Inferior
      originalNormal = normalize(w); // Left
      break;
  }

  let viewPlaneNormal, viewUp;
  const requestedOrientation = orientation.toLowerCase();

  switch (requestedOrientation) {
    case 'axial':
      if (originalOrientation === 'axial') {
        viewPlaneNormal =
          dotProduct(originalNormal, superior) > 0
            ? negate(originalNormal)
            : originalNormal;
        viewUp = negate(originalDown);
      } else if (originalOrientation === 'coronal') {
        viewPlaneNormal = originalDown; // Inferior
        viewUp = negate(originalNormal); // Posterior
      } else if (originalOrientation === 'sagittal') {
        viewPlaneNormal =
          dotProduct(originalDown, superior) < 0
            ? originalDown // Inferior for Volume 3
            : negate(originalDown); // Superior for Volume 4
        viewUp = negate(originalRight); // Posterior
      }
      break;
    case 'coronal':
      if (originalOrientation === 'coronal') {
        viewPlaneNormal =
          dotProduct(originalNormal, anterior) > 0
            ? negate(originalNormal)
            : originalNormal;
        viewUp = negate(originalDown);
      } else if (originalOrientation === 'axial') {
        viewPlaneNormal = negate(originalDown); // Posterior
        viewUp = originalNormal; // Superior
      } else if (originalOrientation === 'sagittal') {
        viewPlaneNormal = negate(originalRight); // Posterior instead of Anterior
        viewUp = negate(originalDown); // Superior
      }
      break;
    case 'sagittal':
      if (originalOrientation === 'sagittal') {
        viewPlaneNormal =
          dotProduct(originalNormal, right) > 0
            ? originalNormal
            : negate(originalNormal);
        viewUp = negate(originalDown);
      } else if (originalOrientation === 'axial') {
        viewPlaneNormal = originalRight; // Right
        viewUp = originalNormal; // Superior
      } else if (originalOrientation === 'coronal') {
        viewPlaneNormal = originalRight; // Right
        viewUp = negate(originalDown); // Superior
      }
      break;
  }

  return {
    viewPlaneNormal: viewPlaneNormal,
    viewUp: viewUp,
  };
};

// Test with your data
// const axialView = computeCameraVectors(imageOrientationPatient, 'axial');
// const sagittalView = computeCameraVectors(imageOrientationPatient, 'sagittal');
// const coronalView = computeCameraVectors(imageOrientationPatient, 'coronal');

// console.log('Camera - Axial:', axialView);
// console.log('Camera - Sagittal:', sagittalView);
// console.log('Camera - Coronal:', coronalView);

export const setAxisOrientedOrientation = (
  orientation,
  viewport,
  default_orientation
) => {
  if (orientation === 'axial')
    viewport.setOrientation(Enums.OrientationAxis.AXIAL);
  else if (orientation === 'coronal')
    viewport.setOrientation(Enums.OrientationAxis.CORONAL);
  else if (orientation === 'sagittal')
    viewport.setOrientation(Enums.OrientationAxis.SAGITTAL);
  else if (default_orientation !== 'none') {
    if (default_orientation === 'axial')
      viewport.setOrientation(Enums.OrientationAxis.AXIAL);
    else if (default_orientation === 'coronal')
      viewport.setOrientation(Enums.OrientationAxis.CORONAL);
    else if (default_orientation === 'sagittal')
      viewport.setOrientation(Enums.OrientationAxis.SAGITTAL);
  }
};
export const getNewOrientationLetters = (position, THIS) => {
  const { loaded, orientations } = THIS.state;
  return computeOrientationLetters(position, loaded, orientations);
};
export const computeOrientationLetters = (position, loaded, orientations) => {
  const total_orientation = orientations.length;
  if (total_orientation > 1 && loaded.length === total_orientation) {
    const imageId = loaded[position];
    let idFile = imageId.replace('wadouri:http://localhost:4000/dicom/', '');
    idFile = parseInt(idFile, 10);
    const index = orientations.findIndex(x => x.idFile === idFile);
    if (index !== -1) {
      const ImageOrientationPatient = orientations[index].Value;
      return orientationLettersImOriPat(ImageOrientationPatient);
    }
  }
  return null;
};
