import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { AppBar, Hidden, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import InputIcon from '@mui/icons-material/Input';
import Typography from '@mui/material/Typography';
import { LatestMessages, LatestNotifications, useStyles } from './components';
import { MessagesSubscription, NotificationQuery } from 'graphql/Subscriptions';
import { DivGrow } from 'components';
import { IsNormalUser } from 'common';
import { withStyles } from 'tss-react/mui';
import { MedicalBlocksLogo } from 'layouts/components';
import { connect } from 'react-redux';
import { setDicomViewer } from 'redux-store/actions';

class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.holder = {
      handleReload: null,
      handleReloadMessages: null,
    };
  }
  handleClickTopbar = page => {
    const { isDicomViewer } = this.props;
    if (isDicomViewer) {
      localStorage.setItem('page', page);
      this.props.setDicomViewer();
    } else if (localStorage.page) {
      localStorage.removeItem('page');
    }
  };
  render() {
    const {
      classes,
      className,
      onSidebarOpen,
      onSidebarSignOut,
      // eslint-disable-next-line no-unused-vars
      isDesktop,
      user,
      // eslint-disable-next-line no-unused-vars
      shouldOpenSidebar,
      minSidebar,
      // eslint-disable-next-line no-unused-vars
      setMiniSidebar,
      handleAddError,
      openSidebar,
      // eslint-disable-next-line no-unused-vars
      isDicomViewer,
      // eslint-disable-next-line no-unused-vars
      setDicomViewer,
      ...rest
    } = this.props;
    let ComponentMessages = null;
    if (IsNormalUser(user)) {
      ComponentMessages = (
        <LatestMessages
          direct_to="/communications"
          handleAddError={handleAddError}
          holder={this.holder}
          loading={this.props.loading}
          setState={state => this.setState(state)}
        />
      );
    }
    let ComponentMenu = null;
    if (minSidebar) {
      ComponentMenu = (
        <IconButton
          color="inherit"
          onClick={() => this.props.setMiniSidebar(false)}
          size="large"
          style={{ paddingRight: 0, marginRight: 0, left: -25 }}>
          <MenuIcon />
        </IconButton>
      );
    }
    return (
      <AppBar {...rest} className={clsx(classes.root, className)}>
        <Toolbar>
          {ComponentMenu}
          <RouterLink to="/">
            <MedicalBlocksLogo onClick={this.handleClickTopbar} />
          </RouterLink>
          <MessagesSubscription
            handleAddError={handleAddError}
            holder={this.holder}
            setState={state => this.setState(state)}
            user={this.props.user}
          />
          <NotificationQuery
            handleAddError={handleAddError}
            holder={this.holder}
            setState={state => this.setState(state)}
          />
          <DivGrow />
          <Hidden lgDown>
            {ComponentMessages}
            <LatestNotifications
              direct_to="/account/activity"
              holder={this.holder}
              icon={<NotificationsIcon />}
              loading={this.state.loading}
              menu_arrow="55.5%"
              setState={state => this.setState(state)}
              title="Latest Notifications"
            />
            <IconButton
              className={classes.signOutButton}
              color="inherit"
              onClick={onSidebarSignOut}
              size="large">
              <InputIcon />
              <Typography
                className={classes.sign_out}
                display="block"
                variant="button">
                {' sign out'}
              </Typography>
            </IconButton>
          </Hidden>
          <Hidden lgUp>
            {!minSidebar && (
              <IconButton color="inherit" onClick={onSidebarOpen} size="large">
                {openSidebar ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
            )}
          </Hidden>
        </Toolbar>
      </AppBar>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

const mapStateToProps = state => {
  let { openSidebar, isDicomViewer } = state.page_globals;
  return {
    openSidebar,
    isDicomViewer,
  };
};

export default connect(mapStateToProps, { setDicomViewer })(
  withStyles(Topbar, useStyles)
);
