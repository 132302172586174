import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  TablePagination,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';

import { EditCenterComponents, EditCreateBottom } from './components';
import { GroupBox, CardEditHeader } from 'components';
import { useStyles } from './components/utils';
import { MainTable } from 'components/Tables/components';
import { columns } from 'components/Tables/TableCenters/utils';
import { rowsPerPageSmall } from 'helpers';

class EditCenter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      center: null,
      original: null,
      page: 0,
      limit: 5,
    };
    this.holder = {
      getCenter: () => this.state.center,
      setDisableAll: disable_all => {
        this.setState({ disable_all });
      },
      resetCenter: () => {
        // this.setState({
        //   center: {
        //     ...this.props.center,
        //   },
        // });
      },
    };
  }

  handleChange = event => {
    const { center } = this.state;
    const key = event.target.name;
    if (key === 'name') {
      center.name = event.target.value;
    } else if (key === 'FullName') {
      center.admin.FullName = event.target.value;
    } else if (key === 'city') {
      center.address.city = event.target.value;
    } else if (key === 'line_1') {
      center.address.line_1 = event.target.value;
    }
    this.setState({ center });
  };
  handleCountryChanged = selected => {
    if (!selected) {
      return;
    }
    const { center } = this.state;
    center.address.country = selected.name;
    this.setState({ center });
  };
  handleAddUser = user => {
    const { center } = this.state;
    center.idAdmin = user.id;
    center.admin = user;
    this.setState({ center });
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.setState({ page: 0, limit: +event.target.value });
  };

  handleSelectedRow = center => {
    this.setState({
      center: {
        ...center,
        address: { ...center.address },
        admin: { ...center.admin },
      },
      original: {
        ...center,
        address: { ...center.address },
        admin: { ...center.admin },
      },
    });
  };
  handleUpdatedCenter = center => {
    this.setState({ center: null });
    this.props.handleUpdatedCenter(center);
  };

  render() {
    const { classes, selected } = this.props;
    let { center, disable_all } = this.state;
    let name = '';
    let country = '';
    let city = '';
    let line_1 = '';
    let admin = null;
    if (center) {
      name = center.name;
      if (center.address) {
        country = center.address.country;
        city = center.address.city;
        line_1 = center.address.line_1;
      }
      if (center.admin) admin = center.admin;
    } else {
      disable_all = true;
    }
    let ComponentEditCenter = null;
    if (center) {
      ComponentEditCenter = (
        <EditCenterComponents
          admin={admin}
          city={city}
          country={country}
          disable_all={disable_all}
          handleAddUser={this.handleAddUser}
          handleChange={this.handleChange}
          handleCountryChanged={this.handleCountryChanged}
          line_1={line_1}
          name={name}
        />
      );
    }
    return (
      <Card className={classes.card}>
        <CardEditHeader
          handleClose={this.props.handleClose}
          title="Edit Center"
        />
        <Divider />
        <CardContent>
          <Grid item style={{ paddingTop: 10 }} xs={12}>
            <MainTable
              columns={columns}
              dense
              disable_checkbox
              handleSelectedRow={this.handleSelectedRow}
              limit={this.state.limit}
              page={this.state.page}
              rows={selected}
            />
            <TablePagination
              component="div"
              count={selected.length}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              page={this.state.page}
              rowsPerPage={this.state.limit}
              rowsPerPageOptions={rowsPerPageSmall}
            />
          </Grid>
          <GroupBox
            style={{ minHeight: 200 }}
            title_box="Click a Row to Selected Center to Edit">
            {ComponentEditCenter}
          </GroupBox>
        </CardContent>
        <CardActions disableSpacing>
          <EditCreateBottom
            handleAddError={this.props.handleAddError}
            handleUpdatedCenter={this.handleUpdatedCenter}
            holder={this.holder}
            original={this.state.original}
          />
        </CardActions>
      </Card>
    );
  }
}

EditCenter.propTypes = {
  classes: PropTypes.object,
  handleUpdatedCenter: PropTypes.func,
};
EditCenter.propTypes = {
  handleUpdatedCenter: () => '',
};
export default withStyles(EditCenter, useStyles);
