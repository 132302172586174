/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  useTheme,
} from '@mui/material';
import { withRouter } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';

import { getLevels, getSorting, stableSort } from 'helpers';
import { columns_explorer } from 'helpers/explorer';
import {
  ExplorerViewListHeader,
  ExplorerViewListItem,
  useStyles,
} from './components';
import { setExplorerFolders } from 'redux-store/actions';
import { ExplorerFloatingMenu } from 'views/Explorer/components/ExplorerViews/components';
import { CircularProgressWaiting } from 'components';
class ExplorerViewListRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      dense: false,
      downloading: null,
      height_row: 58,
      contextMenu: null,
      itemOver: null,
      total_folder_objects: 0,
      mouse_over: false,
      rerender: false,
      rename_folder: null,
    };
    if (props.holder !== undefined) {
      props.holder.renderAgain = () => {
        console.log('forceUpdate');
        this.setState({ rerender: !this.state.rerender });
        this.forceUpdate();
      };
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate() {
    this.updateWindowDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    const { open_explorer } = this.props;
    let new_height_row = this.state.height_row;
    if (window.innerWidth < 723 && open_explorer) {
      new_height_row = 80;
    } else if (window.innerWidth < 568 && !open_explorer) {
      new_height_row = 80;
    } else if (new_height_row === 80) {
      new_height_row = 58;
    }
    if (new_height_row === this.state.height_row) {
      return;
    }

    this.setState({ width: window.innerWidth, height_row: new_height_row });
  };
  setSelected = () => {
    // this.setState({ selected: newSelected });
    // this.props.handleSelected(newSelected.length);
  };
  setPage = () => {
    // this.setState({ page: newPage });
  };
  setDense = newDense => {
    this.setState({ dense: newDense });
  };
  setRowsPerPage = newRowsPerPage => {
    this.setState({ rowsPerPage: newRowsPerPage });
  };

  handleRequestSort = (event, property) => {
    event.preventDefault();
    const { orderBy, order } = this.state;
    const isDesc = orderBy === property && order === 'desc';
    this.setState({
      orderBy: property,
      order: isDesc ? 'asc' : 'desc',
    });
  };
  isDownloading = value => {
    const { downloading } = this.state;
    if (!downloading) return false;
    const { Folder, FolderContent } = value;
    if (Folder) return false;
    if (FolderContent) {
      const { Series, File } = FolderContent;
      if (Series) {
        return false;
      } else if (File && downloading) {
        return downloading.idFolderContent === FolderContent.idFolderContent;
      }
      return false;
    }
  };
  handleOpenFolder = folder => {
    if (!folder) {
      return;
    }
    let { idFolder, folders_paths } = folder;
    if (folders_paths && folders_paths.length) {
      if (folders_paths[0].name === 'Home') {
        folders_paths.splice(0, 1);
      }
      this.props.setExplorerFolders(folders_paths);
    }
    if (idFolder) {
      const { level_1 } = getLevels(this.props);
      if (level_1 === 'shared') {
        this.props.history.push(`/explorer/shared/folder/${idFolder}`);
      } else {
        this.props.history.push(`/explorer/folder/${idFolder}`);
      }
    }
  };
  handleCurrentFolderChanged = () => {
    const { Folder } = this.props;
    const { SubFolders, Files } = Folder;

    const total_folder_objects = SubFolders.length + Files.length;
    this.setState({ total_folder_objects });
  };
  handleContextMenu = (event, itemOverNew) => {
    event.preventDefault();
    const { contextMenu } = this.state;
    this.setState({
      itemOver: itemOverNew ? itemOverNew : null,
      contextMenu:
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
    });
  };
  handleOnRowClick = itemOver => {
    this.props.handleOnRowClick(itemOver);
    if (this.state.contextMenu && !itemOver) return;
    this.setState({ itemOver });
  };
  IsContextMenu = row => {
    const { itemOver } = this.state;
    if (!itemOver || itemOver === undefined) return false;
    const a = `${itemOver.idFolder}${itemOver.idFolderContent}`;
    const b = `${row.idFolder}${row.idFolderContent}`;
    return a === b;
  };
  handleRenameFolder = (status, rename_folder) => {
    if (rename_folder === undefined || !rename_folder) return;
    this.props.handleRenameFolder(status, rename_folder);
    this.setState({ rename_folder });
  };
  render() {
    const {
      classes,
      page,
      SubFolders,
      Files,
      rowsPerPage,
      loading,
      selected_objects,
      handleSelectAll,
      height,
      open_explorer,
      handleDrawerClose,
      cut_objects,
      isMobile,
      isMobileSmall,
    } = this.props;
    const { rename_folder } = this.state;
    const cutObjectsIds = cut_objects
      .filter(x => x.idFolderContent)
      .map(x => x.idFolderContent);
    const cutFolderIds = cut_objects
      .filter(x => x.idFolder)
      .map(x => x.idFolder);
    let rows = SubFolders.filter(x => cutFolderIds.indexOf(x.idFolder) === -1);
    if (rename_folder) {
      const index = rows.map(x => x.idFolder).indexOf(rename_folder.idFolder);
      if (index !== -1) {
        rows[index] = { ...rows[index], ...rename_folder };
      }
    }
    rows = rows.concat(
      Files.filter(x => cutObjectsIds.indexOf(x.idFolderContent) === -1)
    );
    const { dense, order, orderBy, mouse_over } = this.state;
    const height_table = height - 240;
    return (
      <div onContextMenu={mouse_over ? null : this.handleContextMenu}>
        <TableContainer
          className={classes.root_table_container}
          style={{ height: height_table, overflow: 'auto' }}>
          <Table
            aria-label="explorer view list table"
            aria-labelledby="tableExplorerViewList"
            className={classes.table}
            onMouseEnter={() => this.setState({ mouse_over: true })}
            onMouseLeave={() => this.setState({ mouse_over: false })}
            size={dense ? 'small' : 'medium'}
            stickyHeader
            style={{ height: 55, overflow: 'auto', whiteSpace: 'nowrap' }}>
            <ExplorerViewListHeader
              columns={columns_explorer}
              handleDrawerClose={handleDrawerClose}
              handleRequestSort={this.handleRequestSort}
              handleSelectAll={handleSelectAll}
              isMobileSmall={isMobileSmall}
              open_explorer={open_explorer}
              rights={false}
              rowCount={rows.length}
              selected_objects={selected_objects}
            />
            <TableBody style={{ height: '100%', overflow: 'auto' }}>
              {loading ? (
                <TableRow style={{ height: (dense ? 33 : 53) * 4 }}>
                  <TableCell colSpan={5}>
                    <CircularProgressWaiting />
                  </TableCell>
                </TableRow>
              ) : (
                stableSort(rows, getSorting(order, orderBy, columns_explorer))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = this.props.isSelected(row);
                    const downloading = this.isDownloading(row);
                    const isContextMenu = this.IsContextMenu(row);
                    return (
                      <ExplorerViewListItem
                        columns={columns_explorer}
                        dense
                        disabled={this.state.downloading ? true : false}
                        downloading={downloading}
                        handleContentView={this.handleContentView}
                        handleOnRowClick={this.handleOnRowClick}
                        handleOpenAtDoubleClick={
                          this.props.handleOpenAtDoubleClick
                        }
                        handleOpenFolder={this.handleOpenFolder}
                        handleSelected={this.props.handleSelected}
                        idHomeFolder={this.props.idHomeFolder}
                        isContextMenu={isContextMenu}
                        isItemSelected={isItemSelected}
                        isMobile={isMobile}
                        isMobileSmall={isMobileSmall}
                        key={`row-table-project-${index}`}
                        labelId={`table-checkbox-${index}`}
                        onContextMenu={this.handleContextMenu}
                        open_explorer={open_explorer}
                        rights={false}
                        row={row}
                      />
                    );
                  })
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <ExplorerFloatingMenu
          cleanSelections={this.props.cleanSelections}
          contextMenu={this.state.contextMenu}
          Folder={this.props.Folder}
          handleAddError={this.props.handleAddError}
          handleCleanItemOver={() => {
            this.setState({ itemOver: null });
          }}
          handleClose={() => {
            this.setState({ contextMenu: null, itemOver: null });
          }}
          handleCurrentFolderChanged={this.handleCurrentFolderChanged}
          handleDeleteFoldersAndContent={
            this.props.handleDeleteFoldersAndContent
          }
          handleItemChanged={this.props.handleItemChanged}
          handleProgress={this.props.handleProgress}
          handleRenameFolder={this.handleRenameFolder}
          itemOver={this.state.itemOver}
          selected_objects={this.props.selected_objects}
        />
      </div>
    );
  }
}

ExplorerViewListRight.propTypes = {
  classes: PropTypes.object,
};
ExplorerViewListRight.defaultProps = {
  handleSelectAll: () => '',
  handleSelected: () => '',
  handleOnRowClick: () => '',
  isSelected: () => '',
  loading: false,
};
const mapStateToProps = state => {
  const { status } = state.page_explorer;
  let default_page = 0;
  let default_rowsPerPage = 500;
  if (status) {
    if (status.page) default_page = status.page;
    if (status.rowsPerPage) default_rowsPerPage = status.rowsPerPage;
  }
  return {
    page: default_page,
    rowsPerPage: default_rowsPerPage,
  };
};
const ContainerExplorerViewListRight = props => {
  const theme = useTheme();
  return <ExplorerViewListRight theme={theme} {...props} />;
};
export default connect(mapStateToProps, { setExplorerFolders })(
  withStyles(withRouter(ContainerExplorerViewListRight), useStyles)
);
