import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@mui/material';
import { withStyles } from 'tss-react/mui';
// import { ZoomOutMap } from '@mui/icons-material';
import { CardHeaderCustom } from 'components';
import { FormatTime } from 'helpers';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { DialogUnitName } from './components';

const useStyles = theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  margin: {
    margin: theme.spacing(0, 1, 1, 2),
    padding: 0,
  },
  display: {
    display: 'flex',
  },
});

class CardUnitHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
      open: false,
    };
  }

  toggleHover = () => {
    this.setState(prev => ({ hover: !prev.hover }));
  };
  handleClickEdit = () => {
    this.setState(prev => ({ open: !prev.open }));
  };
  handleReName = name => {
    const { handleReNameUnit } = this.props;
    this.setState({ open: false });
    handleReNameUnit(name);
  };

  render() {
    const { classes, createdAt, updatedAt, edit } = this.props;
    const { hover, open } = this.state;
    return (
      <React.Fragment>
        <CardHeaderCustom
          action={
            <React.Fragment>
              <div className={classes.root}>
                {`Updated: ${FormatTime(updatedAt, 'MMM Do, YYYY (HH:mm a)')}`}
              </div>
            </React.Fragment>
          }
          style={{ paddingBottom: '10px', marginBottom: 0 }}
          subheader={
            <div className={classes.display}>
              {`Created: ${FormatTime(createdAt, 'MMM Do, YYYY (HH:mm a)')}`}
            </div>
          }
          sx={{ paddingTop: '5px' }}
          title={
            <Grid
              container
              direction="row"
              onMouseEnter={this.toggleHover}
              onMouseLeave={this.toggleHover}>
              {this.props.title}
              {edit && hover && (
                <IconButton
                  aria-label="delete"
                  className={classes.margin}
                  onClick={this.handleClickEdit}
                  size="small">
                  <BorderColorIcon fontSize="inherit" />
                </IconButton>
              )}
            </Grid>
          }
        />
        <DialogUnitName
          handleClose={this.handleClickEdit}
          handleReName={this.handleReName}
          name={this.props.title}
          open={open}
        />
      </React.Fragment>
    );
  }
}

CardUnitHeader.propTypes = {
  classes: PropTypes.object,
};
CardUnitHeader.defaultProps = {
  title: 'Title Missing',
  subtitle: 'SubTitle Missing',
  edit: true,
};

export default withStyles(CardUnitHeader, useStyles);
