import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CardActionArea,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Paper,
  Typography,
  IconButton,
} from '@mui/material';
import clsx from 'clsx';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import OpenIcon from '@mui/icons-material/ZoomOutMap';
import { copySeries } from 'redux-store/actions/file_folder_manager';

import {
  GetData,
  getStudyDate,
  getStudyPatientID,
  getStudyPatientName,
  getStudyTime,
  IsUserViewer,
} from 'helpers';
import { StudyThumbnails } from '../index';
import { ButtonDeleteStudy, ButtonDownloadStudy } from './components';
import { IconButtonLoad, RowText } from 'components';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { MutationUpdateStudyCounts } from 'graphql/Study/utils_study';
import { setDisplayDicomStudy } from 'redux-store/actions';
import { blue } from '@mui/material/colors';
import { IsNormalUser } from 'common';
const useStyles = () => ({
  root: {
    maxWidth: 345,
  },
  card_selected: {
    border: `2px solid ${blue[200]}`,
  },
  media: {
    height: 140,
  },
  card_actions: {
    maring: 0,
    padding: 0,
  },
});

class StudyCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isSelected: false,
    };
  }

  updateCounts = () => {
    const { Study } = this.props;
    const { loading } = this.state;
    if (loading || Study === undefined || !Study) return;
    const { idStudy } = Study;
    this.setState({ loading: true });
    (async () => {
      MutationUpdateStudyCounts([idStudy])
        .then(res => {
          const data = GetData(res);
          const { ok, studies, errors } = data.updateStudyCounts;
          if (ok) {
            this.props.handleUpdateStudies(studies);
            this.setState({ loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  handleViewStudy = () => {
    const { Study } = this.props;
    this.props.setDisplayDicomStudy(Study);
    this.props.history.push(`/dicom_viewer/study/${Study.idStudy}`);
  };
  handleCardClicked = event => {
    event.preventDefault();
    const { Study } = this.props;
    if (Study === undefined || !Study) return;
    const { Series } = Study;
    if (Series === undefined || !Series || !Series.length) return;
    const { copy_series } = this.props;
    for (let i = 0; i < Series.length; i++) {
      const index = copy_series
        .map(x => x.idSeries)
        .indexOf(Series[i].idSeries);
      if (index === -1) copy_series.push(Series[i]);
      else {
        copy_series.splice(index, 1);
      }
    }
    this.props.copySeries(copy_series);
  };
  isSelected = () => {
    const { Study } = this.props;
    if (Study === undefined || !Study) return false;
    const { Series } = Study;
    if (Series === undefined || !Series || !Series.length) return false;
    const { copy_series } = this.props;
    let total = 0;
    for (let i = 0; i < Series.length; i++) {
      const index = copy_series
        .map(x => x.idSeries)
        .indexOf(Series[i].idSeries);
      if (index !== -1) total++;
    }
    return total > 0;
  };
  render() {
    const { Study, classes, healthy, warnings, user } = this.props;
    const {
      idStudy,
      StudyDescription,
      Series,
      NumberOfStudyRelatedSeries,
      NumberOfStudyRelatedInstances,
    } = Study;
    let sequences = [];
    if (Series) {
      let f = [];
      sequences = Series.map(x => x.sequence)
        .filter(x => x)
        .map(x => {
          if (f.indexOf(x.name) !== -1) return null;
          f.push(x.name);
          return x.name;
        })
        .filter(x => x);
    }
    let title_short = StudyDescription;
    if (StudyDescription.length > 24) {
      title_short = StudyDescription.substring(0, 24) + '...';
    }
    let PatientID = getStudyPatientID(Study);
    if (PatientID.length > 31) {
      PatientID = PatientID.substring(0, 31) + '...';
    }
    let HealtyWarning = null;
    let left = 7;
    if (!healthy) {
      let tooltip = 'Warning Study files';
      if (warnings && warnings.length) {
        tooltip = warnings.join('\n');
      }
      left = 6;
      HealtyWarning = (
        <Grid item xs={3}>
          <IconButtonLoad
            disabled={this.state.loading}
            handleClick={this.updateCounts}
            icon={<ErrorOutlineIcon style={{ color: 'orange' }} />}
            loading={this.state.loading}
            tooltip={tooltip}
          />
        </Grid>
      );
    }
    let DownloadDeleteButtons = null;
    if (IsNormalUser(this.props.user)) {
      DownloadDeleteButtons = (
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="flex-end"
          xs={left}>
          <Grid item xs={6}>
            <ButtonDownloadStudy Study={Study} />
          </Grid>
          <Grid item xs={6}>
            <ButtonDeleteStudy
              handleStudyDeleted={this.props.handleStudyDeleted}
              question_text={
                'This action will delete the seleted Study and its files. This action can not be undone.'
              }
              shared
              Study={Study}
              title_text={'Are you sure that you want to delete this Study?'}
            />
          </Grid>
        </Grid>
      );
    }
    let ComponentOpenDicomViewer = null;
    if (IsUserViewer(user)) {
      ComponentOpenDicomViewer = (
        <Grid item xs={2}>
          <IconButton onClick={this.handleViewStudy} size="large">
            <OpenIcon />
          </IconButton>
        </Grid>
      );
    }
    const isSelected = this.isSelected();
    let sequence_names = '0';
    const total_sequences = sequences.length;
    if (total_sequences) {
      if (total_sequences > 3) {
        sequences.splice(2);
        sequence_names = `${sequences.join(', ')}, ${total_sequences -
          2} more...`;
      } else {
        sequence_names = sequences.join(', ');
      }
    }
    const style = { fontWeight: 600 };
    return (
      <Paper
        // className={classes.root}
        className={clsx(classes.root, {
          [classes.card_selected]: isSelected,
        })}
        elevation={3}
        onClick={this.handleCardClicked}>
        <CardActionArea>
          <StudyThumbnails loading={this.props.loading} Series={Series} />
          <CardContent>
            <Typography component="h2" gutterBottom variant="h5">
              {title_short}
            </Typography>
            <Divider />
            <RowText
              first="Patient Name:"
              second={getStudyPatientName(Study)}
              style={{ marginTop: '10px' }}
              styleBox={style}
            />
            <RowText first="PatientID:" second={PatientID} styleBox={style} />
            <Grid container direction="row">
              <RowText
                first="Series:"
                second={NumberOfStudyRelatedSeries}
                styleBox={style}
              />
              <RowText
                first=", Files:"
                second={NumberOfStudyRelatedInstances}
                styleBox={style}
              />
            </Grid>
            <RowText
              first="Sequences:"
              second={sequence_names}
              styleBox={style}
            />
            <RowText
              first="Date:"
              second={getStudyDate(Study)}
              styleBox={style}
            />
            <RowText
              first="Time:"
              second={getStudyTime(Study)}
              styleBox={style}
            />
          </CardContent>
        </CardActionArea>
        <Divider />
        <CardActions className={classes.card_actions}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between"
            style={{ width: '100%' }}>
            <Grid item xs={3}>
              <Button
                color="primary"
                onClick={() => {
                  this.props.history.push(`/image_analysis/study/${idStudy}`);
                }}
                size="small">
                View
              </Button>
            </Grid>
            {ComponentOpenDicomViewer}
            {HealtyWarning}
            {DownloadDeleteButtons}
          </Grid>
        </CardActions>
      </Paper>
    );
  }
}

StudyCard.propTypes = {
  classes: PropTypes.object,
  handleUpdateStudies: PropTypes.func.isRequired,
  healthy: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  let { study } = state.page_dicom_viewer;
  const { copy_series } = state.manager;
  const { user } = state.info_user;
  return {
    user,
    view_study: study,
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps, { setDisplayDicomStudy, copySeries })(
  withStyles(withRouter(StudyCard), useStyles)
);
