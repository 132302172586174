import React from 'react';
import PropTypes from 'prop-types';
import { Drawer, Grid, IconButton } from '@mui/material';
import { withStyles } from 'tss-react/mui';
// import { grey } from '@mui/material/colors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { connect } from 'react-redux';
import { useTheme } from '@mui/material/styles';

import { CircularProgressWaiting, DivGrow } from 'components';
import { ExplorerViewListTree, useStyles } from './components';
import { GetData, ServerErrors } from 'helpers';
import { QueryHomeFolder, QueryHomeShareFolder } from 'graphql/Folders';
import { setExplorerHome, setExplorerChanged } from 'redux-store/actions';
import { IsNotChangeInternally } from 'helpers/explorer';
import { IsInvalid } from 'helpers';
const CountSubFolders = (folders, total = 0) => {
  if (IsInvalid(folders)) return total;
  for (let i = 0; i < folders.length; i++) {
    total = total + folders[i].total_sub_folders;
    total = CountSubFolders(folders[i].SubFolders, total);
  }
  return total;
};
class ExplorerViewListLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      home_change: false,
      open: true,
      loading: false,
      error: null,
    };
  }

  loadTreeFolders = () => {
    const { LocalHomeFolder, home_change } = this.props;
    if (
      IsNotChangeInternally(this.props) &&
      this.state.home_change === home_change
    ) {
      return;
    }
    const { error } = this.state;
    if (!LocalHomeFolder && !error) {
      this.handleLoadHome();
    }
  };

  componentDidMount() {
    this.loadTreeFolders();
  }

  componentDidUpdate() {
    this.loadTreeFolders();
  }

  handleDrawerClose = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };
  QueryMainHome = () => {
    const { base } = this.props;
    if (base === 'Home') {
      return QueryHomeFolder();
    } else {
      return QueryHomeShareFolder();
    }
  };
  handleLoadHome = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      const { base } = this.props;
      this.QueryMainHome()
        .then(res => {
          const data = GetData(res);
          let query_function = data.home;
          if (base === 'Shared') {
            query_function = data.shared;
          }
          const { ok, Folder, errors } = query_function;
          if (ok) {
            this.props.setExplorerHome(Folder);
            // this.props.setExplorerChanged(this.props.home_change);
            this.setState({
              loading: false,
              home_change: this.props.home_change,
            });
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          const list_errors = ServerErrors(errors);
          this.setState({ loading: false, error: list_errors.join('\n') });
        });
    })();
  };
  render() {
    const {
      classes,
      theme,
      open_explorer,
      handleDrawerClose,
      base,
      LocalHomeFolder,
    } = this.props;
    let { HomeFolder } = this.props;
    const { loading } = this.state;
    if (!loading && !HomeFolder && LocalHomeFolder) {
      HomeFolder = LocalHomeFolder;
    }
    let SubFolders = [];
    if (LocalHomeFolder) {
      SubFolders = LocalHomeFolder.SubFolders;
      // console.log('LocalHomeFolder', { SubFolders });
    } else if (HomeFolder) {
      SubFolders = HomeFolder.SubFolders;
      // console.log('HomeFolder', { SubFolders });
    }
    let total_subfolder = CountSubFolders(SubFolders);
    // console.log({ total_subfolder });
    return (
      <Drawer
        anchor="left"
        className={classes.root}
        open={open_explorer}
        variant="persistent">
        <Grid
          container
          item
          style={{ width: '100%', height: 56, backgroundColor: '#f9f9f9' }}>
          <DivGrow />
          <IconButton
            className={classes.button}
            onClick={handleDrawerClose}
            size="large">
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </Grid>
        {loading ? (
          <CircularProgressWaiting />
        ) : (
          <ExplorerViewListTree
            base={base}
            handleAddError={this.props.handleAddError}
            idHomeFolder={
              HomeFolder ? HomeFolder.idFolder : this.props.idHomeFolder
            }
            SubFolders={SubFolders}
            total_subfolder={total_subfolder}
            user={this.props.user}
          />
        )}
      </Drawer>
    );
  }
}

ExplorerViewListLeft.propTypes = {
  classes: PropTypes.object,
};
ExplorerViewListLeft.defaultProps = {
  HomeFolder: null,
};
const mapStateToProps = state => {
  // console.log(state)
  const { view_type, LocalHomeFolder, home_change } = state.page_explorer;
  // console.log({ home_change });
  return {
    view_type,
    LocalHomeFolder,
    home_change,
  };
};
const ContainerExplorerViewListLeft = props => {
  const theme = useTheme();
  return <ExplorerViewListLeft theme={theme} {...props} />;
};
export default connect(mapStateToProps, {
  setExplorerHome,
  setExplorerChanged,
})(withStyles(ContainerExplorerViewListLeft, useStyles));
