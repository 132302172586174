import { IsInvalid } from 'helpers';
import { wait } from './utils';

export const setupHeightDetection = THIS => {
  try {
    if (typeof window !== 'undefined' && window.ResizeObserver) {
      THIS.resizeHandler = new window.ResizeObserver(entries => {
        if (entries[0]) {
          const height = entries[0].contentRect.height;
          const width = entries[0].contentRect.width;
          if (height !== THIS.state.height || width !== THIS.state.width) {
            THIS.setState({ height, width });
          }
        }
      });

      if (THIS.elementRef.current) {
        THIS.resizeHandler.observe(THIS.elementRef.current);
      }
    }
  } catch (error) {
    console.warn(
      'ResizeObserver not available, falling back to window resize event'
    );
  }
};

export const cleanupHeightDetection = THIS => {
  try {
    if (THIS.resizeHandler) {
      THIS.resizeHandler.disconnect();
      THIS.resizeHandler = null;
    }
  } catch (error) {
    console.warn('Error cleaning up resize detection:', error);
  }
};
// Clean up the current volume to prepare for loading a new one
export const cleanupCurrentVolume = async (THIS, annotationManager, cache) => {
  let { series } = THIS.state;

  if (IsInvalid(series)) {
    return;
  }
  let currentVolumeId = `cornerstoneStreamingImageVolume:${series.idSeries}`;
  try {
    // Remove annotations associated with this volume
    if (annotationManager.getAnnotations) {
      const annotations = annotationManager.getAnnotations();
      if (annotations) {
        Object.keys(annotations).forEach(toolName => {
          const toolAnnotations = annotations[toolName];
          if (toolAnnotations && toolAnnotations.length) {
            toolAnnotations.forEach(annotation => {
              annotationManager.removeAnnotation(annotation.annotationUID);
            });
          }
        });
      }
    }
    // Clear the volume from cache
    if (cache.getVolume(currentVolumeId)) {
      console.log('cleanupCurrentVolume - volume found', currentVolumeId);
      await cache.removeVolumeLoadObject(currentVolumeId);
    } else {
      console.log('cleanupCurrentVolume - no found');
    }

    // Reset state related to the volume
    THIS.setState({
      volume: null,
      loaded: [],
      position: 0,
      selectedAnnotationUID: null,
      cleanupCurrentVolume: null,
    });
    await wait(500);
  } catch (error) {
    console.error('Error cleaning up current volume:', error);
  }
};
export const updateWidthHeight = (viewportId, THIS) => {
  if (!THIS.elementRef.current) {
    return;
  }
  const containerHeight = THIS.elementRef.current.clientHeight;
  const containerWidth = THIS.elementRef.current.clientWidth;
  if (
    containerWidth !== THIS.state.height ||
    containerHeight !== THIS.state.width
  ) {
    THIS.setState({ height: containerHeight, width: containerWidth });

    // Update viewport if available
    if (THIS.renderingEngine) {
      try {
        const viewport = THIS.renderingEngine.getViewport(viewportId);
        if (viewport) {
          // Resize the rendering engine's viewport to match container
          THIS.renderingEngine.resize();
          viewport.render();
        }
      } catch (error) {
        console.warn('Error resizing viewport:', error);
      }
    }
  }
};
