import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
const QUERY_SERIES_BY_IDS = gql`
  query($ids: [LongLong!]!, $page: Int, $limit: Int) {
    allSeriesByIDs(ids: $ids, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      Series {
        idSeries
        SeriesInstanceUID
        SeriesNumber
        SeriesDate
        SeriesTime
        SeriesDescription
        ContrastBolusAgent

        NumberOfSeriesRelatedInstances
        ImageType
        Modality

        ImageType
        NumberOfFrames

        Study {
          StudyDescription
          StudyDate
          MagneticFieldStrength
        }
        Patient {
          PatientName
          PatientBirthDate
          PatientID
        }
        ImageSize {
          Rows
          Columns
          Size
        }
        ImageResolution {
          PixelSpacingX
          PixelSpacingY
          SpacingBetweenSlices
          SliceThickness
          Spacing
        }
        ImageMr {
          ProtocolName
          SequenceName
          EchoNumbers
          EchoTime
          RepetitionTime
          InversionTime
        }
        ImagePlane {
          ImageOrientationPatient
        }
        Thumbnail

        createdAt
        updatedAt
      }
    }
  }
`;

export const QuerySeriesByIDs = (ids, page = null, limit = null) => {
  console.log('QuerySeries');
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES_BY_IDS),
    variables: {
      ids,
      page,
      limit,
    },
  });
};
const QUERY_SERIES_FILE_IDS = gql`
  query($idSeries: LongLong!) {
    allSeriesFileIDs(idSeries: $idSeries) {
      ok
      errors {
        path
        message
      }
      ids
      orientations {
        idFile
        Tag
        Name
        Value
        VR
      }
    }
  }
`;

export const QuerySeriesFileIDs = idSeries => {
  console.log('QuerySeriesFileIDs', { idSeries });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES_FILE_IDS),
    variables: {
      idSeries,
    },
  });
};
