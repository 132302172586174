import React from 'react';
// nodejs library that concatenates classes
import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import CardMedia from '@mui/material/CardMedia';
// import Avatar from '@mui/material/Avatar';
import { connect } from 'react-redux';

import {
  Activity,
  Cloud,
  Logs,
  Profile,
  useStyles,
  RadiusProfileImage,
} from './components';
import { TabPanel } from 'components';
import { Divider } from '@mui/material';
import { calc_image_size } from 'helpers/utils_images';

class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      width: 0,
      height: 0,
      tabs: [
        { name: 'Profile', TabComponent: Profile },
        { name: 'Settings', TabComponent: Cloud },
        { name: 'Activity', TabComponent: Activity },
        { name: 'Logs', TabComponent: Logs },
      ],
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    const { history } = this.props;
    switch (newValue) {
      case 1:
        history.push('/account/settings');
        break;
      case 2:
        history.push('/account/activity');
        break;
      case 3:
        history.push('/account/logs');
        break;
      default:
        history.push('/account/profile');
        break;
    }
  };

  render() {
    const {
      classes,
      match: {
        params: { item },
      },
      user,
    } = this.props;
    const { height } = this.state;
    //
    let selected = 'overview';
    let tabValue = this.state.tabValue;
    if (item === 'account') {
      tabValue = 0;
    } else if (item === 'settings') {
      tabValue = 1;
    } else if (item === 'activity') {
      tabValue = 2;
    } else if (item === 'logs') {
      tabValue = 3;
    } else {
      selected = item;
    }
    let external_height = height;
    let style = null;
    if (height < 590) {
      style = { height: 70 };
      external_height = height + 110;
    } else if (height < 900) {
      style = { height: 100 };
      external_height = height + 85;
    }
    //
    return (
      <div style={{ height: height - 75 }}>
        <CardMedia
          className={classes.media}
          image="/images/profile_1.png"
          style={style}
          title="User Account Settings"
        />
        <RadiusProfileImage classes={classes} user={this.props.user} />
        <Paper className={classes.rect} />
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            {this.state.tabs.map((tab, index) => {
              return <Tab key={`tab-account-${index}`} label={tab.name} />;
            })}
          </Tabs>
          <Divider />
        </Paper>
        <div style={{ height: external_height - 370 }}>
          <div className={classes.content_overview}>
            <div className={classes.folder_container}>
              {this.state.tabs.map((tab, index) => {
                const { TabComponent } = tab;
                let final_height = height;
                if (index === 0) final_height = external_height;
                return (
                  <TabPanel
                    className={classes.files}
                    index={index}
                    key={`tab-panel-account-${index}`}
                    value={tabValue}>
                    <TabComponent
                      handleAddError={this.props.handleAddError}
                      height={final_height}
                      isActive={index === tabValue}
                      item={selected}
                      user={user}
                    />
                  </TabPanel>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Account.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let size_avatar = 0;
  const { user } = state.info_user;
  if (user) {
    size_avatar = calc_image_size(user.avatar);
  }
  //
  return {
    size_avatar,
    user,
  };
};

export default connect(mapStateToProps)(withStyles(Account, useStyles));
