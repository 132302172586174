import gql from 'graphql-tag';
import axios from 'axios';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
const QUERY_PATIENTS_STUDIES = gql`
  query($id_patients: [LongLong!]!, $page: Int, $limit: Int) {
    allStudiesAtPatients(
      id_patients: $id_patients
      page: $page
      limit: $limit
    ) {
      ok
      errors {
        message
      }
      total
      studies {
        idStudy
        StudyDescription
        StudyDate
        StudyTime
        StudyInstanceUID
        AccessionNumber
        NumberOfStudyRelatedInstances
        NumberOfStudyRelatedSeries
        StationName
        updatedAt
        createdAt
      }
    }
  }
`;

export const QueryPatientsStudies = (id_patients, page, limit) => {
  console.log('QueryPatientsStudies: ', id_patients);
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PATIENTS_STUDIES),
    variables: {
      id_patients,
      page,
      limit,
    },
  });
};
