import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_UNIT_VIEWERS = gql`
  query($name: String, $page: Int, $limit: Int) {
    unitAccessViewers(name: $name, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      access_viewers {
        id
        user {
          id
          FullName
        }
        account {
          id
          description
        }
      }
      total
    }
  }
`;

export const QueryUnitAccessViewers = (name, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_UNIT_VIEWERS),
    variables: {
      name,
      page,
      limit,
    },
  });
};
const MUTATION_ADD_UNIT_VIEWERS = gql`
  mutation($inputs: [InputAccessViewer!]!) {
    addAccessViewer(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
      access_viewers {
        id
        idUser
        idAccount
      }
    }
  }
`;

export const MutationAddUnitAccessViewers = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_ADD_UNIT_VIEWERS),
    variables: {
      inputs,
    },
  });
};
const MUTATION_DELETE_UNIT_VIEWERS = gql`
  mutation($inputs: [LongLong!]!) {
    removeAccessViewer(inputs: $inputs) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationDeleteUnitAccessViewers = inputs => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_DELETE_UNIT_VIEWERS),
    variables: {
      inputs,
    },
  });
};
