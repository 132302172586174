import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid } from '@mui/material';
// import { CardButtonActions } from 'components/Cards/components';
import {
  IconButtonLoad,
  SearchUserAccountTypes,
  SearchUsersInput,
  TableGrid,
} from 'components';
import { GetData, IsInvalid, ServerErrorsString } from 'helpers';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  MutationAddUnitAccessViewers,
  MutationDeleteUnitAccessViewers,
  QueryUnitAccessViewers,
} from 'graphql/Units';
import TabUnitDicomViewerToolbar from './TabUnitDicomViewerToolbar';
const headers = [
  {
    field: 'id',
    headerName: 'ID',
    style: {
      width: 20,
      minWidth: 20,
    },
  },
  { field: 'description', headerName: 'Type', align: 'left' },
];
const headers_user = [
  {
    field: 'id',
    headerName: 'ID',
    style: {
      width: 20,
      minWidth: 20,
    },
  },
  {
    field: 'FullName',
    headerName: 'Fullname',
    align: 'left',
  },
];
class TabUnitDicomViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accounts: [],
      selected_accounts: [],
      users: [],
      selected_users: [],
      loading: false,
      saving: false,
      deleting: false,
    };
  }
  componentDidMount() {
    this.handleAccessViewers();
  }
  handleAccessViewers = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryUnitAccessViewers()
        .then(res => {
          const data = GetData(res);
          const { ok, access_viewers, errors } = data.unitAccessViewers;
          if (ok) {
            this.setState({
              accounts: access_viewers
                .filter(x => x.account)
                .map(x => ({
                  ...x.account,
                  idViewer: x.id,
                })),
              users: access_viewers
                .filter(x => x.user)
                .map(x => ({
                  ...x.user,
                  idViewer: x.id,
                })),
              loading: false,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddAccount = account => {
    if (IsInvalid(account)) return;
    const { accounts } = this.state;
    if (accounts.findIndex(x => x.id === account.id) !== -1) {
      this.props.handleAddError(
        `The account (${account.description}) is already here`
      );
      return;
    }
    accounts.push(account);
    this.setState({ accounts });
  };
  handleDelete = () => {
    this.handleRemoveAccessViewers(false);
  };
  handleAddUser = user => {
    if (IsInvalid(user)) return;
    const { users } = this.state;
    if (users.findIndex(x => x.id === user.id) !== -1) {
      this.props.handleAddError(`The User (${user.FullName}) is already here`);
      return;
    }
    users.push(user);
    this.setState({ users });
  };
  handleDeleteUsers = () => {
    this.handleRemoveAccessViewers(true);
  };
  isChanged = () => {
    return (
      this.state.accounts.filter(x => IsInvalid(x.idViewer)).length > 0 ||
      this.state.users.filter(x => IsInvalid(x.idViewer)).length > 0
    );
  };
  handleAddAccessViewers = () => {
    const { saving, users, accounts } = this.state;
    const { unit } = this.props;
    if (saving) return;
    const inputs = users
      .filter(x => IsInvalid(x.idViewer))
      .map(x => ({ idUnit: unit.idUnit, idUser: x.id }))
      .concat(
        accounts
          .filter(x => IsInvalid(x.idViewer))
          .map(x => ({ idUnit: unit.idUnit, idAccount: x.id }))
      );
    this.setState({ saving: true });
    (async () => {
      MutationAddUnitAccessViewers(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, access_viewers } = data.addAccessViewer;
          if (ok) {
            for (let i = 0; i < access_viewers.length; i++) {
              const { id, idUser, idAccount } = access_viewers[i];
              if (idUser) {
                const j = users.findIndex(x => x.id === idUser);
                if (j !== -1) users[j] = { ...users[j], idViewer: id };
              } else {
                const j = accounts.findIndex(x => x.id === idAccount);
                if (j !== -1) accounts[j] = { ...accounts[j], idViewer: id };
              }
            }
            this.setState({ saving: false, accounts, users });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleRemoveAccessViewers = (isUser = false) => {
    const { deleting, selected_users, selected_accounts } = this.state;
    if (deleting) return;
    let inputs = [];
    if (isUser) inputs = selected_users.map(x => x.idViewer);
    else {
      inputs = selected_accounts.map(x => x.idViewer);
    }
    this.setState({ deleting: true });
    (async () => {
      let { users, accounts } = this.state;
      MutationDeleteUnitAccessViewers(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.removeAccessViewer;
          if (ok) {
            if (isUser) {
              users = users.filter(x => !inputs.includes(x.idViewer));
            } else {
              accounts = accounts.filter(x => !inputs.includes(x.idViewer));
            }
            this.setState({
              deleting: false,
              users,
              accounts,
              selected_accounts: isUser ? selected_accounts : [],
              selected_users: isUser ? [] : selected_users,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { classes, height } = this.props;
    const relative_height = height - 270;
    const { selected_accounts, selected_users } = this.state;
    return (
      <Card className={classes.root} style={{ height: relative_height }}>
        <div style={{ paddingRight: 15 }}>
          <TabUnitDicomViewerToolbar
            changed={this.isChanged()}
            handleReload={this.handleAccessViewers}
            handleSaveClicked={() => this.handleAddAccessViewers()}
            loading={this.state.loading}
          />
        </div>
        <CardContent sx={{ paddingBottom: 0 }}>
          <Grid
            container
            spacing={3}
            sx={{ height: relative_height - 43, overflowY: 'auto' }}>
            <Grid container item md={6} xs={12}>
              <Grid
                alignItems="space-between"
                container
                item
                justifyContent="center">
                <Grid item xs={10}>
                  <SearchUserAccountTypes
                    handleAddAccount={this.handleAddAccount}
                    handleAddError={this.props.handleAddError}
                  />
                </Grid>
                <Grid item sx={{ paddingLeft: 2 }} xs={2}>
                  <IconButtonLoad
                    bottom_top
                    handleClick={this.handleDelete}
                    icon={
                      <DeleteIcon
                        style={{
                          color: selected_accounts.length ? 'red' : 'gray',
                        }}
                      />
                    }
                    left_right
                    length={this.state.selected_accounts.length}
                    small
                    tooltip="Delete Selected"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sx={{
                  marginTop: 1,
                  border: '1px solid #e0e0e0',
                  '&.MuiGrid-item': {
                    marginBottom: '8px',
                  },
                }}
                xs={12}>
                <TableGrid
                  checkboxSelection
                  dense
                  external_selected={this.state.selected_accounts}
                  headers={headers}
                  height={relative_height - 180}
                  onSelectedChanged={selected_accounts =>
                    this.setState({ selected_accounts })
                  }
                  rows={this.state.accounts}
                />
              </Grid>
            </Grid>
            <Grid container item md={6} xs={12}>
              <Grid
                alignItems="space-between"
                container
                item
                justifyContent="center"
                xs={12}>
                <Grid item xs={10}>
                  <SearchUsersInput
                    handleAddUser={this.handleAddUser}
                    // holder={this.holder}
                  />
                </Grid>
                <Grid item sx={{ paddingLeft: 2 }} xs={2}>
                  <IconButtonLoad
                    bottom_top
                    handleClick={this.handleDeleteUsers}
                    icon={
                      <DeleteIcon
                        style={{
                          color: selected_users.length ? 'red' : 'gray',
                        }}
                      />
                    }
                    left_right
                    length={selected_users.length}
                    small
                    tooltip="Delete Selected"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                sx={{
                  marginTop: 1,
                  border: '1px solid #e0e0e0',
                  '&.MuiGrid-item': {
                    marginBottom: '8px',
                  },
                }}
                xs={12}>
                <TableGrid
                  checkboxSelection
                  dense
                  external_selected={this.state.selected_users}
                  headers={headers_user}
                  height={relative_height - 180}
                  onSelectedChanged={selected_users =>
                    this.setState({ selected_users })
                  }
                  rows={this.state.users}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

TabUnitDicomViewer.propTypes = {
  classes: PropTypes.object,
};

export default TabUnitDicomViewer;
