import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { HTTP_ADDRESS_DOWNLOAD, HTTP_ADDRESS_GRAPHQL } from 'config';
import {
  // Layouts
  Main as MainLayout,
  Minimal as MinimalLayout,
} from 'layouts';
import {
  Home as HomeView,
  HomePublicAccess as HomePublicAccessView,
  HomeAboutUs as HomeAboutUsView,
  Dashboard as DashboardView,
  QueryRetreive as QueryRetreiveView,
  Explorer as ExplorerView,
  Pipelines as PipelinesView,
  DataSignals as DataSignalsView,
  DataClinics as DataClinicsView,
  DataImages as DataImagesView,
  DownloadUpload as DownloadUploadView,
  Upload as UploadView,
  DicomViewer as DicomViewerView,
  Communications as CommunicationsView,
  Projects as ProjectsView,
  Account as AccountView,
  Management as ManagementView,
  Collaborators as CollaboratorsView,
  Administrator as AdministratorView,
  Settings as SettingsView,
  Shared as SharedView,
  Surveys as PatientSurveyView,
  SignIn as SignInView,
  SignUp as SignUpView,
  NotFound as NotFoundView,
} from '../../views';
import {
  IsAdministrator,
  IsUserPipeline,
  IsUserClinicalData,
  IsUserViewer,
} from 'helpers';
import { IsNormalUser, IsUserClinicalTrialUpload } from 'common';
import RouteWithLayout from './RouteWithLayout';
import { RedirectToServer } from '.';
export const GetDefaultRoutes = (props, is_clinical_allowed) => {
  const { user, ReactGA4 } = props;
  let RouteAdministrator = null;
  if (IsAdministrator(user)) {
    RouteAdministrator = (
      <RouteWithLayout
        component={AdministratorView}
        exact
        layout={MainLayout}
        path="/administrator/:level_1?/:level_2?/:level_3?/:level_4?/:level_5?"
        ReactGA4={ReactGA4}
      />
    );
  }
  if (
    window.location.pathname === '/download' ||
    window.location.pathname === '/download'
  ) {
    return <div />;
  }
  let RoutesToServerGraphql = null;
  let RoutesToServerDownload = null;
  if (HTTP_ADDRESS_DOWNLOAD.includes('161.62.')) {
    RoutesToServerDownload = (
      <RedirectToServer exact link={HTTP_ADDRESS_DOWNLOAD} path="/download" />
    );
    RoutesToServerGraphql = (
      <RedirectToServer exact link={HTTP_ADDRESS_GRAPHQL} path="/graphql" />
    );
  }
  let DevelopenPipelinesView = null;

  if (IsUserPipeline(user)) {
    DevelopenPipelinesView = (
      <RouteWithLayout
        component={PipelinesView}
        exact
        layout={MainLayout}
        path="/pipelines/:level_1?/:level_2?/:level_3?"
        ReactGA4={ReactGA4}
      />
    );
  }
  let DevelopenDataSignalsView = null;
  if (IsAdministrator(user)) {
    DevelopenDataSignalsView = (
      <RouteWithLayout
        component={DataSignalsView}
        exact
        layout={MainLayout}
        path="/signals_data"
        ReactGA4={ReactGA4}
      />
    );
  }
  let DevelopenDataClinicsView = null;

  if (IsUserClinicalData(user, is_clinical_allowed)) {
    DevelopenDataClinicsView = (
      <RouteWithLayout
        component={DataClinicsView}
        exact
        layout={MainLayout}
        path="/clinical_data/:level_1?/:level_2?"
        ReactGA4={ReactGA4}
      />
    );
  }
  let RouteDicomViewer = null;
  if (IsUserViewer(user)) {
    RouteDicomViewer = (
      <RouteWithLayout
        component={DicomViewerView}
        exact
        layout={MainLayout}
        path="/dicom_viewer/:level_1?/:level_2?"
      />
    );
  }
  let RouteCommunications = null;
  let RouteUploadDownload = null;
  let RouteUpload = null;
  if (IsUserClinicalTrialUpload(user)) {
    RouteUpload = (
      <RouteWithLayout
        component={UploadView}
        exact
        layout={MainLayout}
        path="/upload/:type?/:id?"
        ReactGA4={ReactGA4}
      />
    );
  } else if (IsNormalUser(user)) {
    RouteCommunications = (
      <RouteWithLayout
        component={CommunicationsView}
        exact
        layout={MainLayout}
        path="/communications/:type?/:id?"
        ReactGA4={ReactGA4}
      />
    );
    RouteUploadDownload = (
      <RouteWithLayout
        component={DownloadUploadView}
        exact
        layout={MainLayout}
        path="/upload_download/:level_1?"
        ReactGA4={ReactGA4}
      />
    );
  }
  return (
    <Switch>
      <RouteWithLayout
        component={HomeView}
        exact
        path="/"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={HomePublicAccessView}
        exact
        path="/public_access/:level_1?/:level_2?/:level_3?"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={HomeAboutUsView}
        exact
        path="/about-us"
        public_access
        ReactGA4={ReactGA4}
      />
      {/* --------------------------------------------------- */}
      <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard/:level_1?/:level_2?"
        ReactGA4={ReactGA4}
      />
      {/* --------------------------------------------------- */}
      <RouteWithLayout
        component={QueryRetreiveView}
        exact
        layout={MainLayout}
        path="/query_retrieve"
        ReactGA4={ReactGA4}
      />
      {/* --------------------------------------------------- */}
      <RouteWithLayout
        component={ExplorerView}
        exact
        layout={MainLayout}
        path="/explorer/:level_1?/:level_2?/:level_3?"
        ReactGA4={ReactGA4}
      />
      {/* --------------------------------------------------- */}
      {DevelopenPipelinesView}
      {DevelopenDataSignalsView}
      {DevelopenDataClinicsView}
      {/* --------------------------------------------------- */}

      <RouteWithLayout
        component={DataImagesView}
        exact
        layout={MainLayout}
        path="/image_analysis/:level_1?/:level_2?/:level_3?/:level_4?/:level_5?"
        ReactGA4={ReactGA4}
      />
      {/* --------------------------------------------------- */}
      {RouteDicomViewer}
      {RouteUploadDownload}
      {RouteUpload}
      {/* --------------------------------------------------- */}
      {RouteCommunications}
      <RouteWithLayout
        component={ProjectsView}
        exact
        layout={MainLayout}
        path="/project_management/:item?/:level_1?/:level_2?/:level_3?/:level_4?"
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={AccountView}
        exact
        layout={MainLayout}
        path="/account/:item?/:level_1?"
        ReactGA4={ReactGA4}
      />
      {/* --------------------------------------------------- */}
      <RouteWithLayout
        component={ManagementView}
        exact
        layout={MainLayout}
        path="/management/:item?/:level_1?/:level_2?"
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={CollaboratorsView}
        exact
        layout={MainLayout}
        path="/collaborators"
        ReactGA4={ReactGA4}
      />
      {RouteAdministrator}
      {/* --------------------------------------------------- */}
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
        ReactGA4={ReactGA4}
      />
      {/* --------------------------------------------------- */}
      {/* --------------------------------------------------- */}
      <RouteWithLayout
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
        public_access
        ReactGA4={ReactGA4}
      />
      {/* --------------------------------------------------- */}
      {/* --------------------------------------------------- */}
      {RoutesToServerGraphql}
      {RoutesToServerDownload}
      <RouteWithLayout
        component={SharedView}
        exact
        layout={MinimalLayout}
        path="/shared/:type?/:id?/:idFolder?"
        public_access
        ReactGA4={ReactGA4}
      />
      <RouteWithLayout
        component={PatientSurveyView}
        exact
        layout={MinimalLayout}
        path="/survey/:link?"
        public_access
        ReactGA4={ReactGA4}
      />
      {/* --------------------------------------------------- */}
      {/* ------------  This always at the END -------------- */}
      {/* --------------------------------------------------- */}
      <Redirect to="/not-found" />
    </Switch>
  );
};
