import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, InputBase, Toolbar, AppBar, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { MedicalBlocksLogo } from 'layouts/components';
import SaveIcon from '@mui/icons-material/Save';
import { GetData, IsInvalid, ServerErrorsString } from 'helpers';
import { QueryPatients } from 'graphql/PatientsQueries';
import { connect } from 'react-redux';
import {
  setDisplayDicomMultiSeries,
  setDisplayDicomStudy,
} from 'redux-store/actions';
import {
  CircularProgressWaiting,
  IconButtonLoad,
  SearchUserProjects,
} from 'components';
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));
class ToolBarSearchSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_project: null,
      text: undefined,
    };
    const { holder } = props;
    if (holder) {
      holder.queryPatients = (...res) => this.handleSearchPatients(...res);
    }
  }
  handleSearchPatients = (PatientName, newPage, newLimit) => {
    let { loading, selected_project } = this.state;
    let { page, limit } = this.props.state;
    if (loading) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    let idProject = null;
    if (selected_project) {
      idProject = selected_project.id;
    }
    this.setState({ loading: true });
    this.props.setState({ loading_patients: true });
    (async () => {
      QueryPatients({ PatientName, idProject }, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients, total } = data.Patients;
          if (ok) {
            this.setState({
              loading: false,
              patients,
              page,
              limit,
              total,
            });
            this.props.setState({
              patients,
              loading_patients: false,
              page,
              limit,
              total,
            });
            if (!patients.length) {
              this.props.handleAddError(
                // eslint-disable-next-line quotes
                "There are not patients or you don't have the access"
              );
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
          this.props.setState({ loading_patients: true });
        });
    })();
  };
  handleChange = event => {
    const text = event.target.value;
    this.setState({ text });
  };
  onSaveSelectedSeries = () => {
    let {
      study,
      ListSeries,
      state: { ListViewerSeries },
      total,
    } = this.props;
    console.log('onSaveSelectedSeries', {
      ListViewerSeries,
      studies: study ? study.Series.length : null,
      ListSeries: ListSeries.length,
    });
    const new_total = ListViewerSeries.length;
    if (study) {
      if (total && !new_total) {
        study.Series = [];
      } else {
        study.Series = study.Series.filter(item =>
          ListViewerSeries.includes(item.idSeries)
        );
      }
      this.props.setDisplayDicomStudy(study);
    }
    if (ListSeries.length) {
      if (total && !new_total) {
        ListSeries = [];
      } else {
        ListSeries = ListSeries.filter(item =>
          ListViewerSeries.includes(item.idSeries)
        );
      }
      this.props.setDisplayDicomMultiSeries(ListSeries);
    }
    this.props.onSaveSelectedSeries(ListViewerSeries);
  };
  handleProjectSelection = selected_project => {
    this.setState({ selected_project });
  };
  render() {
    const {
      classes,
      user,
      state: { ListViewerSeries, loading_patients, saving },
      total,
    } = this.props;
    const { selected_project } = this.state;
    let disable_save = !ListViewerSeries.length;
    let disable_all = false;
    if (!ListViewerSeries.length && total) {
      disable_save = false;
    }
    if (loading_patients) {
      disable_save = true;
      disable_all = true;
    }
    return (
      <AppBar className={classes.appBar}>
        <Toolbar>
          <MedicalBlocksLogo max_width={50} show_text={false} />
          <Search>
            <div style={{ position: 'absoulte' }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              {loading_patients && (
                <CircularProgressWaiting
                  size={32}
                  style={{
                    position: 'absolute',
                    left: 2.5,
                    top: -2,
                    width: '50px',
                    height: '40px',
                  }}
                  sx_circle={{ color: 'white' }}
                />
              )}
            </div>
            <StyledInputBase
              disabled={!(saving || disable_all) && user ? false : true}
              inputProps={{ 'aria-label': 'search-patients' }}
              onChange={this.handleChange}
              onKeyDown={event => {
                const { key } = event;
                if (key === 'Enter') {
                  this.handleSearchPatients(this.state.text);
                }
              }}
              placeholder="Search Patient Name…"
              value={this.state.text}
            />
          </Search>
          <SearchUserProjects
            colorIcons="white"
            disabled={!(saving || disable_all) && user ? false : true}
            external_selected={selected_project}
            handleAddProject={this.handleProjectSelection}
            handleSetProject={this.handleProjectSelection}
            idUser={user ? user.id : null}
            label=""
            no_icon
            placeholder="Search My Projects..."
            selected={selected_project}
            style_menu={{
              backgroundColor: 'rgb(46, 108, 138)',
              color: 'white',
            }}
            style_textfield_param={{
              color: 'white',
              height: 37,
              backgroundColor: 'rgb(46, 108, 138)',
            }}
            sytle_autocomplete={{
              paddingTop: 0,
              maxWidth: 300,
              color: 'currentColor',
            }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButtonLoad
              badge_style={{
                '.MuiBadge-badge': {
                  background: saving || disable_save ? '#455a64' : 'red',
                  color: saving || disable_save ? '#9e9e9e' : 'white',
                },
              }}
              disabled={saving || disable_save}
              handleClick={this.onSaveSelectedSeries}
              icon={<SaveIcon />}
              length={ListViewerSeries.length}
              loading={saving}
              sx_circle={{ color: '#bdbdbd' }}
              tooltip="Include Series in Viewer"
            />
          </Box>
          <Box sx={{ display: { xs: 'flex', paddingLeft: 25 } }}>
            <IconButton
              aria-label="close"
              color="inherit"
              edge="start"
              onClick={this.props.handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    );
  }
}

ToolBarSearchSeries.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { user } = state.info_user;
  let { study, ListSeries } = state.page_dicom_viewer;
  if (IsInvalid(ListSeries)) {
    ListSeries = [];
  }
  return { study, ListSeries, user };
};

export default connect(mapStateToProps, {
  setDisplayDicomMultiSeries,
  setDisplayDicomStudy,
})(ToolBarSearchSeries);
