import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, IconButton, Slider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Viewer from 'react-viewer';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';

const TinyText = styled(Typography)({
  fontSize: '0.75rem',
  opacity: 0.38,
  fontWeight: 500,
  letterSpacing: 0.2,
  color: 'white',
});

function formatDuration(value) {
  return `${(value / 1000).toFixed(2)}s`;
}
const useStyles = t => ({
  color: 'rgba(255, 255, 255, 0.87)',
  height: 4,
  '& .MuiSlider-thumb': {
    width: 8,
    height: 8,
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    '&::before': {
      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
    },
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${'rgb(0 0 0 / 16%)'}`,
      ...t.applyStyles('dark', {
        boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'}`,
      }),
    },
    '&.Mui-active': {
      width: 20,
      height: 20,
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.28,
  },
  ...t.applyStyles('dark', {
    color: '#fff',
  }),
});
class ViewerAutoPlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentImageIndex: 0,
      autoplay: false,
      autoplaySpeed: 100, // Default autoplay speed in milliseconds
    };
  }
  startAutoplay = () => {
    const { image_files } = this.props;
    this.setState({ autoplay: true });
    this.autoplayInterval = setInterval(() => {
      this.setState(prevState => ({
        currentImageIndex:
          (prevState.currentImageIndex + 1) % image_files.length,
      }));
    }, this.state.autoplaySpeed); // Change image every 3 seconds
  };

  stopAutoplay = () => {
    this.setState({ autoplay: false });
    clearInterval(this.autoplayInterval);
  };
  handleSpeedChange = (event, newValue) => {
    this.setState({ autoplaySpeed: newValue });
    if (this.state.autoplay) {
      this.stopAutoplay();
      this.startAutoplay();
    }
  };
  render() {
    const { classes, image_files, open_text_json_file } = this.props;
    if (image_files.length === 0) return null;
    const { currentImageIndex, autoplay, autoplaySpeed } = this.state;
    return (
      <React.Fragment>
        <Viewer
          activeIndex={currentImageIndex}
          className={classes.viewer}
          drag={false}
          images={image_files.map(file_content => {
            const { File } = file_content;
            return {
              src: `data:image/jpeg;base64,${File.Thumbnail}`,
              alt: File.original_name,
            };
          })}
          onClose={() => {
            this.props.setState({ open_text_json_file: false });
            this.stopAutoplay();
          }}
          visible={open_text_json_file}
        />
        {image_files.length > 1 && (
          <Grid
            className={classes.autoplayButton}
            container
            direction="row"
            size="small"
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
              maxWidth: 500,
            }}>
            <Grid item xs={1}>
              <IconButton
                aria-label={autoplay ? 'play' : 'pause'}
                onClick={autoplay ? this.stopAutoplay : this.startAutoplay}
                size="small">
                {autoplay ? (
                  <PauseRounded sx={{ fontSize: '2rem', color: 'white' }} />
                ) : (
                  <PlayArrowRounded sx={{ fontSize: '2rem', color: 'white' }} />
                )}
              </IconButton>
            </Grid>
            {autoplay && (
              <Grid
                container
                direction="column"
                item
                sx={{
                  justifyContent: 'center',
                  alignItems: 'stretch',
                }}
                xs={10}>
                <Slider
                  aria-label="time-indicator"
                  max={200}
                  min={1}
                  onChange={this.handleSpeedChange}
                  size="small"
                  step={1}
                  sx={t => useStyles(t)}
                  value={autoplaySpeed}
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: -1.5,
                  }}>
                  <TinyText>{formatDuration(autoplaySpeed)}</TinyText>
                  <TinyText>{formatDuration(200)}</TinyText>
                </Box>
              </Grid>
            )}
          </Grid>
        )}
      </React.Fragment>
    );
  }
}

ViewerAutoPlay.propTypes = {
  autoplay: PropTypes.bool,
  autoplaySpeed: PropTypes.number,
  classes: PropTypes.object,
  handleSpeedChange: PropTypes.func,
  startAutoplay: PropTypes.func,
  stopAutoplay: PropTypes.func,
};

export default ViewerAutoPlay;
