import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Breadcrumbs,
  Stack,
  Typography,
} from '@mui/material';
import { PaperComponent } from 'helpers';
import { ExplorerViewListTree } from 'views/Explorer/components/ExplorerViews/components/ExplorerViewList/components/ExplorerViewListLeft/components';
import { GetData, ServerErrors } from 'helpers';
import { QueryHomeFolder } from 'graphql/Folders';
import HomeIcon from '@mui/icons-material/Home';
import { CircularProgressWaiting } from 'components/Components';

class DialogSelectFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      SubFolders: [],
      folder: null,
      init: false,
    };
  }
  componentDidUpdate() {
    if (this.props.open && !this.state.init) this.handleLoadHome();
  }
  handleLoadHome = () => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryHomeFolder()
        .then(res => {
          const data = GetData(res);
          let query_function = data.home;

          const { ok, Folder, errors } = query_function;
          if (ok) {
            this.setState({
              loading: false,
              SubFolders: Folder.SubFolders,
              init: true,
            });
          } else {
            throw errors;
          }
        })
        .catch(errors => {
          const list_errors = ServerErrors(errors);

          this.setState({
            loading: false,
            error: list_errors.join('\n'),
            init: true,
          });
        });
    })();
  };
  handleItemClick = folder => {
    // console.log({ folder });
    this.setState({ folder });
  };
  render() {
    const { open, handleClose } = this.props;
    if (!open) return null;
    const { loading, SubFolders, folder } = this.state;
    let folders_paths = [{ name: 'Home' }];
    if (folder) {
      folders_paths = folder.folders_paths;
    }
    // console.log({ folders_paths });
    return (
      <Dialog
        aria-labelledby="draggable-dialog-title"
        onClose={handleClose}
        open={open}
        PaperComponent={PaperComponent}>
        <DialogTitle id="draggable-dialog-title" style={{ cursor: 'move' }}>
          {this.props.title}
        </DialogTitle>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="center"
          sx={{ maxWidth: 400 }}>
          <Grid item style={{ paddingLeft: 20 }} xs={12}>
            <Stack spacing={2}>
              <Breadcrumbs aria-label="breadcrumb" separator="›">
                {folders_paths.map((folder, index) => {
                  const { name } = folder;
                  let icon = null;
                  if (index === 0) {
                    icon = <HomeIcon fontSize="inherit" sx={{ mr: 0.5 }} />;
                  }
                  return (
                    <Typography
                      color="text.primary"
                      key={`sub-folder-${index}`}>
                      {icon}
                      {name}
                    </Typography>
                  );
                })}
              </Breadcrumbs>
            </Stack>
          </Grid>
          <DialogContent>
            {loading ? (
              <CircularProgressWaiting />
            ) : (
              <ExplorerViewListTree
                base="Home"
                disable_click
                handleAddError={this.props.handleAddError}
                handleItemClick={this.handleItemClick}
                SubFolders={SubFolders}
              />
            )}
          </DialogContent>
        </Grid>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={() => this.props.handleSelectFolder(this.state.folder)}>
            Select
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DialogSelectFolder.propTypes = {
  handleSelectFolder: PropTypes.func,
  title: PropTypes.string,
};
DialogSelectFolder.defaultProps = {
  handleSelectFolder: () => '',
  title: 'Selection of Upload Folder',
};

export default DialogSelectFolder;
