/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Grid } from '@mui/material';
import {
  ButtonDownloadType,
  ButtonGroupLoading,
  ButtonReloadDelete,
  DialogDeleteWarning,
} from 'components';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {
  FileCopy as FileCopyIcon,
  Block as BlockIcon,
} from '@mui/icons-material';
import { deepPurple } from '@mui/material/colors';
import { connect } from 'react-redux';
import { copySeries } from 'redux-store/actions/file_folder_manager';
import {
  useToolbarStyles,
  handleCopySeries,
  DialogWarningDeleteSeries,
} from './components';
import { IsInvalid } from 'helpers';

class ToolBarQueryRetrieve extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: false,
      open_clean: false,
      loading: false,
      loading_delete: false,
    };
  }
  copySeriesToExplorer = ListSeries => {
    const { copy_series } = this.props;

    const total = copy_series.length;
    for (let i = 0; i < ListSeries.length; i++) {
      const series = ListSeries[i];
      const index = copy_series.map(x => x.idSeries).indexOf(series.idSeries);
      if (index === -1) {
        copy_series.push(series);
      }
    }
    const success = total < copy_series.length;
    if (success) {
      this.props.copySeries(copy_series);
    }
    this.setState({
      loading: false,
    });
    if (!success) this.props.handleAddWarning('The series are already copied');
    if (success) {
      this.props.handleAddSuccess(
        'Series are ready to be copied to the explorer'
      );
    }
    this.props.setState({ selected: [], selectedStudies: [] });
  };
  getQuerySeriesSetting = () => {
    const { bar_holder } = this.props;
    let SeriesQuery = {};
    let setting = null;

    if (IsInvalid(bar_holder)) {
      return { SeriesQuery, setting };
    }
    const {
      getModalities,
      getImageType,
      getTabImageType,
      getFilters,
    } = bar_holder;
    if (getModalities) {
      const m = getModalities();
      if (m && m.length) SeriesQuery.Modality = m;
    }
    let imagetype = null;
    if (getTabImageType) {
      imagetype = getTabImageType();
    }
    if (Object.keys(imagetype).length === 0 && getImageType) {
      imagetype = getImageType();
    }
    if (Object.keys(imagetype).length) {
      const { spacing, size } = imagetype;
      if (size) {
        SeriesQuery.ImageSize = size;
      }
      if (spacing) {
        SeriesQuery.ImageSpacing = spacing;
      }
      if (imagetype.nifti && imagetype.nifti !== '') {
        let nifti = 0; //'With NIfTI', 'Without NIfTI'
        if (imagetype.nifti === 'With NIfTI') nifti = 1;
        if (imagetype.nifti === 'Without NIfTI') nifti = 2;
        setting = {
          nifti,
        };
      }
    }
    if (getFilters) {
      const filters = getFilters();
      if (filters) {
        if (filters.id_sequences) {
          setting = { ...setting, id_sequences: filters.id_sequences };
        }
        if (filters.project) {
          setting = { ...setting, id_projects: [filters.project.id] };
        }
      }
    }
    return { SeriesQuery, setting };
  };
  handleMultiEditSeries = () => {
    this.props.history.push('/image_analysis/series/edit/multi');
  };
  getSetting = () => {
    let { page, limit } = this.holder.getPageLimit();
    let setting = {
      page,
      limit,
    };
    const imagetype = this.holder.getImageType();
    if (imagetype.nifti) {
      let nifti = 0;
      if (imagetype.nifti === 'With NIfTI') nifti = 1;
      if (imagetype.nifti === 'Without NIfTI') nifti = 2;
      setting.nifti = nifti;
    }
    const filters = this.holder.getFilters();
    if (filters) {
      setting = {
        ...setting,
        ...filters,
      };
    }
  };
  render() {
    const {
      classes,
      selectedStudies,
      selectedSeries,
      loading,
      numSelected,
      copy_series,
    } = this.props;
    let { editing } = this.state;
    const inputs = JSON.stringify({
      StudyQuery: this.props.current_query,
      setting: this.props.setting,
    });
    let batch_style = {};
    let icon_batch = {};
    let icon_style = {};
    if (numSelected || this.props.size) {
      let right = 1;
      if (numSelected > 99) right = 5;
      batch_style = {
        top: 30,
        right,
      };
      icon_batch = { paddingLeft: 5, marginBottom: 12 };
      icon_style = { marginBottom: 15 };
    }
    const remainingSeries =
      selectedSeries
        .map(x => x.idSeries)
        .filter(id => !copy_series.map(x => x.idSeries).includes(id)).length +
      selectedStudies.length;
    return (
      <React.Fragment>
        <Grid
          alignItems="center"
          container
          direction="row"
          item
          justifyContent="space-between"
          pb={1}
          pt={1}
          xs={12}>
          <Grid item xs={3}>
            <ButtonGroup
              className={classes.root}
              exclusive="false"
              size="small"
              style={{ height: 45 }}
              value="center">
              <ButtonDownloadType
                disabled={!this.props.total_studies}
                inputs={inputs}
                key="query_retrieve"
                type="query_retrieve"
              />
              <ButtonGroupLoading
                badge
                batch_style={batch_style}
                disabled={!numSelected}
                icon={
                  <BorderColorIcon
                    style={{
                      color: editing ? 'gray' : numSelected ? 'blue' : 'gray',
                      ...(editing ? {} : numSelected ? icon_batch : {}),
                    }}
                  />
                }
                icon_style={icon_style}
                loading={editing}
                onClick={() => handleCopySeries(this, true)}
                tooltip="Edit selected series"
                total={numSelected}
              />
              <ButtonGroupLoading
                badge
                batch_style={batch_style}
                disabled={!remainingSeries}
                icon={
                  <FileCopyIcon
                    style={{
                      color: loading
                        ? 'gray'
                        : remainingSeries
                        ? deepPurple[400]
                        : 'gray',
                      ...(remainingSeries ? icon_batch : {}),
                    }}
                  />
                }
                icon_style={icon_style}
                loading={loading}
                onClick={() => handleCopySeries(this)}
                tooltip="Copy Series for Explorer"
                total={remainingSeries}
              />
              <ButtonGroupLoading
                badge
                batch_style={batch_style}
                disabled={!this.props.size}
                icon={
                  <BlockIcon
                    style={{
                      color: this.props.size ? 'green' : 'gray',
                      ...(this.props.size ? icon_batch : {}),
                    }}
                  />
                }
                icon_style={icon_style}
                onClick={() => this.setState({ open_clean: true })}
                tooltip="Clean the copied series"
                total={this.props.size}
              />
            </ButtonGroup>
          </Grid>
          <Grid
            alignItems="center"
            container
            direction="row"
            item
            justifyContent="flex-end"
            pr={1}
            xs={5}>
            <ButtonReloadDelete
              className={classes.margin}
              handleClearSelected={() =>
                this.props.setState({ selectedStudies: [], selectedSeries: [] })
              }
              handleDeleteSelected={() => this.setState({ open_delete: true })}
              handleReloadTable={this.props.handleReload}
              loading_table={this.props.loading}
              numSelected={numSelected}
              small
              tooltip="Reload rules"
              wrapper={{ margin: 0 }}
            />
          </Grid>
        </Grid>
        <DialogWarningDeleteSeries
          copy_series={this.props.copy_series}
          copySeries={this.props.copySeries}
          handleAddError={this.props.handleAddError}
          handleClose={() => this.setState({ open_delete: false })}
          open={this.state.open_delete}
          selectedDeleted={this.props.selectedDeleted}
          selectedSeries={selectedSeries}
          selectedStudies={selectedStudies}
        />
        <DialogDeleteWarning
          handleClose={() => this.setState({ open_clean: false })}
          handleDeleteFolders={() => {
            this.props.copySeries([]);
            this.setState({ open_clean: false });
          }}
          open={this.state.open_clean}
          question_text={
            'You are about to clean the selected series, this action cannot be undones'
          }
          title_text="Clean Selected Series"
        />
      </React.Fragment>
    );
  }
}

ToolBarQueryRetrieve.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { copy_series } = state.manager;
  return {
    copy_series: copy_series ? copy_series : [],
    size: copy_series ? copy_series.length : 0,
  };
};

export default connect(mapStateToProps, { copySeries })(
  withStyles(withRouter(ToolBarQueryRetrieve), useToolbarStyles)
);
