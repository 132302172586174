import { IsInvalid } from 'helpers';
import { orientationLetters } from './utils';
import {
  computeCameraVectors,
  detectMainView,
  getOrientationFromString,
  isAxisOriented,
} from './utils_orientation';
const photometricInterpretations = [
  'RGB',
  'PALETTE COLOR',
  'YBR_FULL',
  'YBR_FULL_422',
  'YBR_PARTIAL_422',
  'YBR_PARTIAL_420',
  'YBR_ICT',
  'YBR_RCT',
];
export const IsSeriesForStack = series => {
  const { PhotometricInterpretation, ImagePlane } = series;
  const { ImageOrientationPatient } = ImagePlane;
  let to_stack = false;
  if (
    series.ImageOrientationPatient === 'Empty' ||
    ImageOrientationPatient === 'Empty' ||
    photometricInterpretations.includes(PhotometricInterpretation)
  ) {
    to_stack = true;
  }
  return to_stack;
};
export const setDefaultCamera = (series, viewport) => {
  let default_orientation = null;
  let orientation_letters = null;
  try {
    if (IsInvalid(series)) return { orientation_letters, default_orientation };
    const ImageOrientationPatient = getSeriesImageOrientation(series);
    if (IsInvalid(ImageOrientationPatient))
      return { orientation_letters, default_orientation };
    default_orientation = detectMainView(ImageOrientationPatient);
    orientation_letters = setCameraVectors(
      series,
      default_orientation,
      viewport
    );
    console.log({ orientation_letters });
    console.log('setDefaultCamera', {
      orientation_letters: orientation_letters
        ? orientation_letters.join(', ')
        : null,
    });
  } catch (error) {
    console.error({ error });
  }
  return { orientation_letters, default_orientation };
};
export const getCameraVectors = (ImageOrientationPatient, orientation) => {
  let viewUp = null;
  let viewPlaneNormal = null;
  let orientation_letters = null;
  const reply_camera = computeCameraVectors(
    ImageOrientationPatient,
    orientation
  );
  if (reply_camera) {
    viewUp = reply_camera.viewUp;
    viewPlaneNormal = reply_camera.viewPlaneNormal;
    orientation_letters = orientationLetters(viewPlaneNormal, viewUp);
  }
  return {
    viewUp,
    viewPlaneNormal,
    orientation_letters,
  };
};
export const getSeriesImageOrientation = series => {
  if (IsInvalid(series)) return null;
  const { ImagePlane } = series;
  let { ImageOrientationPatient } = ImagePlane;
  ImageOrientationPatient = getOrientationFromString(ImageOrientationPatient);
  console.log_dev('getSeriesImageOrientation', {
    IsAxisOriented: isAxisOriented(ImageOrientationPatient),
    MainAxis: detectMainView(ImageOrientationPatient),
  });
  return ImageOrientationPatient;
};
export const setCameraVectors = (series, orientation, viewport) => {
  let orientation_letters = null;
  try {
    let viewUp = null;
    let viewPlaneNormal = null;
    const ImageOrientationPatient = getSeriesImageOrientation(series);
    if (IsInvalid(ImageOrientationPatient)) return null;
    const reply = getCameraVectors(ImageOrientationPatient, orientation);
    if (reply.viewUp) {
      viewUp = reply.viewUp;
      viewPlaneNormal = reply.viewPlaneNormal;
      orientation_letters = reply.orientation_letters;
      viewport.setCamera({ viewUp, viewPlaneNormal });
      viewport.resetCamera();
    }
  } catch (error) {
    console.error({ error });
  }
  return orientation_letters;
};
