import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { TableGrid } from 'components';
import { columns, ToolBarPatientsActions } from './components';
import { QueryPatients } from 'graphql/PatientsQueries';
import { ServerErrorsString, GetData } from 'helpers';
class TabAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      patients: [],
      loading: false,
      total: 0,
      page: 0,
      limit: 50,
    };
  }
  componentDidMount() {
    this.handleUpdate();
  }
  handleUpdate = (name, newPage, newLimit) => {
    let { loading, page, limit } = this.state;
    if (loading) return;
    if (newPage) page = newPage;
    if (newLimit) limit = newLimit;
    this.setState({ loading: true });
    (async () => {
      QueryPatients({ PatientName: name }, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients, total } = data.Patients;
          if (ok) {
            this.setState({
              loading: false,
              patients,
              page,
              limit,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handlePageChange = page => {
    this.handleUpdate(null, page, this.state.limit);
  };
  handleRowsPerPageChange = limit => {
    this.handleUpdate(null, 0, limit);
  };
  handleChangeName = event => {
    event.preventDefault();
    this.handleUpdate(event.target.value);
  };
  render() {
    if (!this.props.isActive) return null;
    const { height } = this.props;
    const { selected } = this.state;
    return (
      <Grid
        container
        rowSpacing={1}
        style={{ padding: 4, paddingBottom: 0, paddingTop: 3 }}>
        <Grid item xs={12}>
          <ToolBarPatientsActions
            handleChangeName={this.handleChangeName}
            handleReloadTable={() => this.handleUpdate()}
            loading={this.state.loading}
            selected={selected}
            view="all"
          />
        </Grid>
        <Grid item sx={{ padding: 2, minHeight: 400 }} xs={12}>
          <Paper>
            <TableGrid
              checkboxSelection
              dense
              // disableSelectionOnClick
              external_selected={this.state.selected}
              headers={columns}
              height={height - 220}
              idRow="idPatient"
              loading={this.state.loading}
              onPageChange={newPage => this.handlePageChange(newPage)}
              onRowsPerPageChange={limit => this.handleRowsPerPageChange(limit)}
              onSelectedChanged={selected => this.setState({ selected })}
              paginationMode="server"
              rowCount={this.state.total}
              rows={this.state.patients}
              rowsPerPage={this.state.limit}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }
}

TabAnalysis.propTypes = {
  classes: PropTypes.object,
};

export default TabAnalysis;
