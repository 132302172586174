import { makeStyles } from 'tss-react/mui';
import { purple } from '@mui/material/colors';
import { mainDrawerWidth } from 'common';

export const useStylesMain = makeStyles()((theme, { width_sidebar }) => ({
  root: {
    palette: {
      primary: purple,
    },
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  shiftContent: {
    paddingLeft: width_sidebar ? width_sidebar : mainDrawerWidth,
  },
  side_mini: {
    paddingLeft: 65,
  },
  main: {
    height: '100%',
    minHeight: '100%',
  },
}));
