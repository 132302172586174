import { FormatTime, IsInvalid, IsInvalidParam } from 'helpers';

export const headers = [
  { field: 'idCountry', headerName: 'ID', width: '10%', align: 'left' },
  {
    field: 'country',
    headerName: 'Country',
    align: 'left',
    width: 250,
  },
  {
    field: 'cities',
    headerName: '#Total Cities',
    align: 'left',
    width: 250,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const cities = params.getValue('cities');
      if (IsInvalid(cities) || !cities.length) return 0;
      return cities.length;
    },
  },
  {
    field: 'centers',
    headerName: '#Total Centers',
    align: 'left',
    width: 250,
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const cities = params.getValue('cities');
      if (IsInvalid(cities) || !cities.length) return 0;
      let total = 0;
      cities.forEach(x => {
        total += x.centers.length;
      });
      return total;
    },
  },
];
export const headers_show = [
  { field: 'id', headerName: 'ID', width: '10%', align: 'left' },
  {
    field: 'name',
    headerName: 'Center',
    align: 'left',
    width: 250,
  },
  {
    field: 'country',
    headerName: 'Country',
    align: 'left',
    width: 250,
  },
  {
    field: 'city',
    headerName: 'City',
    align: 'left',
    width: 250,
    // valueGetter: params => {
    //   if (IsInvalidParam(params)) return '';
    //   const cities = params.getValue('cities');
    //   if (IsInvalid(cities) || !cities.length) return 0;
    //   return cities.length;
    // },
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
