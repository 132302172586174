import React from 'react';
import PropTypes from 'prop-types';
import {
  CenterSettings,
  CreateCenter,
  EditCenter,
  TabCentersHeader,
} from './components';
import { QueryAllCenters } from 'graphql/Units/utils_centers';
import { CreateCardHeader, TableCenters } from 'components';
import { GetData, ServerErrors } from 'helpers';
import MapIcon from '@mui/icons-material/Map';
import { TabSessionsMap } from '../../../TabSystemElements/components/TabSessions/components';

class TabCentersView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deleting: false,
      centers: [],
      init: false,
      page: 0,
      limit: 100,
      total: 0,
      open: '',
      selected: [],
      selected_centers: [],
      from: '',
    };
    this.holder = {
      getSelected: () => this.state.selected,
      getSearchText: null,
      handleReloadCountries: null,
    };
  }

  componentDidMount() {
    this.queryCenters();
  }
  componentDidUpdate() {
    if (!this.state.init) this.queryCenters();
  }
  queryCenters = (query = null, newPage = null, newLimit = null) => {
    if (!this.props.isActive) return;
    if (this.state.loading) return;
    let { name, page, limit } = this.state;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    let country = null;
    if (query) {
      if (query.name) name = query.name;
      if (query.country) country = query.country;
    }
    if (!name && this.holder.getSearchText) {
      name = this.holder.getSearchText();
    }
    this.setState({ loading: true });
    (async () => {
      QueryAllCenters({ name, country }, page, limit)
        .then(res => {
          const data = GetData(res);
          const { centers, ok, errors, total } = data.allCenters;
          if (ok) {
            //
            this.setState({
              loading: false,
              centers,
              init: true,
              page,
              limit,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, page, limit, init: true });
          let errors = ServerErrors(error);
          if (errors.length > 10) {
            const total = errors.length;
            errors = errors.splice(0, 10);
            errors.push(`There are ${total - 10} errors...`);
          }
          this.props.handleAddError(errors.join('\n'));
        });
    })();
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.queryCenters(null, newPage, this.state.limit);
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    this.queryCenters(null, 0, +event.target.value);
  };
  handleDeleteSelected = () => {};
  handleClose = () => {
    this.setState({ open: '' });
  };
  handleAddNewCenter = center => {
    const { centers } = this.state;
    const index = centers.map(x => x.id).indexOf(center.id);
    if (index === -1) {
      centers.push(center);
      this.setState({ centers, open: '' });
    } else {
      this.props.handleAddError('Center is already here');
    }
  };
  handleUpdatedCenter = center => {
    const { centers, selected } = this.state;
    let index = selected.map(x => x.id).indexOf(center.id);
    if (index !== -1) {
      selected[index] = center;
    }
    index = centers.map(x => x.id).indexOf(center.id);
    if (index !== -1) {
      centers[index] = center;
      this.setState({ centers, selected, open: '' });
    } else {
      this.props.handleAddError('Center is not here');
    }
  };
  handelAddNewCenters = newCenters => {
    const { centers } = this.state;
    for (let i = 0; i < newCenters.length; i++) {
      const { id } = newCenters[i];
      const index = centers.map(x => x.id).indexOf(id);
      if (index === -1) {
        centers.unshift(newCenters[i]);
      }
    }
    this.setState({ centers });
  };
  handleSetSelected = selected => {
    this.setState({ selected });
  };
  handleTabChange = open => {
    const options = ['settings', 'show'];
    let { selected, selected_centers, from } = this.state;
    if (selected_centers.length && this.state.open === 'maps') {
      if (from === '') open = 'settings';
      else open = from;
    }
    if (options.indexOf(this.state.open) !== -1) {
      selected = [];
    }
    this.setState({ open, selected });
  };
  handleReload = () => {
    const { open } = this.state;
    if (open === 'settings') {
      this.holder.handleReloadCountries();
    } else {
      this.queryCenters();
    }
  };
  render() {
    const { open, selected } = this.state;
    let selected_centers = this.state.selected_centers;
    if (open === 'create') {
      return (
        <CreateCenter
          handleAddError={this.props.handleAddError}
          handleAddNewCenter={this.handleAddNewCenter}
          handleClose={this.handleClose}
        />
      );
    } else if (open === 'edit') {
      return (
        <EditCenter
          handleAddError={this.props.handleAddError}
          handleClose={this.handleClose}
          handleUpdatedCenter={this.handleUpdatedCenter}
          selected={selected}
        />
      );
    } else if (open === 'maps') {
      if (!selected_centers.length) {
        selected_centers = this.state.centers;
      }
      const centers = selected_centers.filter(x => x.address);
      const total_cities = new Set(centers.map(({ address }) => address.city))
        .size;
      const tota_countries = new Set(
        centers.map(({ address }) => address.country)
      ).size;
      return (
        <>
          <CreateCardHeader
            handleClose={() => {
              this.handleTabChange('');
              this.setState({ selected_centers: [] });
            }}
            icon={<MapIcon style={{ color: 'blue' }} />}
            styles={{ padding: 5, paddingTop: 2, paddingBottom: 2 }}
            title={`Center's Map View of ${tota_countries} Countries and ${total_cities} Cities with ${centers.length} Centers `}
          />
          <TabSessionsMap
            height={this.props.height}
            open={open}
            sessions={centers.map(x => x.address)}
          />
        </>
      );
    }
    return (
      <div>
        <TabCentersHeader
          handelAddNewCenters={this.handelAddNewCenters}
          handleAddError={this.props.handleAddError}
          handleClearSelected={() => this.setState({ selected: [] })}
          handleDeleteSelected={this.handleDeleteSelected}
          handleReload={this.handleReload}
          handleSearchCenter={(name, country) =>
            this.queryCenters({ name, country })
          }
          handleTabChange={this.handleTabChange}
          holder={this.holder}
          loading={this.state.loading}
          open={open}
          selected={selected}
          selected_centers={selected_centers}
          setState={state => this.setState(state)}
        />
        {open === 'settings' || open === 'show' ? (
          <CenterSettings
            centers={this.state.centers}
            handleAddError={this.props.handleAddError}
            handleTabChange={this.handleTabChange}
            height={this.props.height}
            holder={this.holder}
            open={open}
            setState={state => this.setState(state)}
          />
        ) : (
          <TableCenters
            deleting={this.state.deleting}
            dense
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
            handleSetSelected={this.handleSetSelected}
            height={this.props.height - 90}
            hide_toolbar
            holder={this.holder}
            limit={this.state.limit}
            loading={this.state.loading}
            nowrap
            page={this.state.page}
            rows={this.state.centers}
            selected={selected}
            total={this.state.total}
          />
        )}
      </div>
    );
  }
}

TabCentersView.propTypes = {
  classes: PropTypes.object,
};

export default TabCentersView;
