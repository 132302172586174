import React from 'react';
import PropTypes from 'prop-types';

class PanelNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, patients } = this.props;
    let total = 0;
    patients.forEach(x => {
      const { NumberOfPatientRelatedStudies } = x;
      total += NumberOfPatientRelatedStudies;
    });
    return (
      <div className={classes.navigation}>
        <div>
          Search Results{' '}
          <span
            className={
              classes.badge
            }>{`${patients.length} Patient, ${total} Examinations`}</span>
        </div>
        <div
          style={{
            marginLeft: 'auto',
            display: 'flex',
            alignItems: 'center',
          }}>
          {/* <span className={classes.icon}>📋</span>
          <span className={classes.icon}>🔍</span>
          <span className={classes.icon}>📤</span>
          <span className={classes.icon}>🔧</span>
          <span className={classes.icon}>📁</span>
          <span className={classes.icon}>🔗</span>
          <span style={{ marginLeft: '10px' }}>1/1</span> */}
        </div>
      </div>
    );
  }
}

PanelNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default PanelNavigation;
