import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Grid, TextField } from '@mui/material';
import { CardButtonActions } from 'components/Cards/components';
import { MutationUpdateUnitSetting } from 'graphql/Units';
import { GetData, ServerErrorsString } from 'helpers';

class TabUnitLanding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      max_landing: -1,
      min_files_to_landing: -1,
      saving: false,
    };
  }
  componentDidUpdate() {
    const { unit } = this.props;
    if (unit) {
      const { setting } = unit;
      let state = null;
      if (this.state.max_landing < 0) {
        state = { max_landing: setting.max_landing };
      }
      if (this.state.min_files_to_landing < 0) {
        state = {
          ...state,
          min_files_to_landing: setting.min_files_to_landing,
        };
      }
      this.setState(state);
    }
  }
  handleChange = e => {
    this.setState({ [e.target.id]: parseInt(e.target.value, 10) });
  };
  handleUpdateSetting = () => {
    const { unit } = this.props;
    const { saving, max_landing, min_files_to_landing } = this.state;
    if (saving) return;
    const { idUnit } = unit;
    this.setState({ saving: true });
    (async () => {
      MutationUpdateUnitSetting(idUnit, { max_landing, min_files_to_landing })
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.updateUnitSetting;
          if (ok) {
            this.setState({
              saving: false,
              max_landing: -1,
              min_files_to_landing: -1,
            });
            this.props.setUnit({
              ...unit,
              setting: { ...unit.setting, max_landing, min_files_to_landing },
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ saving: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  IsChanged = () => {
    const { max_landing, min_files_to_landing } = this.state;
    if (max_landing < 0 && min_files_to_landing < 0) return false;
    const {
      unit: { setting },
    } = this.props;
    return (
      max_landing !== setting.max_landing ||
      min_files_to_landing !== setting.min_files_to_landing
    );
  };
  render() {
    const { classes, height, unit } = this.props;
    const relative_height = height - 270;
    console.log({ unit });
    return (
      <Card className={classes.root} style={{ height: relative_height }}>
        <CardContent sx={{ paddingBottom: 0 }}>
          <Grid container spacing={3} sx={{ height: relative_height - 49 }}>
            <Grid item>
              <TextField
                id="max_landing"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Max Running Landing"
                onChange={this.handleChange}
                placeholder="Less than 10"
                type="number"
                value={this.state.max_landing}
              />
            </Grid>
            <Grid item>
              <TextField
                id="min_files_to_landing"
                InputLabelProps={{
                  shrink: true,
                }}
                label="Min Files for Landing"
                onChange={this.handleChange}
                placeholder="More than 0"
                type="number"
                value={this.state.min_files_to_landing}
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardButtonActions
          changed={this.IsChanged()}
          handleSaveClicked={() => this.handleUpdateSetting()}
          loading={this.state.saving}
          sx={{ borderTop: '1px solid gray', paddingTop: 0, paddingBottom: 0 }}
        />
      </Card>
    );
  }
}

TabUnitLanding.propTypes = {
  classes: PropTypes.object,
};

export default TabUnitLanding;
