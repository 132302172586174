import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';

const MUATION_UPDATE_UNIT_SETTING = gql`
  mutation($idUnit: LongLong!, $setting: InputUnitSetting) {
    updateUnitSetting(idUnit: $idUnit, setting: $setting) {
      ok
      errors {
        path
        message
      }
    }
  }
`;

export const MutationUpdateUnitSetting = (idUnit, setting) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUATION_UPDATE_UNIT_SETTING),
    variables: {
      idUnit,
      setting,
    },
  });
};
