import {
  PAGE_DICOM_DISPLAY_SERIES,
  PAGE_DICOM_DISPLAY_STUDY,
  PAGE_DICOM_DISPLAY_MULTI_SERIES,
} from './types';

export const setDisplayDicomSeries = series => {
  return {
    type: PAGE_DICOM_DISPLAY_SERIES,
    series,
  };
};
export const setDisplayDicomStudy = study => {
  return {
    type: PAGE_DICOM_DISPLAY_STUDY,
    study,
  };
};
export const setDisplayDicomMultiSeries = ListSeries => {
  console.log({ ListSeries });
  return {
    type: PAGE_DICOM_DISPLAY_MULTI_SERIES,
    ListSeries,
  };
};
