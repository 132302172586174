import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import { TableGrid } from 'components';
import { header_study_search, ToolBarBrowserProjectSeries } from './components';
import {
  GetData,
  IsInvalid,
  rowsPerPageBig,
  ServerErrorsString,
} from 'helpers';
import { QueryProjectSeries } from 'graphql/Projects';

class TabStudySearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      series: [],
      total: 0,
      page: 0,
      limit: 100,
      selected: [],
      query: {},
    };
  }
  loadProjectSeries = (newPage = 0, newLimit = null) => {
    const { project } = this.props;
    if (IsInvalid(project)) {
      this.props.handleAddError('Invalid project, please reload the page');
      return;
    }
    let { loading, page, limit, query } = this.state;
    if (loading) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    (async () => {
      QueryProjectSeries(project.id, query, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, series, total, errors } = data.projectSeriesBrowser;
          if (ok) {
            this.setState({ series, total, loading: false, page, limit });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, page, limit });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { project, isAdmin, isActive, height } = this.props;
    const { loading, series } = this.state;
    // console.log({ height });
    return (
      <div>
        <ToolBarBrowserProjectSeries
          isActive={isActive}
          isAdmin={isAdmin}
          loading={loading}
          loadProjectSeries={this.loadProjectSeries}
          project={project}
          setState={state => this.setState(state)}
          state={this.state}
        />
        <Paper sx={{ marginTop: 1 }}>
          <TableGrid
            checkboxSelection
            dense
            external_selected={this.state.selected}
            headers={header_study_search}
            height={height - 110}
            idRow="idSeries"
            loading={loading}
            onPageChange={page =>
              this.loadProjectSeries(page, this.state.limit)
            }
            onRowsPerPageChange={limit => this.loadProjectSeries(0, limit)}
            onSelectedChanged={selected => this.setState({ selected })}
            page={this.state.page}
            paginationMode="server"
            rowCount={this.state.total}
            rows={series}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
          />
        </Paper>
      </div>
    );
  }
}

TabStudySearch.propTypes = {
  classes: PropTypes.object,
};

export default TabStudySearch;
