import React from 'react';
import PropTypes from 'prop-types';
import { Button, ListItem } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import { CustomRouterLink } from 'common';
import { getColorIcon } from 'theme';

class SidebarNavItemBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { open, menu, classes, isSelected, page, isDicomViewer } = this.props;
    let ComponentBase = null;
    if (menu && menu.length) {
      ComponentBase = (
        <Button className={classes.button} onClick={this.props.onClick}>
          <div
            className={clsx({
              [classes.button_content]: true,
              [classes.button_content_viewer]: isDicomViewer,
            })}>
            <div
              className={clsx({
                [classes.icon]: true,
                [classes.icon_viewer]: isDicomViewer,
              })}>
              {page.icon}
            </div>
            {page.title}
          </div>
          {page.menu && page.menu.length ? (
            open ? (
              <ExpandLess
                sx={{
                  color: getColorIcon(isDicomViewer),
                }}
              />
            ) : (
              <ExpandMore
                sx={{
                  color: getColorIcon(isDicomViewer),
                }}
              />
            )
          ) : null}
        </Button>
      );
    } else {
      ComponentBase = (
        <Button
          activeClassName={classes.active}
          className={classes.button}
          component={CustomRouterLink}
          onClick={this.props.onClick}
          to={page.href}>
          <div
            className={clsx({
              [classes.button_content]: true,
              [classes.button_content_viewer]: isDicomViewer,
            })}>
            <div
              className={clsx({
                [classes.icon]: true,
                [classes.icon_viewer]: isDicomViewer,
              })}>
              {page.icon}
            </div>
            {page.title}
          </div>
          {page.menu && page.menu.length ? (
            open ? (
              <ExpandLess
                sx={{
                  color: getColorIcon(isDicomViewer),
                }}
              />
            ) : (
              <ExpandMore sx={{ color: getColorIcon(isDicomViewer) }} />
            )
          ) : null}
        </Button>
      );
    }
    return (
      <ListItem
        className={clsx({
          [classes.item]: true,
          [classes.selected]: isSelected,
        })}
        disableGutters
        onClick={() => this.props.handleSelected(page.title)}>
        {ComponentBase}
      </ListItem>
    );
  }
}

SidebarNavItemBase.propTypes = {
  classes: PropTypes.object,
};

export default SidebarNavItemBase;
