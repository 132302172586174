export const getStyles = (selectedItems, isGridOpen, justifyContent) => ({
  body: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 20,
    display: 'flex',
    gap: 20,
  },
  container: {
    display: 'flex',
    gap: 20,
    width: '100%',
  },
  leftSection: {
    flex: 1,
  },
  rightSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  dropContainer: {
    border: '2px dashed #ccc',
    borderRadius: 5,
    padding: 15,
    minHeight: 400,
    flexGrow: 1,
    marginBottom: 10,
  },
  dropZone: {
    minHeight: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    alignContent: 'flex-start',
  },
  dropZoneText: {
    width: '100%',
    textAlign: 'center',
    color: '#888',
    padding: 20,
  },
  imagesSection: {
    border: '1px solid #ddd',
    borderRadius: 5,
    overflow: 'hidden',
  },
  sectionHeader: {
    backgroundColor: 'transparent',
    padding: '10px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
    borderBottom: '1px solid gray',
  },
  icon: {
    transition: 'transform 0.3s',
    transform: isGridOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
  },
  imageGrid: {
    display: isGridOpen ? 'grid' : 'none',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: 10,
    padding: 15,
    justifyContent: justifyContent,
    alignItems: 'center',
  },
  imageItem: {
    width: 200,
    height: 200,
    backgroundColor: 'black',
    borderRadius: 4,
    cursor: 'grab',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '2px solid transparent',
  },
  imageItemBefore: {
    content: '✓',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
  },
  droppedItem: {
    width: 80,
    height: 80,
    margin: 5,
    transition: 'border 0.2s ease',
  },
  selected: {
    border: '2px solid #4CAF50',
    boxShadow: '0 0 5px rgba(76, 175, 80, 0.5)',
    position: 'relative',
  },
  selectedAfter: {
    content: '"✓"',
    position: 'absolute',
    top: 2,
    right: 2,
    width: 24,
    height: 24,
    backgroundColor: '#4CAF50',
    color: '#FFFFFF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    zIndex: 2,
    boxShadow: '0 0 2px rgba(0, 0, 0, 0.3)',
  },
  sourceDropped: {
    border: '2px solid #F44336',
    boxShadow: '0 0 5px rgba(244, 67, 54, 0.5)',
    position: 'relative',
  },
  sourceDroppedAfter: {
    position: 'absolute',
    top: 1,
    right: 1,
    width: 20,
    height: 20,
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    zIndex: 2,
    boxShadow: '0 0 2px rgba(0, 0, 0, 0.3)',
  },
  trashContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  trashButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: selectedItems.length > 0 ? '#ffebee' : '#f5f5f5',
    border: `1px solid ${selectedItems.length > 0 ? '#ef5350' : '#ddd'}`,
    borderRadius: 4,
    padding: '8px 15px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
  },
  trashSvg: {
    marginRight: 8,
    fill: selectedItems.length > 0 ? '#ef5350' : 'currentColor',
  },
});
