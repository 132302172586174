import { colors } from '@mui/material';
// eslint-disable-next-line no-unused-vars
import { colorDicomViewerIcons, colorDicomViewerText } from 'theme';

export const useStyles = theme => {
  // console.log({ main: theme.palette.primary.main, icon: theme.palette.icon });
  return {
    root: {},
    item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
    },
    button: {
      color: colors.blueGrey[800],
      padding: '10px 8px',
      justifyContent: 'space-between', //'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
      fontWeight: theme.typography.fontWeightMedium,
    },
    button_content: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
    },
    button_content_viewer: {
      color: colorDicomViewerText,
    },
    icon: {
      icon: '#546e7a', // theme.palette.icon,
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },
    icon_viewer: {
      color: colorDicomViewerIcons,
    },
    active: {
      fontWeight: theme.typography.fontWeightMedium,
    },
    menu_item: {
      fontSize: '13px',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    selected: {
      backgroundColor: '#c8c4c4',
      color: 'white',
      fontWeight: 600,
      borderLeft: '4px solid #949494',
    },
    menu_item_selected: {
      backgroundColor: '#d5d3d3',
      fontWeight: 600,
      borderLeft: '4px solid #898989',
      color: 'grey',
    },
  };
};
