import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { headers, stableSort, getComparator } from '../utils_table_survey';
import TableHeadSurvey from '../TableHeadSurvey';
import { TableSurveyPatientMainRow } from './components';
import { CircularProgressWaiting } from 'components';
class TableSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      rows,
      selected,
      order,
      orderBy,
      rowsPerPage,
      page,
      emptyRows,
      dense,
      loading,
    } = this.props;
    return (
      <Table
        aria-labelledby="tableTitle"
        size={dense ? 'small' : 'medium'}
        stickyHeader
        sx={{ minWidth: 750, whiteSpace: 'nowrap' }}>
        <TableHeadSurvey
          headers={headers}
          numSelected={selected.length}
          onRequestSort={this.props.handleRequestSort}
          onSelectAllClick={this.props.handleSelectAllClick}
          order={order}
          orderBy={orderBy}
          rowCount={rows.length}
        />
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell colSpan={headers.length}>
                <CircularProgressWaiting style={{ minHeight: 200 }} />
              </TableCell>
            </TableRow>
          ) : (
            stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = this.props.isSelected(row);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableSurveyPatientMainRow
                    handleClick={this.props.handleClick}
                    handleOpenPatient={this.props.handleOpenPatient}
                    isItemSelected={isItemSelected}
                    key={`table-row-${index}`}
                    labelId={labelId}
                    row={row}
                  />
                );
              })
          )}
          {emptyRows > 0 && (
            <TableRow
              style={{
                height: (dense ? 33 : 53) * emptyRows,
              }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    );
  }
}

TableSurvey.propTypes = {
  handleOpenPatient: PropTypes.func,
};
TableSurvey.defaultProps = {
  handleOpenPatient: () => '',
};

export default TableSurvey;
