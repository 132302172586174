import React from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
// import clsx from 'clsx';
import PropTypes from 'prop-types';
import { AppBar, Avatar, Divider, Toolbar } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { clearLoginError, login } from 'redux-store/actions/loginActions';
import { logout } from 'redux-store/actions';

import { DivGrow } from 'components';
import { getInitials } from 'helpers';
import { StyledMenu, StyledMenuItem } from 'components/StyledMenu';
import { useMenuStyle } from 'helpers/MainStyles';
import { MedicalBlocksLogo } from 'layouts/components';

const useStyles = {
  root: {
    boxShadow: 'none',
    background: '#095275',
  },
  ...useMenuStyle,
  avatar: {
    border: '3px solid #c0c0c0',
  },
};

class Topbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleLogOut = () => {
    this.setState({ anchorEl: null });
    this.props.logout();
    this.props.history.push('/');
  };
  handleClickMenu = url => {
    this.setState({ anchorEl: null });
    this.props.history.push(url);
  };

  render() {
    const { classes, user } = this.props;
    const { anchorEl } = this.state;
    let MenuLogin = null;
    if (
      user &&
      typeof user !== 'undefined' &&
      typeof user.FullName !== 'undefined'
    ) {
      // const style_menu_item = classNames(classes.dropdownItem);
      MenuLogin = (
        <React.Fragment>
          <Avatar
            alt={getInitials(user.FullName)}
            className={classNames(classes.avatar)}
            onClick={this.handleClick}
            src={user.avatar}
          />
          <StyledMenu
            anchorEl={anchorEl}
            id="customized-menu"
            keepMounted
            onClose={() => this.setState({ anchorEl: null })}
            open={Boolean(anchorEl)}>
            <div className={classes.menu}>
              <StyledMenuItem disabled>Menu</StyledMenuItem>
              <Divider />
              <StyledMenuItem
                onClick={() => this.handleClickMenu('/account/profile')}>
                Account
              </StyledMenuItem>
              <StyledMenuItem
                onClick={() => this.handleClickMenu('/dashboard')}>
                Dashboard
              </StyledMenuItem>
              <Divider />
              <StyledMenuItem onClick={this.handleLogOut}>
                LogOut
              </StyledMenuItem>
            </div>
          </StyledMenu>
        </React.Fragment>
      );
    }
    return (
      <AppBar className={classes.root} color="primary" position="fixed">
        <Toolbar>
          <RouterLink to="/">
            <MedicalBlocksLogo />
          </RouterLink>
          <DivGrow />
          {MenuLogin}
        </Toolbar>
      </AppBar>
    );
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.info_user.user,
  errors: state.info_user.errors,
});

export default connect(mapStateToProps, { login, logout, clearLoginError })(
  withStyles(withRouter(Topbar), useStyles)
);
