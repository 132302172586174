/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';

import { ExplorerViewGalleryItem } from './components';
import { getSorting, stableSort, IsFolder } from 'helpers';
import { columns_explorer } from 'helpers/explorer';
import { ExplorerFloatingMenu } from 'views/Explorer/components/ExplorerViews/components';
import { CircularProgressWaiting } from 'components';

const useStyles = theme => ({
  root_ex_gallery: {
    flexGrow: 1,
    margin: 0,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    width: '100%',
    // height: '100%',
  },
  paper: {
    height: 110,
    width: 100,
  },
});

class ExplorerViewGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rerender: false,
      order: 'asc',
      orderBy: 'name',
      contextMenu: null,
      itemOver: null,
      total_folder_objects: 0,
    };
    if (props.holder !== undefined) {
      props.holder.renderAgain = () => {
        console.log('forceUpdate');
        this.setState({ rerender: !this.state.rerender });
        this.forceUpdate();
      };
    }
  }
  handleContextMenu = event => {
    event.preventDefault();
    // if (!this.state.itemOver) return;
    const { contextMenu } = this.state;
    this.setState({
      contextMenu:
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null,
    });
  };
  handleMouseOverItem = itemOver => {
    if (this.state.contextMenu && !itemOver) return;
    this.setState({ itemOver });
  };
  isContetSelected = item => {
    const { itemOver } = this.state;
    if (itemOver === undefined || !itemOver) return false;
    const overFolder = IsFolder(itemOver);
    if (overFolder) return itemOver.idFolder === item.idFolder;
    else if (!overFolder)
      return itemOver.idFolderContent === item.idFolderContent;
    else return false;
  };
  handleCleanItemOver = () => {
    this.setState({ itemOver: null });
  };
  handleCurrentFolderChanged = () => {
    let { itemOver } = this.state;
    const { Folder } = this.props;
    const { SubFolders, Files } = Folder;

    const total_folder_objects = SubFolders.length + Files.length;
    if (!total_folder_objects) itemOver = null;
    this.setState({
      total_folder_objects,
      itemOver,
    });
    this.props.handleCurrentFolderChanged();
  };
  render() {
    const {
      classes,
      SubFolders,
      Files,
      cut_objects,
      loading,
      idHomeFolder,
      page,
      rowsPerPage,
    } = this.props;
    if (loading) {
      return (
        <CircularProgressWaiting className={classes.grid} direction="column" />
      );
    }
    const cutFolderIds = cut_objects
      .filter(x => x.idFolder)
      .map(x => x.idFolder);
    let rows = SubFolders.filter(x => cutFolderIds.indexOf(x.idFolder) === -1);
    const cutObjectsIds = cut_objects
      .filter(x => x.idFolderContent)
      .map(x => x.idFolderContent);
    rows = rows.concat(
      Files.filter(x => cutObjectsIds.indexOf(x.idFolderContent) === -1)
    );
    let { order, orderBy } = this.state;
    if (this.props.order) {
      order = this.props.order;
    }
    if (this.props.orderBy) {
      orderBy = this.props.orderBy;
    }
    let style = null;
    if (this.state.itemOver) {
      style = { cursor: 'context-menu' };
    }
    return (
      <div
        className={classes.root_ex_gallery}
        onContextMenu={this.handleContextMenu}
        onFocus={() => {}} // Ensure the element can receive focus
        role="button" // Or another appropriate role based on the actual purpose
        style={style}
        tabIndex={0} // Ensure the element is focusable
      >
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}>
          {stableSort(rows, getSorting(order, orderBy, columns_explorer))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((folder_item, index) => {
              const { idFolder } = folder_item;
              let type = 'file';
              if (idFolder) type = 'folder';
              const isSelected = this.props.isSelected(folder_item);
              const isContextMenu = this.isContetSelected(folder_item);
              return (
                <Grid item key={`item-folder-grid-${type}-${index}`}>
                  <Paper className={classes.paper}>
                    <ExplorerViewGalleryItem
                      handleCleanItemOver={this.handleCleanItemOver}
                      handleClickFolderItem={this.props.handleClickFolderItem}
                      handleMouseOverItem={this.handleMouseOverItem}
                      handleOpenAtDoubleClick={
                        this.props.handleOpenAtDoubleClick
                      }
                      handleSelected={this.props.handleSelected}
                      handleSubFolderNameChanged={
                        this.props.handleSubFolderNameChanged
                      }
                      holder={this.props.holder}
                      home_change={this.props.home_change}
                      idHomeFolder={idHomeFolder}
                      isContextMenu={isContextMenu > 0 ? true : false}
                      isSelected={isSelected}
                      value={folder_item}
                    />
                  </Paper>
                </Grid>
              );
            })}
        </Grid>
        <ExplorerFloatingMenu
          cleanSelections={this.props.cleanSelections}
          contextMenu={this.state.contextMenu}
          Folder={this.props.Folder}
          handleAddError={this.props.handleAddError}
          handleCleanItemOver={() => this.setState({ itemOver: null })}
          handleClose={() => this.setState({ contextMenu: null })}
          handleCurrentFolderChanged={this.handleCurrentFolderChanged}
          handleDeleteFoldersAndContent={
            this.props.handleDeleteFoldersAndContent
          }
          handleItemChanged={this.props.handleItemChanged}
          handleProgress={this.props.handleProgress}
          handleRenameFolder={this.props.handleRenameFolder}
          itemOver={this.state.itemOver}
          selected_objects={this.props.selected_objects}
        />
      </div>
    );
  }
}

ExplorerViewGallery.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = state => {
  const { status, home_change } = state.page_explorer;
  let default_page = 0;
  let default_rowsPerPage = 500;
  if (status) {
    if (status.page) default_page = status.page;
    if (status.rowsPerPage) default_rowsPerPage = status.rowsPerPage;
  }
  return {
    home_change,
    page: default_page,
    rowsPerPage: default_rowsPerPage,
  };
};
export default connect(mapStateToProps)(
  withStyles(ExplorerViewGallery, useStyles)
);
