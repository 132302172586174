import moment from 'moment';
import { MutationBigFiles } from './utils_chunk_upload';
import { MutationUploadInputs } from './utils_single_upload';
import { max_file_size, MUTATION_FILE_UPLOAD } from './utls_gql_upload';

export const getFileInfoForEmail = files => {
  try {
    if (files) {
      return files.map(file => {
        const { name, size, lastModifiedDate } = file;
        return {
          filename: name ? name : 'Undefined-Name',
          filesize: size ? size : 0,
          lastModifiedDate: lastModifiedDate
            ? lastModifiedDate
            : `${moment().valueOf()}`,
        };
      });
    } else {
      throw Error('Files is invalid');
    }
  } catch (error) {
    console.log({ error });
    return [];
  }
};
export const MutationUploadFile = (
  file,
  handleProgress,
  idFolder,
  settings,
  file_info
) => {
  let files_information = [];
  if (settings && settings.send_project_email) {
    files_information = getFileInfoForEmail(file_info);
  }
  const { size } = file;
  const query = { file };
  console.log({ file: file ? { name: file.name, size: file.size } : null });
  if (idFolder) {
    query.settings = {
      folderIds: [idFolder],
      files_information,
    };
  }
  if (settings) {
    query.settings = settings;
  }
  if (size < max_file_size) {
    return MutationUploadInputs(query, MUTATION_FILE_UPLOAD, handleProgress);
  } else {
    return MutationBigFiles(file, query, handleProgress);
  }
};
