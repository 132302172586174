import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useMakeStyles } from '../Styles';
import { GetData, ServerErrorsString } from 'helpers';
import SearchAutocomplete from '../SearchAutocomplete';
import { QueryAccountTypes } from 'graphql/Users';

const SearchUserAccountTypes = props => {
  let {
    style,
    external_options,
    external_selected,
    // idUser,
    sytle_autocomplete = null,
    style_textfield_param = null,
    style_menu = null,
    colorIcons = null,
  } = props;
  if (external_options === undefined || !external_options) {
    external_options = [];
  }
  const { classes } = useMakeStyles();

  const [name, setAccountName] = React.useState('');
  const [account, setAccount] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState(external_options);
  let loading = open && options.length === 0;
  if (external_options.length) {
    loading = false;
    if (options.length !== external_options.length) {
      setOptions(external_options);
    }
  }
  if (external_selected && account && account.id !== external_selected.id) {
    setAccount(external_selected);
  }
  React.useEffect(() => {
    if (!loading || external_options.length) {
      return undefined;
    }
    (async () => {
      QueryAccountTypes(name)
        .then(res => {
          const data = GetData(res);
          const { ok, accounts, errors } = data.accountTypes;
          if (ok) {
            if (accounts.length === 0) {
              accounts.push({ name: 'Empty' });
            } else accounts.unshift({ description: 'None', id: 0 });
          } else {
            throw errors;
          }
          setOptions(accounts);
        })
        .catch(error => {
          this.setState({ loading_types: false, empty: true });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  }, [loading, name]);
  const handelAccountNameChanged = event => {
    const name = event.target.value;
    //
    if (options.length) {
      const here = options.filter(x =>
        x.name.toUpperCase().startsWith(name.toUpperCase())
      );
      //
      if (!here.length || !name.length) {
        setOptions([]);
      }
    }
    setAccountName(name);
  };
  const handleAddAccount = account => {
    setAccount(account);
    props.handleAddAccount(account);
  };
  const handleSetAccount = account => {
    setAccount(account);
    if (props.handleSetAccount) {
      props.handleSetAccount(account);
    }
  };
  if (props.no_icon) {
    return (
      <SearchAutocomplete
        clean_output={props.clean_output}
        colorIcons={colorIcons}
        disabled={props.disabled}
        getOptionLabel={option => {
          if (option) {
            const { description } = option;
            if (description) return description;
          }
          return '';
        }}
        handelSearchNameChanged={handelAccountNameChanged}
        handleAddResultObject={handleAddAccount}
        isOptionEqualToValue={(option, value) => {
          if (value) return option.description === value.description;
          if (option.description === '') return true;
          return false;
        }}
        label={props.label}
        loading={loading}
        name={name}
        no_icon
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={account}
        setObject={handleSetAccount}
        setObjectName={setAccountName}
        setOpen={setOpen}
        setOptions={setOptions}
        style_menu={style_menu}
        style_textfield_param={style_textfield_param}
        sytle_autocomplete={sytle_autocomplete}
      />
    );
  }
  return (
    <Paper className={classes.root} style={style}>
      <SearchIcon className={classes.icon} />
      <SearchAutocomplete
        clean_output={props.clean_output}
        disabled={props.disabled}
        getOptionLabel={option => {
          if (option) {
            const { description } = option;
            if (description) return description;
          }
          return '';
        }}
        handelSearchNameChanged={handelAccountNameChanged}
        handleAddResultObject={handleAddAccount}
        isOptionEqualToValue={(option, value) => {
          if (value) return option.description === value.description;
          if (option.description === '') return true;
          return false;
        }}
        label={props.label}
        loading={loading}
        name={name}
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={account}
        setObject={handleSetAccount}
        setObjectName={setAccountName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    </Paper>
  );
};

SearchUserAccountTypes.propTypes = {
  className: PropTypes.string,
  clean_output: PropTypes.bool.isRequired,
  external_options: PropTypes.array.isRequired,
  external_selected: PropTypes.object,
  handleSetAccount: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SearchUserAccountTypes.defaultProps = {
  placeholder: 'Account Types...',
  handleSetAccount: null,
  no_icon: false,
  external_options: [],
  clean_output: true,
  external_selected: null,
  label: 'Accounts',
  disabled: false,
  handleAddAccount: () => '',
  handleAddError: () => '',
};

export default SearchUserAccountTypes;
