import { utilities as csUtils } from '@cornerstonejs/core';
import { IsInvalid } from 'helpers';
import { detectMainView } from './utils_orientation';
import {
  get2DImageDimensions,
  getMousePosition,
  GetOrientationFromViewport,
  validOrientations,
} from './utils_viewport';
import { orientationLetters } from './utils';

export const onVolumeMouseMove = (canvasPos, viewport, THIS) => {
  const { volume } = THIS.state;
  if (!volume) {
    return {};
  }
  // Convert canvas coordinates to world coordinates
  const worldPos = viewport.canvasToWorld(canvasPos);
  const { dimensions, imageData } = volume;
  const index = imageData.worldToIndex(worldPos);
  index[0] = Math.floor(index[0]);
  index[1] = Math.floor(index[1]);
  index[2] = Math.floor(index[2]);
  // Get the view plane normal from viewport info
  const orientation = GetOrientationFromViewport(viewport);
  let { zoom, mouse, imSize, default_orientation } = THIS.state;
  // Calculate 2D pixel coordinates based on orientation
  let pixelPos = [mouse.x, mouse.y];
  mouse = {
    Wx: worldPos[0].toFixed(2),
    Wy: worldPos[1].toFixed(2),
    Wz: worldPos[2].toFixed(2),
    x: pixelPos[0],
    y: pixelPos[1],
    value: 0,
  };

  if (!validOrientations.includes(default_orientation.toLowerCase())) {
    default_orientation = ReCalculateOrientation(THIS);
    if (IsInvalid(default_orientation)) return;
  }
  // console.log({ dimensions });
  if (csUtils.indexWithinDimensions(index, dimensions)) {
    mouse.value = volume.voxelManager.getAtIJK(index[0], index[1], index[2]);
    pixelPos = getMousePosition(index, orientation, default_orientation);
    mouse.x = pixelPos[0];
    mouse.y = pixelPos[1];
  }
  const camera = viewport.getCamera();
  // Get zoom factor (if available from the camera)
  if (camera && camera.parallelScale) {
    const zoomFactor = (1 / camera.parallelScale) * 100;
    zoom = zoomFactor.toFixed(2);
  }
  let viewport_info = imSize;
  try {
    viewport_info = viewport.getSliceViewInfo();
  } catch (error) {
    viewport_info = get2DImageDimensions(
      dimensions,
      orientation,
      default_orientation
    );
  }
  imSize = {
    width: viewport_info.width,
    height: viewport_info.height,
  };
  return {
    zoom,
    mouse,
    imSize,
    default_orientation,
  };
};
export const onStackMouseMove = (canvasPos, viewport, THIS) => {
  if (IsInvalid(viewport)) return;
  let {
    zoom,
    mouse,
    default_orientation,
    imSize,
    orientation_letters,
  } = THIS.state;
  if (!validOrientations.includes(default_orientation.toLowerCase())) {
    default_orientation = ReCalculateOrientation(THIS);
    if (IsInvalid(default_orientation)) return;
  }
  // Convert canvas coordinates to world coordinates
  const worldPos = viewport.canvasToWorld(canvasPos);
  mouse.Wx = worldPos[0].toFixed(2);
  mouse.Wy = worldPos[1].toFixed(2);
  mouse.Wz = worldPos[2].toFixed(2);
  mouse.value = 0;
  const idImage = viewport.getCurrentImageId();
  let imagePos = [0, 0];
  try {
    imagePos = csUtils.worldToImageCoords(idImage, worldPos);
  } catch (error) {
    // console.error('worldToImageCoords', { error });
  }
  const orientation = GetOrientationFromViewport(viewport);
  let imageData = null;
  try {
    imageData = viewport.getImageData().imageData;
  } catch (error) {
    console.error('getImageData', { error });
    return;
  }
  // if (!viewport.getImageData) return;
  // const { imageData } = viewport.getImageData();
  if (imageData) {
    let voxelManager = viewport.getImageData(idImage).voxelManager;
    let index = imageData.worldToIndex(worldPos);
    index[0] = Math.floor(index[0]);
    index[1] = Math.floor(index[1]);
    index[2] = Math.floor(index[2]);
    if (index[0] < 0) index[0] = 0;
    if (index[1] < 0) index[1] = 0;
    if (index[2] < 0) index[2] = 0;
    if (index[0] >= 0 && index[1] >= 0 && index[2] >= 0) {
      const value = voxelManager.getAtIJK(index[0], index[1], index[2]);
      const pixelPos = getMousePosition(
        index,
        orientation,
        default_orientation
      );
      if (value !== undefined && value !== null) mouse.value = value;
      mouse.x = Math.round(pixelPos[0]);
      mouse.y = Math.round(pixelPos[1]);
      // console.log({ pixelPos, imagePos });
    } else {
      console.log('index - null', { index });
      mouse.x = Math.round(imagePos[0]);
      mouse.y = Math.round(imagePos[1]);
    }
  } else {
    console.log('imageData - null');
    mouse.x = Math.round(imagePos[0]);
    mouse.y = Math.round(imagePos[1]);
  }
  const camera = viewport.getCamera();
  // Get zoom factor (if available from the camera)
  if (camera && camera.parallelScale) {
    const { viewUp, viewPlaneNormal } = camera;
    if (orientation_letters[0] === 0)
      orientation_letters = orientationLetters(viewPlaneNormal, viewUp);
    const zoomFactor = (1 / camera.parallelScale) * 100;
    zoom = zoomFactor.toFixed(2);
  }
  return {
    zoom,
    mouse,
    imSize,
    default_orientation,
    orientation_letters,
  };
};
export const ReCalculateOrientation = THIS => {
  const { series } = THIS.state;
  if (IsInvalid(series)) {
    console.error('Re-Calculate - error -series', {
      series,
    });
    return null;
  }
  let { default_orientation } = THIS.state;
  const {
    ImagePlane: { ImageOrientationPatient },
  } = series;
  const prev_orientation = default_orientation;
  default_orientation = detectMainView(ImageOrientationPatient);
  console.log('Re-Calculate', {
    prev_orientation,
    default_orientation,
    ImageOrientationPatient,
  });
  return default_orientation;
};
