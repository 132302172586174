/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Enums,
  eventTarget,
  getRenderingEngine,
  utilities as csUtils,
  metaData,
  cache,
} from '@cornerstonejs/core';
import { init } from '@cornerstonejs/dicom-image-loader';
import * as cornerstoneTools from '@cornerstonejs/tools';
import dicomParser from 'dicom-parser';
import * as cornerstone from '@cornerstonejs/core';

import { ComponentCorners } from './components';
import {
  backgroundColorStack,
  backgroundColorVolume,
  checkForSelectedAnnotation,
  cleanupCurrentVolume,
  cleanupHeightDetection,
  computeOrientationLetters,
  createImageIdsAndCacheMetaDataFiles,
  deleteSelectedAnnotation,
  detectMainView,
  get2DImageDimensions,
  getNewOrientationLetters,
  getSeriesImageOrientation,
  InitAfterLoad,
  initializeViewer,
  isAxisOriented,
  isSeriesAxisOriented,
  IsSeriesForStack,
  loadVolume,
  onStackMouseMove,
  onVolumeMouseMove,
  orientationLetters,
  rotateCamera,
  setCameraVectors,
  setDefaultCamera,
  setupHeightDetection,
  setViewportColormap,
  updateWidthHeight,
  validOrientations,
  viewer_black,
  volumeLoaderScheme,
} from './components/ComponentCorners/utils';
import {
  GetData,
  IsInvalid,
  IsInvalidNumber,
  ServerErrorsString,
} from 'helpers';
import { initDemo } from '../helpers';
import { QuerySeriesFileIDs } from 'graphql/Series';
import { Box, LinearProgress } from '@mui/material';

const {
  IMAGE_CACHE_IMAGE_ADDED,
  VOLUME_VIEWPORT_SCROLL,
  STACK_VIEWPORT_SCROLL,
  VOLUME_LOADED,
  IMAGE_VOLUME_LOADING_COMPLETED,
  VOLUME_CACHE_VOLUME_ADDED,
} = Enums.Events;
const {
  ToolGroupManager,
  StackScrollTool,
  ZoomTool,
  PanTool,
  Enums: csToolsEnums,
  WindowLevelTool,
  LengthTool,
  AngleTool,
  CircleROITool,
  BidirectionalTool,
  annotation: annotationManager,
} = cornerstoneTools;

const { MouseBindings } = csToolsEnums;

const leftClickTools = [
  WindowLevelTool.toolName,
  PanTool.toolName,
  ZoomTool.toolName,
  StackScrollTool.toolName,
];
const measureTools = [
  LengthTool.toolName,
  AngleTool.toolName,
  CircleROITool.toolName,
  BidirectionalTool.toolName,
];
const viewportId = 'CT_SAGITTAL_STACK';
const renderingEngineId = 'myRenderingEngine';
const toolGroupId = 'STACK_TOOL_GROUP_ID';
const defaultLeftClickTool = leftClickTools[3];
const initialColormapNames = [
  'Grayscale',
  'Black-Body Radiation',
  'Cool to Warm (Extended)',
];
const getViewport = () => {
  // Get the Engine
  const renderingEngine = getRenderingEngine(renderingEngineId);
  // console.log({ renderingEngine });
  if (IsInvalid(renderingEngine)) return null;
  // Get the viewport
  return renderingEngine.getViewport(viewportId);
};
class ViewerCorner2D extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aspectRatio: 1.0,
      loaded: [],
      volume: null,
      currentLeftClickTool: defaultLeftClickTool,
      viewportIds: ['CT_AXIAL', 'CT_SAGITTAL', 'CT_OBLIQUE'],
      toolGroup: null,
      progress: 0,
      total: 0,
      total_dicom_files: 0,
      position: 0,
      slider: { min: 0, max: 100 },
      mouse: { Wx: 0, Wy: 0, Wz: 0, x: 0, y: 0, value: 0 },
      imSize: { width: 0, height: 0 },
      zoom: 1,
      orientation_letters: [0, 0, 0, 0],
      width: 0,
      height: 0,
      image: {},
      default_orientation: 'none',
      selectedAnnotationUID: null,
      currentVolumeId: null,
      series: null,
      isLoading: false,
      orientations: [],
    };
    this.observer = null;
    this.elementRef = React.createRef(); // Reference for the slider container;
    this.resizeObserver = null;
    this.renderingEngine = null;
    this.volumeCounter = 0;
    const { holder } = props;
    if (holder) {
      holder.handleToolChange = this.handleToolChange;
      holder.setViewerOrientation = this.handleViewerOrientation;
      holder.setViewerMeasureTool = this.handleViewerMeasureTool;
      holder.setColorSelected = colormapName => {
        const index = initialColormapNames.indexOf(colormapName);
        if (index === -1) colormapName = 'Grayscale';

        const { currentVolumeId } = this.state;
        if (currentVolumeId) {
          setViewportColormap(
            renderingEngineId,
            viewportId,
            currentVolumeId,
            colormapName
          );
        }
      };
      holder.loadSeries = series => {
        const { NumberOfSeriesRelatedInstances } = series;
        const { total, loaded, isLoading, series: prev_series } = this.state;
        console.log({
          total,
          loaded,
          isLoading,
          NumberOfSeriesRelatedInstances,
        });
        if (total && loaded.length === total) {
          this.setState({ total: 0, loaded: [], isLoading: false }, () => {
            this.handleLoadSeries(series);
          });
        } else {
          if (isLoading) {
            if (prev_series && prev_series.NumberOfSeriesRelatedInstances > 1) {
              const volumeId = `${volumeLoaderScheme}:${prev_series.idSeries}`;
              const volume = cache.getVolume(volumeId);
              if (volume) {
                console.log('Volume is found in cache');
                this.setState({ isLoading: false }, () => {
                  this.handleLoadSeries(series);
                });
                return;
              }
            }
          }
          this.handleLoadSeries(series);
        }
      };
    }
  }

  onImageIdProgress = (imageId, total, init) => {
    let { loaded } = this.state;
    if (init) loaded = [];
    const index = loaded.indexOf(imageId);
    if (index === -1) {
      loaded.push(imageId);
    }
    // console.log({ imageId, total, init, loaded: loaded.length });
    this.setState({ loaded, total });
  };
  onImageProgress = async event => {
    if (!event) return;
    let { loaded, init, isLoading } = this.state;
    if (init) {
      loaded = [];
      isLoading = true;
    } else if (isLoading) {
      return;
    }
    const { detail } = event;
    if (!detail) return;
    const { image } = detail;
    let pre_load = false;
    let imageId = null;
    if (!image) {
      if (detail.imageId) {
        imageId = detail.imageId;
        pre_load = true;
      } else return;
    } else {
      imageId = image.imageId;
    }
    if (!imageId) return;

    // Skip if this is from a different series than the one currently loading
    const index = loaded.indexOf(imageId);
    const number_of_slides = this.state.total;
    let {
      position,
      imSize,
      zoom,
      orientation_letters,
      image: imInformation,
      default_orientation,
    } = this.state;

    if (index === -1) {
      loaded.push(imageId);
      if (number_of_slides === loaded.length) {
        if (pre_load) {
          loaded = [];
        } else {
          const { currentVolumeId } = this.state;
          const viewport = getViewport();
          const reply = await InitAfterLoad(
            imageId,
            viewport,
            metaData,
            Enums,
            currentVolumeId
          );
          const { series } = this.state;
          if (series && !isSeriesAxisOriented(series)) {
            const reply_default = setDefaultCamera(series, viewport);
            default_orientation = reply_default.orientation;
            orientation_letters = reply_default.orientation_letters;
          } else {
            default_orientation = reply.orientation;
            orientation_letters = reply.orientation_letters;
          }
          isLoading = false;
          imInformation = reply.imInformation;
          imSize = reply.imSize;
          zoom = reply.zoom;
          position = number_of_slides / 2;
          this.props.handleOrientationChange(default_orientation);
        }
      }
      this.setState({
        init: false,
        loaded,
        orientation_letters,
        isLoading,
        position,
        imSize,
        zoom,
        image: imInformation,
        default_orientation,
      });
    }
  };
  handleVolumeLoaded = e => {
    const { type } = e;
    const { loaded, series } = this.state;
    if (type === 'CORNERSTONE_IMAGE_VOLUME_LOADING_COMPLETED') {
      const { volumeId } = e.detail;
      const volume = cache.getVolume(volumeId);
      console.log('--------------------------------');
      console.log('CORNERSTONE_IMAGE_VOLUME_LOADING_COMPLETED', {
        volume,
        loaded,
      });
      if (volume) {
        const { numFrames, _imageIds } = volume;
        if (numFrames !== loaded.length && loaded.length < _imageIds.length) {
          const viewport = getViewport();
          const { orientation_letters, default_orientation } = setDefaultCamera(
            series,
            viewport
          );
          this.props.handleOrientationChange(default_orientation, true);
          console.log('-----SET - VOLUME-----');
          this.setState({
            total: numFrames,
            loaded: _imageIds,
            position: Math.floor(_imageIds.length / 2),
            orientation_letters,
            default_orientation,
          });
        }
        console.log(type, { numFrames, _imageIds });
      }
      this.props.holder.setLoadSeries(false);
      this.enableInteractions();
      console.log('--------------------------------');
    } else {
      console.log('Event Type:', type); // Should be IMAGE_VOLUME_LOADING_COMPLETED
      console.log('Event Target:', e.target); // The object that dispatched the event
      console.log('Event Detail:', e.detail); // Custom data (if provided)
    }
  };
  async componentDidMount() {
    try {
      setupHeightDetection(this);
      // Initial height measurement
      updateWidthHeight(viewportId, this);
      // Init Cornerstone and related libraries
      await initDemo();
      await initializeViewer(
        viewportId,
        renderingEngineId,
        toolGroupId,
        leftClickTools,
        measureTools,
        this
      );
      // Add window resize listener as fallback
      window.addEventListener('resize', () =>
        updateWidthHeight(viewportId, this)
      );
      eventTarget.addEventListener(VOLUME_LOADED, this.handleVolumeLoaded);
      eventTarget.addEventListener(
        IMAGE_VOLUME_LOADING_COMPLETED,
        this.handleVolumeLoaded
      );
      eventTarget.addEventListener(
        VOLUME_CACHE_VOLUME_ADDED,
        this.handleVolumeLoaded
      );
      // Set up event listeners
      eventTarget.addEventListener(
        IMAGE_CACHE_IMAGE_ADDED,
        this.onImageProgress
      );
      eventTarget.addEventListener(
        VOLUME_VIEWPORT_SCROLL,
        this.handleViewerImageScroll
      );
      this.elementRef.current.addEventListener(
        STACK_VIEWPORT_SCROLL,
        this.handleViewerImageScroll
      );
    } catch (error) {
      console.error({ error });
      this.setState({ isLoading: false });
    }
  }
  componentDidUpdate() {
    const { isLoading, series, total, loaded } = this.state;

    if (series) {
      // const { NumberOfFrames } = series;
      // if (total === 1 && NumberOfFrames === 1) disable = true;
    }
    if (total > 0 && isLoading && loaded.length + 1 >= total) {
      this.setState({ isLoading: false });
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', () =>
      updateWidthHeight(viewportId, this)
    );
    cleanupHeightDetection(this);

    // Remove event listeners
    eventTarget.removeEventListener(
      IMAGE_CACHE_IMAGE_ADDED,
      this.onImageProgress
    );
    eventTarget.removeEventListener(
      VOLUME_VIEWPORT_SCROLL,
      this.handleViewerImageScroll
    );
    this.elementRef.current.removeEventListener(
      STACK_VIEWPORT_SCROLL,
      this.handleViewerImageScroll
    );
    if (this.elementRef.current) {
      this.elementRef.current.removeEventListener(
        csToolsEnums.Events.KEY_DOWN,
        this.handleKeyPress
      );
      this.elementRef.current.removeEventListener(
        'mousemove',
        this.handleMouseMove
      );
      this.elementRef.current.removeEventListener(
        'mousedown',
        this.handleMouseDown
      );
    }

    // Cleanup volumes and rendering engine
    cleanupCurrentVolume(this, annotationManager, cache);

    if (this.renderingEngine) {
      this.renderingEngine.destroy();
      this.renderingEngine = null;
    }
    if (this.observer) this.observer.disconnect();
  }
  // Disable interactions when loading starts
  disableInteractions = () => {
    const viewport = getViewport();
    if (viewport) viewport.element.style.pointerEvents = 'none';
    // Disable pointer events
    else console.error('disableInteractions - viewport - null');
    cornerstoneTools.state.isInteractingWithTool = true; // Prevent tool interactions
    console.log('Interactions disabled during load');
  };

  // Enable interactions when loading finishes
  enableInteractions = () => {
    const viewport = getViewport();
    if (viewport) viewport.element.style.pointerEvents = 'auto'; // Re-enable pointer events
    cornerstoneTools.state.isInteractingWithTool = false; // Allow tool interactions
    console.log('Interactions re-enabled');
  };
  handleLoadSeries = series => {
    if (IsInvalid(series)) {
      return;
    }

    const { idSeries } = series;
    const { isLoading, series: prev_series } = this.state;

    if (isLoading) {
      console.warn('Already loading a series, please wait');
      return;
    }
    if (prev_series && idSeries === prev_series.idSeries) {
      return;
    }
    this.disableInteractions();
    this.props.holder.setLoadSeries(true);
    let total_dicom_files = 0;
    if (series) {
      const { NumberOfFrames, NumberOfSeriesRelatedInstances } = series;
      if (NumberOfFrames && NumberOfFrames > 1) {
        total_dicom_files = NumberOfFrames;
      } else if (NumberOfSeriesRelatedInstances) {
        total_dicom_files = NumberOfSeriesRelatedInstances;
      }
    }
    this.setState({ isLoading: true, loaded: [], total_dicom_files });

    QuerySeriesFileIDs(idSeries)
      .then(async res => {
        const data = GetData(res);
        const { ok, ids, orientations, errors } = data.allSeriesFileIDs;

        if (ok) {
          const {
            NumberOfFrames,
            Modality,
            NumberOfSeriesRelatedInstances,
            ImageSize,
            ImageType,
            ImageMr,
          } = series;
          console.log({ series });
          if (!cornerstone.isCornerstoneInitialized()) {
            await cornerstone.init();
          }

          init({
            cornerstone,
            dicomParser,
          });
          const viewport = getViewport();
          // viewport.setVolumes([]);
          const {
            imageIds,
            errors,
          } = await createImageIdsAndCacheMetaDataFiles(
            {
              instances: ids,
              modality: Modality,
            },
            this.onImageIdProgress
          );
          if (IsInvalid(imageIds)) {
            throw Error(`Server is Down or ${errors} DICOM files were deleted`);
          }
          console.log({
            total_imageIds: imageIds.length,
            ImageType,
            ProtocolName: ImageMr.ProtocolName,
            SequenceName: ImageMr.SequenceName,
          });

          // Clean up any existing volume first
          await cleanupCurrentVolume(this, annotationManager, cache);
          let newViewport = viewport;
          if (
            NumberOfSeriesRelatedInstances === 1 ||
            imageIds.length < 5 ||
            orientations.length > 1 ||
            IsSeriesForStack(series)
          ) {
            console.log('STACK - Display', {
              TotalOrientations: orientations.length,
            });
            let total = 1;
            if (NumberOfFrames > 1) {
              total = NumberOfFrames;
            } else {
              total = NumberOfSeriesRelatedInstances;
            }
            const imSize = {
              width: ImageSize.Columns,
              height: ImageSize.Rows,
            };
            let position = Math.floor(imageIds.length / 2);
            if (viewport.type === Enums.ViewportType.ORTHOGRAPHIC) {
              console.log('Transform Viewport from  ORTHOGRAPHIC to STACK');
              let volume = null;
              if (
                prev_series &&
                prev_series.NumberOfSeriesRelatedInstances > 1
              ) {
                volume = cache.getVolume(
                  `${volumeLoaderScheme}:${prev_series.idSeries}`
                );
              }
              if (volume) {
                newViewport = await csUtils.convertVolumeToStackViewport({
                  viewport: viewport,
                  options: {
                    background: viewer_black
                      ? [0.0, 0.0, 0.0]
                      : backgroundColorStack,
                  },
                });
              } else {
                // Disable the current ORTHOGRAPHIC viewport
                const renderingEngine = getRenderingEngine(renderingEngineId);
                renderingEngine.disableElement(viewportId);
                // Create a new STACK viewport
                const stackViewportInput = {
                  viewportId, // Reuse the same ID
                  type: Enums.ViewportType.STACK,
                  element: this.elementRef.current,
                  defaultOptions: {
                    background: viewer_black
                      ? [0.0, 0.0, 0.0]
                      : backgroundColorStack,
                  },
                };
                renderingEngine.setViewports([stackViewportInput]);
                newViewport = renderingEngine.getViewport(viewportId);
              }
            }
            let { orientation_letters, default_orientation } = setDefaultCamera(
              series,
              newViewport
            );
            if (IsInvalid(orientation_letters)) {
              throw Error('Error preparing the series for Stack');
            }
            const ori_letters = computeOrientationLetters(
              position,
              imageIds,
              orientations
            );
            if (ori_letters) orientation_letters = ori_letters;
            await newViewport.setStack(imageIds, position);
            this.setState({
              aspectRatio: imSize.height / imSize.width,
              imSize,
              isLoading: false,
              total,
              loaded: imageIds,
              volume: null,
              currentVolumeId: null,
              series,
              position,
              default_orientation,
              orientation_letters,
              orientations,
            });
            this.props.handleOrientationChange(default_orientation, true);
            this.props.holder.setLoadSeries(false);
            this.enableInteractions();
          } else if (imageIds.length > 1) {
            if (viewport.type === Enums.ViewportType.STACK) {
              console.log('Transform Viewport from  STACK to ORTHOGRAPHIC');
              let currentVolumeId = null;
              if (series)
                currentVolumeId = `${volumeLoaderScheme}:${this.state.series.idSeries}`;
              if (currentVolumeId && cache.getVolume(currentVolumeId)) {
                newViewport = await csUtils.convertStackToVolumeViewport({
                  viewport: viewport,
                  options: {
                    background: viewer_black
                      ? [0, 0, 0]
                      : backgroundColorVolume,
                    volumeId: currentVolumeId,
                  },
                });
              } else {
                // Disable the current ORTHOGRAPHIC viewport
                const renderingEngine = getRenderingEngine(renderingEngineId);
                renderingEngine.disableElement(viewportId);
                // Create a new STACK viewport
                const stackViewportInput = {
                  viewportId, // Reuse the same ID
                  type: Enums.ViewportType.ORTHOGRAPHIC,
                  element: this.elementRef.current,
                  defaultOptions: {
                    background: viewer_black
                      ? [0, 0, 0]
                      : backgroundColorVolume,
                  },
                };
                renderingEngine.setViewports([stackViewportInput]);
                newViewport = renderingEngine.getViewport(viewportId);
              }
            }
            const {
              orientation_letters,
              default_orientation,
            } = setDefaultCamera(series, newViewport);
            if (IsInvalid(orientation_letters)) {
              throw Error('Error preparing the series for Volume');
            }
            await loadVolume(
              imageIds,
              series,
              viewportId,
              renderingEngineId,
              annotationManager,
              default_orientation,
              orientation_letters,
              this
            );
            this.props.handleOrientationChange(default_orientation, false);
          } else {
            console.warn('There are no images in series');
            return;
          }
          const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
          // Set the tool group on the viewport
          if (toolGroup) {
            toolGroup.addViewport(newViewport.id, renderingEngineId);
          }
        } else {
          throw errors;
        }
      })
      .catch(error => {
        this.props.handleAddError(ServerErrorsString(error));
        this.props.holder.setLoadSeries(false);
        this.enableInteractions();
        this.setState({ isLoading: false });
      });
  };

  handleViewerMeasureTool = toolName => {
    const { currentLeftClickTool } = this.state;
    const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
    // Ensure the tool group is available
    if (!toolGroup) {
      console.error('Tool group is not initialized.');
      return;
    }
    console.log('handleViewerMeasureTool', { toolName, measureTools });
    if (toolName === 'None') toolName = defaultLeftClickTool;
    toolGroup.setToolPassive(currentLeftClickTool);
    toolGroup.setToolActive(toolName, {
      bindings: [
        {
          mouseButton: MouseBindings.Primary, // Left Click
        },
      ],
    });
    this.setState({ currentLeftClickTool: toolName });
  };

  handleViewerOrientation = orientation => {
    const { volume, series } = this.state;
    let { default_orientation } = this.state;
    if (IsInvalid(series)) return;
    const viewport = getViewport();
    if (IsInvalid(volume)) {
      if (viewport.type === Enums.ViewportType.STACK) {
        this.props.handleAddError(
          'MPR is not available for this type of series.'
        );
        this.props.handleOrientationChange(default_orientation);
      } else {
        console.error('Volume is not found');
      }
      return;
    }

    let { imSize, orientation_letters } = this.state;
    const ImageOrientationPatient = getSeriesImageOrientation(series);
    if (IsInvalid(ImageOrientationPatient)) {
      return;
    }
    let position = 0;
    let total = 0;
    // Get the rendering engine
    const { dimensions } = volume;
    console.log('handleViewerOrientation', { dimensions });
    if (!validOrientations.includes(default_orientation.toLowerCase())) {
      console.log('Re-Calculate', {
        default_orientation,
        ImageOrientationPatient,
      });
      default_orientation = detectMainView(ImageOrientationPatient);
    }
    let viewport_info = imSize;
    const new_letters = setCameraVectors(series, orientation, viewport);
    if (new_letters) {
      orientation_letters = new_letters;
    }
    viewport_info = get2DImageDimensions(
      dimensions,
      orientation,
      default_orientation
    );
    if (viewport_info.depth) {
      total = viewport_info.depth;
      position = viewport_info.middle;
    }
    if (isAxisOriented(ImageOrientationPatient)) {
      try {
        viewport_info = viewport.getSliceViewInfo();
        const { slicePlane } = viewport_info;
        total = dimensions[slicePlane];
        position = viewport_info.sliceIndex + 1;
      } catch (error) {
        console.error('Error getting slice view info:', error);
      }
    }
    imSize = {
      width: viewport_info.width,
      height: viewport_info.height,
    };
    const camera = viewport.getCamera();
    const { viewUp, viewPlaneNormal } = camera;
    console.log('handleViewerOrientation', {
      width: viewport_info.width,
      height: viewport_info.height,
      total,
      orientation_letters,
      viewUp,
      viewPlaneNormal,
      position,
    });
    this.setState(
      {
        aspectRatio: viewport_info.height / viewport_info.width,
        imSize,
        position,
        total,
        orientation_letters,
      },
      () => {
        viewport.render();
      }
    );
  };

  handleKeyPress = event => {
    const key = event.detail.key.toLowerCase();
    console.log({ key });
    if (key === 'r') {
      // Get the viewport
      const viewport = getViewport();
      // Resets the viewport's camera
      viewport.resetCamera();
      // Resets the viewport's properties
      viewport.resetProperties();
      viewport.setViewPresentation({ rotation: 0 });
      viewport.render();
    } else if (key === 'delete' || key === 'backspace') {
      deleteSelectedAnnotation(
        annotationManager,
        renderingEngineId,
        viewportId,
        toolGroupId,
        this
      );
    } else if (key === 'arrowdown' || key === 'arrowup') {
      let { position } = this.state;
      if (key === 'arrowdown') position++;
      else position--;
      this.handleSliderImageChange({ target: { value: position } });
    }
  };

  handleMouseMove = event => {
    if (!this.elementRef.current) {
      return;
    }
    const rect = this.elementRef.current.getBoundingClientRect();
    const canvasPos = [
      Math.floor(event.clientX - rect.left),
      Math.floor(event.clientY - rect.top),
    ];
    // Get the viewport
    const viewport = getViewport();
    let response = {};
    if (viewport.type === Enums.ViewportType.STACK) {
      response = onStackMouseMove(canvasPos, viewport, this);
    } else {
      response = onVolumeMouseMove(canvasPos, viewport, this);
    }
    this.setState({
      ...response,
    });
  };

  // Handle mouse down events for selection
  handleMouseDown = () => {
    // Let the normal event handling continue
    setTimeout(() => {
      // Check if an annotation was selected after the event
      checkForSelectedAnnotation(annotationManager, this);
    }, 100);
  };

  // Select an annotation by UID
  selectAnnotation = selectedAnotation => {
    const uid = selectedAnotation.annotationUID;

    // Verify the annotation exists before selecting it
    try {
      const annotationData = annotationManager.state.getAnnotation(uid);
      if (!annotationData) {
        console.warn('Cannot select non-existent annotation:', uid);
        return;
      }
      // Update state with the selected annotation
      this.setState({ selectedAnnotationUID: uid });
      // Optionally highlight the annotation in the viewport
      try {
        selectedAnotation.highlighted = true;
        // Get the viewport
        const viewport = getViewport();
        viewport.render();
        // If the tool type is available, also update the selected tool
        if (annotationData.metadata?.toolName) {
          this.setState({ selectedToolName: annotationData.metadata.toolName });
        }
      } catch (selectionError) {
        console.warn('Error setting annotation as selected:', selectionError);
        // Still update the state even if highlighting fails
      }
    } catch (error) {
      console.error('Error selecting annotation:', error);
    }
  };

  handleToolChange = selectedValue => {
    const { currentLeftClickTool } = this.state;
    const toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
    // Ensure the tool group is available
    if (!toolGroup) {
      console.error('Tool group is not initialized.');
      return;
    }
    const index = leftClickTools.indexOf(selectedValue);
    if (index === -1) {
      if (selectedValue === 'rotate_left') this.handleRotate(-90);
      else if (selectedValue === 'rotate_right') this.handleRotate(90);
      else if (selectedValue === 'flip_vertical') this.handleFlip(true);
      else if (selectedValue === 'flip_horizontal') this.handleFlip();
      else console.error(`Tool name (${selectedValue}) is not here.`);
      return;
    }

    toolGroup.setToolPassive(currentLeftClickTool);

    toolGroup.setToolActive(selectedValue, {
      bindings: [
        {
          mouseButton: MouseBindings.Primary, // Left Click
        },
      ],
    });
    this.setState({ currentLeftClickTool: selectedValue });
  };

  handleRotate = (rotate = 90) => {
    // Get the viewport
    const viewport = getViewport();
    const camera = viewport.getCamera();
    const { viewUp, viewPlaneNormal } = rotateCamera(
      camera.viewPlaneNormal,
      camera.viewUp,
      rotate
    );
    viewport.setCamera({ viewUp, viewPlaneNormal });
    viewport.resetCamera();
    viewport.render();
    const orientation_letters = orientationLetters(viewPlaneNormal, viewUp);
    this.setState({ orientation_letters });
  };

  handleFlip = vertical => {
    // Get the viewport
    const viewport = getViewport();

    let { flipHorizontal, flipVertical } = viewport.getCamera();
    if (vertical) flipVertical = !flipVertical;
    else flipHorizontal = !flipHorizontal;
    viewport.setCamera({ flipHorizontal, flipVertical });
    const camera = viewport.getCamera();
    const { viewUp, viewPlaneNormal } = camera;
    const orientation_letters = orientationLetters(viewPlaneNormal, viewUp);
    viewport.render();
    this.setState({ orientation_letters });
    console.log({ orientation_letters, viewUp, viewPlaneNormal });
  };

  handleViewerImageScroll = event => {
    const { detail } = event;
    if (!detail) return;
    const { isLoading } = this.state;
    if (isLoading) {
      return;
    }
    let { desiredStepIndex, newImageIdIndex } = detail;
    if (IsInvalidNumber(desiredStepIndex)) desiredStepIndex = newImageIdIndex;
    if (IsInvalidNumber(desiredStepIndex)) {
      desiredStepIndex = 0;
    }
    let { orientation_letters } = this.state;
    const new_letters = getNewOrientationLetters(desiredStepIndex, this);
    if (new_letters) orientation_letters = new_letters;
    this.setState({ position: desiredStepIndex, orientation_letters });
  };

  handleSliderImageChange = event => {
    let { total, series, orientation_letters } = this.state;
    if (series) {
      const { NumberOfFrames } = series;
      if (NumberOfFrames > 1) {
        total = NumberOfFrames;
      }
    }
    let position = event.target.value;
    // Get the viewport
    const viewport = getViewport();
    position = Math.max(position, 0);
    if (position >= total) position = total - 1;
    const new_letters = getNewOrientationLetters(position, this);
    if (new_letters) orientation_letters = new_letters;
    this.setState({ position, orientation_letters }, async () => {
      // Set the new image index, the viewport itself does a re-render
      await csUtils.jumpToSlice(viewport.element, { imageIndex: position });
    });
  };

  render() {
    const {
      position,
      isLoading,
      loaded,
      mouse,
      imSize,
      zoom,
      image,
      orientation_letters,
      series,
      total,
      height,
      total_dicom_files,
    } = this.state;
    let disable = false;
    if (series) {
      const { NumberOfFrames } = series;
      if (total === 1 && NumberOfFrames === 1) disable = true;
    }
    let display_progress = false;
    let delta_y = 1;
    if ((!isLoading && total > 1) || total_dicom_files > 1) {
      display_progress = !isLoading && loaded.length;
      delta_y = 30;
    }
    let load = loaded.length === 0 ? total : loaded.length + 1;
    let progress = 100;
    if (total > 0) progress = (load / total) * 100;
    // console.log({ isLoading, total_dicom_files, loaded: loaded.length });
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <div
          onContextMenu={e => e.preventDefault()}
          ref={this.elementRef}
          style={{
            width: '100%',
            height: `calc(100% - ${delta_y}px)`,
            position: 'relative',
          }}>
          <ComponentCorners
            display_progress={display_progress}
            height={height}
            image={series ? series : image}
            imSize={imSize}
            mouse={mouse}
            orientation_letters={orientation_letters}
            position={position + 1}
            slider={{ max: total }}
            vtkContainerRef={this.elementRef}
            zoom={zoom}
          />
        </div>
        {display_progress ? (
          <input
            id="slider"
            max={total - 1}
            min={0}
            onChange={this.handleSliderImageChange}
            style={{
              width: '100%',
              display: disable === 1 ? 'none' : undefined,
            }}
            type="range"
            value={position}
          />
        ) : total || total_dicom_files ? (
          <Box sx={{ width: '100%' }}>
            <LinearProgress value={progress} variant="determinate" />
          </Box>
        ) : null}
      </div>
    );
  }
}

ViewerCorner2D.propTypes = {
  classes: PropTypes.object,
  handleOrientationChange: PropTypes.func,
  holder: PropTypes.object,
};

ViewerCorner2D.defaultProps = {
  handleOrientationChange: () => '',
};

export default ViewerCorner2D;
