import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Divider, Drawer } from '@mui/material';
import { connect } from 'react-redux';

import { BottomSidebar, Profile, SidebarNav } from './components';
import { calc_image_size } from 'helpers/utils_images';
import { getPages } from './components/utils_pages';
import { useStyleMainSidebar } from './components/useStyleMainSidebar';
import { QueryAccountClinicalData } from 'graphql/DataClinics/utils_clinical_accounts';
import { QueryUsersClinicals } from 'graphql/Users/utils_user_clinical';
import { GetData, IsInvalid, IsInvalidUser, IsValid } from 'helpers';

const Sidebar = props => {
  const {
    open,
    variant,
    onClose,
    className,
    user,
    isDesktop,
    openSidebar,
    minSidebar,
    isDicomViewer,
  } = props;
  const [authorizedClinics, setAuthorizedClinics] = useState([]);

  useEffect(() => {
    const getData = async () => {
      if (IsInvalidUser(user)) return;
      const { id, type } = user;
      const getAuthorizedClinics = async () => {
        try {
          let clinical_user = [];
          let clinical_account = [];
          const userData = await QueryUsersClinicals(id);
          const userDataResponse = GetData(userData);
          let {
            clinical_type: userClinicalType,
            clinical_types,
          } = userDataResponse.userClinicalDataType;
          if (IsInvalid(clinical_types)) clinical_types = [];
          const clinical_type = clinical_types.map(userStudies => {
            return userClinicalType.find(
              item => item.id === userStudies.idClinicalDataType
            );
          });

          if (IsValid(clinical_type)) {
            clinical_user = clinical_type;
          }
          const accountData = await QueryAccountClinicalData(type);
          const accountDataResponse = GetData(accountData);
          const {
            clinical_type: accountClinicalType,
          } = accountDataResponse.accountClinicalData;
          if (IsValid(accountClinicalType)) {
            clinical_account = accountClinicalType;
          }
          let authorized_clinics = clinical_user.concat(clinical_account);
          authorized_clinics = authorized_clinics.filter(
            (obj, index, self) => index === self.findIndex(o => o.id === obj.id)
          );
          setAuthorizedClinics(authorized_clinics);
        } catch (error) {
          console.log('Sidebar - AuthorizedClinics', { error });
        }
      };
      getAuthorizedClinics();
    };
    getData();
  }, []);

  const { classes } = useStyleMainSidebar();
  const pages = getPages(user, authorizedClinics);
  // console.log({ open, openSidebar });
  let ComponentProfile = null;
  if (minSidebar) {
    ComponentProfile = <div style={{ paddingTop: 50 }} />;
  } else {
    ComponentProfile = <Profile user={user} />;
  }
  let isViewer = true;
  if (variant === 'temporary' && openSidebar) {
    isViewer = false;
  }
  if (isDicomViewer) {
    isViewer = isDicomViewer;
  }
  return (
    <Drawer
      anchor="left"
      classes={{
        paper: clsx({
          [classes.drawer]: open && !minSidebar,
          [classes.drawer_closed]: minSidebar,
          [classes.drawer_viewer]: isDicomViewer,
        }),
      }}
      className={clsx({ [classes.drawer_on_top]: !isDesktop })}
      onClose={onClose}
      open={open}
      variant={variant}>
      <div className={clsx(classes.root, className)}>
        <div
          className={clsx({
            [classes.container]: true,
            [classes.container_viewer]: isDicomViewer,
          })}>
          <div className={classes.container_items}>
            {ComponentProfile}
            <Divider className={classes.divider} />
            <SidebarNav
              className={classes.nav}
              key="main_sidebar"
              minSidebar={minSidebar}
              openSidebar={openSidebar}
              pages={pages}
            />
          </div>
        </div>
      </div>
      <div
        className={clsx({
          [classes.mainSideBarBottom]: true,
          [classes.mainSideBarBottom_viewer]: isViewer,
        })}>
        <BottomSidebar
          handleCloseSidebar={props.onMiniSidebar}
          minSidebar={minSidebar}
          variant={variant}
        />
      </div>
    </Drawer>
  );
};
//mainSideBarBottom_viewer
Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  let size = 0;
  const { user } = state.info_user;
  let { openSidebar, isDicomViewer } = state.page_globals;
  if (user) {
    size = calc_image_size(user.avatar);
  }
  //
  return {
    user,
    size,
    openSidebar,
    isDicomViewer,
  };
};

export default connect(mapStateToProps)(Sidebar);
