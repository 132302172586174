import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Divider } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import { CardButtonActions } from 'components/Cards/components';
import { UnitDescription } from '../../../index';
import CardUnitHeader from '../CardUnitHeader';

class TabUnitGeneral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, state, unit, admin, height } = this.props;
    const { loading, unit_changed, setting_changed, loading_update } = state;
    const { name, description, institution, api } = state.unit_modify;
    const { updatedAt, createdAt } = unit;
    return (
      <Card className={classes.root} style={{ height: height - 270 }}>
        <CardUnitHeader
          createdAt={createdAt}
          handleMaximize={() => this.props.handleMaximize('sites')}
          handleReNameUnit={this.props.handleReNameUnit}
          title={name}
          updatedAt={updatedAt}
        />
        <Divider variant="middle" />
        <CardContent
          className={classes.content}
          style={{ height: height - 300, overflowY: 'auto', paddingTop: 0 }}>
          <UnitDescription
            admin={admin}
            api={api}
            description={description}
            handelChange={this.props.handelChange}
            handelChangeText={this.props.handelChangeText}
            handleAddError={this.props.handleAddError}
            height={height - 300}
            holder={this.props.holder}
            institution={institution}
            unit={unit}
          />
        </CardContent>
        <Divider variant="middle" />
        <CardButtonActions
          changed={setting_changed || unit_changed}
          changed_left={!loading}
          handleLeftClick={this.props.runUpdateSummary}
          handleSaveClicked={() => this.props.runUpdateSave()}
          icon_left={<UpdateIcon />}
          left
          loading={loading}
          loading_left={loading_update}
          tooltip_left="Update Summary"
        />
      </Card>
    );
  }
}

TabUnitGeneral.propTypes = {
  classes: PropTypes.object,
};

export default TabUnitGeneral;
