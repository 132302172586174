export const useStyles = () => ({
  appBar: {
    boxShadow: 'none',
    background: '#095275',
    position: 'relative',
  },
  main: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 0,
    color: '#fff',
    backgroundColor: '#1a2439',
    height: 'calc(100% - 65px)',
  },
  bottomMain: {
    overflowX: 'hidden',
    overflowY: 'auto',
    margin: 0,
    padding: 0,
    height: 'calc(100% - 60px)',
  },
  mainContent: {
    display: 'flex',
    height: 'calc(100% - 5px)',
  },
  leftPanel: {
    width: '69%',
    overflowY: 'auto',
    borderRight: '1px solid #333',
  },
  rightPanel: {
    width: '31%',
    // maxWidth: '300px',
    backgroundColor: '#1a2439',
    height: 'calc(100% - 5px)',
  },
  infoItemHeader: {
    position: 'relative',
    paddingTop: '15px',
    minWidth: 100,
  },
  smallLabel: {
    fontSize: '12px',
    color: '#999',
    position: 'absolute',
    top: '-9px',
  },
  header: {
    backgroundColor: '#1a2439',
    padding: '10px',
    borderBottom: '1px solid #333',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  patientInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    minHeight: 40,
  },
  patientName: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  navigation: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    backgroundColor: '#1a2439',
    // borderBottom: '1px solid #333',
  },
  navigationButton: {
    margin: '0 5px',
    color: '#999',
    fontSize: '16px',
  },
  sectionHeader: {
    backgroundColor: '#1a2439',
    padding: '10px',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchHeader: {
    backgroundColor: '#1a2439',
    padding: '5px',
    borderBottom: '1px solid #333',
  },
  searchRow: {
    padding: '5px',
    borderBottom: '1px solid #333',
    display: 'flex',
    '&:hover': {
      backgroundColor: '#2a3854',
    },
  },
  searchRowSelected: {
    backgroundColor: '#2a3854',
  },
  anamneseSection: {
    marginTop: '20px',
    border: '1px solid #333',
    paddingBottom: '10px',
  },
  tableContainer: {
    maxHeight: '300px', // Vertical scroll limit
    overflowX: 'auto', // Enable horizontal scrolling
    overflowY: 'auto', // Enable vertical scrolling
    width: '100%', // Container takes full parent width
    display: 'block', // Ensures the container can scroll
  },
  anamnese_table_head: {
    // display: 'table',
    // width: '100%',
    // tableLayout: 'inherit', // Match table’s layout
    backgroundColor: '#1a2439',
    color: '#999',
  },
  anamneseTable: {
    padding: 10,
    borderCollapse: 'collapse',
    borderSpacing: 0,
    width: 'auto', // Let table width be determined by content (not 100%)
    minWidth: '100%', // Ensure it’s at least as wide as the container
    tableLayout: 'auto', // Allow columns to size based on content (or use 'fixed' with explicit widths)
    whiteSpace: 'nowrap', // Prevent text wrapping
    '& thead': {
      display: 'table',
      width: '100%',
      tableLayout: 'inherit', // Match table’s layout
      backgroundColor: '#1a2439',
      color: '#999',
    },
    '& tbody': {
      display: 'table',
      width: '100%',
      tableLayout: 'inherit', // Match table’s layout
    },
    '& th': {
      textAlign: 'left',
      padding: '5px',
      borderBottom: '1px solid #333',
      backgroundColor: '#1a2439',
    },
    '& td': {
      padding: '5px',
      borderBottom: '1px solid #333',
    },
  },
  anamneseRow: {
    width: '100%',
    '&:hover': {
      backgroundColor: '#2a3854',
    },
  },
  anamneseRowSelected: {
    backgroundColor: '#2a3854',
  },
  infoItem: {
    marginBottom: '15px',
  },
  infoLabel: {
    color: '#999',
    fontSize: '14px',
    marginBottom: '5px',
  },
  imagesSection: {
    marginTop: '5px',
  },
  imageGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '5px',
  },
  imageItem: {
    backgroundColor: '#000',
    width: 196,
    height: 196,
  },
  imageItemDropped: {
    backgroundColor: '#000',
    width: 76,
    height: 76,
  },
  controlBar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '5px',
    paddingLeft: '10px',
    paddingRight: '10px',
    backgroundColor: '#1a2439',
    borderBottom: '1px solid #333',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  icon: {
    margin: '0 3px',
    color: '#999',
  },

  searchResults: {
    display: 'flex',
    backgroundColor: '#1a2439',
    border: '1px solid #333',
    margin: 10,
    marginBottom: '20px',
  },
  // searchResults: {
  //   width: '100%',
  //   borderCollapse: 'collapse',
  //   border: '1px solid #333',
  //   marginBottom: '20px',
  //   display: 'block' /* Change to block to enable scrolling */,
  //   overflowX: 'auto' /* Enable horizontal scrolling */,
  //   overflowY: 'auto' /* Enable vertical scrolling */,
  //   whiteSpace: 'nowrap' /* Prevent text wrapping for horizontal scroll */,
  //   '& th': {
  //     textAlign: 'left',
  //     padding: '8px',
  //     borderBottom: '1px solid #333',
  //     backgroundColor: '#1a2439',
  //     fontWeight: 'normal',
  //     color: '#999',
  //   },
  //   '& td': {
  //     padding: '8px',
  //     borderBottom: '1px solid #333',
  //   },
  //   '& tr.selected': {
  //     backgroundColor: '#2a3854',
  //   },
  // },
});
