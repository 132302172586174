const useStyles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  channels: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
    flexGrow: 1,
  },
  content: {
    height: '100%',
    overflowY: 'scroll',
    flexGrow: 1,
  },
  media: {
    height: 190,
  },
  // rect: {
  //   height: 65,
  //   marginTop: -72,
  //   indexZ: -1,
  // },
  avatar: {
    marginLeft: 20,
    marginTop: -75,
    // height: 110,
    // width: 100,
    flexShrink: 0,
    flexGrow: 0,
    indexZ: 2,
    borderWidth: 8,
    borderColor: theme.palette.white,
  },
  avatar_image: {
    height: 110,
    width: 100,
    // bgcolor: 'background.paper',
    borderColor: '#f4f6f8',
    m: 1,
    border: 6,
    style: { width: '6rem', height: '6rem' },
  },
  imgFluid: {
    maxWidth: '100%',
    height: 'auto',
    indexZ: 1,
  },
  imgRounded: {
    borderRadius: '6px !important',
  },
  imgRoundedCircle: {
    borderRadius: '50% !important',
  },
  imgRaised: {
    boxShadow:
      '0 5px 15px -8px rgba(0, 0, 0, 0.24), 0 8px 10px -5px rgba(0, 0, 0, 0.2)',
  },
  external: {
    paddingBottom: 0,
    paddingLeft: 1,
    paddingRight: 1,
    paddingTop: 0.5,
  },
});

export default useStyles;
