import { colorDicomViewer } from 'theme';

export const useStyles = theme => ({
  root: {
    margin: theme.spacing(1),
  },
  toolbar: {
    backgroundColor: colorDicomViewer,
    paddingBottom: 2,
  },
  mainContent: {
    flexGrow: 1,
  },
});
export { default as SideStudySeriesBar } from './SideStudySeriesBar';
export { default as ToolBarViewers } from './ToolBarViewers';
export { default as GridViewers } from './GridViewers';
export { default as DialogSearchSeries } from './DialogSearchSeries';
