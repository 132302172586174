import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { SelectedArray } from 'helpers';
import { CircularProgressWaiting, DialogLogin, SnackMessage } from 'components';
import { isEqual, SharedViewer, checkType, loadLink } from './components';
import { IsInvalid } from 'helpers';

const useStyles = () => ({
  root: {},
});

class Shared extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folder: null,
      link: null,
      code: '',
      loading: false,
      error: '',
      selected: [],
      open: false,
      url: null,
    };
    this.holder = {
      getContents: null,
    };
  }

  componentDidMount() {
    console.log('Shared - componentDidMount');
    const shared = checkType(this);
    if (!shared) return;
    const { folder } = this.state;
    if (!folder) {
      loadLink(shared.id, shared.type, this);
    }
  }

  componentDidUpdate() {
    console.log('Shared - componentDidUpdate');
    const shared = checkType(this);
    if (!shared) return;
    const { folder } = this.state;
    if (!folder) {
      loadLink(shared.id, shared.type, this);
    }
  }
  handleCloseError = () => {
    const { error } = this.state;
    if (error === 'This is a private link, you need to login first') {
      this.setState({ error: '', open: true });
    } else {
      this.setState({ error: '' });
      this.props.history.push('/');
    }
  };
  getIndex = item => {
    const { selected } = this.state;
    for (let i = 0; i < selected.length; i++) {
      if (isEqual(item, selected[i])) {
        return i;
      }
    }
    return -1;
  };
  handleSelected = item => {
    const { selected } = this.state;
    const index = this.getIndex(item);
    const newSelected = SelectedArray(selected, item, index);
    this.setState({ selected: newSelected });
  };
  handleSelectAll = () => {
    const { selected } = this.state;
    let contents = [];
    if (this.holder.getContents) {
      contents = this.holder.getContents();
    }
    if (selected.length !== contents.length) {
      this.setState({ selected: contents });
    } else {
      this.setState({ selected: [] });
    }
  };
  handleCloseDialog = user => {
    if (IsInvalid(user)) {
      this.setState({ open: false });
      this.props.history.push('/');
    } else {
      this.handleLogin();
    }
  };
  handleLogin = () => {
    const { url } = this.state;
    this.setState({ open: false, folder: null, link: null, code: '' });
    this.props.history.push(url);
  };

  render() {
    const { error, loading, selected, open } = this.state;
    if (error !== '') {
      return (
        <SnackMessage
          handleClose={this.handleCloseError}
          message_text={error !== '' ? error : 'Unknown warning'}
          open={error && error !== '' ? true : false}
          type="error"
        />
      );
    }
    if (open) {
      return (
        <DialogLogin
          handleClose={this.handleCloseDialog}
          handleLogin={this.handleLogin}
          open={open}
          ReactGA4={this.props.ReactGA4}
        />
      );
    }
    if (loading) {
      return (
        <CircularProgressWaiting style={{ height: '100%', width: '100%' }} />
      );
    }
    const { folder, code, view_type, link } = this.state;
    const { isMobile, height } = this.props;

    if (!folder) return null;
    const shared = checkType(this);
    if (!shared) return null;
    return (
      <React.Fragment>
        <SharedViewer
          code={code}
          handleAddError={this.props.handleAddError}
          handleReload={code => loadLink(code, shared.type, this)}
          handleSelectAll={this.handleSelectAll}
          handleSelected={this.handleSelected}
          height={height}
          holder={this.holder}
          home={folder}
          idFolder={shared ? shared.idFolder : null}
          isMobile={isMobile}
          link={link}
          selected={selected}
          type={shared.type}
          view_type={view_type}
        />
      </React.Fragment>
    );
  }
}

Shared.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(Shared, useStyles);
