import * as cornerstone from '@cornerstonejs/core';
import cornerstoneDICOMImageLoader from '@cornerstonejs/dicom-image-loader';
import { utilities } from '@cornerstonejs/core';
import { convertMultiframeImageIds } from 'views/DicomViewer/components/GridViewers/components/helpers/convertMultiframeImageIds';
import removeInvalidTags from 'views/DicomViewer/components/GridViewers/components/helpers/removeInvalidTags';
import dcmjs from 'dcmjs';
import getPixelSpacingInformation from 'views/DicomViewer/components/GridViewers/components/helpers/getPixelSpacingInformation';
import getPTImageIdInstanceMetadata from 'views/DicomViewer/components/GridViewers/components/helpers/getPTImageIdInstanceMetadata';
import ptScalingMetaDataProvider from 'views/DicomViewer/components/GridViewers/components/helpers/ptScalingMetaDataProvider';
import { calculateSUVScalingFactors } from '@cornerstonejs/calculate-suv';
import { HTTP_ADDRESS_DICOM_STREAM } from 'config';

const { DicomMetaDictionary } = dcmjs.data;
const { calibratedPixelSpacingMetadataProvider } = utilities;

const {
  // cache,
  imageLoader,
  metaData,
} = cornerstone;

async function initCornerstone() {
  if (!cornerstone.isCornerstoneInitialized()) {
    await cornerstone.init();
    console.log('Cornerstone initialized');
  }
}
// Function to get both image and instanceMetaData
async function loadImageAndMetaData(imageId) {
  try {
    let image = null;
    // Load and cache the image
    await cornerstoneDICOMImageLoader.wadouri.loadImage(imageId).promise;
    // Attempt to get metadata automatically populated by the loader
    let instanceMetaData = metaData.get('instance', imageId);
    // If metadata isn’t populated, extract it from the image or fetch it
    if (!instanceMetaData) {
      image = await imageLoader.loadAndCacheImage(imageId);
      // Construct metadata from the image properties (if available)
      instanceMetaData = {
        sopInstanceUid: image.imageId.split('wadouri:')[1], // Simplistic example
        rows: image.rows,
        columns: image.columns,
        pixelSpacing: image.pixelSpacing,
        // Add more fields as needed, depending on what’s available in image
      };
    }
    // Return both
    return { image, instanceMetaData };
  } catch (error) {
    console.error('Error loading image or generating metadata:', error);
    throw error;
  }
}
export const createImageIdsAndCacheMetaDataFiles = async (
  { instances, modality, convertMultiframe = true },
  onImageIdProgress
) => {
  // console.log('createImageIdsAndCacheMetaDataFiles', { instances, modality });
  try {
    await initCornerstone();
  } catch (error) {
    console.log({ error });
  }
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('refreshToken');
  // Configure global options with authorization headers
  cornerstoneDICOMImageLoader.internal.setOptions({
    beforeSend: function(xhr) {
      xhr.setRequestHeader('x-token', `${token}`);
      xhr.setRequestHeader('x-refresh-token', `${refreshToken}`);
    },
  });
  // if sop instance is provided we should filter the instances to only include the one we want
  let imageIds = [];
  const total = instances.length;
  let errors = [];
  for (let i = 0; i < total; i++) {
    const imageId = `wadouri:${HTTP_ADDRESS_DICOM_STREAM}/` + instances[i];
    let instanceMetaData = null;
    try {
      onImageIdProgress(imageId, total, i === 0);
      const reply = await loadImageAndMetaData(imageId);
      if (reply) instanceMetaData = reply.instanceMetaData;
    } catch (error) {
      console.log({ error });
      errors.push(`Error loading DICOM file: ${instances[i]}`);
      continue;
    }
    // Load the image, which parses the DICOM file and extracts metadata
    if (!instanceMetaData) {
      console.log('instanceMetaData - invalid');
      continue;
    }
    // instanceMetaData.ImageOrientationPatient = [1, 0, 0, 0, 1, 0];
    cornerstoneDICOMImageLoader.wadors.metaDataManager.add(
      imageId,
      instanceMetaData
    );
    // const { InstanceNumber, SlicePosition } = instanceMetaData;
    // console.log({ InstanceNumber, instanceMetaData });
    imageIds.push(imageId);
  }
  if (errors.length) {
    return { imageIds: null, errors: errors.length };
  }
  // if the image ids represent multiframe information, creates a new list with one image id per frame
  // if not multiframe data available, just returns the same list given
  if (convertMultiframe) {
    imageIds = convertMultiframeImageIds(imageIds);
  }

  try {
    imageIds.forEach(imageId => {
      let instanceMetaData = cornerstoneDICOMImageLoader.wadors.metaDataManager.get(
        imageId
      );

      if (!instanceMetaData) {
        return;
      }
      // console.log('instanceMetaData', instanceMetaData);
      // It was using JSON.parse(JSON.stringify(...)) before but it is 8x slower
      instanceMetaData = removeInvalidTags(instanceMetaData);
      // instanceMetaData.ImageOrientationPatient = [1, 0, 0, 0, 1, 0];
      if (instanceMetaData) {
        // Add calibrated pixel spacing
        const metadata = DicomMetaDictionary.naturalizeDataset(
          instanceMetaData
        );
        const pixelSpacing = getPixelSpacingInformation(metadata);

        if (pixelSpacing) {
          calibratedPixelSpacingMetadataProvider.add(imageId, {
            rowPixelSpacing: parseFloat(pixelSpacing[0]),
            columnPixelSpacing: parseFloat(pixelSpacing[1]),
          });
        }
      }
    });
  } catch (error) {
    console.log({ error });
  }

  // we don't want to add non-pet
  // Note: for 99% of scanners SUV calculation is consistent bw slices
  if (modality === 'PT') {
    const InstanceMetadataArray = [];
    imageIds.forEach(imageId => {
      const instanceMetadata = getPTImageIdInstanceMetadata(imageId);

      // TODO: Temporary fix because static-wado is producing a string, not an array of values
      // (or maybe dcmjs isn't parsing it correctly?)
      // It's showing up like 'DECY\\ATTN\\SCAT\\DTIM\\RAN\\RADL\\DCAL\\SLSENS\\NORM'
      // but calculate-suv expects ['DECY', 'ATTN', ...]
      if (typeof instanceMetadata.CorrectedImage === 'string') {
        instanceMetadata.CorrectedImage = instanceMetadata.CorrectedImage.split(
          '\\'
        );
      }

      if (instanceMetadata) {
        InstanceMetadataArray.push(instanceMetadata);
      }
    });
    if (InstanceMetadataArray.length) {
      try {
        const suvScalingFactors = calculateSUVScalingFactors(
          InstanceMetadataArray
        );
        InstanceMetadataArray.forEach((instanceMetadata, index) => {
          ptScalingMetaDataProvider.addInstance(
            imageIds[index],
            suvScalingFactors[index]
          );
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  return { imageIds, errors: 0 };
};
// Get Cornerstone imageIds and fetch metadata
// const imageIds = await createImageIdsAndCacheMetaData({
//   StudyInstanceUID:
//     '1.3.6.1.4.1.14519.5.2.1.7009.2403.334240657131972136850343327463',
//   SeriesInstanceUID:
//     '1.3.6.1.4.1.14519.5.2.1.7009.2403.226151125820845824875394858561',
//   wadoRsRoot: 'https://d14fa38qiwhyfd.cloudfront.net/dicomweb',
// });
// const imageIds = await createImageIdsAndCacheMetaDataFiles({
//   instances: [1],
//   modality: 'CT',
// });
// this.loadVolume(imageIds);
