import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import { withStyles } from 'tss-react/mui';
import { Button, ButtonGroup, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MapIcon from '@mui/icons-material/Map';
import {
  ButtonDownloadType,
  ButtonFileUpload,
  ButtonReloadDelete,
  DivGrow,
  SearchCountriesAtSystem,
  SearchText,
} from 'components';
import { MutationUploadCenterCSV } from 'graphql/Units/utils_mutation_centers';
import { ServerErrorsString, GetData } from 'helpers';
import SettingsIcon from '@mui/icons-material/Settings';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import VisibilityIcon from '@mui/icons-material/Visibility';
const useStyles = () => ({
  root: {
    height: 38,
    margin: 0,
    padding: 0,
    paddingTop: 5,
    paddingLeft: 10,
  },
});

class TabCentersHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      uploading: false,
      countries: [],
      selected_country: null,
      country: '',
      length: 0,
    };
  }
  handleProgress = event => {
    const { loading, total_download } = this.state;
    let { loaded, total } = event;
    if (total === 0 || isNaN(total) || total === undefined) {
      total = total_download;
    }
    if (this.props.handleProgress) {
      this.props.handleProgress({ loaded, total }, loading);
    }
  };
  handleSelectFile = event => {
    const { uploading, page, limit } = this.state;
    if (uploading) return;
    const { files } = event.target;
    if (!files.length) {
      return;
    }

    this.setState({ uploading: true, progress_all: 0 });
    (async () => {
      MutationUploadCenterCSV(files[0], page, limit, this.handleProgress)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, centers, total } = data.uploadNewCenters;
          if (ok) {
            this.setState({
              uploading: false,
              count_changed: false,
            });
            this.props.handelAddNewCenters(centers, total);
            if (errors.length) {
              throw errors;
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ uploading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleAddCountry = selected_country => {
    console.log({ selected_country });
    let name = null;
    if (this.props.holder.getSearchText) {
      name = this.props.holder.getSearchText();
    }
    this.setState({ selected_country }, () => {
      this.props.handleSearchCenter(
        name,
        selected_country ? selected_country.country : null
      );
    });
  };
  render() {
    const {
      classes,
      selected,
      loading,
      holder,
      open,
      selected_centers,
    } = this.props;
    const { selected_country } = this.state;
    let open_countries = false;
    let disable_map = false;
    if (open === 'settings' || open === 'show') {
      disable_map = true;
      open_countries = true;
      if (selected_centers.length) {
        disable_map = false;
      }
    }
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start"
        pb={1}
        pt={1}>
        <Grid item style={{ minWidth: open_countries ? 350 : 270 }} xs={3.4}>
          <ButtonGroup
            className={classes.root}
            exclusive="false"
            size="small"
            value="center">
            {open_countries ? (
              <Button
                onClick={() => {
                  this.props.setState({ selected_centers: [], from: '' });
                  this.props.handleTabChange('');
                }}>
                <LocationCityIcon style={{ color: 'blue' }} />
              </Button>
            ) : (
              <Button onClick={() => this.props.handleTabChange('create')}>
                <AddIcon style={{ color: 'blue' }} />
              </Button>
            )}
            {open_countries ? (
              <Button
                disabled={!selected.length}
                onClick={() => this.props.handleTabChange('show')}>
                <VisibilityIcon
                  style={{ color: selected.length ? 'red' : 'gray' }}
                />
              </Button>
            ) : (
              <Button
                disabled={!selected.length}
                onClick={() => this.props.handleTabChange('edit')}>
                <EditIcon style={{ color: selected.length ? 'red' : 'gray' }} />
              </Button>
            )}
            {open_countries ? null : (
              <Button onClick={() => this.props.handleTabChange('settings')}>
                <SettingsIcon style={{ color: 'green' }} />
              </Button>
            )}
            {open_countries ? null : (
              <ButtonFileUpload
                handleSelectFile={this.handleSelectFile}
                uploading={this.state.uploading}
              />
            )}
            {open_countries ? null : (
              <ButtonDownloadType key="center" type="centers" />
            )}
            <Button
              disabled={disable_map}
              onClick={() => this.props.handleTabChange('maps')}>
              <MapIcon style={{ color: disable_map ? 'gray' : 'blue' }} />
            </Button>
          </ButtonGroup>
        </Grid>
        {!open_countries ? (
          <React.Fragment>
            <Grid item xs={3}>
              <SearchCountriesAtSystem
                handleAddCountry={this.handleAddCountry}
                handleAddError={this.props.handleAddError}
                no_icon
                selected={selected_country}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item pl={2} sm xs={6}>
              <SearchText
                handleSearchText={name =>
                  this.props.handleSearchCenter(
                    name,
                    selected_country ? selected_country.country : null
                  )
                }
                holder={holder}
                id="id-search-admin-centers"
                loading={loading}
                placeholder="Search Center by name"
              />
            </Grid>
          </React.Fragment>
        ) : (
          <DivGrow />
        )}
        <Grid item pr={2}>
          <ButtonReloadDelete
            handleClearSelected={() => this.props.setState({ selected: [] })}
            handleDeleteSelected={this.props.handleDeleteSelected}
            handleReloadTable={() => this.props.handleReload()}
            loading={this.props.deleting}
            loading_table={this.props.loading}
            numSelected={open_countries ? 0 : selected.length}
            small
            tooltip="Reload sequences"
            wrapper={{ margin: 0 }}
          />
        </Grid>
      </Grid>
    );
  }
}

TabCentersHeader.propTypes = {
  classes: PropTypes.object,
  handleOpenCreate: PropTypes.func,
  handleOpenEdit: PropTypes.func,
  selected: PropTypes.array,
};
TabCentersHeader.defaultProps = {
  handleOpenCreate: () => '',
  handleOpenEdit: () => '',
  handleAddError: () => '',
  handelAddNewCenters: () => '',
  selected: [],
};
export default withStyles(TabCentersHeader, useStyles);
