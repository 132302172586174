import {
  PAGE_GLOBAL_SET_PAGE,
  PAGE_GLOBAL_OPEN_SIDEBAR,
  PAGE_GLOBAL_SET_SKIP_ONLINE,
  PAGE_GLOBAL_MIN_SIDEBAR,
  PAGE_GLOBAL_IS_DICOM_VIEWER,
} from './types';

export const setCurrentPage = page => dispatch => {
  dispatch({
    type: PAGE_GLOBAL_SET_PAGE,
    page,
  });
};
export const setOpenSidebar = openSidebar => dispatch => {
  dispatch({
    type: PAGE_GLOBAL_OPEN_SIDEBAR,
    openSidebar,
  });
};
export const setMiniSidebar = minSidebar => dispatch => {
  dispatch({
    type: PAGE_GLOBAL_MIN_SIDEBAR,
    minSidebar,
  });
};
export const setSkipOnline = skip_online => dispatch => {
  dispatch({
    type: PAGE_GLOBAL_SET_SKIP_ONLINE,
    skip_online,
  });
};
export const setDicomViewer = isDicomViewer => dispatch => {
  console.log({ page: localStorage.page, isDicomViewer });
  if (localStorage.page !== 'Dicom Viewer' && isDicomViewer) {
    localStorage.setItem('page', 'Dicom Viewer');
  } else if (localStorage.page === 'Dicom Viewer' && !isDicomViewer) {
    localStorage.removeItem('page');
  }
  dispatch({
    type: PAGE_GLOBAL_IS_DICOM_VIEWER,
    isDicomViewer,
  });
};
