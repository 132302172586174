/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import {
  setDicomViewer,
  setDisplayDicomSeries,
  setDisplayDicomStudy,
} from 'redux-store/actions';
import {
  DialogSearchSeries,
  GridViewers,
  SideStudySeriesBar,
  ToolBarViewers,
  useStyles,
} from './components';
import { colorBorderDicomViewer } from 'theme';
import { IsInvalid } from 'helpers';
const getUniquesSeries = Series => {
  return Object.values(
    Series.reduce((acc, item) => {
      acc[item.idSeries] = item;
      return acc;
    }, {})
  );
};
class DicomViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading_series: false,
      open_thumbnails: false,
      orientation: 'none',
      disable_orientation: false,
      selected_series: null,
      ListViewerSeries: [],
      open_search: false,
      full_screen: false,
    };
    this.holder = {
      loadSeries: null,
      reloadSidebar: null,
      handleToolChange: null,
      handleOrientationChange: null,
      handleToolSelected: null,
      handleColorSelected: null,
      setLoadSeries: loading_series => this.setState({ loading_series }),
    };
    this.componentRef = React.createRef();
  }
  componentDidMount() {
    document.addEventListener('fullscreenchange', this.handleFullScreenChange);
    const { study, ListSeries, isDicomViewer } = this.props;
    if (study) {
      const { Series } = study;
      let uniques = getUniquesSeries(Series);
      if (Series.length !== uniques.length) {
        study.Series = uniques;
        this.props.setDisplayDicomStudy(study);
      }
      this.setState({
        ListViewerSeries: getUniquesSeries(study.Series.concat(ListSeries)),
      });
    } else if (ListSeries.length) {
      this.setState({
        ListViewerSeries: getUniquesSeries(ListSeries),
      });
    }
    // console.log('componentDidMount - 1', { isDicomViewer });
    if (IsInvalid(isDicomViewer) || !isDicomViewer) {
      // console.log('componentDidMount - 2', { isDicomViewer });
      this.props.setDicomViewer(true);
    }
    //433, 432, 431, 430, 412
    // handleSampleSeries([65, 64, 62, 50, 43], this);
  }
  componentDidUpdate() {
    const { isDicomViewer } = this.props;
    if (IsInvalid(isDicomViewer)) {
      console.log('componentDidUpdate', { isDicomViewer });
      this.props.setDicomViewer(true);
    }
  }
  componentWillUnmount() {
    document.removeEventListener(
      'fullscreenchange',
      this.handleFullScreenChange
    );
  }
  handleFullScreenChange = () => {
    this.setState({
      full_screen: !!document.fullscreenElement,
    });
  };
  handleOnSeriesClick = selected_series => {
    console.log('handleOnSeriesClick', { idSeries: selected_series?.idSeries });
    if (IsInvalid(selected_series)) return;
    this.setState({ selected_series }, () => {
      if (this.holder.loadSeries) {
        this.holder.loadSeries(selected_series);
      }
    });
  };
  handleColorSelected = toolName => {
    if (this.holder.handleColorSelected) {
      this.holder.handleColorSelected(toolName);
    }
  };
  handleImageAction = interactions => {
    console.log_dev({ interactions });
    if (this.holder.handleToolChange) {
      this.holder.handleToolChange(interactions);
    }
  };
  handleOrientationChange = orientation => {
    console.log_dev({ orientation });
    if (this.holder.handleOrientationChange) {
      this.setState({ orientation }, () => {
        this.holder.handleOrientationChange(orientation);
      });
    }
  };
  handleToolSelected = toolName => {
    if (this.holder.handleToolSelected) {
      this.holder.handleToolSelected(toolName);
    }
  };
  toggleFullScreen = full_screen => {
    if (full_screen) {
      // Enter full screen
      if (this.componentRef.current.requestFullscreen) {
        this.componentRef.current.requestFullscreen();
      } else if (this.componentRef.current.webkitRequestFullscreen) {
        // Safari
        this.componentRef.current.webkitRequestFullscreen();
      } else if (this.componentRef.current.msRequestFullscreen) {
        // IE11
        this.componentRef.current.msRequestFullscreen();
      }
    } else {
      // Exit full screen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        // Safari
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        // IE11
        document.msExitFullscreen();
      }
    }
    this.setState({ full_screen });
  };
  render() {
    const { open_thumbnails, ListViewerSeries } = this.state;
    const { classes } = this.props;
    let { series, height, study, ListSeries } = this.props;
    if (ListViewerSeries.length > 0) {
      if (IsInvalid(this.state.selected_series)) series = ListViewerSeries[0];
      else series = this.state.selected_series;
      if (IsInvalid(study)) {
        study = {
          Series: ListViewerSeries,
        };
      } else {
        study.Series = getUniquesSeries(study.Series.concat(ListViewerSeries));
      }
      study.StudyDescription = 'Multiple-Series';
    } else if (IsInvalid(series) && this.state.selected_series) {
      series = this.state.selected_series;
    }
    console.cout('DicomViewer - render', {
      idSeries: series?.idSeries,
      study: study ? study.Series.map(x => x.idSeries) : null,
      ListSeries: ListSeries.map(x => x.idSeries),
      ListViewerSeries: ListViewerSeries.map(x => x.idSeries),
    });
    let height_main = height - 118.5;
    let height_sidebar = height - 10;
    if (this.state.full_screen) {
      height_main = height - 50;
      height_sidebar = height + 50;
    }
    return (
      <Box
        ref={this.componentRef}
        sx={{ backgroundColor: 'black', display: 'flex' }}>
        <DialogSearchSeries
          handleAddError={this.props.handleAddError}
          ListSeries={ListSeries}
          open_search={this.state.open_search}
          setState={(state, res) => {
            if (IsInvalid(res)) this.setState(state);
            else this.setState(state, res);
          }}
          state={this.state}
        />
        <SideStudySeriesBar
          handleDrawerClose={open_thumbnails =>
            this.setState({ open_thumbnails })
          }
          height={height_sidebar}
          onSeriesClick={this.handleOnSeriesClick}
          open={open_thumbnails}
          series={series}
          setState={state => this.setState(state)}
          state={this.state}
          study={study}
        />
        <Box component="main" sx={{ flexGrow: 1, backgroundColor: '#333' }}>
          <Grid
            alignItems="flex-start"
            container
            direction="row"
            justifyContent="flex-start"
            style={{
              height: height - 70,
              width: '100%',
              flexGrow: 1,
            }}>
            <Grid
              className={classes.toolbar}
              item
              style={{ maxHeight: 55, borderColor: colorBorderDicomViewer }}
              xs={12}>
              <ToolBarViewers
                actions={{ maximize: this.state.full_screen }}
                disable_orientation={this.state.disable_orientation}
                handleAddError={this.props.handleAddError}
                handleColorSelected={this.handleColorSelected}
                handleImageAction={this.handleImageAction}
                handleMaximize={full_screen =>
                  this.toggleFullScreen(full_screen)
                }
                handleOrientationChange={this.handleOrientationChange}
                handleReset={() => {
                  if (this.holder.reloadSidebar) this.holder.reloadSidebar();
                }}
                handleToolSelected={this.handleToolSelected}
                holder={this.holder}
                isDesktop={this.props.isDesktop}
                orientation={this.state.orientation}
              />
            </Grid>
            <Grid
              item
              style={{ backgroundColor: 'black', height: height_main }}
              xs={12}>
              <GridViewers
                handleAddError={this.props.handleAddError}
                handleOrientationChange={(orientation, disable_orientation) =>
                  this.setState({ orientation, disable_orientation })
                }
                height={height_main}
                holder={this.holder}
                isDesktop={this.props.isDesktop}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

DicomViewer.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  let { series, study, ListSeries } = state.page_dicom_viewer;
  let {
    openSidebar,
    width_sidebar,
    minSidebar,
    isDicomViewer,
  } = state.page_globals;
  const { isDesktop } = state.layout;
  let width = window.innerWidth;
  if (openSidebar && isDesktop) {
    if (minSidebar) width -= 63;
    else width -= width_sidebar;
  }
  if (IsInvalid(ListSeries)) {
    ListSeries = [];
  }
  return {
    series,
    study,
    width,
    isDesktop,
    minSidebar,
    ListSeries,
    isDicomViewer,
  };
};

export default connect(mapStateToProps, {
  setDisplayDicomSeries,
  setDisplayDicomStudy,
  setDicomViewer,
})(withStyles(withRouter(DicomViewer), useStyles));
