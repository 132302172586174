/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  Divider,
  Popper,
  Grow,
  MenuList,
  MenuItem,
  ClickAwayListener,
  IconButton,
  Grid,
  Avatar,
} from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import PanToolIcon from '@mui/icons-material/PanTool';
import MouseIcon from '@mui/icons-material/Mouse';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import ContrastIcon from '@mui/icons-material/Contrast';
import { Replay as ReloadIcon } from '@mui/icons-material';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import BrushIcon from '@mui/icons-material/Brush';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import FlipIcon from '@mui/icons-material/Flip';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import Color from './Colors/Color';
import { IsInvalid } from 'helpers';
import { setOpenSidebar } from 'redux-store/actions';
import { colorBorderDicomViewer, colorDicomViewer } from 'theme';
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: theme.spacing(0.5),
    border: 0,
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));
const useStyles = () => ({
  button: {
    margin: 0,
    padding: 6,
    '&.MuiButtonBase-root': {
      '&.MuiToggleButton-root': {
        '&.Mui-selected': {
          backgroundColor: 'rgba(38, 50, 56, 0.4)',
        },
      },
    },
  },
});
class ToolBarViewer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      interactions: 'StackScroll',
      anchorRef: null,
      activeMenu: 'default',
      menu: { name: 'None', IconMenu: DoNotDisturbAltIcon },
      menu_list: [
        { name: 'None', IconMenu: DoNotDisturbAltIcon },
        { name: 'Length', IconMenu: OpenInFullIcon },
        { name: 'Angle', IconMenu: ArchitectureIcon },
        {
          name: 'Circular ROI',
          IconMenu: PanoramaFishEyeIcon,
          internal: 'CircleROI',
        },
        { name: 'Bidirectional', IconMenu: ShuffleIcon },
      ],
      menu_colors: [
        { name: 'Grayscale', url: null },
        { name: 'Black-Body Radiation', url: '/images/option_1.png' },
        { name: 'Cool to Warm (Extended)', url: '/images/option_2.png' },
      ],
      menu_color: { name: 'Grayscale', url: null },
      to_black: false,
    };
    const { holder } = props;
    if (holder) {
      // holder.handleToolChange;
    }
  }
  componentDidUpdate(_, prevState) {
    const viewer = document.getElementById('viewer');
    if (!viewer) return;

    if (this.state.anchorRef && !prevState.anchorRef) {
      viewer.style.pointerEvents = 'none'; // Disable mouse events on Cornerstone
    } else if (!this.state.anchorRef && prevState.anchorRef) {
      viewer.style.pointerEvents = 'auto'; // Re-enable Cornerstone interactions
    }
  }

  handleClick = (event, menuType) => {
    event.preventDefault();
    const anchorRef =
      event.currentTarget === this.state.anchorRef ? null : event.currentTarget;
    this.setState({ anchorRef, activeMenu: menuType });
  };
  handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      this.setState({ anchorRef: null });
    } else if (event.key === 'Escape') {
      this.setState({ anchorRef: null });
    }
  };
  handleClickActions = (event, formats) => {
    const buttons = ['visible', 'scale', 'black_screen'];
    console.log({ formats });
    for (let i = 0; i < buttons.length; i++) {
      const button = buttons[i];

      if (this.wasActive(button, formats)) {
        console.log({
          button,
          active: this.wasActive(button, formats) ? 'Yes' : 'No',
        });
        this.props.handleFormats(formats);
        this.props.handleRightAction(button);
        return;
      }
    }
  };

  wasActive = (button, new_formats) => {
    const { formats } = this.props;
    if (formats.includes(button) && !new_formats.includes(button)) return true;
    if (!formats.includes(button) && new_formats.includes(button)) return true;
    return false;
  };

  handleColorSelect = color => {
    this.setState({
      anchorRef: null,
      menu: { name: 'None', IconMenu: DoNotDisturbAltIcon },
    });
    this.props.handleColorSelect(color); // Pass the selected color back to the parent
    console.log('Selected Color', { color });
  };

  renderColorPalette = () => {
    // from the enum Colors so it can be used int the vtkImageMapToColors
    const colors = [];
    for (let i = 1; i < 21; i++) {
      colors.push(Color[i]);
    }

    return (
      <Grid container spacing={1} sx={{ width: 180, padding: '5px' }}>
        <Grid item xs={12}>
          <div
            onClick={() => this.handleColorSelect(null)} // Reset color when "None" is selected
            style={{
              width: '100%',
              textAlign: 'center',
              cursor: 'pointer',
              padding: '5px 0',
              border: '1px solid #000',
              fontWeight: 'bold',
            }}>
            None
          </div>
        </Grid>
        {colors.map(color => (
          <Grid item key={color[0]} xs={3}>
            <div
              onClick={() => this.handleColorSelect(color[0])}
              style={{
                background: `rgb(${color[1]}, ${color[2]}, ${color[3]})`,
                width: '30px',
                height: '30px',
                border: '1px solid #000',
                margin: '2px',
                cursor: 'pointer',
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  };
  handleViewerActions = (event, interactions) => {
    let { menu } = this.state;
    if (menu.name !== 'None') {
      menu = { name: 'None', IconMenu: DoNotDisturbAltIcon };
    }
    if (IsInvalid(interactions)) interactions = this.state.interactions;
    else this.setState({ interactions, menu });
    this.props.handleImageAction(interactions);
  };
  handleToolSelected = toolObject => {
    console.log({ toolObject });
    this.setState(
      { menu: toolObject, anchorRef: null, interactions: null },
      () => {
        let name = toolObject.name;
        if (toolObject.internal) name = toolObject.internal;
        this.props.handleToolSelected(name);
      }
    );
  };
  handleColorSelected = toolObject => {
    console.log({ toolObject });
    this.setState(
      { menu_color: toolObject, anchorRef: null, interactions: null },
      () => {
        this.props.handleColorSelected(toolObject.name);
      }
    );
  };
  render() {
    const {
      actions,
      orientation,
      classes,
      showMenuIcon,
      openSidebar,
    } = this.props;
    const {
      // visible,
      maximize,
    } = actions;
    const { interactions, anchorRef, activeMenu, menu } = this.state;
    const { formats } = this.props.formats;
    let open = Boolean(anchorRef);
    let MenuIconAction = DesignServicesIcon;
    // console.log({ menu });
    if (menu.name !== 'None') MenuIconAction = menu.IconMenu;
    let IconMaximize = FullscreenIcon;
    if (maximize) IconMaximize = FullscreenExitIcon;
    const zIndexMenus = 30000;
    let ComponentRight = null;
    if (showMenuIcon) {
      ComponentRight = (
        <IconButton onClick={() => this.props.setOpenSidebar(!openSidebar)}>
          {openSidebar ? (
            <MenuOpenIcon sx={{ color: 'white' }} />
          ) : (
            <MenuIcon sx={{ color: 'white' }} />
          )}
        </IconButton>
      );
    }
    let ComponentFullscreen = (
      <ToggleButton
        aria-label="maximize"
        onClick={() => this.props.handleMaximize(!maximize)}
        sx={{ border: maximize ? undefined : 'none' }}
        value="maximize">
        <IconMaximize />
      </ToggleButton>
    );
    if (maximize) {
      ComponentRight = ComponentFullscreen;
      ComponentFullscreen = null;
    }
    return (
      <div style={{ backgroundColor: 'transparent' }}>
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            border: theme => `1px solid ${theme.palette.divider}`,
            flexWrap: 'wrap',
            backgroundColor: colorDicomViewer,
            borderColor: colorBorderDicomViewer,
          }}>
          <Grid
            container
            direction="row"
            sx={{
              width: '100%',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderColor: colorBorderDicomViewer,
            }}>
            <Grid item style={{ minWidth: 360, maxWidth: 400 }} xs={3.3}>
              <StyledToggleButtonGroup
                exclusive
                onChange={this.handleViewerActions}
                size="small"
                value={interactions}>
                <ToggleButton
                  aria-label="StackScroll"
                  className={classes.button}
                  value="StackScroll">
                  <MouseIcon />
                </ToggleButton>
                <ToggleButton
                  aria-label="WindowLevel"
                  className={classes.button}
                  value="WindowLevel">
                  <ContrastIcon />
                </ToggleButton>
                <ToggleButton
                  aria-label="Zoom"
                  className={classes.button}
                  value="Zoom">
                  <SearchIcon />
                </ToggleButton>
                <ToggleButton
                  aria-label="Pan"
                  className={classes.button}
                  value="Pan">
                  <PanToolIcon />
                </ToggleButton>
                <ToggleButton aria-label="rotate_left" value="rotate_left">
                  <RotateLeftIcon />
                </ToggleButton>
                <ToggleButton aria-label="rotate_right" value="rotate_right">
                  <RotateRightIcon />
                </ToggleButton>
                <ToggleButton aria-label="flip_vertical" value="flip_vertical">
                  <FlipIcon style={{ transform: 'rotate(90deg)' }} />
                </ToggleButton>
                <ToggleButton
                  aria-label="flip_horizontal"
                  value="flip_horizontal">
                  <FlipIcon />
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Grid>
            <Divider
              flexItem
              orientation="vertical"
              sx={{ mx: 0.5, my: 1, width: '5px', borderColor: '#c2b9b9' }}
            />
            <Grid item style={{ minWidth: 130, maxWidth: 130 }} xs={3.3}>
              <StyledToggleButtonGroup
                disabled={this.props.disable_orientation}
                exclusive
                onChange={(event, newOrientation) => {
                  if (IsInvalid(newOrientation)) return;
                  this.props.handleOrientationChange(newOrientation);
                }}
                value={orientation}>
                <ToggleButton
                  aria-label="axial"
                  className={classes.button}
                  value="axial">
                  <Avatar
                    src="/images/axial.png"
                    sx={{ width: 25, height: 30, borderRadius: 0 }}
                  />
                </ToggleButton>
                <ToggleButton
                  aria-label="coronal"
                  className={classes.button}
                  value="coronal">
                  <Avatar
                    src="/images/coronal.png"
                    sx={{ width: 25, height: 30, borderRadius: 0 }}
                  />
                </ToggleButton>
                <ToggleButton
                  aria-label="sagittal"
                  className={classes.button}
                  value="sagittal">
                  <Avatar
                    src="/images/sagittal.png"
                    sx={{ width: 30, height: 30, borderRadius: 0 }}
                  />
                </ToggleButton>
              </StyledToggleButtonGroup>
            </Grid>
            <Divider
              flexItem
              orientation="vertical"
              sx={{ mx: 0.5, my: 1, width: '5px', borderColor: '#c2b9b9' }}
            />
            <Grid
              container
              direction="row"
              item
              sx={{
                justifyContent: 'flex-between',
                alignItems: 'center',
                minWidth: 140,
                maxWidth: 142,
              }}
              xs>
              <Grid item sx={{ minWidth: 125 }} xs={2.5}>
                <StyledToggleButtonGroup
                  aria-label="text formatting"
                  onChange={this.handleClickActions}
                  size="small"
                  value={formats}>
                  {/*Bucket  */}
                  <ToggleButton
                    aria-label="measuring_tools"
                    onClick={e => {
                      this.handleClick(e, 'measuring_tools');
                    }}
                    value="measuring_tools">
                    <MenuIconAction />
                    <ArrowDropDownIcon />
                  </ToggleButton>
                  {/*Paint brush  */}
                  <ToggleButton
                    aria-label="paintbrush"
                    onClick={e => this.handleClick(e, 'paintbrush')}
                    value="paintbrush">
                    {this.props.selectedColor >= 1 &&
                    this.props.selectedColor <= 20 ? (
                      <div
                        style={{
                          backgroundColor: `rgb(${
                            Color[this.props.selectedColor][1]
                          },${Color[this.props.selectedColor][2]}, ${
                            Color[this.props.selectedColor][3]
                          })`,
                          width: '20px',
                          height: '20px',
                          border: '1px solid black',
                          marginRight: '5px',
                        }}
                      />
                    ) : this.state.menu_color.url ? (
                      <Avatar
                        src={this.state.menu_color.url}
                        sx={{
                          width: 20,
                          height: 25,
                          borderRadius: 0,
                          margin: 0,
                          padding: 0,
                        }}
                      />
                    ) : (
                      <BrushIcon />
                    )}
                    <ArrowDropDownIcon />
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Grid>
              <Divider
                flexItem
                orientation="vertical"
                sx={{ mx: 0.5, my: 1, width: '5px', borderColor: '#c2b9b9' }}
              />
              {/* FullScreen */}
              {ComponentFullscreen}
              <div style={{ maring: 0, padding: 0, flexGrow: 1 }} />
              <Grid
                alignItems="center"
                container
                direction="row"
                item
                justifyContent="flex-end"
                xs={1}>
                <IconButton onClick={() => this.props.handleReset()}>
                  <ReloadIcon />
                </IconButton>
              </Grid>
              {ComponentRight}
            </Grid>
          </Grid>
          <Popper
            anchorEl={anchorRef}
            disablePortal
            open={open}
            placement="bottom-start"
            role={undefined}
            sx={{ zIndex: zIndexMenus, display: open ? null : 'none' }}
            transition>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                  zIndex: zIndexMenus,
                }}>
                <Paper
                  sx={{
                    zIndex: zIndexMenus,
                    pointerEvents: 'auto', // Ensures it detects mouse events
                  }}>
                  <ClickAwayListener
                    onClickAway={() => {
                      this.setState({ anchorRef: null, open: false });
                    }}>
                    {activeMenu === 'paintbrush' ? (
                      // this.renderColorPalette() // Show the color palette for paintbrush
                      <MenuList
                        aria-labelledby="color-map-button"
                        autoFocusItem={open}
                        id="color-map-menu"
                        // onKeyDown={this.handleListKeyDown}
                      >
                        {this.state.menu_colors.map(option => {
                          const { name, url } = option;
                          return (
                            <MenuItem
                              disableRipple
                              key={name}
                              onClick={() => {
                                this.handleColorSelected(option);
                              }}
                              selected={this.state.menu_color.name === name}>
                              <Avatar
                                src={url ? url : '/images/option_0.png'}
                                sx={{
                                  width: 20,
                                  height: 25,
                                  borderRadius: 0,
                                  margin: 0,
                                  padding: 0,
                                }}
                              />
                              <div style={{ paddingLeft: 10 }}>{name}</div>
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    ) : (
                      <MenuList
                        aria-labelledby="measuring_tools-button"
                        autoFocusItem={open}
                        id="measuring_tools-menu"
                        onKeyDown={this.handleListKeyDown}>
                        {this.state.menu_list.map(option => {
                          const { name, IconMenu } = option;
                          return (
                            <MenuItem
                              disableRipple
                              key={name}
                              onClick={() => {
                                console.log({ option });
                                this.handleToolSelected(option);
                              }}
                              selected={this.state.menu.name === name}>
                              <IconMenu />
                              <div style={{ paddingLeft: 10 }}>{name}</div>
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    )}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Paper>
      </div>
    );
  }
}

ToolBarViewer.propTypes = {
  classes: PropTypes.object,
};
ToolBarViewer.defaultProps = {
  handleColorSelected: () => '',
  handleToolSelected: () => '',
  handleOrientationAction: () => '',
  handleColorSelect: () => '',
  handleImageAction: () => '',
  handleRightAction: () => '',
  handleReset: () => '',
  maximize: false,
  handleMaximize: () => '',
  actions: {
    visible: false,
    maximize: false,
    black_screen: false,
  },
  formats: [],
  handleFormats: () => '',
  orientation: 'none',
  disable_orientation: false,
};

const mapStateToProps = state => {
  let { openSidebar, width_sidebar, minSidebar } = state.page_globals;
  const { isDesktop } = state.layout;
  let width = window.innerWidth;
  if (openSidebar && isDesktop) {
    if (minSidebar) width -= 63;
    else width -= width_sidebar;
  }
  let showMenuIcon = false;
  if (!minSidebar && isDesktop) {
    showMenuIcon = true;
  }
  return { width, isDesktop, showMenuIcon, openSidebar };
};

export default connect(mapStateToProps, { setOpenSidebar })(
  withStyles(ToolBarViewer, useStyles)
);
