/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { getRightStyles } from './styleRight';

import { RightContent, RightToolbar } from './components';

class PanelRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      droppedItems: [],
      selectedItems: [],
      isGridOpen: false,
    };
    const { holder } = props;
    if (holder) {
      holder.setDroppedItems = ListViewerSeries =>
        this.setState({
          droppedItems: ListViewerSeries.map(series => ({
            idSeries: series.idSeries,
            url: '/api/placeholder/80/80',
            series,
          })),
        });
    }
  }

  render() {
    const { classes } = this.props;
    const { selectedItems, isGridOpen, droppedItems } = this.state;
    const styles = getRightStyles(selectedItems, isGridOpen, droppedItems);
    // console.log('PanelRight', { selectedItems, isGridOpen, droppedItems });

    return (
      <div className={classes.rightPanel}>
        <div className={classes.controlBar}>
          <RightToolbar
            classes={classes}
            droppedItems={droppedItems}
            handleAddError={this.props.handleAddError}
            onDroppedSeries={this.props.onDroppedSeries}
            selectedItems={selectedItems}
            setState={state => this.setState(state)}
          />
        </div>
        <div style={styles.rightSection}>
          <RightContent
            classes={classes}
            droppedItems={droppedItems}
            handleAddError={this.props.handleAddError}
            holder={this.props.holder}
            onDroppedSeries={this.props.onDroppedSeries}
            selectedItems={selectedItems}
            setState={state => this.setState(state)}
            styles={styles}
          />
        </div>
      </div>
    );
  }
}

PanelRight.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default PanelRight;
