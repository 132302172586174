import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import {
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { GetContentThumbnail, GetContentName, GetContentMonth } from 'helpers';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { CircularProgressWaiting } from 'components';
class ContainerPictures extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getProjectObjects = () => {
    const { project_objects } = this.props;
    return project_objects
      .map(x => {
        const { folder_content, center } = x;
        if (folder_content === undefined || !folder_content) return null;
        const { idFolderContent } = folder_content;
        const img = GetContentThumbnail(folder_content);
        const title = GetContentName(folder_content);
        const month = GetContentMonth(folder_content);
        let name = null;
        if (folder_content.File.metadata)
          name = folder_content.File.metadata.name;
        if (img === undefined || !img) return null;
        let author = '';
        if (center) author = center.name;
        return {
          idFolderContent,
          img,
          title,
          month,
          author,
          name,
        };
      })
      .filter(x => x);
  };
  handleOpenImage = folder_content => {
    if (folder_content === undefined || !folder_content) return;
    const { idFolderContent } = folder_content;
    // console.log({ idFolderContent });
    this.props.history.push(`/dashboard/view/${idFolderContent}`);
  };
  render() {
    const { height, loading } = this.props;
    let delta = 203;
    if (loading) {
      return (
        <CircularProgressWaiting
          sx={{
            height: height - delta,
            width: '100%',
            maxHeight: height - delta,
          }}
        />
      );
    }
    const project_objects = this.getProjectObjects();
    let months = [];
    return (
      <div style={{ width: '100%', height: height - delta }}>
        <ImageList cols={3} sx={{ width: '100%', maxHeight: height - delta }}>
          {project_objects.map((item, index) => {
            let ComponentMonth = null;
            const { month } = item;
            const i = months.indexOf(month);
            if (month && month !== '' && i === -1) {
              ComponentMonth = (
                <ImageListItem
                  cols={3}
                  key="Subheader"
                  style={{ maxHeight: '70px' }}>
                  <ListSubheader component="div">{month}</ListSubheader>
                </ImageListItem>
              );
              months.push(month);
            }
            return (
              <React.Fragment key={`item.img-${index}`}>
                {ComponentMonth}
                <ImageListItem style={{ maxHeight: '300px' }}>
                  <img
                    alt={item.title}
                    loading="lazy"
                    src={item.img}
                    style={{
                      height: 'auto',
                      width: '100%',
                      // maxWidth: '300px',
                      maxHeight: '300px',
                      objectFit: 'contain',
                    }}
                  />
                  <ImageListItemBar
                    actionIcon={
                      <IconButton
                        aria-label={`info about ${item.title}`}
                        onClick={() => this.handleOpenImage(item)}
                        sx={{ color: 'rgba(100, 255, 100, 0.54)' }}>
                        <ZoomOutMapIcon />
                      </IconButton>
                    }
                    subtitle={item.author}
                    title={item.name ? item.name : item.title}
                  />
                </ImageListItem>
              </React.Fragment>
            );
          })}
        </ImageList>
      </div>
    );
  }
}

ContainerPictures.propTypes = {
  classes: PropTypes.object,
};

export default withRouter(ContainerPictures);
