import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { columns } from './utils';
import { QueryPatients } from 'graphql/PatientsQueries';
import { rowsPerPageBig } from 'helpers';
import { ServerErrorsString, GetData } from 'helpers';

class TabPatientImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      selected: [],
      patients: [],
      loading: false,
      total: 0,
      page: 0,
      limit: 50,
    };
    const { holder } = props;
    if (holder) {
      holder.reloadDicoms = name => this.handleUpdate(name);
      holder.reloadDicomsClean = () => this.setState({ name: '' });
    }
  }
  componentDidMount() {
    this.handleUpdate();
  }
  handleUpdate = (name, newPage, newLimit) => {
    let { loading, page, limit } = this.state;
    if (loading) return;
    if (newPage) page = newPage;
    if (newLimit) limit = newLimit;
    this.setState({ loading: true });
    (async () => {
      QueryPatients({ PatientName: name }, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, patients, total } = data.Patients;
          if (ok) {
            this.setState({
              loading: false,
              patients,
              page,
              limit,
              total,
            });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handlePageChange = page => {
    this.handleUpdate(null, page, this.state.limit);
  };
  handleRowsPerPageChange = limit => {
    this.handleUpdate(null, 0, limit);
  };
  handleChangeName = event => {
    event.preventDefault();
    this.handleUpdate(event.target.value);
  };
  render() {
    const { height } = this.props;
    return (
      <div>
        <TableGrid
          checkboxSelection
          dense
          // disableSelectionOnClick
          external_selected={this.state.selected}
          headers={columns}
          height={height - 260}
          idRow="idPatient"
          loading={this.state.loading}
          onPageChange={newPage => this.handlePageChange(newPage)}
          onRowsPerPageChange={limit => this.handleRowsPerPageChange(limit)}
          onSelectedChanged={selected => this.setState({ selected })}
          paginationMode="server"
          rowCount={this.state.total}
          rows={this.state.patients}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
        />
      </div>
    );
  }
}

TabPatientImages.propTypes = {
  classes: PropTypes.object,
};

export default TabPatientImages;
