export const IsAdministrator = user => {
  if (user === undefined || !user) return false;
  const { type } = user;
  if (type === 1 || type === 11) return true;
  return false;
};

export const IsUserPipeline = user => {
  if (user === undefined || !user) return false;
  if (IsAdministrator(user)) return true;
  const { type } = user;
  if (type === 1 || type === 13) return true;
  return false;
};
export const IsUserClinicalData = (user, is_clinical_allowed) => {
  if (user === undefined || !user) return false;
  if (IsAdministrator(user)) return true;
  const { type } = user;
  if (type === 1) return true;
  if (is_clinical_allowed) return true;
  return false;
};

export const IsUserDownloadLink = user => {
  if (user === undefined || !user) return false;
  if (IsAdministrator(user)) return true;
  const { type } = user;
  if (type === 1 || type === 11 || type === 8) return true;
  return false;
};
export const IsUserSharedLink = user => {
  if (user === undefined || !user) return false;
  if (IsAdministrator(user)) return true;
  const { type } = user;
  if (type === 1 || type === 11 || type === 8 || type === 13) return true;
  return false;
};
export const IsUserViewer = user => {
  if (user === undefined || !user) return false;
  if (IsAdministrator(user)) return true;
  const { viewer_access } = user;
  return viewer_access > 0;
};
