import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

const TabsSmall = styled(Tabs)(({ height_tab }) => ({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
  minHeight: height_tab ? height_tab : '48px',
  '& .MuiTabs-flexContainer': {
    height: height_tab ? height_tab : '48px', // Adjust the height of the tabs container
  },
  '& .MuiTabs-scroller': {
    height: height_tab ? height_tab : '48px', // Adjust the height of the tabs container
  },
}));

export default TabsSmall;
