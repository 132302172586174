import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, CardMedia } from '@mui/material';
import { GetThumbnailFile } from 'helpers';

class RightContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.dropZoneRef = React.createRef();
  }
  handleDragStart = (e, item) => {
    e.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        html: `<div class="image-item" draggable="true" data-idSeries="${item.idSeries}" style="background-image: url('${item.url}')"></div>`,
        idSeries: item.idSeries,
      })
    );
    e.target.style.opacity = '0.8';
  };
  handleItemClick = (e, itemId) => {
    e.stopPropagation();
    const isMultiSelect = e.ctrlKey || e.metaKey;

    this.props.setState(prevState => {
      const { selectedItems } = prevState;
      if (!isMultiSelect) {
        return {
          selectedItems: selectedItems.includes(itemId) ? [] : [itemId],
        };
      }
      return {
        selectedItems: selectedItems.includes(itemId)
          ? selectedItems.filter(id => id !== itemId)
          : [...selectedItems, itemId],
      };
    });
  };
  handleDrop = e => {
    e.preventDefault();
    let { droppedItems } = this.props;
    const data = JSON.parse(e.dataTransfer.getData('text/plain'));
    console.log({ data });
    if (data) {
      const { idSeries } = data;
      if (droppedItems.map(x => x.idSeries).includes(idSeries)) {
        this.props.handleAddError(`Series ID: ${idSeries} is here`);
        return;
      }
      const series = this.props.holder.getSeries(idSeries);
      console.log('handleDrop', { series });
      droppedItems = [
        ...droppedItems,
        { idSeries, url: '/api/placeholder/80/80', series },
      ];
      this.props.onDroppedSeries(droppedItems);
      this.props.setState({ droppedItems });
    }
    this.dropZoneRef.current.style.backgroundColor = 'transparent';
    this.dropZoneRef.current.style.border = '2px dashed #ccc';
  };
  handleDragLeave = () => {
    this.dropZoneRef.current.style.backgroundColor = 'transparent';
    this.dropZoneRef.current.style.border = '2px dashed #ccc';
  };
  handleDragOver = e => {
    e.preventDefault();
    this.dropZoneRef.current.style.backgroundColor = '#3e4d6b';
    this.dropZoneRef.current.style.border = '2px dashed #4a90e2';
  };
  render() {
    const { styles, droppedItems, classes, selectedItems } = this.props;
    let width = 0;
    if (this.dropZoneRef.current) {
      width = this.dropZoneRef.current.offsetWidth;
    }
    let count = 0;
    if (width) {
      count = parseInt(width / 87);
    }
    // console.log({ width, count });
    return (
      <div style={styles.dropContainer}>
        <Box
          component="div"
          onDragLeave={this.handleDragLeave}
          onDragOver={this.handleDragOver}
          onDrop={this.handleDrop}
          ref={this.dropZoneRef}
          style={styles.dropZone}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: `calc(100% - ${count ? width - count * 87 : 1}px)`,
            }}>
            {droppedItems.length === 0 ? (
              <div style={styles.dropZoneText}>Drag images here</div>
            ) : (
              droppedItems.map((item, index) => {
                const {
                  series: { idSeries, Thumbnail, Modality },
                } = item;
                let thumbnail = GetThumbnailFile('file.dcm');
                if (Thumbnail && Thumbnail !== '') {
                  thumbnail = `data:image/jpeg;base64,${Thumbnail}`;
                } else if (Modality === 'SR') {
                  thumbnail = '/images/icons/icon_sr.png';
                } else if (Modality === 'PR') {
                  thumbnail = '/images/icons/icon_pr.png';
                }
                return (
                  <Box
                    component="div"
                    draggable
                    key={`${idSeries}-${index}`}
                    onClick={e => this.handleItemClick(e, idSeries)}
                    onDragEnd={this.handleDragEnd}
                    onDragStart={e => this.handleDragStart(e, item)}
                    style={{
                      ...styles.imageItem,
                      ...styles.droppedItem,
                      backgroundImage: `url(${item.url})`,
                      ...(selectedItems.includes(idSeries)
                        ? styles.selected
                        : {}),
                    }}>
                    {selectedItems.includes(idSeries) && (
                      <div style={styles.selectedAfter}>
                        <CheckCircleIcon
                          style={{ color: 'green', width: 20, height: 20 }}
                        />
                      </div>
                    )}
                    <CardMedia
                      className={classes.imageItemDropped}
                      image={thumbnail}
                      title="Image title"
                    />
                  </Box>
                );
              })
            )}
          </div>
        </Box>
      </div>
    );
  }
}

RightContent.propTypes = {
  classes: PropTypes.object,
};

export default RightContent;
