/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import { rowsPerPageBig } from 'helpers';
import { headers, PanelNavigation } from './components';

class LeftSectionPatients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isGridOpen: true,
      page: 0,
      limit: 50,
    };
  }
  toggleGrid = () => {
    this.setState(prevState => ({
      isGridOpen: !prevState.isGridOpen,
    }));
  };
  handlePageChange = page => {
    const { holder, state } = this.props;
    if (holder && holder.queryPatients) {
      holder.queryPatients(null, page, state.limit);
    }
  };
  handleLimitChange = limit => {
    const { holder } = this.props;
    console.log({ a: holder.queryPatients });
    if (holder && holder.queryPatients) {
      holder.queryPatients(null, 0, limit);
    }
  };
  render() {
    const { isGridOpen } = this.state;
    const { classes, state } = this.props;
    const styles = {
      sectionHeader: {
        width: '100%',
        backgroundColor: 'transparent',
        padding: '5px 15px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        userSelect: 'none',
        borderBottom: isGridOpen ? '1px solid white' : '',
      },
      icon: {
        transition: 'transform 0.3s',
        transform: isGridOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
      },
      sectionContainer: {
        display: isGridOpen ? 'grid' : 'none',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        margin: 10,
        border: '1px solid gray',
      },
    };
    return (
      <div
        style={{
          flex: '1 1 0%',
          border: '1px solid rgb(221, 221, 221)',
          borderRadius: '5px',
          overflow: 'hidden',
        }}>
        <div
          className={classes.sectionHeader}
          onClick={this.toggleGrid}
          style={styles.sectionHeader}>
          <PanelNavigation
            classes={classes}
            handleAddError={this.props.handleAddError}
            patients={state.patients}
          />
          <span style={styles.icon}>▼</span>
        </div>
        <div style={styles.sectionContainer}>
          <TableGrid
            dense
            disableSelectionOnClick
            external_selected={this.state.selected}
            headers={headers}
            idRow="idPatient"
            loading={state.loading_patients}
            onHighlightChange={this.props.onPatientClicked}
            onPageChange={page => this.handlePageChange(page)}
            onRowClicked={this.props.onPatientClicked}
            onRowsPerPageChange={limit => this.handleLimitChange(limit)}
            onSelectedChanged={selected => this.setState({ selected })}
            page={state.page}
            paginationMode="server"
            row_height={33}
            rowCount={state.total}
            rows={state.patients}
            rowsPerPage={state.limit}
            rowsPerPageOptions={rowsPerPageBig}
            style_body_cell={{
              color: 'white',
              fontSize: '14px',
              borderBottom: '1px solid #333',
            }}
            style_header={{
              backgroundColor: '#1a2439',
              color: 'white',
              fontFamily: 'Arial, sans-serif',
              fontSize: '15px',
              borderBottom: '1px solid #333',
              header_active: {
                '&.Mui-active': {
                  color: 'gray',
                },
              },
            }}
            style_pagination={{
              '&.MuiTablePagination-root': { color: '#b4bfc5;' },
              '.MuiTablePagination-selectLabel': {
                color: 'white',
                paddingTop: 1,
              },
              '.MuiTablePagination-displayedRows': {
                color: 'white',
                paddingTop: 1,
              },
            }}
            style_rows={{
              backgroundColor: '#1a2439',
              '&.Mui-selected': {
                backgroundColor: '#354f82',
              },
              '&.MuiTableRow-hover:hover': {
                backgroundColor: '#2a3854', // Change to your desired hover color
              },
            }}
            style_table={{
              backgroundColor: 'transparent',
              minHeight: 200,
              maxHeight: 300,
            }}
          />
        </div>
      </div>
    );
  }
}

LeftSectionPatients.propTypes = {
  classes: PropTypes.object,
};

export default LeftSectionPatients;
