import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Button, Grid, ListItemAvatar, ListItemButton, ListItemText, Paper, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ProgressUndefined from './ProgressUndefined';
// import { chart } from './utils';
import {
  GetData,
  GetNameFromHeader,
  GetResponseError,
  GetThumbnailFile,
  IsInvalid,
  ServerErrorsString,
} from 'helpers';
import { MutationRunDockerTempAccess } from 'graphql/Dockers';

import { keyframes } from '@emotion/react';
import {
  DownloadItemsAtKey,
  GenerateDownload,
  MutationDownloadFinished,
} from 'graphql/Download';
// import { GridChartItem } from './components';

class SwissICHResults extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // option_left: {
      //   ...chart,
      //   series: [0],
      //   labels: ['Presence'],
      // },
      // option_center: {
      //   ...chart,
      //   series: [0],
      //   labels: ['Origins'],
      // },
      // option_right: {
      //   ...chart,
      //   series: [0],
      //   labels: ['Risk'],
      //   pathRadius: '10', // P
      //   grid: {
      //     padding: {
      //       top: -10,
      //       left: -40,
      //       right: -40,
      //       bottom: -30,
      //     },
      //   },
      // },
      running: false,
      finished: false,
      diagnosis: '',
      SwissICH: null,
    };
    const { holder } = props;
    if (holder) {
      holder.reset = () => {
        console.log('run - reset');
        // const { option_left, option_center, option_right } = this.state;
        this.setState({
          running: false,
          finished: false,
          // option_left: { ...option_left, series: [0] },
          // option_center: { ...option_center, series: [0] },
          // option_right: { ...option_right, series: [0] },
          diagnosis: '',
        });
      };
      holder.startRun = () => this.handleRun();
      holder.setStateResults = state => this.setState(state);
    }
  }
  componentDidUpdate() {
    const { disabled_all } = this.props;
    const { diagnosis } = this.state;
    if (disabled_all && diagnosis !== '' && this.props.holder.reset) {
      this.props.holder.reset();
    }
  }
  generateZip = () => {
    const { SwissICH } = this.state;
    const { idFolder, file_objects, series } = SwissICH;
    console.log({ idFolder, file_objects, series });

    if (IsInvalid(idFolder)) return;
    const inputs = [{ idFolder }];
    this.props.handleDownloadTriggered(true);
    (async () => {
      let download_key = null;
      try {
        const res = await GenerateDownload(inputs);
        const data = GetData(res);
        const {
          downloadGenerate: { ok, key, errors: server_error },
        } = data;
        if (ok) {
          download_key = key;
        } else if (server_error) {
          this.setState({
            downloading: false,
            error: ServerErrorsString(server_error),
          });
          this.props.handleDownloadTriggered(false);
          return;
        }
      } catch (error) {
        this.setState({
          downloading: false,
          error: ServerErrorsString(error),
        });
        this.props.handleDownloadTriggered(false);
        return;
      }
      DownloadItemsAtKey(download_key)
        .then(async response => {
          const name = GetNameFromHeader(response);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', name);
          link.style.display = 'none';
          link.target = '_blank';
          link.hidden = true;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.setState({ downloading: false });
          await MutationDownloadFinished(download_key);
          this.props.handleDownloadTriggered(false);
        })
        .catch(async error => {
          error = await GetResponseError(error);
          console.log({ error });
          this.setState({ downloading: false });
          this.props.handleDownloadTriggered(false);
        });
    })();
  };

  handleRun = () => {
    const {
      access,
      folder_content,
      // threshold,
      // isNifti,
      // isCropped,
    } = this.props;
    const { running } = this.state;
    if (running) return;
    console.log('SwissICHResults - handleRun', {
      access,
      folder_content,
      // threshold,
      // isNifti,
      // isCropped,
    });
    this.setState({ running: true });
    if (this.props.holder.setTotal) {
      this.props.holder.setTotal(1);
    }
    const { idFolderContent } = folder_content;
    const inputs = {
      idUserTemp: access.id,
      idDockerImage: access.idDocker,
      idFolderContent,
      setting: '',
      // JSON.stringify({
      //   threshold: threshold / 100,
      //   isNifti,
      //   isCropped,
      // }),
    };
    (async () => {
      MutationRunDockerTempAccess(inputs)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, results } = data.runDockerTempAccess;
          if (ok) {
            console.log({ results });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ running: false });
          this.props.setState({ running: false, active: 0 });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleStart = () => {
    // const { option_left, option_center, option_right } = this.state;
    this.setState(
      {
        running: false,
        finished: false,
        // option_left: { ...option_left, series: [0] },
        // option_center: { ...option_center, series: [0] },
        // option_right: { ...option_right, series: [0] },
        diagnosis: '',
      },
      () => {
        this.props.handleStart();
      }
    );
  };
  render() {
    const { diagnosis, finished, running, SwissICH } = this.state;
    const { /*threshold,*/ full_run, active, disabled_all, files } = this.props;
    // const divider = {
    //   margin: '0px',
    //   flexShrink: '0',
    //   borderWidth: '0px thin 0px 0px',
    //   borderColor: 'rgba(145 158 171 / 0.5)',
    //   height: 'auto',
    //   alignSelf: 'stretch',
    //   borderStyle: 'dashed',
    // };
    const { file_objects /*, series*/ } = SwissICH;
    let ComponentProgress = null;
    if (running) {
      ComponentProgress = <ProgressUndefined />;
    }
    const blinkAnimation = keyframes`
      0% { opacity: 1; }
      50% { opacity: 0.2; }
      100% { opacity: 1; }
    `;
    return (
      <Paper
        disabled={active || full_run}
        sx={{
          backgroundImage: 'none',
          overflow: 'hidden',
          position: 'relative',
          borderRadius: '16px',
          width: '100%',
          border: `${active ? '2px' : '1px'} solid ${
            active ? 'green' : full_run ? '#f5f5f5' : ''
          }`,
        }}>
        <Grid
          container
          direction="row"
          item
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 1,
          }}
          xs={12}>
          <Grid item xs={2}>
            <Button
              disabled={
                disabled_all ||
                running ||
                // !threshold ||
                full_run ||
                !files.length
              }
              onClick={this.handleStart}
              startIcon={<PlayArrowIcon />}
              sx={{
                textTransform: 'none',
                backgroundColor: '#43a047',
                '&:hover': {
                  backgroundColor: '#66bb6a', // Hover background color
                },
              }}
              variant="contained">
              Run
            </Button>
          </Grid>
          <Grid
            container
            item
            sx={{ justifyContent: 'flex-end', alignItems: 'center' }}
            xs={2}>
            <Button
              disabled={running || !finished || full_run}
              endIcon={<CloudDownloadIcon />}
              onClick={this.generateZip()}
              // onClick={this.generateCSV}
              sx={{
                textTransform: 'none',
                backgroundColor: '#039be5',
                '&:hover': {
                  backgroundColor: '#29b6f6', // Hover background color
                },
              }}
              variant="contained">
              Download as zip
            </Button>
          </Grid>
        </Grid>
        {ComponentProgress}
        <Grid
          container
          direction="row"
          item
          sx={{
            pt: 0,
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
          xs={12}>
          
          {file_objects.map(item => (
            <ListItemButton
              key={`item-${item.idFileObject}`}>
              <ListItemAvatar>
                <Avatar src={GetThumbnailFile(item.original_name)} variant="rounded" />
              </ListItemAvatar>
              <ListItemText primary={item.original_name} />
            </ListItemButton>
          ))}
        </Grid>
        <Grid container p={2}>
          <Grid
            container
            direction="row"
            item
            sx={{
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}>
            <Grid item sx={{ width: 30 }}>
              <CheckIcon sx={{ color: 'green' }} />
            </Grid>
            <Grid item sx={{ width: 90, color: 'green', fontWeight: 600 }}>
              Diagnosis:
            </Grid>
            <Grid item xs>
              {running ? (
                <Typography
                  sx={{
                    color: '#fb8c00',
                    fontWeight: 600,
                    textAlign: 'center',
                    animation: `${blinkAnimation} 3s linear infinite`,
                  }}>
                  Please wait 1 to 2 minutes...
                  <br />
                  Processing time may vary depending on the size of your image
                  <br />
                  (up to 12 minutes if a GPU is not available)
                </Typography>
              ) : diagnosis !== '' ? (
                <Typography sx={{ color: 'blue', fontWeight: 600 }}>
                  {diagnosis}
                </Typography>
              ) : (
                <Typography sx={{ color: 'red' }}>
                  Please run the SwissICH algorithm to get the diagnosis of your
                  input data
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

SwissICHResults.propTypes = {
  classes: PropTypes.object,
};

export default SwissICHResults;
