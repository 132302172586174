import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';

const QUERY_ALL_COUNTRIES = gql`
  query($country: String, $page: Int, $limit: Int) {
    allCountries(country: $country, page: $page, limit: $limit) {
      ok
      errors {
        path
        message
      }
      countries {
        id
        country
        cities {
          id
          city
        }
      }
      total
    }
  }
`;
export const QueryCountries = (country, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_ALL_COUNTRIES),
    variables: {
      country,
      page,
      limit,
    },
  });
};
