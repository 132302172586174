import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, Tooltip, Zoom } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { green } from '@mui/material/colors';
import Badge from '@mui/material/Badge';
const StyledBadge = styled(Badge)(({ theme, ...props }) => ({
  '& .MuiBadge-badge': {
    right: props.right ? props.right : 0,
    top: props.top ? props.top : 17,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: props.batch_color ? props.batch_color : '#d50000',
  },
}));

const useStyles = () => ({
  fabProgress: {
    color: green[800],
    position: 'absolute',
    top: 10,
    left: 8,
    zIndex: 1,
  },
});

class ButtonGroupLoading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      classes,
      loading,
      icon,
      disabled,
      color,
      badge,
      color_batch,
    } = this.props;
    let { batch_style, icon_style, total } = this.props;
    if (loading) {
      total = 0;
      batch_style = {};
      icon_style = {};
    }
    if (!icon) {
      icon = (
        <SaveIcon style={{ color: disabled ? 'gray' : color, ...icon_style }} />
      );
    }
    let icon_component = null;
    if (badge) {
      icon_component = (
        <Tooltip
          placement={this.props.placement}
          title={this.props.tooltip}
          TransitionComponent={Zoom}>
          <StyledBadge
            badgeContent={total}
            color={color_batch}
            {...batch_style}>
            {icon}
          </StyledBadge>
        </Tooltip>
      );
    } else {
      icon_component = (
        <Tooltip
          placement={this.props.placement}
          title={this.props.tooltip}
          TransitionComponent={Zoom}>
          {icon}
        </Tooltip>
      );
    }
    return (
      <Button
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        style={{
          margin: 0,
          padding: 0,
          paddingRight: badge && total ? 10 : 0,
        }}>
        {icon_component}
        {loading && (
          <CircularProgress className={classes.fabProgress} size={27} />
        )}
      </Button>
    );
  }
}

ButtonGroupLoading.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
};
ButtonGroupLoading.defaultProps = {
  disabled: false,
  loading: false,
  icon: null,
  onClick: () => '',
  color: 'blue',
  total: 0,
  badge: false,
  color_batch: 'secondary',
  batch_style: {},
  icon_style: {},
  placement: undefined,
};
export default withStyles(ButtonGroupLoading, useStyles);
