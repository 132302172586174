import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
import {
  series_base,
  series_full,
  series_full_no_thumb,
} from 'graphql/Series/utils_series_query';

const QUERY_STUDIES_AND_SERIES = gql`
  query($StudyQuery: StudyQuery) {
    Studies(StudyQuery: $StudyQuery) {
      ok
      errors {
        message
      }
      total
      studies {
        idStudy
        StudyDescription
        StudyInstanceUID
        Patient {
          FamilyName
          GivenName
          MiddleName

          PatientID
          PatientBirthDate
        }
        Series {
          ${series_base}
          Thumbnail
          
          ImageSize {
            Rows
            Columns
            Size
          }
          sequence {
            idSequence
            name
          }
          ImageResolution {
            PixelSpacingX
            PixelSpacingY
            SpacingBetweenSlices
            SliceThickness
            Spacing
          }
        }
      }
    }
  }
`;

export const StudySeriesQuery = (idStudy, settings) => {
  console.log('StudySeriesQuery');
  const StudyQuery = {
    idStudy: idStudy,
  };
  if (settings) {
    if (settings.modalities) StudyQuery.ModalitiesInStudy = settings.modalities;
    if (settings.SeriesDescription)
      StudyQuery.SeriesDescription = settings.SeriesDescription;
    if (settings.SeriesDate) StudyQuery.SeriesDate = settings.SeriesDate;
  }

  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_STUDIES_AND_SERIES),
    variables: {
      StudyQuery,
    },
  });
};

const QUERY_STUDIES = gql`
  query Studies($StudyQuery: StudyQuery, $setting: StudyQuerySetting) {
    Studies(StudyQuery: $StudyQuery, setting: $setting) {
      ok
      errors {
        path
        message
      }
      total
      studies {
        idStudy
        Patient {
          FamilyName
          GivenName
          MiddleName

          PatientID
          PatientBirthDate
        }
        StudyDescription
        ModalitiesInStudy
        StudyDate
        StudyTime
        AccessionNumber
        StudyInstanceUID
        NumberOfStudyRelatedInstances
        NumberOfStudyRelatedSeries
      }
    }
  }
`;

export const StudyQueryRetrieve = (StudyQuery, setting) => {
  console.log('StudyQueryRetrieve', {
    page: setting.page,
    limit: setting.limit,
  });
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_STUDIES),
    variables: {
      StudyQuery,
      setting,
    },
  });
};

const GET_STUDIES_THUMBNAILS = gql`
  query(
    $inputs: StudyQuerySmall!
    $level: Int
    $page: Int
    $limit: Int
    $reload: Boolean
  ) {
    StudiesDatabase(
      inputs: $inputs
      level: $level
      page: $page
      limit: $limit
      reload: $reload
    ) {
      ok
      errors {
        message
      }
      total
      studies {
        idStudy
        Patient {
          FamilyName
          GivenName
          PatientID
          PatientBirthDate
        }
        StudyDescription
        StudyDate
        StudyTime
        StudyInstanceUID
        AccessionNumber
        NumberOfStudyRelatedInstances
        NumberOfStudyRelatedSeries
      }
    }
  }
`;

export const StudiesDatabase = (
  inputs,
  page,
  limit,
  level,
  reload = undefined
) => {
  console.log('StudiesDatabase', { page, limit });
  if (inputs && inputs.idProject) {
    level = 1;
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(GET_STUDIES_THUMBNAILS),
    variables: {
      inputs,
      page,
      limit,
      level,
      reload,
    },
  });
};

const QUERY_SERIES_AT_STUDIES = gql`
  query(
    $ids: [LongLong!]!
    $page: Int
    $limit: Int
    $SeriesQuery: SeriesQuery
    $setting: SettingQuerySeries
  ) {
    SeriesAtStudies(
      ids: $ids
      page: $page
      limit: $limit
      SeriesQuery: $SeriesQuery
      setting: $setting
    ) {
      ok
      ListSeries {
        ${series_full_no_thumb}
      }
      errors {
        path
        message
      }
    }
  }
`;

export const SeriesAtStudies = (ids, page, limit, query, setting) => {
  console.log('SeriesAtStudies');
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_SERIES_AT_STUDIES),
    variables: {
      ids,
      page,
      limit,
      SeriesQuery: query,
      setting,
    },
  });
};
const QUERY_STUDIES_SERIES = gql`
  query($id_studies: [LongLong!]!) {
    StudiesSeries(id_studies: $id_studies) {
      ok
      studies_series {
        idStudy
        series {
          ${series_full}
        }
      }
      total
      errors {
        path
        message
      }
    }
  }
`;

export const QueryStudiesSeries = id_studies => {
  console.log('QueryStudiesSeries');
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_STUDIES_SERIES),
    variables: {
      id_studies,
    },
  });
};
