import React from 'react';
import PropTypes from 'prop-types';
import { v1 as uuid } from 'uuid';
import { bern_location, loadGoogleScript } from 'common';
import { IsInvalid } from 'helpers';

class TabSessionsMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      map: null,
      id: uuid(),
      markers: [],
      geocoder: null,
    };
    const { holder } = props;
    if (holder) {
      holder.removeMarker = ids => {
        console.log({ ids });
        const { markers } = this.state;
        for (let i = 0; i < ids.length; i++) {
          const index = markers.map(x => x.id).indexOf(ids[i]);
          console.log({ index });
          if (index !== -1) {
            markers[index].marker.setMap(null);
            markers.splice(index, 1);
          } else {
            console.log('Marker does not exist at index ' + index);
          }
        }
        this.setState({ markers });
      };
    }
  }
  handleScriptLoad = () => {
    const geocoder = new window.google.maps.Geocoder();
    const map = new window.google.maps.Map(
      document.getElementById(this.state.id),
      bern_location
    );
    this.setState({ geocoder, map }, () => {
      this.loadMaps();
    });
  };
  loadMaps = () => {
    let { geocoder, map } = this.state;
    if (IsInvalid(window.google) || IsInvalid(geocoder)) {
      if (IsInvalid(geocoder)) {
        geocoder = new window.google.maps.Geocoder();
        map = new window.google.maps.Map(
          document.getElementById(this.state.id),
          bern_location
        );
      }
      if (IsInvalid(geocoder)) {
        console.error('Google Maps is not available');
        return;
      }
    }
    const { sessions } = this.props;
    console.log('loadMaps', { sessions });
    const { markers } = this.state;
    if (IsInvalid(map)) return;
    let total = markers.length;
    const list_country = [];
    sessions.forEach(session => {
      if (IsInvalid(session)) return;
      const { id, city, country } = session;
      const index = markers.map(x => x.id).indexOf(id);
      if (index !== -1) {
        console.log({ session, index });
        return;
      }
      if (IsInvalid(city, true) || IsInvalid(country, true)) {
        console.log({
          session,
        });
        return;
      }
      if (city === 'Unknown' || country === 'Unknown') {
        console.log({
          session,
        });
        return;
      }
      // console.log({ address: `${city}, ${country}` });
      if (list_country.indexOf(country) === -1) list_country.push(country);
      geocoder.geocode(
        { address: `${city}, ${country}` },
        (results, status) => {
          if (status === 'OK') {
            const location = results[0].geometry.location;
            // const { lat, lng } = location;
            // console.log({ city, lat: lat(), lng: lng() });
            let marker = null;
            // Check if AdvancedMarkerElement is available
            if (
              window.google.maps.marker &&
              window.google.maps.marker.AdvancedMarkerElement
            ) {
              marker = new window.google.maps.marker.AdvancedMarkerElement({
                map: map,
                position: location,
                title: `${city}, ${country}`,
              });
            } else {
              // Fallback to regular Marker if AdvancedMarkerElement is not available
              marker = new window.google.maps.Marker({
                map: map,
                position: location,
                title: `${city}, ${country}`,
              });
            }
            // console.log({ id, marker });
            markers.push({ id, marker });
            map.setCenter(bern_location.center);
          } else {
            console.error(
              'Geocode was not successful for the following reason:',
              status
            );
          }
          console.log('Geocode', {
            total: list_country.length,
            total_markers: markers.length,
          });
        }
      );
    });
    console.log({ total: list_country.length, total_markers: markers.length });
    if (total !== markers.length) this.setState({ markers, geocoder, map });
    else this.setState({ geocoder, map });
  };
  componentDidMount() {
    console.log('componentDidMount');
    const { geocoder } = this.state;
    if (IsInvalid(window.google) || IsInvalid(geocoder)) {
      console.error('Loading Google Maps');
      loadGoogleScript(this);
    } else {
      this.loadMaps();
    }
  }
  render() {
    let { height } = this.props;
    if (IsInvalid(height)) height = '500px';
    return <div id={this.state.id} style={{ height }} />;
  }
}

TabSessionsMap.propTypes = {
  classes: PropTypes.object,
};

export default TabSessionsMap;
