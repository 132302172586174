export const GetOrientationFromViewport = (viewport) => {
  // Get the current camera
  const camera = viewport.getCamera();
  return GetOrientationFromCamera(camera);
};
export const GetOrientationFromCamera = (camera) => {
  // Get the current camera
  const { viewPlaneNormal } = camera;
  return GetOrientationFromPlaneNormal(viewPlaneNormal);
};
export const GetOrientationFromPlaneNormal = (viewPlaneNormal) => {
  // Determine orientation based on the view plane normal
  let orientation;

  // Get the absolute values to handle negative directions
  const absX = Math.abs(viewPlaneNormal[0]);
  const absY = Math.abs(viewPlaneNormal[1]);
  const absZ = Math.abs(viewPlaneNormal[2]);

  // Find the dominant axis of the normal vector
  if (absZ > absX && absZ > absY) {
    // If Z-axis is dominant, we're viewing an axial slice
    orientation = 'axial';
    // 2D pixel coordinates would be [x, y] or [index[0], index[1]]
  } else if (absX > absY && absX > absZ) {
    // If X-axis is dominant, we're viewing a sagittal slice
    orientation = 'sagittal';
    // 2D pixel coordinates would be [y, z] or [index[1], index[2]]
  } else {
    // If Y-axis is dominant, we're viewing a coronal slice
    orientation = 'coronal';
    // 2D pixel coordinates would be [x, z] or [index[0], index[2]]
  }

  return orientation;
};

export const getMousePosition = (
  index,
  desiredOrientation,
  originalOrientation
) => {
  const i = Math.floor(index[0]);
  const j = Math.floor(index[1]);
  const k = Math.floor(index[2]);

  // If original and desired orientations match, use index[0] and index[1] directly
  if (originalOrientation.toLowerCase() === desiredOrientation.toLowerCase()) {
    return [i, j];
  }

  // Axis mappings based on original orientation
  const volumeAxisMap = {
    axial: { i: 'x', j: 'y', k: 'z' }, // i,j = X,Y (rows, columns), k = Z (slices)
    sagittal: { i: 'y', j: 'z', k: 'x' }, // i,j = Y,Z (columns, slices), k = X (rows)
    coronal: { i: 'x', j: 'z', k: 'y' }, // i,j = X,Z (rows, slices), k = Y (columns)
  };

  // Desired plane axis requirements
  const planeAxisMap = {
    axial: ['x', 'y'], // i,j = X,Y
    sagittal: ['y', 'z'], // i,j = Y,Z
    coronal: ['x', 'z'], // i,j = X,Z
  };

  const originalAxes = volumeAxisMap[originalOrientation.toLowerCase()];
  const desiredAxes = planeAxisMap[desiredOrientation.toLowerCase()];

  // Map volume indices to axes
  const indexMap = {
    x: originalAxes.i === 'x' ? i : originalAxes.j === 'x' ? j : k,
    y: originalAxes.i === 'y' ? i : originalAxes.j === 'y' ? j : k,
    z: originalAxes.i === 'z' ? i : originalAxes.j === 'z' ? j : k,
  };

  // Extract the two indices for the desired plane
  const pixelPos = [
    indexMap[desiredAxes[0]], // First axis of desired plane
    indexMap[desiredAxes[1]], // Second axis of desired plane
  ];

  return pixelPos;
};
export const validOrientations = ['axial', 'sagittal', 'coronal'];
export const get2DImageDimensions = (
  dimensions,
  desiredOrientation,
  originalOrientation
) => {
  // Validate inputs
  if (!Array.isArray(dimensions) || dimensions.length !== 3) {
    console.error('Dimensions must be an array of 3 numbers', { dimensions });
    return;
  }
  if (
    !validOrientations.includes(originalOrientation.toLowerCase()) ||
    !validOrientations.includes(desiredOrientation.toLowerCase())
  ) {
    console.error('Orientations must be "axial", "sagittal", or "coronal"', {
      originalOrientation,
      desiredOrientation,
    });
    return;
  }

  const iDim = dimensions[0]; // e.g., 160
  const jDim = dimensions[1]; // e.g., 160
  const kDim = dimensions[2]; // e.g., 128

  // If original and desired orientations match, return i,j dimensions directly
  if (originalOrientation.toLowerCase() === desiredOrientation.toLowerCase()) {
    return {
      width: iDim,
      height: jDim,
      depth: kDim,
      middle: parseInt(kDim / 2.0),
    };
  }

  // Axis mappings based on original orientation
  const volumeAxisMap = {
    axial: { i: 'x', j: 'y', k: 'z' }, // i=X, j=Y, k=Z
    sagittal: { i: 'y', j: 'z', k: 'x' }, // i=Y, j=Z, k=X
    coronal: { i: 'x', j: 'z', k: 'y' }, // i=X, j=Z, k=Y
  };

  // Desired plane axis requirements
  const planeAxisMap = {
    axial: ['x', 'y'], // width=X, height=Y
    sagittal: ['y', 'z'], // width=Y, height=Z
    coronal: ['x', 'z'], // width=X, height=Z
  };

  const originalAxes = volumeAxisMap[originalOrientation.toLowerCase()];
  const desiredAxes = planeAxisMap[desiredOrientation.toLowerCase()];

  // Map volume dimensions to anatomical axes
  const dimMap = {
    x: originalAxes.i === 'x' ? iDim : originalAxes.j === 'x' ? jDim : kDim,
    y: originalAxes.i === 'y' ? iDim : originalAxes.j === 'y' ? jDim : kDim,
    z: originalAxes.i === 'z' ? iDim : originalAxes.j === 'z' ? jDim : kDim,
  };
  // Determine the depth axis (the one not used in width or height)
  const allAxes = ['x', 'y', 'z'];
  const depthAxis = allAxes.find((axis) => !desiredAxes.includes(axis));

  // Extract the two dimensions for the desired plane
  const imageDims = {
    width: dimMap[desiredAxes[0]], // First axis of desired plane
    height: dimMap[desiredAxes[1]], // Second axis of desired plane
    depth: dimMap[depthAxis], // Remaining axis as depth
    middle: parseInt(dimMap[depthAxis] / 2.0),
  };

  return imageDims;
};
