import React from 'react';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { getInitials, GetThumbnailFile } from 'helpers';

import { Drawer, DrawerHeader, useStyles } from './useStyles';
import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Grid,
  useTheme,
} from '@mui/material';
// import PageviewIcon from '@mui/icons-material/Pageview';
// import FindInPageIcon from '@mui/icons-material/FindInPage';
// import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import SearchIcon from './SearchIcon';
import { colorBorderDicomViewer, colorDicomViewer } from 'theme';
class SideStudySeriesBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      add_text: false,
    };
  }
  handleDrawerOpenClose = () => {
    const { open } = this.props;
    const new_open = !open;
    console.log('Drawer is fully open!');
    this.props.handleDrawerClose(new_open);
    // Add your logic here (e.g., this.setState, API calls, etc.)
    if (new_open) {
      setTimeout(() => {
        // Check if an annotation was selected after the event
        this.setState({ add_text: true });
      }, 200);
    } else {
      this.setState({ add_text: false });
    }
  };
  handleOpenSearch = () => {
    console.log('Open Search');
    this.props.setState({ open_search: true });
  };
  render() {
    const { add_text } = this.state;
    const {
      classes,
      open,
      height,
      state: { loading_series },
    } = this.props;
    let { series, study } = this.props;
    let currentID = 0;
    let text = '';
    if (study && open) {
      text = study.StudyDescription;
      if (text === '' || !text) text = 'Series List';
    }
    if (study && study.Series && study.Series.length) {
      if (series) {
        currentID = series.idSeries;
      }
    } else if (series) {
      study = { Series: [series] };
      if (series) {
        currentID = series.idSeries;
      }
    } else {
      study = { Series: [] };
    }
    let style = null;
    if (height) {
      style = {
        height,
        maxHeight: height,
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: colorDicomViewer,
      };
      if (!open) {
        style.minWidth = 50;
      }
    }
    let ComponentText = null;
    if (text && add_text) {
      ComponentText = (
        <Grid item>
          <Typography
            noWrap
            sx={{ textTransform: 'none', maxWidth: 170 }}
            variant="h6">
            {text}
          </Typography>
        </Grid>
      );
    }
    return (
      <Drawer
        classes={{
          paper: clsx(classes.drawer_paper, {}),
        }}
        open={open}
        sx={{ borderColor: colorBorderDicomViewer }}
        variant="permanent" //persistent,permanent
      >
        <DrawerHeader>
          <Grid
            container
            direction="row"
            sx={{
              padding: 0,
              margin: 0,
              justifyContent: open ? 'space-between' : 'flex-start',
              alignItems: 'center',
              backgroundColor: colorDicomViewer,
              border: `1px solid ${colorBorderDicomViewer}`,
            }}>
            <Grid item sx={{ maxWidth: 30 }}>
              <SearchIcon onClick={this.handleOpenSearch} />
            </Grid>
            {ComponentText}
            <Grid item sx={{ maxWidth: 30 }}>
              <IconButton onClick={this.handleDrawerOpenClose} size="small">
                {!open ? (
                  <ChevronRightIcon sx={{ color: 'white' }} />
                ) : (
                  <ChevronLeftIcon sx={{ color: 'white' }} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </DrawerHeader>
        <Divider sx={{ borderColor: colorBorderDicomViewer }} />
        <div style={style}>
          <List>
            {study.Series.map((Series, index) => {
              let {
                idSeries,
                SeriesDescription,
                Thumbnail,
                NumberOfFrames,
                NumberOfSeriesRelatedInstances,
              } = Series;
              if (SeriesDescription === '' || !SeriesDescription)
                SeriesDescription = 'Empty';
              let thumbnail = GetThumbnailFile('file.dcm');
              if (Thumbnail && Thumbnail !== '') {
                thumbnail = `data:image/jpeg;base64,${Thumbnail}`;
              } else if (Series && Series.Modality === 'SR') {
                thumbnail = '/images/icons/icon_sr.png';
              } else if (Series && Series.Modality === 'PR') {
                thumbnail = '/images/icons/icon_pr.png';
              }
              let selected = currentID === idSeries;
              let isLoading = false;
              if (loading_series && selected) {
                isLoading = true;
              }
              // if (index === 3) isLoading = true;
              let total_dicom_files = 0;
              let multiframe = '';
              if (NumberOfFrames && NumberOfFrames > 1) {
                total_dicom_files = NumberOfFrames;
                multiframe = '(MF)';
              } else if (NumberOfSeriesRelatedInstances) {
                total_dicom_files = NumberOfSeriesRelatedInstances;
              }
              return (
                <ListItem
                  className={clsx({
                    [classes.item]: true,
                    [classes.selected]: selected,
                  })}
                  disablePadding
                  key={`thumbnail-series-${idSeries}-${index}`}
                  onClick={() => this.props.onSeriesClick(Series)}
                  selected={selected}
                  sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      paddingLeft: open ? 1.5 : undefined,
                    }}>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 1.5 : 'auto',
                        justifyContent: 'center',
                      }}>
                      <img
                        alt={getInitials(SeriesDescription)}
                        src={thumbnail}
                        style={{ height: 50, margin: 0, pading: 0 }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography
                          className={classes.text_center}
                          component="div"
                          gutterBottom
                          sx={{ color: '#fafafa' }}
                          variant="h6">
                          {SeriesDescription}
                        </Typography>
                      }
                      secondary={`Files: ${total_dicom_files} ${multiframe}`}
                      sx={{
                        opacity: open ? 1 : 0,
                        '.MuiListItemText-secondary': {
                          color: '#eeeeee',
                        },
                      }}
                    />
                  </ListItemButton>
                  {isLoading ? (
                    <Box
                      sx={{
                        position: 'absolute',
                        zIndex: 500,
                        color: 'white',
                        top: 'calc(50% - 20px)' /* Half of height */,
                        left: 'calc(50% - 20px)' /* Half of width */,
                      }}>
                      <CircularProgress
                        size={40}
                        sx={theme => ({
                          filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))', // Adds shadow
                          color: theme.palette.grey[500],
                          ...theme.applyStyles('dark', {
                            color: theme.palette.grey[800],
                          }),
                        })}
                        thickness={4}
                        value={100}
                        variant="determinate"
                      />
                      <CircularProgress
                        disableShrink
                        size={40}
                        sx={theme => ({
                          color: 'rgba(40, 83, 112,1)',
                          animationDuration: '850ms',
                          position: 'absolute',
                          left: 0,
                          filter: 'drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))', // Adds shadow
                          [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                          },
                          ...theme.applyStyles('dark', {
                            color: '#308fe8',
                          }),
                        })}
                        thickness={4}
                        variant="indeterminate"
                      />
                    </Box>
                  ) : null}
                </ListItem>
              );
            })}
          </List>
        </div>
      </Drawer>
    );
  }
}

SideStudySeriesBar.propTypes = {
  classes: PropTypes.object,
};

SideStudySeriesBar.defaultProps = {
  open: false,
  handleDrawerClose: () => '',
  isMobileSmall: false,
  study: [],
  series: null,
  onSeriesClick: () => '',
};
const ContainerSideStudySeriesBar = props => {
  const theme = useTheme();
  return <SideStudySeriesBar theme={theme} {...props} />;
};
export default withStyles(ContainerSideStudySeriesBar, useStyles);
