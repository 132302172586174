import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableCell, TableSortLabel } from '@mui/material';
import { CellSortMenu } from './components';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
const useStyles = () => ({
  root_left: {
    // position: 'absolute',
    // 'border-left-style': 'solid',
    borderLeft: '3px outset #cecccc',
    // 'border-left': '2px outset #0004ff',
    paddingLeft: 5,
    width: '100%',
    // top: '5%',
    // bottom: 0,
    // 'left': '5px',
    // '&::after': {
    //   position: 'absolute',
    //   top: 0, left: 0, bottom: '-100px',
    //   width: '2px',
    //   background: 'rgb(254, 91, 31)',
    //   content: ''
    // }
  },
  root_right: {
    // 'border-right-style': 'solid',
    borderRight: '3px outset #e0e0e029',
    paddingRight: 5,
    // 'line-height': '12px',
  },
});
class TableGridHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMouseOver: false,
    };
  }
  getObject = () => {
    const { header } = this.props;
    if (header === undefined) return '';
    const { headerName } = header;
    return headerName;
  };
  handleMouseHover = () => {
    this.setState(prev_state => ({ isMouseOver: !prev_state.isMouseOver }));
  };
  render() {
    const {
      classes,
      header,
      order,
      orderBy,
      onSortHeaderClicked,
      last,
      style_header_active,
    } = this.props;
    let align = 'left';
    if (header.numeric) align = 'right';
    if (header.align) align = header.align;
    let style = {
      paddingRight: 3,
      paddingLeft: 2,
    };
    if (header.width) {
      let width = header.width;
      if (
        (header.type === 'date' || header.type === 'datetime') &&
        !header.edit
      ) {
        width -= 80;
      }
      style = {
        minWidth: width,
        backgroundColor: 'transparent',
        paddingLeft: 2,
        paddingRight: last ? 5 : 0,
      };
    }
    if (header.style) {
      style = {
        ...style,
        ...header.style,
      };
    }
    if (this.props.style) {
      style = {
        ...style,
        ...this.props.style,
      };
    }
    let RightElement = <div />;
    if (align === 'left') {
      RightElement = null;
    }
    // console.log({ style });
    let text_cell = this.getObject();
    let minWidth = 50;
    if (text_cell && text_cell.length) {
      minWidth = text_cell.length * 7.5;
      if (header.style && style.minWidth < minWidth) {
        style = {
          ...style,
          minWidth,
        };
      }
    } else {
      return <TableCell align={align} style={style} />;
    }
    let style_active = {};
    if (style_header_active) {
      style_active = {
        ...style_header_active,
      };
      // {
      // example style active for TableSortLabel
      // '&.Mui-active': {
      //   color: 'red',
      // },
    }
    return (
      <TableCell align={align} style={style}>
        <div
          className={clsx({
            [classes.root_left]: true,
            [classes.root_right]: last,
          })}>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="space-between"
            onMouseEnter={() => this.setState({ isMouseOver: true })}
            onMouseLeave={() => this.setState({ isMouseOver: false })}>
            {RightElement}
            <TableSortLabel
              active={orderBy === header.field}
              direction={order}
              onClick={() => onSortHeaderClicked(header.field)}
              sx={{
                width: 'calc(100% - 20px)',
                justifyContent: 'space-between',
                ...style_active,
              }}>
              {text_cell}
            </TableSortLabel>
            <CellSortMenu
              disabled_filter={this.props.disabled_filter}
              filter={this.props.filter}
              header={header}
              headerName={header.headerName}
              isMouseOver={this.state.isMouseOver}
              onClickHideColumn={this.props.onClickHideColumn}
              onClickShowColumn={this.props.onClickShowColumn}
              onSearchFilterChanged={this.props.onSearchFilterChanged}
              onSortHeader={this.props.onSortHeader}
              order={order}
              orderBy={orderBy}
            />
          </Grid>
        </div>
      </TableCell>
    );
  }
}

TableGridHeaderCell.propTypes = {
  classes: PropTypes.object,
  onSortHeaderClicked: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string,
};
TableGridHeaderCell.defaultProps = {
  header: '',
  onSortHeaderClicked: () => '',
  onSortHeader: () => '',
  onClickHideColumn: () => '',
  onClickShowColumn: () => '',
  onSearchFilterChanged: () => '',
  order: '',
  orderBy: '',
  last: false,
  filter: null,
};

export default withStyles(TableGridHeaderCell, useStyles);
