import {
  RenderingEngine,
  Enums,
  eventTarget,
  // volumeLoader,
  // setVolumesForViewports,
  getRenderingEngine,
  // utilities as csUtils,
  // metaData,
  cache,
} from '@cornerstonejs/core';
import * as cornerstoneTools from '@cornerstonejs/tools';

const {
  ToolGroupManager,
  StackScrollTool,
  ZoomTool,
  PanTool,
  Enums: csToolsEnums,
  WindowLevelTool,
  LengthTool,
  AngleTool,
  CircleROITool,
  BidirectionalTool,
  // annotation: annotationManager,
} = cornerstoneTools;

import { GetConerDate, GetConerTime, orientationLetters } from './utils';
import { GetOrientationFromViewport } from './utils_viewport';
const { ViewportType } = Enums;
const { MouseBindings } = csToolsEnums;

export const viewer_black = true;
export const backgroundColorStack = [0.0, 0.4, 0.0];
export const backgroundColorVolume = [0.2, 0, 0.2];

export const InitAfterLoad = async (
  imageId,
  viewport,
  metaData,
  Enums,
  currentVolumeId
) => {
  let orientation_letters = '';
  let isLoading = false;
  let position = 0;
  let imSize = {
    width: 0,
    height: 0,
  };
  // Determine orientation based on the view plane normal
  const orientation = GetOrientationFromViewport(viewport);
  const GENERAL_SERIES = metaData.get(
    Enums.MetadataModules.GENERAL_SERIES,
    imageId
  );
  const IMAGE_PLANE = metaData.get(Enums.MetadataModules.IMAGE_PLANE, imageId);
  try {
    const viewport_info = viewport.getSliceViewInfo();
    position = viewport_info.sliceIndex + 1;
    imSize = {
      width: viewport_info.width,
      height: viewport_info.height,
    };
  } catch (error) {
    const { columns, rows } = IMAGE_PLANE;
    imSize = {
      width: columns,
      height: rows,
    };
    console.log({ error, imSize });
  }
  // Get the current camera
  const camera = viewport.getCamera();
  let zoom = 1;
  // Get zoom factor (if available from the camera)
  if (camera && camera.parallelScale) {
    const { viewUp, viewPlaneNormal } = camera;
    const zoomFactor = (1 / camera.parallelScale) * 100;
    zoom = zoomFactor.toFixed(2);
    orientation_letters = orientationLetters(viewPlaneNormal, viewUp);
  }

  // const IMAGE_PIXEL = metaData.get(Enums.MetadataModules.IMAGE_PIXEL, imageId);
  // const GENERAL_IMAGE = metaData.get(
  //   Enums.MetadataModules.GENERAL_IMAGE,
  //   imageId
  // );
  const PATIENT = metaData.get(Enums.MetadataModules.PATIENT, imageId);
  const GENERAL_STUDY = metaData.get(
    Enums.MetadataModules.GENERAL_STUDY,
    imageId
  );

  const { studyDescription } = GENERAL_STUDY;
  const { patientName, patientID } = PATIENT;
  const { sliceThickness, imageOrientationPatient, pixelSpacing } = IMAGE_PLANE;
  const imInformation = {
    StudyDate: GetConerDate(GENERAL_STUDY?.studyDate),
    StudyTime: GetConerTime(GENERAL_STUDY?.studyTime),
    StudyDescription: studyDescription,
    PatientName: patientName
      ? patientName.Alphabetic
        ? patientName.Alphabetic
        : patientName
      : '',
    Thickness: sliceThickness,
    ImageOrientationPatient: imageOrientationPatient,
    PixelSpacing: pixelSpacing,
    PatientID: patientID,
    Modality: GENERAL_SERIES?.modality || undefined,
    SeriesNumber: GENERAL_SERIES?.seriesNumber || undefined,
    SeriesDate: GetConerDate(GENERAL_SERIES?.seriesDate),
    SeriesTime: GetConerTime(GENERAL_SERIES?.seriesTime),
    ProtocolName: GENERAL_STUDY?.protocolName || undefined,
  };
  // console.log({
  //   PATIENT,
  //   GENERAL_STUDY,
  //   GENERAL_SERIES,
  //   GENERAL_IMAGE,
  //   IMAGE_PIXEL,
  //   IMAGE_PLANE,
  // });
  const volume = cache.getVolume(currentVolumeId);
  if (volume) {
    const scalarDataArray = volume.voxelManager.getCompleteScalarDataArray();
    // const getScalarData = volume.voxelManager.getScalarData();
    console.log('Volume is found in cache', {
      scalarDataArray: scalarDataArray ? scalarDataArray.length : 0,
      // getScalarData,
    });
    const { lower, upper } = computeOptimalRange(scalarDataArray);
    console.log({ lower, upper });
    viewport.setProperties({
      voiRange: { lower, upper },
    });
  }
  return {
    imSize,
    orientation_letters, // RAI
    orientation, // Sagittal, Axial, Coronal
    isLoading,
    position,
    imInformation,
    zoom,
  };
};
export const initializeViewer = async (
  viewportId,
  renderingEngineId,
  toolGroupId,
  leftClickTools,
  measureTools,
  THIS
) => {
  if (!THIS.elementRef.current) {
    throw new Error('Viewer element not found');
  }
  console.log('initializeViewer');
  THIS.elementRef.current.addEventListener(
    csToolsEnums.Events.KEY_DOWN,
    THIS.handleKeyPress
  );
  // console.log('B');
  THIS.elementRef.current.addEventListener('mousemove', THIS.handleMouseMove);
  THIS.elementRef.current.addEventListener('mousedown', THIS.handleMouseDown);
  eventTarget.addEventListener(
    'cornerstoneimageloadprogress',
    THIS.onImageProgress
  );
  // console.log('C');

  // Add tools to Cornerstone3D
  cornerstoneTools.addTool(WindowLevelTool);
  cornerstoneTools.addTool(PanTool);
  cornerstoneTools.addTool(ZoomTool);
  cornerstoneTools.addTool(StackScrollTool);
  cornerstoneTools.addTool(LengthTool);
  cornerstoneTools.addTool(AngleTool);
  cornerstoneTools.addTool(CircleROITool);
  cornerstoneTools.addTool(BidirectionalTool);

  // Get the rendering engine
  THIS.renderingEngine = getRenderingEngine(renderingEngineId);
  if (!THIS.renderingEngine) {
    // Initialize rendering engine
    THIS.renderingEngine = new RenderingEngine(renderingEngineId);
  }
  // console.log('E');

  // Create viewport
  const viewportInput = {
    viewportId,
    type: ViewportType.ORTHOGRAPHIC,
    element: THIS.elementRef.current,
    defaultOptions: {
      background: viewer_black ? [0.0, 0.0, 0.0] : backgroundColorVolume,
    },
  };

  // Define a tool group, which defines how mouse events map to tool commands for
  // Any viewport using the group
  let toolGroup = ToolGroupManager.getToolGroup(toolGroupId);
  if (!toolGroup) {
    toolGroup = ToolGroupManager.createToolGroup(toolGroupId);
    leftClickTools.forEach(toolName => {
      toolGroup.addTool(toolName);
    });
    measureTools.forEach(toolName => {
      toolGroup.addTool(toolName);
    });
    // As the Stack Scroll mouse wheel is a tool using the `mouseWheelCallback`
    // hook instead of mouse buttons, it does not need to assign any mouse button.
    toolGroup.setToolActive(StackScrollTool.toolName, {
      bindings: [{ mouseButton: MouseBindings.Wheel }],
    });
    toolGroup.setToolActive(StackScrollTool.toolName, {
      bindings: [{ mouseButton: MouseBindings.Primary }], // Middle Click
    });
    toolGroup.setToolActive(WindowLevelTool.toolName, {
      bindings: [{ mouseButton: MouseBindings.Secondary }], // Middle Click
    });
    toolGroup.addViewport(viewportId, renderingEngineId);
  }

  THIS.renderingEngine.enableElement(viewportInput);
  // console.log('F');
};
// Helper function to compute range from scalar data
export const computeOptimalRange = scalarData => {
  if (!scalarData || scalarData.length === 0) {
    // Default values for CT
    return { lower: 40, upper: 400 };
  }
  // Sample the data for performance
  const step = Math.max(1, Math.floor(scalarData.length / 10000));
  const samples = [];

  for (let i = 0; i < scalarData.length; i += step) {
    samples.push(scalarData[i]);
  }

  // Sort samples to find percentiles
  samples.sort((a, b) => a - b);

  // Check if all values are positive
  const minValue = samples[0];
  const hasNegativeValues = minValue < 0;

  // Use different strategies based on data characteristics
  let lower, upper;

  // Get 5th and 95th percentiles
  const lowerPercentile = samples[Math.floor(samples.length * 0.05)];
  const upperPercentile = samples[Math.floor(samples.length * 0.95)];

  if (hasNegativeValues) {
    // If we have negative values, use the minimum (or close to it) as the lower bound
    // You could use the actual minimum or a small percentile (1st or 2nd)
    lower = samples[Math.floor(samples.length * 0.01)]; // Use 1st percentile
  } else {
    // For all positive values, you might want the lower bound to be 0 or slightly above
    // depending on your imaging context
    if (lowerPercentile < 10) {
      // If values start very close to zero
      lower = 0; // Use exact zero
    } else {
      // Otherwise use the 5th percentile
      lower = lowerPercentile;
    }
  }

  // For upper bound, typically use a high percentile
  upper = upperPercentile;

  return { lower, upper };
};
