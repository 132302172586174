import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { QueryPatientStudies } from 'graphql/Dicom/Patients';
import { getParams, GetData, ServerErrorsString, IsInvalid } from 'helpers';
import { PatientGridView, PatientListView } from './components';
import { withStyles } from 'tss-react/mui';
import { CircularProgressWaiting } from 'components';
const useStyles = () => ({
  root: {},
});
class ViewDatabasePatient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studies: [],
      total: 0,
    };
    const { holder } = props;
    if (holder) {
      holder.runQueryPatients = query => {
        let page = null;
        let limit = null;
        let descrip = null;
        if (query) {
          const { current_page, current_limit, description } = query;
          page = current_page;
          limit = current_limit;
          descrip = description;
        }
        this.handleLoadStudies(descrip, page, limit);
      };
      holder.getPatientIDs = () => this.getPatientIDs();
    }
  }
  componentDidMount() {
    this.handleLoadStudies();
  }
  getPatientIDs = () => {
    const { ids_patients } = this.props;
    const params = getParams(this.props);
    // console.log({ ids_patients, params });
    if (params) {
      const { level_2 } = params;
      if (level_2 === 'group') {
        return ids_patients;
      } else if (/^\d+$/.test(level_2)) {
        const idPatient = parseInt(level_2, 10);
        return [idPatient];
      } else if (IsInvalid(level_2)) {
        return [];
      }
    }
    return ids_patients;
  };
  handleLoadStudies = (description, new_page, new_limit) => {
    let { page, limit } = this.props;
    const { loading } = this.props;
    if (loading) return;
    const ids_patients = this.getPatientIDs();
    // if (!ids_patients.length) {
    //   return;
    // }
    if (new_limit) {
      page = new_page;
      limit = new_limit;
    }
    this.props.setState({ loading: true });
    (async () => {
      QueryPatientStudies(ids_patients, description, page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, studies, total } = data.patientsStudies;
          if (ok) {
            this.setState({ studies, total });
            this.props.setState({ loading: false, Total: total, page, limit });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { loading, display_database, height } = this.props;
    if (loading) {
      return <CircularProgressWaiting direction="column" />;
    }
    if (display_database === 'list') {
      return (
        <PatientListView
          handleAddError={this.props.handleAddError}
          height={height}
          loading={loading}
          studies={this.state.studies}
          total={this.state.total}
          user={this.props.user}
        />
      );
    } else {
      return (
        <PatientGridView
          handleAddError={this.props.handleAddError}
          height={height}
          loading={loading}
          studies={this.state.studies}
          total={this.state.total}
          user={this.props.user}
        />
      );
    }
  }
}

ViewDatabasePatient.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  const { ids_patients } = state.page_database;
  return {
    total_patients: ids_patients ? ids_patients.length : 0,
    ids_patients: ids_patients ? ids_patients : [],
  };
};

export default connect(mapStateToProps)(
  withStyles(withRouter(ViewDatabasePatient), useStyles)
);
