export const useStyles = theme => ({
  root_gallery: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  control: {
    padding: theme.spacing(2),
  },
  viewer: {
    zIndex: 19999, // Set a high z-index value
    position: 'fixed', // Ensure it covers the full browser
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.9)', // Darker transparent background color
    color: '#ffffff', // Whiter text color
  },
  autoplayButton: {
    position: 'absolute',
    top: '2px',
    left: '20px',
    zIndex: 20000,
    backgroundColor: 'transparent',
    color: '#000000',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    padding: 0,
    marging: 0,
  },
  slider: {
    position: 'absolute',
    bottom: '60px',
    right: '20px',
    zIndex: 20000,
    width: '200px',
    color: 'white',
    height: 4,
    '& .MuiSlider-thumb': {
      width: 8,
      height: 8,
      transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
      '&::before': {
        boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
      },
      '&:hover, &.Mui-focusVisible': {
        boxShadow: '0px 0px 0px 8px rgb(0 0 0 / 16%)',
      },
      '&.Mui-active': {
        width: 20,
        height: 20,
      },
    },
    '& .MuiSlider-rail': {
      opacity: 0.28,
    },
  },
});
