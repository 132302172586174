export const useStyles = () => ({
  box: {
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.01)',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.8)' /* Add a shadow to the text */,
    color: 'white',
    fontFamily: 'monospace',
    fontSize: '10px',
    padding: '1em',
    paddingTop: 2,
    pointerEvents: 'none',
    // zIndex: 1000,
  },
  // Top Orienatation
  top_left: {
    left: '48.5%',
    top: '1px',
  },
  text: {
    padding: 0,
    margin: 0,
    height: 14,
  },
  text_right: {
    marginLeft: 'auto',
    marginRight: 0,
  },
  // Left Orientation
  bottom_left: {
    left: '1px',
    bottom: '45.5%',
  },
  // Bottom Orientation
  bottom_right: {
    right: '48.5%',
    bottom: 0,
    textAlign: 'right',
  },
  // Right Orientation
  top_right: {
    right: '1px',
    top: '48%',
    textAlign: 'right',
  },
});
