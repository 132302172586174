import { makeStyles } from 'tss-react/mui';
import { mainDrawerWidth } from 'common';
import {
  colorBorderDicomViewer,
  colorDicomViewer,
  colorDicomViewerText,
  // getColorBackground,
} from 'theme';

export const useStyleMainSidebar = makeStyles()(theme => ({
  drawer_on_top: {
    zIndex: 3000,
  },
  drawer: {
    // backgroundColor: getColorBackground(),
    // border: `1px solid ${colorBorderDicomViewer}`,
    width: mainDrawerWidth,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
  },
  drawer_open: {
    // backgroundColor: getColorBackground(),
    width: mainDrawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  },
  drawer_closed: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  },
  drawer_viewer: {
    backgroundColor: colorDicomViewer,
    border: `1px solid ${colorBorderDicomViewer}`,
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 50px)',
  },
  divider: {
    margin: theme.spacing(0.5, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  toolbarIcon: {
    position: 'absolute',
    bottom: '5px',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 5px',
    ...theme.mixins.toolbar,
  },
  container: {
    width: '100%',
    height: '100%',
  },
  container_viewer: {
    backgroundColor: colorDicomViewer,
    color: colorDicomViewerText,
  },
  container_items: {
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
  },
  mainSideBarBottom: {
    top: 'auto',
    height: '50px',
    bottom: 0,
    background: '#e0e0e0',
  },
  mainSideBarBottom_viewer: {
    backgroundColor: colorDicomViewer,
    border: `1px solid ${colorBorderDicomViewer}`,
    color: 'white',
  },
}));
