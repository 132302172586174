import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { withStyles } from 'tss-react/mui';
import {
  PanelPaientInfo,
  PanelRight,
  PanelLeft,
  useStyles,
  ToolBarSearchSeries,
} from './components';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

class DialogSearchSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      patient: null,
      patients: [],
      page: 0,
      limit: 50,
      total: 0,
      ListViewerSeries: [],
      loading_patients: false,
    };
    this.holder = {
      onDroppedSeries: null,
      getSeries: null,
      setDroppedItems: null,
      cleanSeriesInStudy: null,
      queryPatients: null,
    };
  }
  componentDidMount() {
    // console.log('DialogSearchSeries - componentDidMount');
  }
  componentDidUpdate() {
    const { status } = this.state;
    const {
      open_search,
      state: { ListViewerSeries },
    } = this.props;
    // console.log('DialogSearchSeries -componentDidUpdate', {
    //   status: status,
    //   open_search: open_search,
    //   ListViewerSeries: [...ListViewerSeries],
    // });
    if (open_search && !status) {
      // console.log('DialogSearchSeries -componentDidUpdate - A');
      this.setState({ status: true, ListViewerSeries }, () => {
        // console.log('DialogSearchSeries -componentDidUpdate - B', {
        //   status: this.state.status,
        //   ListViewerSeries: this.state.ListViewerSeries,
        // });
        this.holder.setDroppedItems(ListViewerSeries);
      });
    }
  }
  handleClose = () => {
    this.holder.cleanSeriesInStudy();
    this.props.setState({ open_search: false }, () => {
      this.setState({ status: false, ListViewerSeries: [] });
    });
  };
  handleNewSeries = series => {
    this.setState({ ListViewerSeries: series });
  };
  handleSaveSelectedSeries = ListViewerSeries => {
    this.holder.cleanSeriesInStudy();
    this.props.setState({ ListViewerSeries, open_search: false }, () => {
      this.setState({ status: false, ListViewerSeries: [] });
    });
  };
  render() {
    const { open_search, classes } = this.props;
    return (
      <Dialog
        disablePortal // Force dialog to render within parent DOM
        fullScreen
        onClose={this.handleClose}
        open={open_search}
        sx={{ zIndex: 1300 }} // Ensure high z-index (default for MUI dialogs)
        TransitionComponent={Transition}>
        <ToolBarSearchSeries
          classes={classes}
          handleAddError={this.props.handleAddError}
          handleClose={this.handleClose}
          holder={this.holder}
          onSaveSelectedSeries={this.handleSaveSelectedSeries}
          setState={state => this.setState(state)}
          state={this.state}
          total={this.props.state.ListViewerSeries.length}
        />
        <div className={classes.main}>
          <div className={classes.header}>
            <PanelPaientInfo
              classes={classes}
              handleAddError={this.props.handleAddError}
              patient={this.state.patient}
              state={this.state}
            />
          </div>
          <div className={classes.bottomMain}>
            <div className={classes.mainContent}>
              <PanelLeft
                classes={classes}
                handleAddError={this.props.handleAddError}
                holder={this.holder}
                onPatientClicked={patient => this.setState({ patient })}
                state={this.state}
              />
              <PanelRight
                classes={classes}
                handleAddError={this.props.handleAddError}
                holder={this.holder}
                onDroppedSeries={dropped => {
                  const ListSeries = dropped.map(x => x.series).filter(x => x);
                  this.handleNewSeries(ListSeries);
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}
DialogSearchSeries.defaultProps = {
  onSaveSelectedSeries: () => '',
};
export default withStyles(DialogSearchSeries, useStyles);
