import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Grid, Typography } from '@mui/material';
import { HomeFooterSubscribe } from 'views/Home/components';
import { SlideSoftware } from 'views/HomePublicAccess/components/HomeSoftware/components/SoftwareMain/components';
import { page_aaoca, page_swiss_ich } from './pages_projects';

class ProjectsMain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Box py={6}>
        <Container>
          <Grid container minHeight="40vh" spacing={3}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  position: 'relative',
                  left: -40,
                  fontSize: 25,
                  fontWeight: 1000,
                  marginBottom: 2,
                }}
                variant="h4">
                Current Projects:
              </Typography>
              <SlideSoftware pages={[page_aaoca, page_swiss_ich]} />
            </Grid>
          </Grid>
        </Container>
        <HomeFooterSubscribe
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
        />
      </Box>
    );
  }
}

ProjectsMain.propTypes = {
  classes: PropTypes.object,
};

export default ProjectsMain;
