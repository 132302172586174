import React from 'react';

import { withStyles } from 'tss-react/mui';

import PropTypes from 'prop-types';
import { Divider, Paper, Tab, Tabs } from '@mui/material';

import { MyUnits, UnitEdit, UnitSummary, UnitTopBar } from './components';
import { BoxWaiting, TabPanel } from 'components';
import useStyles from './Styles';
import { withRouter } from 'react-router-dom';
import { MyUnit } from 'graphql/Units';
import { GetData, getParams, ServerErrorsString } from 'helpers';

const tabs = {
  summary: 2,
  myunits: 1,
  main: 0,
};

class Units extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0,
      unit: null,
      init: false,
    };
    this.holder = {
      openAddNewUnit: null,
    };
  }

  componentDidMount() {
    if (this.props.isActive) this.loadUnit();
  }

  componentDidUpdate() {
    const { tabValue } = this.state;
    const params = getParams(this.props);
    if (params) {
      const { level_1 } = params;
      if (typeof level_1 === 'undefined') {
        if (tabValue !== 0) this.setState({ tabValue: 0 });
      } else if (tabs[level_1] !== tabValue) {
        this.setState({ tabValue: tabs[level_1] });
      }
    }
    if (this.props.isActive && !this.state.init) this.loadUnit();
  }

  loadUnit = () => {
    const { loading } = this.state;
    if (loading) return;
    const { user } = this.props;
    this.setState({ loading: true });
    MyUnit(user.id)
      .then(res => {
        const data = GetData(res);
        const { ok, unit, errors } = data.MyUnit;
        if (ok) {
          this.setState({ unit, loading: false, init: true });
        } else {
          throw errors;
        }
      })
      .catch(error => {
        this.setState({ loading: true, init: true });
        this.props.handleAddError(ServerErrorsString(error));
      });
  };
  handleTabValue = (event, newValue) => {
    event.preventDefault();
    const { history } = this.props;
    newValue = parseInt(newValue, 10);
    this.setState({ tabValue: newValue });
    switch (newValue) {
      case 2:
        history.push('/management/units/summary');
        break;
      case 1:
        history.push('/management/units/my_units');
        break;
      default:
        history.push('/management/units/main_unit');
        break;
    }
  };
  setUnit = unit => {
    this.setState({ unit });
  };

  render() {
    if (!this.props.isActive) return null;
    const { classes, user, isDesktop, height } = this.props;
    let { tabValue, unit, loading } = this.state;
    let readOnly = true;
    if (unit) {
      readOnly = user.id === unit.Admin.id ? false : true;
    }
    tabValue = parseInt(tabValue, 10);
    if (isNaN(tabValue) || tabValue === undefined) {
      tabValue = 0;
    }
    console.log({ user });
    return (
      <React.Fragment>
        <UnitTopBar loading={loading} readOnly={readOnly} unit={unit} />
        <Paper square>
          <Tabs
            indicatorColor="primary"
            onChange={this.handleTabValue}
            textColor="primary"
            value={tabValue}
            variant="scrollable">
            <Tab label="Main Unit" />
            <Tab label="My Units" />
            <Tab label="Summary" />
          </Tabs>
          <Divider />
        </Paper>
        <div style={{ height: height - 230, overflow: 'auto' }}>
          <div className={classes.content_overview}>
            {loading ? (
              <BoxWaiting />
            ) : (
              <div className={classes.external}>
                <TabPanel className={classes.files} index={0} value={tabValue}>
                  <UnitEdit
                    handleAddError={this.props.handleAddError}
                    height={height}
                    history={this.props.history}
                    match={this.props.match}
                    readOnly={readOnly}
                    setUnit={this.setUnit}
                    unit={unit}
                    user={user}
                  />
                </TabPanel>
                <TabPanel className={classes.files} index={1} value={tabValue}>
                  <MyUnits
                    handleAddError={this.props.handleAddError}
                    height={height}
                    history={this.props.history}
                    holder={this.holder}
                    match={this.props.match}
                    readOnly={readOnly}
                    unit={unit}
                    user={user}
                  />
                </TabPanel>
                <TabPanel className={classes.files} index={2} value={tabValue}>
                  <UnitSummary
                    handleAddError={this.props.handleAddError}
                    height={height}
                    history={this.props.history}
                    holder={this.holder}
                    isDesktop={isDesktop}
                    match={this.props.match}
                    myunit={unit}
                    readOnly={readOnly}
                    user={user}
                  />
                </TabPanel>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Units.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withStyles(withRouter(Units), useStyles);
