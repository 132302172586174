export const colorDicomViewer = 'rgba(40, 83, 112,1)';
export const colorBorderDicomViewer = 'gray';
export const colorDicomViewerIcons = 'white';
export const colorDicomViewerText = 'white';

// export const colorDicomViewer = undefined;
// export const colorBorderDicomViewer = undefined;
// export const colorDicomViewerIcons = '#546e7a';
// export const colorDicomViewerText = '#546e7a';
export const IsDicomViewer = () => {
  console.log({
    page: localStorage.page,
    viewer_access: localStorage.viewer_access,
  });
  if (
    localStorage.page === 'Dicom Viewer' &&
    localStorage.viewer_access === '1'
  ) {
    return true;
  }
  console.log('A');
  return false;
};
export const getColorBackground = () => {
  let colorDicomViewer = undefined;
  if (IsDicomViewer()) colorDicomViewer = 'rgba(40, 83, 112,1)';
  return colorDicomViewer;
};
export const getColorBoder = () => {
  let colorBorderDicomViewer = undefined;
  if (IsDicomViewer()) colorBorderDicomViewer = 'gray';
  return colorBorderDicomViewer;
};
export const getColorIcon = (viewer = false) => {
  let colorDicomViewerIcons = '#546e7a';
  if (viewer) colorDicomViewerIcons = 'white';
  return colorDicomViewerIcons;
};
export const getColorText = (viewer = false) => {
  let colorDicomViewerText = '#546e7a';
  if (viewer) colorDicomViewerText = 'white';
  return colorDicomViewerText;
};
