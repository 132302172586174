import {
  FormatSeriesDate,
  FormatTime,
  getUserFullName,
  IsInvalid,
  IsInvalidParam,
} from 'helpers';

export { default as ToolBarBrowserProjectSeries } from './ToolBarBrowserProjectSeries';

export const header_study_search = [
  { field: 'idSeries', headerName: 'ID', width: 75, align: 'left' },
  {
    field: 'user',
    headerName: 'User',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('Owner');
      if (IsInvalid(value)) return '';
      return getUserFullName(value);
    },
  },
  {
    field: 'PatientName',
    headerName: 'PatientName',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const Patient = params.getValue('Patient');
      if (IsInvalid(Patient)) return '';
      return Patient.PatientName;
    },
  },
  {
    field: 'AccessionNumber',
    headerName: 'AccessionNumber',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const Study = params.getValue('Study');
      if (IsInvalid(Study)) return '';
      return Study.AccessionNumber;
    },
  },
  {
    field: 'StudyDate',
    headerName: 'StudyDate',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const Study = params.getValue('Study');
      if (IsInvalid(Study)) return '';
      return FormatSeriesDate(Study.StudyDate, 'yyyy/MM/DD');
    },
  },
  {
    field: 'NumberOfSeriesRelatedInstances',
    headerName: '#Files',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      let CurrentNumberOfFiles = params.getValue('CurrentNumberOfFiles');
      if (IsInvalid(CurrentNumberOfFiles)) CurrentNumberOfFiles = 0;
      let NumberOfSeriesRelatedInstances = params.getValue(
        'NumberOfSeriesRelatedInstances'
      );
      if (IsInvalid(NumberOfSeriesRelatedInstances))
        NumberOfSeriesRelatedInstances = 0;
      if (CurrentNumberOfFiles >= NumberOfSeriesRelatedInstances)
        return CurrentNumberOfFiles;
      return NumberOfSeriesRelatedInstances;
    },
  },
  {
    field: 'SeriesDescription',
    headerName: 'SeriesDescription',
    width: 75,
    align: 'left',
  },
  {
    field: 'SeriesNumber',
    headerName: 'SeriesNumber',
    width: 75,
    align: 'left',
  },
  {
    field: 'Modality',
    headerName: 'Modality',
    width: 75,
    align: 'left',
  },
  {
    field: 'ProtocolName',
    headerName: 'ProtocolName',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const ImageMr = params.getValue('ImageMr');
      if (IsInvalid(ImageMr)) return '';
      return ImageMr.ProtocolName;
    },
  },
  {
    field: 'SliceThickness',
    headerName: 'SliceThickness',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const ImageResolution = params.getValue('ImageResolution');
      if (IsInvalid(ImageResolution)) return '';
      return ImageResolution.SliceThickness;
    },
  },
  {
    field: 'SpacingBetweenSlices',
    headerName: 'SpacingBetweenSlices',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const ImageResolution = params.getValue('ImageResolution');
      if (IsInvalid(ImageResolution)) return '';
      return ImageResolution.SpacingBetweenSlices;
    },
  },
  {
    field: 'ContrastBolusAgent',
    headerName: 'ContrastBolusAgent',
    width: 75,
    align: 'left',
  },
  {
    field: 'ManufacturerModelName',
    headerName: 'ManufacturerModelName',
    width: 75,
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const Study = params.getValue('Study');
      if (IsInvalid(Study)) return '';
      return Study.ManufacturerModelName;
    },
  },
  {
    field: 'KVP',
    headerName: 'KVP',
    width: 75,
    align: 'left',
  },
  {
    field: 'DataCollectionDiameter',
    headerName: 'DataCollectionDiameter',
    width: 75,
    align: 'left',
  },
  {
    field: 'SingleCollimationWidth',
    headerName: 'SingleCollimationWidth',
    width: 75,
    align: 'left',
  },
  {
    field: 'createdAt',
    headerName: 'Created At',
    align: 'left',
    width: 222,
    type: 'datetime',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const value = params.getValue('createdAt');
      if (IsInvalid(value)) return '';
      return FormatTime(value, 'MMM Do - YYYY, h:mm a');
    },
  },
];
