import React from 'react';
import PropTypes from 'prop-types';
import {
  HomeFooter,
  HomeFooterInformation,
  HomeHeader,
  HomeLogos,
} from 'views/Home/components';
import { HomeMain } from 'components';
import 'views/Home/Home.css';
import { Typography } from '@mui/material';
import clsx from 'clsx';
import { getParams } from 'helpers';
import { CardiologyAAOCA, ProjectsMain, SwissICHComponent } from './components';


class HomeProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, user, isDesktop, isAuthenticated } = this.props;
    let className = undefined;
    const reply = getParams(this.props);
    // let variant = 'h2';
    // console.log({ reply });
    if (reply && reply.level_2) {
      className = classes.tiny;
      const { level_2 } = reply;
      if (level_2 === 'aaoca') {
        return (
          <>
            <HomeHeader
              changeColorOnScroll={{
                height: 15,
                color: 'white',
              }}
              color="transparent"
              fixed
              isAuthenticated={isAuthenticated}
              isDesktop={isDesktop}
              style={{ paddingTop: 0, paddingBottom: 0 }}
              user={user}
            />
            <HomeMain
              image={'/images/software.jpg'}
              medium={false}
              small={false}
              style={{ height: '130px' }}
            />
            <div className={clsx(classes.main, classes.mainRaised)}>
              <div className={classes.container}>
                <CardiologyAAOCA {...this.props} />
              </div>
            </div>
          </>
        );
      }
      else if (level_2 === 'swissICH') {
        return (
          <>
            <HomeHeader
              changeColorOnScroll={{
                height: 15,
                color: 'white',
              }}
              color="transparent"
              fixed
              isAuthenticated={isAuthenticated}
              isDesktop={isDesktop}
              style={{ paddingTop: 0, paddingBottom: 0 }}
              user={user}
            />
            <HomeMain
              image={'/images/software.jpg'}
              medium={false}
              small={false}
              style={{ height: '130px' }}
            />
            <div className={clsx(classes.main, classes.mainRaised)}>
              <div className={classes.container}>
                <SwissICHComponent {...this.props} />
              </div>
            </div>
          </>
        );
      }
    }
    return (
      <React.Fragment>
        <HomeHeader
          changeColorOnScroll={{
            height: 200,
            color: 'white',
          }}
          color="transparent"
          fixed
          isAuthenticated={isAuthenticated}
          isDesktop={isDesktop}
          user={user}
        />
        <HomeMain className={className} image={'/images/software.jpg'} small>
          <div className={classes.container}>
            <Typography mb={2} sx={{ color: 'white' }} variant="h2">
              Projects
            </Typography>
          </div>
        </HomeMain>
        <div className={clsx(classes.main, classes.mainRaised)}>
          <div className={classes.container}>
            <ProjectsMain />
          </div>
        </div>
        <HomeLogos />
        <HomeFooterInformation />
        <HomeFooter />
      </React.Fragment>
    );
  }
}

HomeProjects.propTypes = {
  classes: PropTypes.object,
};

export default HomeProjects;
