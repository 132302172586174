export { default as PanelNavigation } from './PanelNavigation/PanelNavigation';
export const headers = [
  {
    field: 'FullName',
    headerName: 'Name',
    width: '10%',
    align: 'left',
    style: { backgroundColor: 'transparent' },
  },
  {
    field: 'PatientSex',
    headerName: 'Gender',
    align: 'left',
  },
  { field: 'PatientBirthDate', headerName: 'BirthDay', align: 'left' },
  { field: 'PatientID', headerName: 'PatientID', align: 'left' },
  {
    field: 'description',
    headerName: 'Description',
    width: '10%',
    align: 'left',
  },
  {
    field: 'NumberOfPatientRelatedStudies',
    headerName: 'Studies',
    align: 'left',
  },
  {
    field: 'NumberOfPatientRelatedSeries',
    headerName: 'Series',
    align: 'left',
  },
];
