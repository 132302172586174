import React from 'react';
import PropTypes from 'prop-types';
import {
  LeftSectionPatients,
  LeftSectionStudies,
  LeftSectionStudySeries,
  LelfSectionHeader,
} from './components';

class PanelLeft extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      study: null,
    };
    this.holder = { getStudySeries: null, cleanSeriesInStudy: null };
    const { holder } = props;
    if (holder) {
      holder.getSeries = idSeries => {
        return this.holder.getStudySeries(idSeries);
      };
      holder.cleanSeriesInStudy = () => this.holder.cleanSeriesInStudy();
    }
  }
  handleStudyClick = study => {
    this.setState({ study });
  };
  render() {
    const {
      classes,
      state: { ListViewerSeries },
    } = this.props;
    return (
      <div className={classes.leftPanel}>
        <div className={classes.searchResults}>
          <LeftSectionPatients
            classes={classes}
            handleAddError={this.props.handleAddError}
            holder={this.props.holder}
            onPatientClicked={this.props.onPatientClicked}
            state={this.props.state}
          />
        </div>
        <div className={classes.anamneseSection}>
          <LelfSectionHeader
            classes={classes}
            handleAddError={this.props.handleAddError}
            state={this.props.state}
          />
          <LeftSectionStudies
            classes={classes}
            handleAddError={this.props.handleAddError}
            onStudyClicked={this.handleStudyClick}
            state={this.props.state}
          />
        </div>
        <div className={classes.imagesSection}>
          <LeftSectionStudySeries
            classes={classes}
            handleAddError={this.props.handleAddError}
            holder={this.holder}
            ListViewerSeries={ListViewerSeries}
            state={this.state}
          />
        </div>
      </div>
    );
  }
}

PanelLeft.propTypes = {
  classes: PropTypes.object,
};

export default PanelLeft;
