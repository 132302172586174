import React, { Component } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Chart, registerables } from 'chart.js';
import validate from 'validate.js';
import { Provider } from 'react-redux';
import ReactGA4 from 'react-ga4';
import axios from 'axios';
import { PersistGate } from 'redux-persist/integration/react';
import { Helmet } from 'react-helmet';
import { v1 as uuid } from 'uuid';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

import ApolloClientProvider from './apollo';
import { setAuthorizationToken } from './helpers';
import theme from './theme';
import validators from './common/validators';
import Routes from './Routes';
import { GetSecurityPolicy, IsLocalProduction } from './config/security_policy';

import { persistor, store } from './redux-store/store';
import {
  setCurrentUser,
  setReactGA4,
} from './redux-store/actions/loginActions';
import { setCurrentPage } from './redux-store/actions';
import { LOGIN_USER } from './redux-store/actions/types';
import { HTTP_ADDRESS_WEB } from 'config';

const browserHistory = createBrowserHistory();

Chart.register(...registerables);

validate.validators = {
  ...validate.validators,
  ...validators,
};
let saved_user = null;
let id_user = uuid();
if (localStorage.id_user) {
  id_user = localStorage.id_user;
} else {
  localStorage.setItem('id_user', id_user);
}

if (localStorage.token) {
  saved_user = setAuthorizationToken(
    localStorage.token,
    localStorage.refreshToken
  );
  store.dispatch(setCurrentUser(LOGIN_USER, saved_user));
  if (saved_user) {
    id_user = window.btoa(saved_user.email);
  }
}
browserHistory.listen(location => {
  let page = location.pathname;
  if (page === undefined || !page) return;
  // Send pageview with a custom path
  ReactGA4.send({
    hitType: 'pageview',
    page: page,
  });
  if (page && page.startsWith('/')) {
    page = page.substring(1);
  }
  store.dispatch(setCurrentPage(page));
});
console.cout = (...res) => {
  if (HTTP_ADDRESS_WEB.includes('localhost')) console.log(...res);
};
console.log_dev = (...res) => {
  let state = false;
  if (state) console.log(...res);
};
class App extends Component {
  constructor(props) {
    super(props);
    this.online_interaval = null;
  }
  componentDidMount() {
    ReactGA4.initialize('G-FR9T8MXNH3', {
      gaOptions: {
        userId: id_user,
      },
    });
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    window.process = { ...window.process };
    setReactGA4(ReactGA4);
  }
  componentDidUpdate() {
    ReactGA4.initialize('G-FR9T8MXNH3', {
      gaOptions: {
        userId: id_user,
      },
    });
    setReactGA4(ReactGA4);
  }
  render() {
    let meta_data = null;
    if (IsLocalProduction()) {
      meta_data = (
        <Helmet>
          <meta
            content={GetSecurityPolicy()}
            httpEquiv="Content-Security-Policy"
          />
        </Helmet>
      );
    }
    return (
      <ApolloClientProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Router history={browserHistory}>
                  {meta_data}
                  <Routes ReactGA4={ReactGA4} />
                </Router>
              </ThemeProvider>
            </StyledEngineProvider>
          </PersistGate>
        </Provider>
      </ApolloClientProvider>
    );
  }
}
export default App;
