import React from 'react';
import PropTypes from 'prop-types';
import { ViewerCorner2D } from './components';

class GridViewers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.holder = {
      handleToolChange: null,
      setViewerOrientation: null,
      setViewerMeasureTool: null,
      setColorSelected: null,
      loadSeries: null,
      setLoadSeries: (...res) => props.holder.setLoadSeries(...res),
    };
    const { holder } = props;
    if (holder) {
      holder.loadSeries = this.handleLoadSeries;
      holder.handleToolChange = this.handleToolChange;
      holder.handleOrientationChange = this.setViewerOrientation;
      holder.handleToolSelected = this.setToolSelected;
      holder.handleColorSelected = this.setColorSelected;
    }
  }
  componentDidMount() {}

  setViewerOrientation = orientation => {
    if (this.holder.setViewerOrientation) {
      this.holder.setViewerOrientation(orientation);
    }
  };
  setToolSelected = toolName => {
    if (this.holder.setViewerMeasureTool) {
      this.holder.setViewerMeasureTool(toolName);
    }
  };
  setColorSelected = toolName => {
    if (this.holder.setColorSelected) {
      this.holder.setColorSelected(toolName);
    }
  };
  handleLoadSeries = series => {
    console.log_dev({ series });
    if (this.holder.loadSeries) {
      this.holder.loadSeries(series);
    }
  };
  handleToolChange = toolName => {
    if (this.holder.handleToolChange) {
      this.holder.handleToolChange(toolName);
    }
  };
  handleOrientationChange = (orientation, disable = false) => {
    console.log_dev({ orientation });
    this.props.handleOrientationChange(orientation, disable);
  };
  render() {
    const { height } = this.props;
    return (
      <div style={{ height }}>
        <ViewerCorner2D
          handleAddError={this.props.handleAddError}
          handleOrientationChange={this.handleOrientationChange}
          holder={this.holder}
        />
      </div>
    );
  }
}

GridViewers.propTypes = {
  classes: PropTypes.object,
};

export default GridViewers;
