import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

class SearchIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // const styles = {
    //   search_button: {
    //     paddingLeft: 2,
    //     width: '30px',
    //     height: '52px',
    //     backgroundColor: '#095275',
    //     border: 'none',
    //     borderRadius: '4px',
    //     cursor: 'pointer',
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     transition: 'background-color 0.3s ease',
    //     padding: 0,
    //     overflow: 'hidden',
    //     '& :hover': {
    //       backgroundColor: 'red',
    //     },
    //   },
    // };
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <Box
        component="div"
        onClick={this.props.onClick}
        sx={{
          marginLeft: '1px',
          width: '30px',
          height: '52px',
          backgroundColor: '#095275',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          transition: 'background-color 0.3s ease',
          padding: 0,
          overflow: 'hidden',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.3)',
          '&:hover': {
            backgroundColor: '#5da6c9',
          },
        }}>
        <svg
          height="40"
          viewBox="0 0 30 40"
          width="30"
          xmlns="http://www.w3.org/2000/svg">
          {/* <!-- Database icon like the image --> */}
          <g fill="none" stroke="gray" strokeWidth="1.7">
            {/* <!-- Top ellipse --> */}
            <ellipse cx="15" cy="8" rx="10" ry="5" />

            {/* <!-- Sides --> */}
            <line x1="5" x2="5" y1="8" y2="32" />
            <line x1="25" x2="25" y1="8" y2="32" />

            {/* <!-- Bottom ellipse --> */}
            <ellipse cx="15" cy="32" rx="10" ry="5" />

            {/* <!-- Middle sections --> */}
            <path d="M5,16 C5,18.5 9.5,20 15,20 C20.5,20 25,18.5 25,16" />
            <path d="M5,24 C5,26.5 9.5,28 15,28 C20.5,28 25,26.5 25,24" />
          </g>

          {/* <!-- Even bigger and black magnifying glass on top --> */}
          <g transform="translate(7, 12) scale(0.8)">
            <circle
              cx="10"
              cy="10"
              fill="none"
              r="7"
              stroke="#fff"
              strokeWidth="3"
            />
            <line
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth="3.5"
              x1="15.5"
              x2="21"
              y1="15.5"
              y2="21"
            />
          </g>
        </svg>
      </Box>
    );
  }
}

SearchIcon.propTypes = {
  classes: PropTypes.object,
};

export default SearchIcon;
