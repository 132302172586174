const Color = Object.freeze({
  // ID : [index, R , G , B, r, g, b, Opacity] RGB [0-255] rgb [0-1]
  0: [0, 255, 255, 255, 1, 1, 1, 0], // Transperent
  1: [1, 76, 0, 76, 0.3, 0, 0.3, 1],
  2: [2, 0, 191, 102, 0, 0.75, 0.4, 1],
  3: [3, 0, 0, 255, 0, 0, 1, 1],
  4: [4, 217, 0, 0, 0.85, 0, 0, 1],
  5: [5, 0, 26, 166, 0, 0.1, 0.65, 1],
  6: [6, 102, 0, 0, 0.4, 0, 0, 1],
  7: [7, 0, 0, 76, 0, 0, 0.3, 1],
  8: [8, 26, 128, 0, 0.1, 0.5, 0, 1],
  9: [9, 0, 255, 255, 0, 1, 1, 1],
  10: [10, 166, 166, 0, 0.65, 0.65, 0, 1],
  11: [11, 0, 128, 0, 0, 0.5, 0, 1],
  12: [12, 255, 51, 51, 1, 0.2, 0.2, 1],
  13: [13, 255, 0, 255, 1, 0, 1, 1],
  14: [14, 0, 76, 102, 0, 0.3, 0.4, 1],
  15: [15, 255, 255, 0, 1, 1, 0, 1],
  16: [16, 102, 115, 166, 0.4, 0.45, 0.65, 1],
  17: [17, 0, 255, 0, 0, 1, 0, 1],
  18: [18, 166, 166, 166, 0.65, 0.65, 0.65, 1],
  19: [19, 166, 0, 166, 0.65, 0, 0.65, 1],
  20: [20, 76, 76, 76, 0.3, 0.3, 0.3, 1],
});

export default Color;
