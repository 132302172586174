import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Subscription } from '@apollo/client/react/components';
import { connect } from 'react-redux';
import {
  addNewNotification,
  reloadNotification,
  setNotifications,
} from 'redux-store/actions';
import {
  QueryNotifications,
  SUBSCRIPTION_NEW_NOTIFICATION,
} from 'graphql/Communications';
import {
  AvatarResize,
  NotificationsAvatarResize,
} from 'redux-store/actions/notifications';
import { IsInvalid, ServerErrorsString, GetData } from 'helpers';

class NotificationQuery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    const { holder } = props;
    if (holder) {
      holder.handleReload = () => {
        this.handleReload();
      };
    }
  }
  componentDidMount() {
    this.handleReload();
  }
  componentDidUpdate() {
    const { notifications_reload } = this.props;
    if (notifications_reload) {
      this.props.reloadNotification(false);
      this.handleReload();
    }
  }
  handleReload = (page = 0, limit = 100) => {
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    this.props.setState({ loading: true });
    const { dashboard_view } = this.props;
    let mode = null;
    let idProject = null;
    if (dashboard_view) {
      mode = dashboard_view.visualization_mode;
      if (dashboard_view.selected_project)
        idProject = dashboard_view.selected_project.id;
    }
    (async () => {
      QueryNotifications(null, page, limit, { mode, idProject })
        .then(res => {
          const data = GetData(res);
          const { ok, errors, notifications, total } = data.notifications;
          if (ok) {
            this.setState({ loading: false, total });
            this.handleNotifications(notifications);
            this.props.setState({ loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.props.handleAddError(ServerErrorsString(error));
          this.setState({ loading: false });
          this.props.setState({ loading: false });
          this.props.setNotifications([]);
        });
    })();
  };
  handleNotifications = notifications => {
    (async () => {
      const finale = await NotificationsAvatarResize(notifications);
      this.props.setNotifications(finale);
    })();
  };
  onSubscriptionDataMessages = ondata => {
    // console.log({ ondata });
    if (IsInvalid(ondata)) return;
    const { subscriptionData } = ondata;
    if (IsInvalid(subscriptionData)) return;
    const { loading, data } = subscriptionData;
    if (!loading && data) {
      const { newNotification } = data;
      if (newNotification) {
        const { notifications } = this.props;
        const index = notifications.map(x => x.id).indexOf(newNotification.id);
        if (index === -1) {
          (async () => {
            const finale = await AvatarResize(newNotification);
            this.props.addNewNotification(finale);
          })();
        }
      }
    }
  };
  render() {
    const { children } = this.props;
    return (
      <Subscription
        fetchPolicy={'no-cache'}
        onSubscriptionData={this.onSubscriptionDataMessages}
        shouldResubscribe
        subscription={SUBSCRIPTION_NEW_NOTIFICATION}
        variables={{}}>
        {({ loading, error }) => {
          if (!children) {
            return null;
          }
          return React.Children.map(children, function(child) {
            return React.cloneElement(child, {
              loading,
              error,
            });
          });
        }}
      </Subscription>
    );
  }
}

NotificationQuery.propTypes = {
  classes: PropTypes.object,
};
NotificationQuery.defaultProps = {
  setState: () => '',
};
const mapStateToProps = state => {
  let { page_activity } = state;
  let { dashboard_view } = state.page_settings;
  let { notifications } = page_activity;
  let size = 0;
  if (notifications === undefined) {
    size = 0;
    notifications = [];
  } else {
    size = notifications.map(x => x.read).length;
  }
  if (IsInvalid(page_activity.shared_folders)) {
    page_activity = {
      shared_folders: [],
      chat_creation: [],
      chat_messages: [],
      group_message: [],
      group_creation: [],
      notifications: [],
      notifications_reload: false,
    };
  }
  // console.log('NotificationQuery', { size });
  return {
    shared_folders: page_activity.shared_folders,
    chat_creation: page_activity.chat_creation,
    chat_messages: page_activity.chat_messages,
    notifications_reload: page_activity.notifications_reload,
    dashboard_view: dashboard_view,
    size,
    notifications,
  };
};

export default connect(mapStateToProps, {
  addNewNotification,
  setNotifications,
  reloadNotification,
})(NotificationQuery);
