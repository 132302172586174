import React from 'react';
import PropTypes from 'prop-types';
import { TableGrid } from 'components';
import {
  GetData,
  IsInvalid,
  IsInvalidParam,
  rowsPerPageBig,
  ServerErrorsString,
} from 'helpers';
import { QueryPatientsStudies } from 'graphql/PatientsQueries';
import moment from 'moment';
const headers = [
  {
    field: 'arrow',
    headerName: '',
    width: '35px',
    align: 'left',
  },
  {
    field: 'status',
    headerName: '',
    width: '35px',
    align: 'left',
  },
  {
    field: 'date',
    headerName: 'Date and Time',
    align: 'left',
    valueGetter: params => {
      if (IsInvalidParam(params)) return '';
      const StudyDate = params.getValue('StudyDate');
      if (IsInvalid(StudyDate)) return '';
      const StudyTime = params.getValue('StudyTime	');
      if (IsInvalid(StudyTime)) return '';
      return moment(`${StudyDate} ${StudyTime}`, 'YYYY-MM-DD HH:mm:ss').format(
        'DD.MM.YYYY, HH:mm'
      );
    },
  },
  {
    field: 'StudyDescription',
    headerName: 'Description',
    width: '10%',
    align: 'left',
  },
  { field: 'StationName', headerName: 'Station Name', align: 'left' },
  {
    field: 'NumberOfStudyRelatedSeries',
    headerName: 'Number of Series',
    align: 'left',
  },
  {
    field: 'NumberOfStudyRelatedInstances',
    headerName: 'Number of Files',
    align: 'left',
  },
];
class LeftSectionStudies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studies: [],
      patient: null,
      error: null,
      loading: false,
      selected: [],
      page: 0,
      limit: 100,
    };
    this.holder = { setHighlight: null };
  }
  componentDidUpdate() {
    const { patient } = this.props.state;
    if (IsInvalid(patient)) {
      return;
    }
    if (!this.state.patient && patient) {
      this.handleLoadStudies(patient);
    } else if (
      this.state.patient &&
      patient &&
      this.state.patient.idPatient !== patient.idPatient
    ) {
      this.handleLoadStudies(patient);
    }
  }
  handleLoadStudies = (patient, newPage = null, newLimit = null) => {
    const { loading, error } = this.state;
    let { page, limit } = this.state;
    if (loading || error) return;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    if (IsInvalid(patient)) {
      this.setState({ page, limit });
      return;
    }
    this.setState({ loading: true });
    (async () => {
      QueryPatientsStudies([patient.idPatient], page, limit)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, studies, total } = data.allStudiesAtPatients;
          if (ok) {
            const ListStudies = studies.map(x => ({
              ...x,
              arrow: '▶',
              status: '📝',
            }));
            const study = ListStudies.length ? ListStudies[0] : null;
            this.setState({
              patient,
              loading: false,
              studies: ListStudies,
              page,
              limit,
              total,
              error: null,
            });
            if (this.holder.setHighlight) {
              this.holder.setHighlight(study);
            }
            // this.props.setState({ studies });
            this.props.onStudyClicked(study);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, error });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { patient } = this.state;
    return (
      <div style={{ margin: 5, border: '1px solid gray' }}>
        <TableGrid
          dense
          external_selected={this.state.selected}
          headers={headers}
          holder={this.holder}
          idRow="idStudy"
          loading={this.state.loading}
          onPageChange={page =>
            this.handleLoadStudies(patient, page, this.state.limit)
          }
          onRowClicked={this.props.onStudyClicked}
          onRowsPerPageChange={limit =>
            this.handleLoadStudies(patient, 0, limit)
          }
          onSelectedChanged={selected => this.setState({ selected })}
          page={this.state.page}
          paginationMode="server"
          row_height={33}
          rowCount={this.state.total}
          rows={this.state.studies}
          rowsPerPage={this.state.limit}
          rowsPerPageOptions={rowsPerPageBig}
          style_body_cell={{
            color: 'white',
            fontSize: '14px',
            borderBottom: '1px solid #333',
          }}
          style_header={{
            backgroundColor: 'transparent',
            color: 'white',
            fontFamily: 'Arial, sans-serif',
            fontSize: '15px',
            borderBottom: '1px solid #333',
            header_active: {
              '&.Mui-active': {
                color: 'gray',
              },
            },
          }}
          style_pagination={{
            '&.MuiTablePagination-root': { color: '#b4bfc5;' },
            '.MuiTablePagination-selectLabel': {
              color: 'white',
              paddingTop: 1,
            },
            '.MuiTablePagination-displayedRows': {
              color: 'white',
              paddingTop: 1,
            },
          }}
          style_rows={{
            '&.Mui-selected': {
              backgroundColor: '#354f82',
            },
            '&.MuiTableRow-hover:hover': {
              backgroundColor: '#2a3854', // Change to your desired hover color
            },
          }}
          style_table={{
            backgroundColor: 'transparent',
            minHeight: 200,
          }}
        />
      </div>
    );
  }
}

LeftSectionStudies.propTypes = {
  classes: PropTypes.object,
};

export default LeftSectionStudies;
