/* eslint-disable indent */
import React from 'react';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SidebarNavItemSmall from '../SidebarNavItemSmall';
import { useStyles } from './Styles';
import { SidebarNavItemBase, SidebarNavItemCollapse } from './components';
import { IsValid } from 'helpers';
const { REACT_APP_ENV } = process.env;

class SidebarNavItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selected_menu: 0,
    };
  }
  componentDidUpdate = () => {
    const selected_menu = this.getSelectedSubIndex();
    if (selected_menu !== this.state.selected_menu) {
      this.setState({ selected_menu: selected_menu });
    }
  };
  getSelectedSubIndex = () => {
    const {
      page,
      match: { url },
    } = this.props;
    // const { page } = this.state;
    const url_parts = url.split('/');
    url_parts.shift();
    let { selected_menu } = this.state;
    const total = url_parts.length;
    if (total < 2) {
      return selected_menu;
    }
    let sub_menu = url_parts[1];
    if (url_parts[0] === 'project_management' && total === 3) {
      sub_menu = url_parts[2];
    }
    if (typeof page.menu !== 'undefined') {
      const index_menu = page.menu
        .map(x =>
          x
            .toLowerCase()
            .replace(' ', '_')
            .replace('/', '_')
        )
        .indexOf(sub_menu);
      if (page.menu.length >= index_menu) {
        selected_menu = index_menu;
      }
    }
    return selected_menu;
  };
  getUrlParams = () => {
    const {
      match: { url },
    } = this.props;
    const url_parts = url.split('/');
    url_parts.shift();
    return url_parts;
  };
  handleClick = index => {
    const { history, page } = this.props;
    // const { page } = this.state;
    const url_parts = this.getUrlParams();
    const total = url_parts.length;
    const base_name = page.href.split('/');
    base_name.shift();
    if (REACT_APP_ENV !== 'production') {
      console.log({ url_parts, base_name });
    }
    if (base_name.length && total) {
      if (base_name[0] !== url_parts[0]) {
        if (IsValid(page.menu) && page.menu.length >= 1) {
          let sub_menu = url_parts[1];
          if (base_name[0] === 'project_management' && total === 3) {
            sub_menu = url_parts[1] + '/' + url_parts[2];
          } else {
            sub_menu = page.menu[0]
              .toLowerCase()
              .replace(' ', '_')
              .replace('/', '_');
          }
          history.push(`${page.href}/${sub_menu}`);
        } else {
          history.push(page.href);
        }
      } else {
        this.setState(prev_state => ({ open: !prev_state.open }));
      }
    }
    const selected_menu = this.getSelectedSubIndex();
    if (selected_menu !== this.state.selected_menu) {
      this.handleSelectedMenu(index, selected_menu);
    }
  };
  handleSelectedMenu = (index, index_menu) => {
    //console.log({ index, index_menu });
    this.setState({ selected_menu: index_menu });
    this.props.handleSelected(index);
  };
  getMenu = () => {
    const { page } = this.props;
    if (page === 'undefined' || !page) return null;
    const parent_ref = page.title
      .toLowerCase()
      .replace(' ', '_')
      .replace('/', '_');
    let { menu } = page;
    if (parent_ref !== 'project_management') {
      return menu;
    }
    const url_parts = this.getUrlParams();
    if (
      url_parts.length > 1 &&
      (url_parts[1] === 'browser' || url_parts[0] !== 'project_management')
    ) {
      menu = ['Browser'];
    } else {
      /** empty */
    }
    return menu;
  };

  render() {
    const {
      classes,
      page,
      index,
      selected,
      handleSelected,
      minSidebar,
      isDicomViewer,
    } = this.props;
    const { open, selected_menu } = this.state;
    const url_parts = this.getUrlParams();
    const menu = this.getMenu();
    const isSelected = index === selected;
    if (minSidebar) {
      return (
        <SidebarNavItemSmall
          classes={classes}
          handleSelected={title => handleSelected(index, title)}
          index={index}
          isDicomViewer={isDicomViewer}
          isSelected={isSelected}
          page={page}
        />
      );
    }
    return [
      <SidebarNavItemBase
        classes={classes}
        handleSelected={title => handleSelected(index, title)}
        isDicomViewer={isDicomViewer}
        isSelected={isSelected}
        key={`menu-base-${page.title}`}
        menu={menu}
        onClick={this.handleClick}
        open={open}
        page={page}
      />,
      <SidebarNavItemCollapse
        classes={classes}
        isDicomViewer={isDicomViewer}
        isSelected={isSelected}
        key={`menu-collapse-${page.title}`}
        menu={menu}
        open={open}
        page={page}
        selected_menu={selected_menu}
        url_parts={url_parts}
      />,
    ];
  }
}

SidebarNavItem.propTypes = {
  className: PropTypes.string,
};
export default withRouter(withStyles(SidebarNavItem, useStyles));
