/* eslint-disable no-unused-vars */
export const getRightStyles = (selectedItems, isGridOpen, items) => ({
  body: {
    fontFamily: 'Arial, sans-serif',
    margin: 0,
    padding: 20,
    display: 'flex',
    gap: 20,
  },
  container: {
    display: 'flex',
    gap: 20,
    width: '100%',
  },
  leftSection: {
    flex: 1,
  },
  rightSection: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    minWidth: '290px',
    height: 'calc(100% - 29px)',
  },
  dropContainer: {
    border: '2px dashed #ccc',
    borderRadius: 5,
    padding: 10,
    minHeight: 400,
    flexGrow: 1,
    height: '100%',
    minWidth: '285px',
  },
  dropZone: {
    minHeight: 360,
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    gap: 2,
    justifyContent: 'center',
    alignItems: 'flex-start',
    overflowY: 'auto', // Enables vertical scrolling when content overflows
  },
  dropZoneText: {
    width: '100%',
    minHeight: 360,
    height: 'calc(100% - 5px)',
    textAlign: 'center',
    color: '#888',
    padding: 20,
  },
  imagesSection: {
    border: '1px solid #ddd',
    borderRadius: 5,
    overflow: 'hidden',
  },
  sectionHeader: {
    backgroundColor: '#f5f5f5',
    padding: '10px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    userSelect: 'none',
  },
  icon: {
    transition: 'transform 0.3s',
    transform: isGridOpen ? 'rotate(0deg)' : 'rotate(-90deg)',
  },
  imageGrid: {
    display: isGridOpen ? 'grid' : 'none',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: 5,
    padding: 10,
  },
  imageItem: {
    height: 80,
    backgroundColor: '#e9e9e9',
    borderRadius: 4,
    cursor: 'grab',
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    border: '2px solid transparent',
  },
  droppedItem: {
    width: 80,
    height: 80,
    margin: 2,
    transition: 'border 0.2s ease',
  },
  imageItemBefore: {
    content: '✓',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
  },
  selected: {
    border: '2px solid #4CAF50',
    boxShadow: '0 0 5px rgba(76, 175, 80, 0.5)',
    position: 'relative',
  },
  selectedAfter: {
    position: 'absolute',
    top: 1,
    right: 1,
    width: 20,
    height: 20,
    backgroundColor: 'transparent',
    color: '#FFFFFF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    zIndex: 2,
  },
  sourceDropped: {
    border: '2px solid #F44336',
    boxShadow: '0 0 5px rgba(244, 67, 54, 0.5)',
    position: 'relative',
  },
  sourceDroppedAfter: {
    content: '"✓"',
    position: 'absolute',
    top: 2,
    right: 2,
    width: 24,
    height: 24,
    backgroundColor: '#F44336',
    color: '#FFFFFF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16,
    fontWeight: 'bold',
    zIndex: 2,
    boxShadow: '0 0 2px rgba(0, 0, 0, 0.3)',
  },
  trashContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  trashButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: selectedItems.length > 0 ? '#ffebee' : '#f5f5f5',
    border: `1px solid ${selectedItems.length > 0 ? '#ef5350' : '#ddd'}`,
    borderRadius: 4,
    padding: '8px 15px',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
  },
  trashSvg: {
    marginRight: 8,
    fill: selectedItems.length > 0 ? '#ef5350' : 'currentColor',
  },
});
