import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';

import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { IsInvalid } from 'helpers';

const MUTATION_UPDATE_ONLINE_USER = gql`
  mutation($input: InputUserOnile!) {
    updateLastSeen(input: $input) {
      ok
      errors {
        path
        message
      }
      online {
        id
        idUser
        last_seen
      }
    }
  }
`;

export const MutationUpdateOnlineUser = user => {
  if (IsInvalid(user.idSession)) {
    console.log('MutationUpdateOnlineUser', { user });
  }
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(MUTATION_UPDATE_ONLINE_USER),
    variables: {
      input: {
        idSession: user.idSession,
        last_seen: new Date().toISOString(),
      },
    },
  });
};
