/* eslint-disable quotes */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import { Avatar, Box, LinearProgress, Typography } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import FilterDramaIcon from '@mui/icons-material/FilterDrama';
import { FormatBytes } from 'helpers';
import { logout } from 'redux-store/actions/loginActions';
import { connect } from 'react-redux';
import { updateUser } from 'redux-store/actions/userActions';
import { calc_image_size } from 'helpers/utils_images';
import { getColorText } from 'theme';

const useStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    marginBottom: 0,
    paddingBottom: 0,
  },
  progress: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'fit-content',
    margin: theme.spacing(1, 2, 0, 2),
    padding: theme.spacing(0),
  },
  storage: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
  },
  avatar: {
    marginTop: theme.spacing(1),
    width: 80,
    height: 80,
  },
  name: {
    marginTop: theme.spacing(1),
  },
});
const BorderLinearProgress = withStyles(LinearProgress, theme => ({
  root: {
    width: '95%',
    height: 5,
    borderRadius: 5,
    marginTop: 2,
    marginBottom: 0,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  colorPrimary: {
    backgroundColor: grey[400],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: blue[500],
  },
}));

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idUser: null,
      setting: null,
      empty: false,
      error: '',
      photo: null,
    };
    this.elementRef = React.createRef();
  }
  render() {
    const { classes, className, user, setting, isDicomViewer } = this.props;
    let storage = 0;
    let text_storage = '0 of 10 T used';
    // const { setting } = this.state;
    if (setting) {
      const used = FormatBytes(setting.used);
      const total = FormatBytes(setting.storage);
      text_storage = `${used} of ${total} used`;
      storage = Math.ceil((setting.used / setting.storage) * 100, 10);
    }
    let image_avatar = user.photo;
    if (image_avatar === undefined || !image_avatar) {
      image_avatar = '/images/avatars/avatar_0.png';
    }
    const colorText = getColorText(isDicomViewer);
    return (
      <React.Fragment>
        <div className={clsx(classes.root, className)}>
          <Avatar
            alt="Person"
            className={classes.avatar}
            component={RouterLink}
            src={image_avatar}
            to="/account/profile"
          />
          <Typography
            className={classes.name}
            sx={{ color: colorText }}
            variant="h4">
            {user.firstName}
          </Typography>
          {/* <Typography variant="body2">{user.bio}</Typography> */}
        </div>
        <div className={classes.progress}>
          <div className={classes.storage}>
            <FilterDramaIcon style={{ marginRight: 10 }} />
            <Box fontWeight="fontWeightBold">Storage</Box>
          </div>
          <BorderLinearProgress
            color="primary"
            value={storage}
            variant="determinate"
          />
          <Typography
            style={{
              marginLeft: 10,
              marginBottom: 0,
              color: colorText,
            }}
            variant="body2">
            {text_storage}
          </Typography>
        </div>
      </React.Fragment>
    );
  }
}

Profile.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  let size_avatar = 0;
  const { user, setting } = state.info_user;
  const { isDicomViewer } = state.page_globals;
  let idUser = -1;
  if (user) {
    size_avatar = calc_image_size(user.avatar);
    idUser = user.id;
  }
  //
  return {
    size_avatar,
    setting,
    idUser,
    isDicomViewer: isDicomViewer === undefined ? false : isDicomViewer,
  };
};
export default connect(mapStateToProps, {
  logout,
  updateUser,
})(withStyles(Profile, useStyles));
