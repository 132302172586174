import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { series_full } from 'graphql/Series/utils_series_query';

const QUERY_PROJECT_SERIES = gql`
  query(
    $idProject: LongLong!
    $input_query: InputProjectSeries
    $page: Int
    $limit: Int
  ) {
    projectSeriesBrowser(
      idProject: $idProject
      input_query: $input_query
      page: $page
      limit: $limit
    ) {
      ok
      errors {
        path
        message
      }
      total
      series {
        Study {
          StudyDescription
          StudyDate
          AccessionNumber
          ManufacturerModelName
        }
        Patient {
          PatientName
        }
        SeriesNumber
        ContrastBolusAgent
        NumberOfSeriesRelatedInstances
        ${series_full}
        ImageMr {
          ProtocolName
          MRAcquisitionType
          SequenceName
        }
        SingleCollimationWidth
        DataCollectionDiameter
        KVP
      }
    }
  }
`;

export const QueryProjectSeries = (idProject, input_query, page, limit) => {
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PROJECT_SERIES),
    variables: { idProject, input_query, page, limit },
  });
};
