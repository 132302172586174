export { default as TabProjectFiles } from './TabProjectFiles';
export { default as TabProjectOverview } from './TabProjectOverview';
export { default as TabProjectActivity } from './TabProjectActivity';
export { default as TabProjectTeams } from './TabProjectTeams';
export { default as TabProjectSetting } from './TabProjectSetting';
export { default as TabStudySearch } from './TabStudySearch';

export const useStyles = () => ({
  content_overview: {
    width: '100%',
    height: '100%',
    paddingBottom: 5,
    overflowY: 'auto',
  },
  root_content_overview: {
    width: '100%',
    height: '100%',
  },
  files: {
    paddingTop: 10,
    paddingLeft: 1,
    paddingRight: 1,
    height: '100%',
  },
});
