/* eslint-disable indent */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, CircularProgress, Tooltip } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import IconButton from '@mui/material/IconButton';
import { PhotoCamera as PhotoCameraIcon } from '@mui/icons-material';
import clsx from 'clsx';
import { green, grey } from '@mui/material/colors';

const useStyles = () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  wrapper: {
    margin: 0,
    // marginLeft: 0,
    position: 'relative',
    maxWidth: 70,
  },
  badge: {
    background: green[800],
  },
  badge_disabled: {
    background: grey[500],
  },
  progress: {
    color: green[800],
    position: 'absolute',
    top: 4,
    left: 3.5,
    zIndex: 1,
  },
  progressSmall: {
    color: green[800],
    position: 'absolute',
    top: 0,
    left: -1,
    zIndex: 1,
  },
});

class IconButtonLoad extends Component {
  render() {
    const {
      classes,
      title,
      icon,
      loading,
      small,
      tooltip,
      disabled,
      handleClick,
      length,
      bottom_top,
      left_right,
      color,
      IconButtonProps,
      badge_style,
      sx_circle,
    } = this.props;
    const defaultProps = {
      anchorOrigin: {
        vertical: bottom_top ? 'bottom' : 'top',
        horizontal: left_right ? 'left' : 'right',
      },
      color: 'secondary',
      children: icon,
      sx: badge_style,
    };
    const badge = loading
      ? classes.badge_disabled
      : color
      ? color
      : classes.badge;
    //
    return (
      <div className={classes.wrapper}>
        <Tooltip title={tooltip}>
          <span style={{ margin: 0, padding: 0 }}>
            <IconButton
              aria-label={title}
              // className={className}
              component="span"
              disabled={loading || disabled}
              onClick={handleClick}
              {...IconButtonProps}
              size={small ? 'small' : 'large'}>
              <Badge
                badgeContent={length}
                classes={{ badge: badge }}
                {...defaultProps}
              />
            </IconButton>
          </span>
        </Tooltip>
        {loading && (
          <CircularProgress
            className={clsx({
              [classes.progress]: !small,
              [classes.progressSmall]: small,
            })}
            disableShrink
            size={small ? 35 : 40}
            style={sx_circle}
          />
        )}
      </div>
    );
  }
}

IconButtonLoad.propTypes = {
  bottom_top: PropTypes.bool.isRequired,
  className: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  icon: PropTypes.object.isRequired,
  left_right: PropTypes.bool.isRequired,
  length: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  small: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};
IconButtonLoad.defaultProps = {
  className: {},
  classes: {},
  icon: <PhotoCameraIcon />,
  title: 'Undefined',
  tooltip: 'Undefined',
  small: false,
  disabled: false,
  loading: false,
  length: 0,
  left_right: false,
  bottom_top: false,
  color: null,
  IconButtonProps: {
    color: 'inherit',
  },
  badge_style: {},
};
export default withStyles(IconButtonLoad, useStyles);
