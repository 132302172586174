import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@mui/material';
import {
  ButtonsAcceptDelete,
  CircularProgressWaiting,
  GroupBox,
} from 'components';
import { QueryRequestUser } from 'graphql/Users/utils_request';
import { AcceptRequest, DeleteRequest } from 'graphql/Users';

import { GetData, ServerErrorsString } from 'helpers';
class GroupRequestUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      request: null,
      accepting: false,
      deleting: false,
    };
  }
  componentDidMount() {
    this.updateRequest();
  }
  componentDidUpdate() {
    if (this.props.loading) {
      this.updateRequest();
    }
  }
  updateRequest = () => {
    const { user } = this.props;
    if (!user || user === undefined || user.isActive) return null;
    const { loading } = this.state;
    if (loading) return;
    this.setState({ loading: true });
    (async () => {
      QueryRequestUser(user.id)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, request } = data.usersRequest;
          if (ok) {
            this.setState({ loading: false, request });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          this.setState({ loading: false });
        });
    })();
  };
  handleAcceptUsers = () => {
    const { accepting, request } = this.state;
    console.log({ accepting, request });
    if (accepting || !request) return;

    this.setState({ accepting: true });
    (async () => {
      AcceptRequest(request.idRequest)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.registerRequest;
          if (ok) {
            this.setState({ accepting: false });
            this.props.handleUserRequest(request.idUser, true);
          } else {
            throw errors;
          }
        })
        .catch(error => {
          error = ServerErrorsString(error);

          this.setState({ accepting: false, error });
          if (error.startsWith('The request is not here')) {
            this.props.handleUserRequest(request.idRequest, true);
          }
          this.props.handleAddError(error);
        });
    })();
  };
  handleDeleteUsers = () => {
    const { deleting, request } = this.state;
    if (deleting || !request) return;
    this.setState({ deleting: true });

    (async () => {
      DeleteRequest(request.idRequest)
        .then(res => {
          const data = GetData(res);
          const { ok, errors } = data.deleteRequest;
          if (ok) {
            this.props.handleUserRequest(request.idUser, false);
            this.setState({ deleting: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ deleting: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  render() {
    const { user } = this.props;
    if (!user || user === undefined || user.isActive) return null;
    const { loading, request } = this.state;
    let Content = null;
    if (loading) {
      Content = <CircularProgressWaiting />;
    } else if (request) {
      Content = (
        <Grid
          alignItems="stretch"
          container
          direction="column"
          justifyContent="center">
          <Grid item sx={{ width: '100%' }} xs={12}>
            <TextField
              defaultValue={request.Message}
              fullWidth
              id="reason-of-request"
              InputProps={{
                readOnly: true,
              }}
              label="Reason of the request"
              size="small"
              style={{ marginTop: 10 }}
            />
          </Grid>
          <Grid item xs={12}>
            <ButtonsAcceptDelete
              accepting={this.state.accepting}
              deleting={this.state.deleting}
              handleAcceptUsers={this.handleAcceptUsers}
              handleDeleteUsers={this.handleDeleteUsers}
            />
          </Grid>
        </Grid>
      );
    }
    return <GroupBox title_box="User Request ">{Content}</GroupBox>;
  }
}

GroupRequestUser.propTypes = {
  user: PropTypes.object.isRequired,
};

export default GroupRequestUser;
