export const FETCH_POSTS = 'FETCH_POSTS';
export const NEW_POST = 'NEW_POST';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_ERRORS = 'LOGIN_ERRORS';
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_PREV_USER_ID = 'LOGIN_PREV_USER_ID';
export const LOGIN_USER_UPDATE = 'LOGIN_USER_UPDATE';

export const LOGOUT_USER_MANAGER = 'LOGOUT_USER_MANAGER';
export const LOGOUT_USER_LAYAOUT = 'LOGOUT_USER_LAYAOUT';
export const LOGOUT_USER_NOTIFICATION = 'LOGOUT_USER_NOTIFICATION';
export const LOGOUT_USER_PAGE_DATABASE = 'LOGOUT_USER_PAGE_DATABASE';
export const LOGOUT_USER_PAGE_EXPLORER = 'LOGOUT_USER_PAGE_EXPLORER';
export const LOGOUT_USER_PAGE_PROJECT = 'LOGOUT_USER_PAGE_PROJECT';
export const LOGOUT_USER_PAGE_PROJECTS = 'LOGOUT_USER_PAGE_PROJECTS';
export const LOGOUT_USER_PAGE_TEAMS = 'LOGOUT_USER_PAGE_TEAMS';
export const LOGOUT_USER_PAGE_UPLOAD_DOWNLOADS =
  'LOGOUT_USER_PAGE_UPLOAD_DOWNLOADS';
export const LOGOUT_USER_CREATE_PROJECT = 'LOGOUT_USER_CREATE_PROJECT';
export const LOGOUT_USER_REGISTER = 'LOGOUT_USER_REGISTER';
export const LOGOUT_USER_PAGE_SETTING = 'LOGOUT_USER_PAGE_SETTING';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';
export const UPDATE_AVATAR_ERROR = 'UPDATE_AVATAR_ERROR';
export const UPDATE_USER_SETTING = 'UPDATE_USER_SETTING';

export const CREATE_PROJECT_TYPE = 'CREATE_PROJECT_TYPE';
export const CREATE_PROJECT_SETTINGS = 'CREATE_PROJECT_SETTINGS';
export const CREATE_PROJECT_USERS = 'CREATE_PROJECT_USERS';
export const CREATE_PROJECT_TEAMS = 'CREATE_PROJECT_TEAMS';
export const CREATE_PROJECT_SETTINGS_IMAGE_TYPES =
  'CREATE_PROJECT_SETTINGS_IMAGE_TYPES';
export const CREATE_PROJECT_SETTINGS_FOLDER_STRUCTURE =
  'CREATE_PROJECT_SETTINGS_FOLDER_STRUCTURE';
export const CREATE_PROJECT_SETTINGS_NOTIFICATIONS =
  'CREATE_PROJECT_SETTINGS_NOTIFICATIONS';
export const CREATE_PROJECT_SETTINGS_SITES = 'CREATE_PROJECT_SETTINGS_SITES';
export const CREATE_PROJECT_CREATED = 'CREATE_PROJECT_CREATED';

export const PAGE_TEAMS_UPDATE_TEAMS = 'PAGE_TEAMS_UPDATE_TEAMS';
export const PAGE_TEAMS_SET_CURRENT_TEAM = 'PAGE_TEAMS_SET_CURRENT_TEAM';
export const PAGE_TEAMS_UPDATE_TEAM = 'PAGE_TEAMS_UPDATE_TEAM';
export const PAGE_TEAMS_UPDATE_TEAM_ERRORS = 'PAGE_TEAMS_UPDATE_TEAM_ERRORS';
export const PAGE_TEAMS_UPDATE_TEAM_MEMBERS = 'PAGE_TEAMS_UPDATE_TEAM_MEMBERS';
export const PAGE_TEAMS_UPDATE_TEAM_AVATAR = 'PAGE_TEAMS_UPDATE_TEAM_AVATAR';
export const PAGE_TEAMS_LOAD_TEAMS = 'PAGE_TEAMS_LOAD_TEAMS';
export const PAGE_TEAMS_LOGOUT = 'PAGE_TEAMS_LOGOUT';

export const PAGE_PROJECTS_UPDATE_PROJECTS = 'PAGE_PROJECTS_UPDATE_PROJECTS';
export const PAGE_PROJECT_UPDATE_PROJECT_ERRORS =
  'PAGE_PROJECT_UPDATE_PROJECT_ERRORS';
export const PAGE_PROJECTS_LOAD_PROJECTS = 'PAGE_PROJECTS_LOAD_PROJECTS';
export const PAGE_PROJECTS_LOGOUT = 'PAGE_PROJECTS_LOGOUT';
export const PAGE_PROJECTS_SET_PROJECT = 'PAGE_PROJECTS_SET_PROJECT';
export const PAGE_PROJECTS_SAVING = 'PAGE_PROJECTS_SAVING';
export const PAGE_PROJECTS_WARNING = 'PAGE_PROJECTS_WARNING';
export const PAGE_PROJECTS_SET_NOTIFICATIONS =
  'PAGE_PROJECTS_SET_NOTIFICATIONS';
export const PAGE_PROJECTS_INIT = 'PAGE_PROJECTS_INIT';
export const PAGE_PROJECTS_PROJECT_TEAMS = 'PAGE_PROJECTS_PROJECT_TEAMS';

export const PAGE_EXPLORER_BOTTOM_BAR_FOLDERS =
  'PAGE_EXPLORER_BOTTOM_BAR_FOLDERS';
export const PAGE_EXPLORER_UPLOAD_PROGRESS = 'PAGE_EXPLORER_UPLOAD_PROGRESS';
export const PAGE_EXPLORER_UPLOAD_PROGRESS_FILES =
  'PAGE_EXPLORER_UPLOAD_PROGRESS_FILES';
export const PAGE_EXPLORER_UPLOAD_TOTAL_FILES =
  'PAGE_EXPLORER_UPLOAD_TOTAL_FILES';
export const PAGE_EXPLORER_UPLOAD_RESET = 'PAGE_EXPLORER_UPLOAD_RESET';
export const LAYOUT_SET = 'LAYOUT_SET';

export const NOTIFICATION_NEW_SHARE_FOLDER = 'NOTIFICATION_NEW_SHARE_FOLDER';
export const NOTIFICATION_DELETE_SHARE_FOLDER =
  'NOTIFICATION_DELETE_SHARE_FOLDER';
export const NOTIFICATION_NEW_CHAT = 'NOTIFICATION_NEW_CHAT';
export const NOTIFICATION_NEW_CHAT_MESSAGE = 'NOTIFICATION_NEW_CHAT_MESSAGE';
export const NOTIFICATION_NEW_CHAT_MESSAGE_READ =
  'NOTIFICATION_NEW_CHAT_MESSAGE_READ';
export const NOTIFICATION_ADD_CHAT_MESSAGES = 'NOTIFICATION_ADD_CHAT_MESSAGES';
export const NOTIFICATION_NEW = 'NOTIFICATION_NEW';
export const NOTIFICATION_SET = 'NOTIFICATION_SET';
export const NOTIFICATION_RELOAD = 'NOTIFICATION_RELOAD';

export const MANAGER_CLEAN_COPY_CUT = 'MANAGER_CLEAN_COPY_CUT';
export const MANAGER_COPY_SERIES = 'MANAGER_COPY_SERIES';
export const MANAGER_COPY_FILES = 'MANAGER_COPY_FILES';
export const MANAGER_COPY = 'MANAGER_COPY';
export const MANAGER_JOB_SERIES_COPY = 'MANAGER_JOB_SERIES_COPY';
export const MANAGER_JOB_FILES_COPY = 'MANAGER_JOB_FILES_COPY';
export const MANAGER_JOB_PROCESSES_NEW = 'MANAGER_JOB_PROCESSES_NEW';
export const MANAGER_CUT = 'MANAGER_CUT';
export const MANAGER_SET_CUT = 'MANAGER_SET_CUT';
export const MANAGER_SET_COPY = 'MANAGER_SET_COPY';
export const MANAGER_PASTE_START = 'MANAGER_PASTE_START';
export const MANAGER_PASTE_END = 'MANAGER_PASTE_END';
export const MANAGER_CLEAN_ERROR = 'MANAGER_CLEAN_ERROR';
export const MANAGER_ADD_LANDING = 'MANAGER_ADD_LANDING';

export const PAGE_UPLOAD_DOWNLOADS_ADD = 'PAGE_UPLOAD_DOWNLOADS_ADD';
export const PAGE_UPLOAD_DOWNLOADS = 'PAGE_UPLOAD_DOWNLOADS';

export const PAGE_DATABASE_VIEWTYPE = 'PAGE_DATABASE_VIEWTYPE';
export const PAGE_DATABASE_DISPLAYTYPE = 'PAGE_DATABASE_DISPLAYTYPE';
export const PAGE_DATABASE_STUDY_SELECTED = 'PAGE_DATABASE_STUDY_SELECTED';
export const PAGE_DATABASE_SERIES_SEQUENCE = 'PAGE_DATABASE_SERIES_SEQUENCE';
export const PAGE_DATABASE_QUERY_LEVEL = 'PAGE_DATABASE_QUERY_LEVEL';
export const PAGE_DATABASE_QUERY_PROJECT = 'PAGE_DATABASE_QUERY_PROJECT';
export const PAGE_DATABASE_PATIENTS_IDS = 'PAGE_DATABASE_PATIENTS_IDS';

export const PAGE_EXPLORER_VIEWTYPE = 'PAGE_EXPLORER_VIEWTYPE';
export const PAGE_EXPLORER_OPEN = 'PAGE_EXPLORER_OPEN';
export const PAGE_EXPLORER_SET_HOME = 'PAGE_EXPLORER_SET_HOME';
export const PAGE_EXPLORER_SET_HOME_SUB_FOLDER =
  'PAGE_EXPLORER_SET_HOME_SUB_FOLDER';
export const PAGE_EXPLORER_SET_NODE_ID = 'PAGE_EXPLORER_SET_NODE_ID';
export const PAGE_EXPLORER_STATUS_SET = 'PAGE_EXPLORER_STATUS_SET';
export const PAGE_EXPLORER_STATUS_LIMIT = 'PAGE_EXPLORER_STATUS_LIMIT';
export const PAGE_EXPLORER_STATUS_PAGE = 'PAGE_EXPLORER_STATUS_PAGE';
export const PAGE_EXPLORER_CHANGED = 'PAGE_EXPLORER_CHANGED';
export const PAGE_EXPLORER_FOLDER_BAR_CLICKED =
  'PAGE_EXPLORER_FOLDER_BAR_CLICKED';

export const PAGE_SHARED_SET_SUBFOLDERS = 'PAGE_SHARED_SET_SUBFOLDERS';

export const PAGE_PROJECT_UPDATE_SUBFOLDERS = 'PAGE_PROJECT_UPDATE_SUBFOLDERS';

export const PAGE_SETTING_DASHBOARD_SET = 'PAGE_SETTING_DASHBOARD_SET';
export const PAGE_SETTING_DASHBOARD_MODE = 'PAGE_SETTING_DASHBOARD_MODE';
export const PAGE_SETTING_DASHBOARD_UPDATE = 'PAGE_SETTING_DASHBOARD_UPDATE';
export const PAGE_SETTING_DASHBOARD_PAGE_DESIGNER =
  'PAGE_SETTING_DASHBOARD_PAGE_DESIGNER';

export const PAGE_DICOM_DISPLAY_SERIES = 'PAGE_DICOM_DISPLAY_SERIES';
export const PAGE_DICOM_DISPLAY_STUDY = 'PAGE_DICOM_DISPLAY_STUDY';
export const PAGE_DICOM_DISPLAY_MULTI_SERIES =
  'PAGE_DICOM_DISPLAY_MULTI_SERIES';
export const LOGOUT_DICOM_VIEWER = 'LOGOUT_DICOM_VIEWER';

export const LOGOUT_USER_PAGE_GLOBAL = 'LOGOUT_USER_PAGE_GLOBAL';
export const PAGE_GLOBAL_SET_PAGE = 'PAGE_GLOBAL_SET_PAGE';
export const PAGE_GLOBAL_OPEN_SIDEBAR = 'PAGE_GLOBAL_OPEN_SIDEBAR';
export const PAGE_GLOBAL_SET_SKIP_ONLINE = 'PAGE_GLOBAL_SET_SKIP_ONLINE';
export const PAGE_GLOBAL_MIN_SIDEBAR = 'PAGE_GLOBAL_MIN_SIDEBAR';
export const PAGE_GLOBAL_IS_DICOM_VIEWER = 'PAGE_GLOBAL_IS_DICOM_VIEWER';

export const RUNNING_PROCESSES = 'RUNNING_PROCESSES';

export const PAGE_PIPELINE_BLOCKS = 'PAGE_PIPELINE_BLOCKS';
export const LOGOUT_PAGE_PIPELINE_BLOCKS = 'LOGOUT_PAGE_PIPELINE_BLOCKS';
