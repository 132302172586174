import { vec3, quat } from 'gl-matrix';
import moment from 'moment';
import { IsInvalid } from 'helpers';
export const volumeLoaderScheme = 'cornerstoneStreamingImageVolume'; // Loader id which defines which volume loader to use
export const orientationLetters = (normal, viewUp) => {
  const crossN_Up = vec3.cross(vec3.create(), normal, viewUp);
  const planeVectors = [viewUp, crossN_Up];
  return orientationLettersFromPlaneVectors(planeVectors);
};
export const orientationLettersImOriPat = (ImageOrientationPatient) => {
  let rowVector = [0, 0, 0];
  let colVector = [0, 0, 0];
  if (
    Array.isArray(ImageOrientationPatient) && // Value is an array
    ImageOrientationPatient.length === 6 // Array has 6 elements
  ) {
    if (ImageOrientationPatient.every((v) => typeof v === 'number')) {
      rowVector = [
        ImageOrientationPatient[0],
        ImageOrientationPatient[1],
        ImageOrientationPatient[2],
      ];
      colVector = [
        ImageOrientationPatient[3],
        ImageOrientationPatient[4],
        ImageOrientationPatient[5],
      ];
    } else {
      rowVector = [
        parseFloat(ImageOrientationPatient[0]),
        parseFloat(ImageOrientationPatient[1]),
        parseFloat(ImageOrientationPatient[2]),
      ];
      colVector = [
        parseFloat(ImageOrientationPatient[3]),
        parseFloat(ImageOrientationPatient[4]),
        parseFloat(ImageOrientationPatient[5]),
      ];
    }
  } else {
    return [0, 0, 0, 0];
  }
  return orientationLettersFromPlaneVectors([
    colVector.map((x) => x * -1),
    rowVector.map((x) => x * -1),
  ]);
};
export const orientationLettersFromPlaneVectors = (planeVectors) => {
  let orientation = [0, 0, 0, 0];
  for (let i = 0; i < 4; i++) {
    const vec = planeVectors[i % 2];
    const inv = i > 1;

    const textOrietnation = computeVectorOrientation(vec, inv);

    orientation[i] = textOrietnation;
  }
  return orientation;
};
const computeVectorOrientation = (vec, inv = false) => {
  let orientationX = '';
  let orientationY = '';
  let orientationZ = '';
  let textOrietnation = '';

  if (inv) {
    orientationX = -vec[0] < 0 ? 'R' : 'L';
    orientationY = -vec[1] < 0 ? 'A' : 'P';
    orientationZ = -vec[2] < 0 ? 'I' : 'S';
  } else {
    orientationX = vec[0] < 0 ? 'R' : 'L';
    orientationY = vec[1] < 0 ? 'A' : 'P';
    orientationZ = vec[2] < 0 ? 'I' : 'S';
  }

  let absX = Math.abs(vec[0]);
  let absY = Math.abs(vec[1]);
  let absZ = Math.abs(vec[2]);

  // get first 3 AXIS
  for (let i = 0; i < 3; i++) {
    if (absX > 0.2 && absX >= absY && absX >= absZ) {
      textOrietnation += orientationX;
      absX = 0;
    } else if (absY > 0.2 && absY >= absX && absY >= absZ) {
      textOrietnation += orientationY;
      absY = 0;
    } else if (absZ > 0.2 && absZ >= absX && absZ >= absY) {
      textOrietnation += orientationZ;
      absZ = 0;
    }
  }
  return textOrietnation;
};

export const GetConerDate = (corner_date, format = 'YYYY-MM-DD') => {
  if (IsInvalid(corner_date)) return 'N/A';
  const { day, month, year } = corner_date;
  const dateString = `${day}/${month}/${year}`;
  return moment(dateString, 'D/M/YYYY').format(format);
};
export const GetConerTime = (corner_time, format = 'HH:mm:ss') => {
  if (IsInvalid(corner_time)) return 'N/A';
  // Extract time components
  const { fractionalSeconds, hours, minutes, seconds } = corner_time;
  // Construct a valid time string
  const timeString = `${hours}:${minutes}:${seconds}`;
  // Append fractional seconds if they exist
  if (fractionalSeconds) {
    const timeWithFractionalSeconds = fractionalSeconds
      ? `${timeString}.${fractionalSeconds}`
      : timeString;
    // Parse and format the time
    return moment(timeWithFractionalSeconds, 'HH:mm:ss.SSS').format(format);
  }
  return moment(timeString, 'HH:mm:ss').format(format);
};
export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const rotateCamera = (viewPlaneNormal, viewUp, degrees) => {
  // Normalize the input vectors to ensure proper rotation
  const normalizedNormal = vec3.create();
  vec3.normalize(normalizedNormal, viewPlaneNormal);

  const normalizedViewUp = vec3.create();
  vec3.normalize(normalizedViewUp, viewUp);

  // Convert degrees to radians
  const radians = (degrees * Math.PI) / 180;

  // Create a quaternion for rotation around viewPlaneNormal
  const rotationQuat = quat.create();
  quat.setAxisAngle(rotationQuat, normalizedNormal, radians);

  // Rotate viewUp around viewPlaneNormal
  const rotatedViewUp = vec3.create();
  vec3.transformQuat(rotatedViewUp, normalizedViewUp, rotationQuat);

  // Ensure the result is normalized (should be, but floating-point precision can drift)
  vec3.normalize(rotatedViewUp, rotatedViewUp);

  return {
    viewPlaneNormal: [...viewPlaneNormal], // Return original normal unchanged
    viewUp: rotatedViewUp,
  };
};
