import React from 'react';
import PropTypes from 'prop-types';
import { Badge, IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { IsInvalid } from 'helpers';
import {
  setDisplayDicomMultiSeries,
  setDisplayDicomStudy,
} from 'redux-store/actions';
import { connect } from 'react-redux';
const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: -2,
    top: -2,
    border: '2px solid #333',
    padding: '0 2px',
    backgroundColor: '#673ab7',
    width: 15, // Set circle width to 15px
    height: 15, // Set circle height to 15px
    minWidth: 15, // Ensure it
    fontSize: 8,
  },
}));
class RightToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleDelete = () => {
    let { droppedItems, selectedItems } = this.props;
    droppedItems = droppedItems.filter(
      item => !selectedItems.includes(item.idSeries)
    );
    this.props.setState({
      droppedItems,
      selectedItems: [],
    });
    this.props.onDroppedSeries(droppedItems);
  };
  render() {
    const { classes, selectedItems } = this.props;
    const enable = selectedItems.length > 0;
    return (
      <div className={classes.pagination}>
        <div>Series at Viewer</div>
        <Tooltip title="Delete Series">
          <span>
            <IconButton
              aria-label="delete-series"
              color="inherit"
              disabled={!enable}
              onClick={this.handleDelete}
              size="small">
              <StyledBadge
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                badgeContent={selectedItems.length}
                color="error"
                size="small">
                <DeleteIcon
                  size="small"
                  sx={{
                    width: 15,
                    height: 15,
                    color: enable ? 'red' : 'gray',
                  }}
                />
              </StyledBadge>
            </IconButton>
          </span>
        </Tooltip>
        <span style={{ cursor: 'pointer' }}>▶</span>
      </div>
    );
  }
}

RightToolbar.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = state => {
  let { study, ListSeries } = state.page_dicom_viewer;
  if (IsInvalid(ListSeries)) {
    ListSeries = [];
  }
  return { study, ListSeries };
};

export default connect(mapStateToProps, {
  setDisplayDicomMultiSeries,
  setDisplayDicomStudy,
})(RightToolbar);
