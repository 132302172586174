import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { withRouter } from 'react-router-dom';

const useStyles = () => ({
  main_button: {
    palette: {
      primary: 'white',
      secondary: 'white',
    },
    cursor: 'pointer',
    maxWidth: '40px',
  },
  main_text: {
    marginTop: '4px',
    marginLeft: '10px',
    fontFamily: 'Arial',
    fontWeight: 700,
    palette: {
      primary: 'white',
      secondary: 'white',
    },
    cursor: 'pointer',
  },
  white: {
    color: 'white',
  },
  black: {
    color: '#787878',
  },
});

class MedicalBlocksLogo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color_to: 'white',
    };
  }

  componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener('scroll', this.headerColorChange);
    }
  }

  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener('scroll', this.headerColorChange);
    }
  }

  headerColorChange = () => {
    const { changeColorOnScroll } = this.props;
    const { color_to } = this.state;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      if (color_to === 'white') {
        this.setState({ color_to: 'black' });
      }
    } else {
      if (color_to === 'black') {
        this.setState({ color_to: 'white' });
      }
    }
  };
  handleLogoClick = () => {
    this.props.history.push('/');
    this.props.onClick('/');
  };
  render() {
    const { classes, max_width, show_text } = this.props;
    const { color_to } = this.state;
    let ComponentText = null;
    if (show_text) {
      ComponentText = (
        <Typography
          className={clsx({
            [classes.main_text]: true,
            [classes.white]: color_to === 'white',
            [classes.black]: color_to !== 'white',
          })}
          onClick={this.handleLogoClick}
          variant="h3">
          Medical-Blocks
        </Typography>
      );
    }
    return (
      <Grid
        alignItems="center"
        container
        direction="row"
        justifyContent="flex-start"
        sx={{ maxWidth: max_width }}>
        <AccountBalanceIcon
          className={clsx({
            [classes.main_button]: true,
            [classes.white]: color_to === 'white',
            [classes.black]: color_to !== 'white',
          })}
          fontSize="large"
        />
        {ComponentText}
      </Grid>
    );
  }
}

MedicalBlocksLogo.propTypes = {
  classes: PropTypes.object.isRequired,
};
MedicalBlocksLogo.defaultProps = {
  changeColorOnScroll: null,
  show_text: true,
  max_width: '100%',
  onClick: () => '',
};
export default withStyles(withRouter(MedicalBlocksLogo), useStyles);
