import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Grid } from '@mui/material';

class CircularProgressWaiting extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      style,
      direction,
      className,
      sx,
      spacing,
      size,
      sx_circle,
    } = this.props;
    return (
      <Grid
        alignItems="center"
        className={className}
        container
        direction={direction}
        item
        justifyContent="center"
        spacing={spacing}
        style={style ? style : undefined}
        sx={sx}
        xs={12}>
        <CircularProgress size={size} sx={sx_circle} />
      </Grid>
    );
  }
}

CircularProgressWaiting.propTypes = {
  classes: PropTypes.object,
};
CircularProgressWaiting.defaultProps = {
  direction: 'row',
};
export default CircularProgressWaiting;
