import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TablePagination,
  //  Divider
} from '@mui/material';
import TableGridHeader from '../TableGridHeader';
import TableGridRow from '../TableGridRow';
import { SelectedArray, rowsPerPageSmall } from 'helpers';
import { v1 as uuid } from 'uuid';
import clsx from 'clsx';
import TableGridRowLoading from '../TableGridRowLoading';
import { withStyles } from 'tss-react/mui';
import { DialogShowColumns, useStyles, keydown } from './components';
import {
  getFinalRows,
  getCellValue,
  getHeaders,
  CopyToClipboard,
  getSortFunc,
  getSortRows,
} from './utils_table_grid';
import {
  getIdValue,
  getCleanRow,
  findMinMaxIndices,
  GetSelectedBox,
  IsInsideSelectedBox,
  getSyleSelectedBox,
} from '../table_utils';
import { IsInvalid } from 'helpers';

class TableGrid extends React.Component {
  constructor(props) {
    super(props);
    let {
      page,
      rowsPerPage,
      external_selected,
      external_edited,
      filter,
      headers_to_hide,
    } = props;
    let key = '';
    if (filter) {
      key = filter.key;
    } else {
      filter = null;
    }
    this.state = {
      order: '',
      orderBy: '',
      selected: external_selected ? external_selected : [],
      page: page ? page : 0,
      limit: rowsPerPage,
      shiftPress: false,
      rowHighlight: null,
      rowClicked: null,
      rows_edited: external_edited ? external_edited : [],
      headers_to_hide: headers_to_hide ? headers_to_hide : [],
      open_show_column: false,
      filter,
      key,
      row_cell_clicked: null,
      selectedCells: [],
      cell_init: null,
    };
    this.currentRows = null;
    if (props.holder) {
      props.holder.cleanHighlight = () =>
        this.setState({ rowHighlight: null, rowClicked: null });
      props.holder.setHighlight = rowHighlight =>
        this.setState({ rowHighlight, rowClicked: rowHighlight });
    }
    this.holder = {
      onAllSelectChanged: [],
      IsItemSelected: row => this.handleIsItemSelected(row),
    };
  }
  componentDidMount() {
    window.addEventListener('keydown', keydown, false);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', keydown);
  }
  componentDidUpdate() {
    const { selected, rows_edited } = this.state;
    const { external_selected, external_edited } = this.props;
    if (external_selected && external_selected.length !== selected.length) {
      this.setState({ selected: external_selected });
    }
    if (external_edited && external_edited.length !== rows_edited.length) {
      this.setState({ rows_edited: external_edited });
    }
  }
  handleRowEdited = (edit, row) => {
    let { rows_edited } = this.state;
    const index = this.getIndexAtRow(rows_edited, row);
    if (!edit && index === -1) return;
    if (!edit) {
      rows_edited.splice(index, 1);
    } else if (index === -1) {
      rows_edited.push({ ...row });
    } else {
      rows_edited[index] = { ...row };
    }
    this.setState({ rows_edited });
    const selectedIndex = this.getIndexAtRow(this.state.selected, { ...row });
    this.toggleSelection(row, selectedIndex);
    this.props.onRowEditedChanged(rows_edited);
  };
  handleSelectAllClick = () => {
    let { selected } = this.state;
    if (selected.length) {
      selected = [];
    } else {
      selected = getFinalRows(this.props.rows, this.state, this.props);
    }
    this.setState({ selected }, () => {
      const onSelections = this.holder.onAllSelectChanged;
      let isSelected = selected.length ? true : false;
      for (let i = 0; i < onSelections.length; i++) {
        onSelections[i](isSelected);
      }
      this.props.onSelectedChanged(selected);
    });
  };

  getIndexAtRow = (rows, row) => {
    return rows
      .map(x => getIdValue(x, this.props))
      .indexOf(getIdValue(row, this.props));
  };
  handleIsItemSelected = row => {
    const { selected } = this.state;
    const index = this.getIndexAtRow(selected, row);
    return index !== -1;
  };
  handleIsRowSelected = row => {
    const { rowHighlight } = this.state;
    if (!rowHighlight) return false;
    return getIdValue(row, this.props) === getIdValue(rowHighlight, this.props);
  };
  IsRowClicked = row => {
    const { rowClicked } = this.state;
    if (!rowClicked) return false;
    return getIdValue(row, this.props) === getIdValue(rowClicked, this.props);
  };
  handleSelectItemClicked = row => {
    const { selected } = this.state;
    const index = selected ? this.getIndexAtRow(selected, row) : 0;
    const newSelected = SelectedArray(selected, row, index);
    this.setState({ selected: newSelected });
    this.props.onSelectedChanged(newSelected);
  };
  handleChangePage = (event, newPage) => {
    event.preventDefault();
    this.setState({ page: newPage });
    if (this.props.onPageChange) {
      this.props.onPageChange(newPage);
    }
  };
  handleChangeRowsPerPage = event => {
    event.preventDefault();
    let limit = +event.target.value;
    this.setState({ page: 0, limit });
    if (this.props.onRowsPerPageChange) {
      this.props.onRowsPerPageChange(limit);
    }
  };
  handleSortHeaderClicked = (orderBy, order) => {
    this.setState({ orderBy, order });
    this.props.onSortHeaderClicked(orderBy, order);
  };

  handleShowHideColumn = header => {
    if (header === undefined || !header) return;
    let { headers_to_hide } = this.state;
    const index = headers_to_hide.map(x => x.field).indexOf(header.field);
    if (index === -1) {
      headers_to_hide.push(header);
    } else {
      headers_to_hide.splice(index, 1);
    }
    this.setState({ headers_to_hide });
  };
  handleClickHideColumn = headers_to_hide => {
    this.setState({ headers_to_hide });
  };
  handleClickShowColumn = () => {
    this.setState({ open_show_column: true });
  };
  keyDownHandler = event => {
    if (!this.currentRows) return;
    const total_rows = this.currentRows.length;
    const { key, ctrlKey, metaKey } = event;
    if ((ctrlKey || metaKey) && key) {
      if (key.toLowerCase() === 'v') {
        /** empty */
      } else if (key.toLowerCase() === 'c') {
        const { rows, headers } = this.props;
        CopyToClipboard(rows, this.state.selectedCells, headers);
      }
      // Ctrl+V was detected
      // console.log('Ctrl+V detected');
    }
    let { rowHighlight, row_cell_clicked } = this.state;
    const { cell_selection } = this.props;
    if (key === 'Shift') {
      this.setState({ shiftPress: true });
    } else if (key === 'ArrowDown' && rowHighlight) {
      let index = this.getIndexAtRow(this.currentRows, rowHighlight);
      if (index !== -1) {
        index++;
        if (index >= total_rows - 1) {
          index = total_rows - 1;
        }
        rowHighlight = this.currentRows[index];
        if (cell_selection && row_cell_clicked)
          row_cell_clicked = {
            cell: row_cell_clicked.cell,
            row: rowHighlight,
          };
        this.setState({
          rowHighlight,
          row_cell_clicked,
          rowClicked: this.currentRows[index],
        });
        this.props.onCellClicked(row_cell_clicked);
      } else {
        this.setState({ rowHighlight: null });
      }
      this.props.onHighlightChange(rowHighlight);
    } else if (key === 'ArrowUp' && rowHighlight) {
      let index = this.getIndexAtRow(this.currentRows, rowHighlight);
      if (index !== -1) {
        index--;
        if (index < 0) {
          index = 0;
        }
        rowHighlight = this.currentRows[index];
        if (cell_selection && row_cell_clicked)
          row_cell_clicked = {
            cell: row_cell_clicked.cell,
            row: rowHighlight,
          };
        this.setState({
          rowHighlight,
          row_cell_clicked,
          rowClicked: this.currentRows[index],
        });
        this.props.onCellClicked(row_cell_clicked);
      } else {
        this.setState({ rowHighlight: null });
      }
      this.props.onHighlightChange(rowHighlight);
    } else if (key === 'ArrowLeft') {
      if (cell_selection && row_cell_clicked) {
        const cells = Object.keys(row_cell_clicked.row);
        let i = cells.indexOf(row_cell_clicked.cell);
        i--;
        let min_cell = 0;
        if (this.props.checkboxSelection) {
          min_cell = 1;
        }
        if (i >= min_cell && i < cells.length) {
          row_cell_clicked.cell = cells[i];
          this.setState({ row_cell_clicked });
          this.props.onCellClicked(row_cell_clicked);
        }
      }
    } else if (key === 'ArrowRight') {
      if (cell_selection && row_cell_clicked) {
        const cells = Object.keys(row_cell_clicked.row);
        let i = cells.indexOf(row_cell_clicked.cell);
        i++;
        if (i >= 0 && i < cells.length) {
          row_cell_clicked.cell = cells[i];
          this.setState({ row_cell_clicked });
          this.props.onCellClicked(row_cell_clicked);
        }
      }
    }
  };
  keyUpHandler = event => {
    const { key } = event;
    if (key === 'Shift') {
      this.setState({ shiftPress: false });
    }
  };

  toggleSelection = (row, index) => {
    const editedRow = this.state.rows_edited;
    let clickedRowIsEdited = editedRow.some(value => value.id == row.id);
    let selectedRows = this.state.selected;
    if (clickedRowIsEdited) {
      const selectedIndex = selectedRows.findIndex(
        selected => selected.id == row.id
      );
      if (selectedIndex === -1) {
        selectedRows.push({ ...row });
      } else {
        selectedRows[selectedIndex] = { ...row };
      }
    } else if (index !== -1) {
      selectedRows.splice(index, 1);
    }
    this.setState({ selected: selectedRows });
  };

  handleRowClicked = row => {
    let { rowHighlight, rowClicked } = this.state;
    if (!rowClicked) {
      rowClicked = row;
    } else if (
      getIdValue(rowClicked, this.props) !== getIdValue(row, this.props)
    ) {
      rowClicked = row;
    }
    if (
      rowHighlight &&
      getIdValue(rowHighlight, this.props) === getIdValue(row, this.props)
    ) {
      rowHighlight = null;
    } else {
      rowHighlight = row;
    }
    this.setState({
      rowHighlight,
      rowClicked,
    });
    this.props.onRowClicked(rowHighlight);
  };
  handleSearchFilterChanged = filter => {
    let key = null;
    if (filter) key = filter.key;
    this.setState({ filter, key });
    this.props.onFilterChanded(filter);
  };
  getRowStyle = row => {
    const { row_styles } = this.props;
    if (row_styles && row_styles.length) {
      let index = this.getIndexAtRow(row_styles, row);
      if (index !== -1) {
        return row_styles[index];
      }
    }
    return null;
  };
  hanldeCellSingleClick = row_cell_clicked => {
    this.setState({ row_cell_clicked });
    this.props.onCellClicked(row_cell_clicked);
  };
  handleOnCellPaste = (row_cell, text) => {
    const { paste } = this.props;
    if (IsInvalid(paste)) return;
    if (IsInvalid(row_cell)) return;
    let rowsAndColumns = [];
    let { row, cell } = row_cell;
    if (text && text === '') {
      /** empty */
    } else {
      const lines = text.split('\n');
      for (const line of lines) {
        const columns = line.split('\t'); // Change '\t' to your desired delimiter
        rowsAndColumns.push(columns);
      }
    }
    const keys = Object.keys(row);
    let { rows_edited } = this.state;
    const row_init = this.getIndexAtRow(this.props.rows, row);
    let new_rows = [];
    for (let i = 0; i < rowsAndColumns.length; i++) {
      const columns = rowsAndColumns[i];
      let index = keys.indexOf(cell);
      for (let j = 0; j < columns.length; j++) {
        if (j >= keys.length) continue;
        row[keys[index]] = columns[j];
        index = index + 1;
      }
      const index_edit = this.getIndexAtRow(rows_edited, row);
      if (index_edit === -1) {
        rows_edited.push({ ...row });
      } else {
        rows_edited[index_edit] = {
          ...row,
        };
      }
      const next_row = row_init + i + 1;
      let total = this.props.rows.length; //+ new_rows.length;
      if (i + 1 >= rowsAndColumns.length) {
        continue;
      }
      if (next_row >= total) {
        let { idRow } = this.props;
        if (row && row.id) {
          idRow = 'id';
        }
        row = {
          ...getCleanRow(row, this.props),
          [idRow]: total + new_rows.length,
        };
        new_rows.push(row);
      } else {
        row = this.props.rows[next_row];
      }
    }
    if (new_rows.length) {
      this.props.onAddNewRows(new_rows);
    }
    this.setState({ rows_edited });
  };
  handleCellMouseDown = (event, row, col) => {
    if (!this.props.grid) return;
    // Start a new selection by clearing the selectedCells array
    this.setState({ selectedCells: [{ row, col }], cell_init: { row, col } });
    document.body.classList.add('disable-select');
  };

  handleCellMouseMove = (event, row, col) => {
    if (!this.props.grid) return;
    let { selectedCells, cell_init } = this.state;
    // If the mouse button is pressed (mousedown event was triggered)
    // and the current cell is not already selected, update the selection
    // const here = selectedCells.find(
    //   cell => cell.row === row && cell.col === col
    // );
    if (event.buttons === 1) {
      selectedCells = [cell_init, { row, col }];
      const reply = findMinMaxIndices(selectedCells);
      if (reply) {
        selectedCells = GetSelectedBox(reply);
      }
      this.setState({ selectedCells });
    }
  };

  handleCellMouseUp = () => {
    // The selection is completed when the mouse button is released
    // You can perform any actions with the selectedCells here
    document.body.classList.remove('disable-select');
  };
  IsCellSelected = (row, col) => {
    if (!this.props.grid) return false;
    const { selectedCells } = this.state;
    const reply = findMinMaxIndices(selectedCells);
    if (IsInsideSelectedBox(reply, row, col)) return false;
    return selectedCells.some(cell => cell.row === row && cell.col === col);
  };
  getStyleBox = (row, col) => {
    if (!this.props.grid) return null;
    const { selectedCells } = this.state;
    const reply = findMinMaxIndices(selectedCells);
    return getSyleSelectedBox(reply, row, col);
  };
  render() {
    let {
      rows,
      checkboxSelection,
      rowCount,
      dense,
      loading,
      rowsPerPageOptions,
      paginationMode,
      height,
      style_table,
      style_body_cell,
      style_pagination,
    } = this.props;
    const { classes } = this.props;
    let { page, limit, order, orderBy, rows_edited } = this.state;
    if (this.props.onRowsPerPageChange && limit !== this.props.rowsPerPage) {
      limit = this.props.rowsPerPage;
    }
    const headers = getHeaders(this.props, this.state);
    let colSpan = headers.length;
    if (checkboxSelection) colSpan++;
    let total_limit = page * limit + limit;
    let start_limit = page * limit;
    if (paginationMode === 'server') {
      start_limit = 0;
      total_limit = limit;
    }
    let style = {
      maxHeight: 440,
      overflow: 'auto',
      ...style_table,
    };
    if (height && height > 50) {
      style = { ...style, height, maxHeight: height };
    }
    const final_rows = getFinalRows(rows, this.state, this.props);
    this.currentRows = getSortRows(
      final_rows,
      getSortFunc(order, orderBy, headers)
    );
    if (total_limit > 0) {
      this.currentRows = this.currentRows.slice(start_limit, total_limit);
    }
    if (rowCount === undefined) {
      rowCount = rows.length;
    }
    if (rows.length !== final_rows.length) {
      rowCount = final_rows.length;
    }
    if (isNaN(rowCount)) {
      rowCount = 0;
    }
    let ComponentPagination = null;
    if (this.props.show_pagination) {
      ComponentPagination = (
        <React.Fragment>
          <TablePagination
            component="div"
            count={rowCount}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={rowsPerPageOptions}
            style={{ width: '100%' }}
            sx={{
              borderTop: '1px solid gray',
              ...style_pagination,
            }}
          />
        </React.Fragment>
      );
    }
    let row_cell_clicked = null;
    if (this.props.cell_selection) {
      row_cell_clicked = this.state.row_cell_clicked;
    }
    return (
      <div className={classes.parent}>
        <div className={classes.child}>
          <div style={style}>
            <Table
              aria-label={`table-grid-${uuid()}`}
              className={clsx({
                [classes.table]: !this.props.nowrap,
                [classes.root]: this.state.shiftPress,
              })}
              onKeyDown={this.keyDownHandler}
              onKeyUp={this.keyUpHandler}
              size={dense ? 'small' : 'medium'}
              stickyHeader>
              <TableGridHeader
                checkboxSelection={checkboxSelection}
                filter={this.state.filter}
                headers={headers}
                headers_to_hide={this.state.headers_to_hide}
                numSelected={this.state.selected.length}
                onClickHideColumn={this.handleClickHideColumn}
                onClickShowColumn={this.handleClickShowColumn}
                onSearchFilterChanged={this.handleSearchFilterChanged}
                onSelectAllClick={this.handleSelectAllClick}
                onSortHeaderClicked={this.handleSortHeaderClicked}
                rowCount={rowCount}
                style_header={this.props.style_header}
              />
              <TableBody>
                {loading ? (
                  <TableGridRowLoading colSpan={colSpan} dense={dense} />
                ) : (
                  this.currentRows.map((row, index) => {
                    const idModify = rows_edited
                      .map(x => getIdValue(x, this.props))
                      .indexOf(getIdValue(row, this.props));
                    let row_original = null;
                    if (idModify !== -1) {
                      const idOriginal = rows
                        .map(x => getIdValue(x, this.props))
                        .indexOf(getIdValue(row, this.props));
                      if (idOriginal !== -1) {
                        row_original = { ...rows[idOriginal] };
                      }
                    }
                    let isItemSelected = this.handleIsItemSelected(row);
                    let isRowSelected = this.handleIsRowSelected(row);
                    // if (index === 0) isRowSelected = true; for testing selection
                    let add_row_style = this.getRowStyle(row);
                    return (
                      <TableGridRow
                        add_row_style={add_row_style}
                        cell_selection={this.props.cell_selection}
                        checkboxSelection={checkboxSelection}
                        getCellValue={getCellValue}
                        getColorCellText={this.props.getColorCellText}
                        getStyleBox={this.getStyleBox}
                        grid={this.props.grid}
                        handleRowClicked={this.handleRowClicked}
                        headers={headers}
                        holder={this.holder}
                        idRow={this.props.idRow}
                        IsCellSelected={this.IsCellSelected}
                        isItemSelected={isItemSelected}
                        isRowClicked={this.IsRowClicked(row)}
                        isRowSelected={isRowSelected}
                        key={`table-grid-row-${index}`}
                        onButtonClick={this.props.onButtonClick}
                        onCellMouseDown={this.handleCellMouseDown}
                        onCellMouseMove={this.handleCellMouseMove}
                        onCellMouseUp={this.handleCellMouseUp}
                        onCellPaste={this.handleOnCellPaste}
                        onCellSingleClick={this.hanldeCellSingleClick}
                        onRowDoubleClick={this.handleRowClicked}
                        onRowEdited={this.handleRowEdited}
                        onSelectItemClicked={this.handleSelectItemClicked}
                        row={row}
                        row_cell_clicked={row_cell_clicked}
                        row_height={this.props.row_height}
                        row_original={row_original}
                        rowIndex={index}
                        saving={this.props.saving}
                        style_body_cell={style_body_cell}
                        style_rows={this.props.style_rows}
                      />
                    );
                  })
                )}
              </TableBody>
            </Table>
          </div>
          {ComponentPagination}
          <DialogShowColumns
            handleClose={() => this.setState({ open_show_column: false })}
            headers={this.props.headers}
            headers_to_hide={this.state.headers_to_hide}
            onShowHideColumn={this.handleShowHideColumn}
            open={this.state.open_show_column}
          />
        </div>
      </div>
    );
  }
}

TableGrid.propTypes = {
  cell_selection: PropTypes.bool,
  checkboxSelection: PropTypes.bool,
  dense: PropTypes.bool,
  getColorCellText: PropTypes.func,
  grid: PropTypes.bool,
  height: PropTypes.number,
  idRow: PropTypes.string,
  loading: PropTypes.bool,
  onButtonClick: PropTypes.func,
  onHighlightChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowClicked: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectedChanged: PropTypes.func,
  onSortHeaderClicked: PropTypes.func,
  page: PropTypes.number,
  paginationMode: PropTypes.string,
  rowCount: PropTypes.number,
  row_styles: PropTypes.array,
  rows: PropTypes.array,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  show_pagination: PropTypes.bool,
  style_pagination: PropTypes.object,
  style_table: PropTypes.object,
};
TableGrid.defaultProps = {
  style_rows: {},
  style_header: {},
  style_body_cell: {},
  cell_selection: false,
  idRow: null,
  grid: false,
  filter: null,
  nowrap: false,
  headers: [],
  row_styles: [],
  headers_to_hide: [],
  external_selected: null,
  rows: [],
  checkboxSelection: false,
  dense: false,
  loading: false,
  rowsPerPageOptions: rowsPerPageSmall,
  paginationMode: 'client',
  onSelectedChanged: () => '',
  onRowClicked: () => '',
  onRowEditedChanged: () => '',
  onFilterChanded: () => '',
  onHeadersToHideChanged: () => '',
  onHighlightChange: () => '',
  onRowDoubleClick: () => '',
  onButtonClick: () => '',
  onCellClicked: () => '',
  onAddNewRows: () => '',
  onSortHeaderClicked: () => '',
  getColorCellText: () => {
    return null;
  },
  show_pagination: true,
  rowsPerPage: 5,
  rowCount: undefined,
  style_table: {},
  style_pagination: {},
};

export default withStyles(TableGrid, useStyles);
