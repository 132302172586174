// import { cache } from '@cornerstonejs/core';
const windowWidth = 1000;
const windowCenter = 40;

const lower = windowCenter - windowWidth / 2.0;
const upper = windowCenter + windowWidth / 2.0;

const ctVoiRange = { lower, upper };

export default function setCtTransferFunctionForVolumeActor(props) {
  const {
    volumeActor,
    //  volumeId
  } = props;

  // if (volumeActor) {
  //   if (volumeActor.getMapper) {
  //     console.log('getMapper');
  //     const mapper = volumeActor.getMapper();
  //     if (mapper) {
  //       const imageData = mapper.getInputData();
  //       console.log({ imageData });
  //       if (imageData && imageData.getPointData) {
  //         const pointData = imageData.getPointData();
  //         const scalars = pointData.getScalars();
  //         console.log({ pointData, scalars });

  //         if (scalars) {
  //           const scalarData = scalars.getData();
  //           console.log(
  //             'Scalar data accessed from viewport actor',
  //             scalarData.length
  //           );

  //           // Now you can compute and set the VOI range
  //           // ...
  //         }
  //       } else {
  //         console.log('no Scalar');
  //       }
  //     } else {
  //       console.log('no mapper');
  //     }
  //   } else {
  //     console.log('no mapper', { volumeActor });
  //   }
  // }
  volumeActor
    .getProperty()
    .getRGBTransferFunction(0)
    .setMappingRange(lower, upper);
}

export { ctVoiRange };
