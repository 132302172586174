import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

class RowText extends Component {
  render() {
    const { first, second, display, width, styleTypography } = this.props;
    let { style, styleBox } = this.props;
    if (width) {
      if (style) {
        style = { ...style, width };
      } else {
        style = { width };
      }
    }
    if (display) {
      if (style) {
        style = { ...style, display };
      } else {
        style = { display };
      }
    }
    if (styleBox !== undefined && styleBox) {
      styleBox = { ...styleBox, marginRight: '5px' };
    } else {
      styleBox = {
        marginRight: '5px',
        ...styleBox,
      };
    }
    return (
      <div style={style}>
        <Box display="inline" style={{ ...styleBox }}>
          {first}
        </Box>
        <Typography
          color="textSecondary"
          component="p"
          display="inline"
          style={{ ...styleTypography }}
          variant={this.props.variant_second}>
          {second}
        </Typography>
      </div>
    );
  }
}

RowText.propTypes = {
  classes: PropTypes.object,
};
RowText.defaultProps = {
  style: null,
  variant_first: 'body2',
  variant_second: 'body2',
};
export default RowText;
