import axios from 'axios';
import gql from 'graphql-tag';
import { print } from 'graphql';
import { HTTP_ADDRESS_GRAPHQL } from 'config';
import { series_base } from 'graphql/Series/utils_series_query';

const QUERY_PATIENT_STUDIES = gql`
  query($ids: [LongLong!],$input: InputSeriesQuery, $page: Int, $limit: Int) {
    patientsStudies(ids: $ids,input:$input, page: $page, limit: $limit) {
      ok
      errors {
        message
      }
      total
      studies {
        idStudy
        StudyDescription
        StudyInstanceUID
        Patient {
          PatientName
          PatientID
          PatientBirthDate
        }
        StudyDate
        StudyTime
        AccessionNumber
        NumberOfStudyRelatedInstances
        NumberOfStudyRelatedSeries
        Series {
          ${series_base}
          Thumbnail
          
          ImageSize {
            Rows
            Columns
            Size
          }
          sequence {
            idSequence
            name
          }
          ImageResolution {
            PixelSpacingX
            PixelSpacingY
            SpacingBetweenSlices
            SliceThickness
            Spacing
          }
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const QueryPatientStudies = (ids, description, page, limit) => {
  let input = null;
  if (description) input = { description };
  return axios.post(HTTP_ADDRESS_GRAPHQL, {
    query: print(QUERY_PATIENT_STUDIES),
    variables: {
      ids,
      page,
      limit,
      input,
    },
  });
};
