import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { withRouter } from 'react-router-dom';
import {
  ViewerAutoPlay,
  ButtonMenuSpeed,
  ExplorerDropArea,
  ExplorerViewGallery,
  ExplorerViewList,
  useStyles,
} from './components';
import { IsInvalid, SelectedArray } from 'helpers';
import { DialogViewFile } from 'components';
import { IsFileReadable } from 'common';

class ExplorerViews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      numSelected: 0,
      selected_objects: [],
      open_text_json_file: false,
      open_other_file: false,
      file_to_open: null,
      image_files: [],
    };
    this.holder = {
      renderAgain: null,
    };
    if (typeof props.holder !== 'undefined') {
      props.holder.clearSelection = () => {
        this.setState({ selected_objects: [] });
      };
      props.holder.renderAgain = () => {
        this.forceUpdate();
        if (this.holder.renderAgain) {
          this.holder.renderAgain();
        }
      };
    }
  }

  handleSelectAll = () => {
    const { selected_objects } = this.state;
    if (selected_objects.length) {
      this.setState({ selected_objects: [] });
      this.props.handleSelectedAll([], []);
    } else {
      let { SubFolders, Files } = this.props.Folder;
      this.setState({ selected_objects: SubFolders.concat(Files) });
      this.props.handleSelectedAll(SubFolders, Files);
    }
  };
  getIndex = value => {
    if (!value || value === undefined) return -1;
    const { selected_objects } = this.state;
    let { idFolder, idFolderContent, File, Series } = value;
    if (File || Series) idFolder = null;
    let index = -1;
    for (let i = 0; i < selected_objects.length; i++) {
      const obj = selected_objects[i];
      if (
        idFolderContent !== undefined &&
        idFolderContent === obj.idFolderContent
      ) {
        index = i;
      } else if (idFolder !== undefined && idFolder === obj.idFolder) {
        index = i;
      }
    }
    return index;
  };
  handleSelected = value => {
    // console.log('handleSelected', { value });
    const { idFolder, idFolderContent } = value;
    let Folder = null;
    let FolderContent = null;
    if (idFolder && (idFolderContent === undefined || !idFolderContent)) {
      Folder = value;
    } else if (idFolderContent) {
      FolderContent = value;
    }
    const index = this.getIndex(value);
    // console.log({ index, value });
    let { selected_objects } = this.state;
    selected_objects = SelectedArray(selected_objects, value, index);
    this.setState({ selected_objects });
    this.props.handleSelected(Folder, FolderContent);
  };
  isSelected = value => {
    const index = this.getIndex(value);
    return index !== -1;
  };
  cleanSelections = () => {
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state.selected_objects = [];
    this.props.holder.clearSelectedObjects();
  };
  getExplorerType = () => {
    const { view_type, Folder, handleClickFolderItem, ...res } = this.props;
    const { selected_objects } = this.state;
    let { SubFolders, Files } = Folder;
    if (!SubFolders) {
      SubFolders = [];
    }
    if (!Files) {
      Files = [];
    }
    let idHomeFolder = -1;
    if (this.props.HomeFolder) {
      idHomeFolder = this.props.HomeFolder.idFolder;
    }
    switch (view_type) {
      case 1:
        return (
          <ExplorerViewList
            {...res}
            cleanSelections={this.cleanSelections}
            Files={Files}
            Folder={Folder}
            handleOnRowClick={handleClickFolderItem}
            handleOpenAtDoubleClick={this.handleOpenAtDoubleClick}
            handleSelectAll={this.handleSelectAll}
            handleSelected={this.handleSelected}
            holder={this.holder}
            idHomeFolder={idHomeFolder}
            isSelected={this.isSelected}
            selected_objects={selected_objects}
            SubFolders={SubFolders}
          />
        );
      default:
        return (
          <ExplorerViewGallery
            {...res}
            cleanSelections={this.cleanSelections}
            Files={Files}
            Folder={Folder}
            handleClickFolderItem={handleClickFolderItem}
            handleOpenAtDoubleClick={this.handleOpenAtDoubleClick}
            handleSelected={this.handleSelected}
            holder={this.holder}
            idHomeFolder={idHomeFolder}
            isSelected={this.isSelected}
            selected_objects={selected_objects}
            SubFolders={SubFolders}
          />
        );
    }
  };

  handleOpenAtDoubleClick = item => {
    if (item === undefined || !item) {
      return;
    }
    const { File, Series } = item;
    const { user } = this.props;
    if (File) {
      if (IsFileReadable(File)) {
        const { original_name } = File;
        let image_files = [];
        if (
          original_name &&
          original_name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)
        ) {
          image_files = this.getImageFiles();
        }
        this.setState({
          image_files,
          isMouseOver: false,
          open_text_json_file: true,
          open_other_file: false,
          file_to_open: File,
        });
      } else {
        const enable_open = user && user.idCategory === 2;
        if (enable_open) {
          this.setState({
            isMouseOver: false,
            open_text_json_file: false,
            open_other_file: true,
            file_to_open: File,
          });
        }
      }
    } else {
      this.handleOpenSeries(Series);
    }
  };
  handleOpenSeries = Series => {
    if (Series === undefined || !Series) {
      return;
    }
    const { idSeries, idStudy } = Series;
    if (!idSeries) return;
    this.props.history.push(
      `/image_analysis/study/${idStudy}/series/${idSeries}/view`
    );
  };
  getImageFiles = () => {
    const { Folder } = this.props;
    if (IsInvalid(Folder)) return [];
    const { Files } = Folder;
    if (IsInvalid(Files)) return [];
    return Files.filter(file => {
      const { File } = file;
      if (IsInvalid(File)) return false;
      const { original_name } = File;
      return (
        original_name &&
        original_name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)
      );
    });
  };

  render() {
    const { classes, base, Folder, open_drawer } = this.props;
    const {
      selected_objects,
      open_text_json_file,
      image_files,
      file_to_open,
    } = this.state;
    let ComponentViewer = null;
    if (open_text_json_file) {
      if (image_files.length) {
        ComponentViewer = (
          <ViewerAutoPlay
            classes={classes}
            image_files={image_files}
            open_text_json_file={open_text_json_file}
            setState={state => this.setState(state)}
          />
        );
      } else if (file_to_open) {
        ComponentViewer = (
          <DialogViewFile
            file={file_to_open}
            handleAddError={this.props.handleAddError}
            handleClose={() =>
              this.setState({
                open_text_json_file: false,
                file_to_open: null,
              })
            }
            open_edit={this.state.open_text_json_file}
          />
        );
      }
    }
    return (
      <div className={classes.root_gallery}>
        <ButtonMenuSpeed
          base={base}
          cleanSelections={this.cleanSelections}
          Folder={Folder}
          handleRemoveFromFolder={this.props.handleRemoveFromFolder}
          open_drawer={open_drawer}
          selected_objects={selected_objects}
        />
        <ExplorerDropArea
          handleUploadFiles={this.props.handleUploadFiles}
          idFolder={Folder.idFolder}
          user={this.props.user}>
          {this.getExplorerType()}
          {ComponentViewer}
        </ExplorerDropArea>
      </div>
    );
  }
}

ExplorerViews.propTypes = {
  Folder: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
  const size = state.manager.cut.length;
  return {
    isMobile: state.layout.isMobile,
    isMobileSmall: state.layout.isMobileSmall,
    user: state.info_user.user,
    view_type: state.page_explorer.view_type,
    cut_objects: state.manager.cut,
    size,
  };
};
export default connect(
  mapStateToProps,
  null
)(withStyles(withRouter(ExplorerViews), useStyles));
