import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { AppBar, Drawer, Hidden, IconButton, Toolbar } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logout } from 'redux-store/actions/loginActions';
// core components
import useStyles from './Styles';
import Menu from '@mui/icons-material/Menu';
import { RightButtons } from './components';
import { MedicalBlocksLogo } from 'layouts/components';

class HomeHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      scroll_on: false,
    };
  }

  componentDidMount() {
    if (this.props.changeColorOnScroll) {
      window.addEventListener('scroll', this.headerColorChange);
    }
  }

  componentWillUnmount() {
    if (this.props.changeColorOnScroll) {
      window.removeEventListener('scroll', this.headerColorChange);
    }
  }

  handleDrawerToggle = () => {
    this.setState(p => ({ mobileOpen: !p.mobileOpen }));
  };
  headerColorChange = () => {
    const { changeColorOnScroll } = this.props;
    const windowsScrollTop = window.pageYOffset;
    // console.log({ windowsScrollTop, h: changeColorOnScroll.height });
    if (windowsScrollTop > changeColorOnScroll.height) {
      this.setState({ scroll_on: true });
    } else {
      this.setState({ scroll_on: false });
    }
  };

  render() {
    const {
      classes,
      isAuthenticated,
      user,
      color,
      leftLinks,
      fixed,
      absolute,
      logout,
      changeColorOnScroll,
    } = this.props;
    let { style } = this.props;
    const { mobileOpen, scroll_on } = this.state;
    let current_color = color;
    if (scroll_on) current_color = changeColorOnScroll.color;
    const appBarClasses = clsx({
      [classes.appBar]: true,
      [classes[current_color]]: current_color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
    });
    if (style === undefined) style = {};
    return (
      <AppBar className={appBarClasses} style={style}>
        <Toolbar className={classes.container}>
          {leftLinks !== undefined ? (
            <MedicalBlocksLogo
              changeColorOnScroll={this.props.changeColorOnScroll}
            />
          ) : null}
          <div className={classes.flex}>
            {leftLinks !== undefined ? (
              <Hidden implementation="css" lgDown>
                {leftLinks}
              </Hidden>
            ) : (
              <MedicalBlocksLogo
                changeColorOnScroll={this.props.changeColorOnScroll}
              />
            )}
          </div>
          <Hidden implementation="css" mdDown>
            <RightButtons
              changeColorOnScroll={this.props.changeColorOnScroll}
              isAuthenticated={isAuthenticated}
              logout={logout}
              user={user}
            />
          </Hidden>
          <Hidden mdUp>
            <IconButton
              aria-label="open drawer"
              color="inherit"
              onClick={this.handleDrawerToggle}
              size="large">
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
        <Hidden implementation="js" mdUp>
          <Drawer
            anchor={'right'}
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={this.handleDrawerToggle}
            open={mobileOpen}
            variant="temporary">
            <div className={classes.appResponsive}>
              {leftLinks}
              <RightButtons
                changeColorOnScroll={this.props.changeColorOnScroll}
                inDrawer
                isAuthenticated={isAuthenticated}
                logout={logout}
                user={user}
              />
            </div>
          </Drawer>
        </Hidden>
      </AppBar>
    );
  }
}

HomeHeader.defaultProp = {
  color: 'white',
  isAuthenticated: false,
};

HomeHeader.propTypes = {
  BrandComponent: PropTypes.object,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'info',
      'success',
      'warning',
      'danger',
      'transparent',
      'white',
      'rose',
      'dark',
    ]).isRequired,
  }),
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'transparent',
    'white',
    'rose',
    'dark',
  ]),
  fixed: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  leftLinks: PropTypes.node,
  rightLinks: PropTypes.node,
};
export default connect(null, { logout })(
  withStyles(withRouter(HomeHeader), useStyles)
);
