import React from 'react';
import PropTypes from 'prop-types';
import { DivCorner, DivOrientation } from './components';

class ComponentCorners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      volumen: null,
    };
  }

  render() {
    let width = 0;
    let height = 0;
    const {
      mouse,
      vtkContainerRef,
      imSize,
      position,
      slider,
      zoom,
      image,
      orientation_letters,
      height: div_height,
    } = this.props;
    if (vtkContainerRef.current) {
      const rect = vtkContainerRef.current.getBoundingClientRect();
      // console.log({ rect });
      width = rect.width;
      height = rect.height;
    }
    let series_bottom = `TE: ${image?.EchoTime ||
      'Unknown'} TR: ${image?.RepetitionTime || 'Unknown'}`;
    let series_top = `StudyDate: ${image?.StudyDate ||
      image?.SeriesDate ||
      'Unknown'}`;
    let series_middle = `FS: ${image?.MagneticFieldStrength || 'Unknown'}`;
    let study_bottom = `ProtocolName: ${image?.ProtocolName || 'Unknown'} `;
    if (image.ImageMr) {
      study_bottom = `ProtocolName: ${image.ImageMr?.ProtocolName ||
        'Unknown'} `;
      series_bottom = `TE: ${image.ImageMr?.EchoTime || 'Unknown'} TR: ${image
        .ImageMr?.RepetitionTime || 'Unknown'}`;
    }
    let study_middle = `StudyDescription: ${image?.StudyDescription ||
      'Unknown'}`;
    if (image.Study) {
      study_middle = `StudyDescription: ${image?.Study?.StudyDescription ||
        'Unknown'}`;
      series_middle = `FS: ${image?.Study?.MagneticFieldStrength || 'Unknown'}`;
    }
    if (image.Modality !== 'MR') {
      study_bottom = series_top;
      series_top = `SeriesNumber: ${image?.SeriesNumber || ''}`;
      series_middle = `SeriesTime: ${image.SeriesTime}`;
      if (image.SeriesDescription)
        series_bottom = `SeriesDescription: ${image?.SeriesDescription || ''}`;
      else series_bottom = '';
    }

    let study_top = `Name: ${image?.PatientName || 'Unknown'}`;
    if (image.Patient) {
      study_top = `Name: ${image?.Patient?.PatientName || 'Unknown'}`;
    }
    let image_bottom = `Thickness: ${image?.Thickness || '1'} `;
    if (image.ImageResolution) {
      const { SpacingBetweenSlices, SliceThickness } = image.ImageResolution;
      if (SpacingBetweenSlices || SliceThickness)
        image_bottom = `Thickness: ${image.ImageResolution?.SliceThickness ||
          image.ImageResolution?.SpacingBetweenSlices ||
          '1'} `;
      else
        image_bottom = `Spacing: ${image.ImageResolution?.Spacing ||
          'Unknown'} `;
    }
    let total_images = slider.max;
    if (image.NumberOfSeriesRelatedInstances > total_images) {
      total_images = image.NumberOfSeriesRelatedInstances;
    }
    let pixel_value = 0;
    if (mouse) {
      if (Array.isArray(mouse.value)) {
        pixel_value = ' [' + mouse.value.join(', ') + ']';
      } else if (mouse.value) {
        pixel_value = mouse.value.toFixed(2);
      }
    }
    // console.log({ value: mouse.value });
    return (
      <div
        style={{
          position: 'absolute',
          height: div_height ? div_height - 5 : '100%',
          width: '100%',
          zIndex: 1000,
        }}>
        <DivCorner
          line_bottom={`x: ${mouse.x} px, y: ${mouse.y} px, value:${pixel_value}`}
          line_last={`x: ${mouse.Wx} mm, y: ${mouse.Wy} mm, z:${mouse.Wz} mm`}
          line_middel={`View size: ${Math.round(width)}x${Math.round(height)}`}
          line_top={`Image size: ${Math.round(imSize.width)}x${Math.round(
            imSize.height
          )}`}
          vtkContainerRef={vtkContainerRef}
        />
        <DivCorner
          bottom
          line_bottom={image_bottom}
          line_middel={`Zoom: ${zoom} %`}
          line_top={`Image: ${Math.round(position)}/${total_images}`}
          vtkContainerRef={vtkContainerRef}
        />
        <DivCorner
          bottom
          line_bottom={series_bottom}
          line_middel={series_middle}
          line_top={series_top}
          right
          vtkContainerRef={vtkContainerRef}
        />
        <DivCorner
          line_bottom={study_bottom}
          line_middel={study_middle}
          line_top={study_top}
          right
          vtkContainerRef={vtkContainerRef}
        />
        {/* Top */}
        <DivOrientation
          orientation={`${
            orientation_letters[0] ? orientation_letters[0] : ''
          }`}
          vtkContainerRef={vtkContainerRef}
        />
        {/* Left */}
        <DivOrientation
          bottom
          orientation={`${
            orientation_letters[1] ? orientation_letters[1] : ''
          }`}
          vtkContainerRef={vtkContainerRef}
        />
        {/* Bottom */}
        <DivOrientation
          bottom
          orientation={`${
            orientation_letters[2] ? orientation_letters[2] : ''
          }`}
          right
          vtkContainerRef={vtkContainerRef}
        />
        {/* Right */}
        <DivOrientation
          orientation={`${
            orientation_letters[3] ? orientation_letters[3] : ''
          }`}
          right
          vtkContainerRef={vtkContainerRef}
        />
      </div>
    );
  }
}

ComponentCorners.propTypes = {
  classes: PropTypes.object,
};
ComponentCorners.defaultProps = {
  image: {
    EchoTime: '',
    RepetitionTime: '',
    MagneticFieldStrength: '',
    StudyDate: '',
    ProtocolName: '',
    PatientName: '',
    StudyDescription: '',
  },
};

export default ComponentCorners;
