import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'tss-react/mui';
import clsx from 'clsx';
import { useStyles } from './useStylesOrientation';

class DivOrientation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, bottom, right, orientation, vtkContainerRef } = this.props;
    let minWidth = 250;
    let maxWidth = '100%';
    if (vtkContainerRef.current) {
      const rect = vtkContainerRef.current.getBoundingClientRect();
      // console.log({ rect });
      minWidth = rect.width * 0.45;
      maxWidth = rect.width * 0.5;
    }
    let box = classes.top_left;
    let text = null;
    if (bottom && right) {
      box = classes.bottom_right;
      text = classes.text_right;
    } else if (bottom && !right) {
      box = classes.bottom_left;
      text = null;
    } else if (!bottom && right) {
      box = classes.top_right;
      // eslint-disable-next-line no-unused-vars
      text = null;
    }
    return (
      <div className={clsx(classes.box, box)} style={{ minWidth, maxWidth }}>
        <div className={clsx(classes.text)}>{orientation}</div>
      </div>
    );
  }
}

DivOrientation.propTypes = {
  bottom: PropTypes.bool,
  classes: PropTypes.object,
  orientation: PropTypes.string,
  right: PropTypes.bool,
};
DivOrientation.defaultProps = {
  bottom: false,
  right: false,
  orientation: '',
};

export default withStyles(DivOrientation, useStyles);
