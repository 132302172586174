import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { GetUserFromTokenDecoded } from './user_utils';

export const CheckAxionHeaders = () => {
  // eslint-disable-next-line no-prototype-builtins
  if (axios.defaults.headers.common.hasOwnProperty('x-token')) {
    return;
  }
  if (localStorage.token) {
    axios.defaults.headers.common['x-token'] = localStorage.token;
  }
  if (localStorage.refreshToken) {
    axios.defaults.headers.common['x-refresh-token'] =
      localStorage.refreshToken;
  }
};
export const setAuthorizationToken = (token, refreshToken) => {
  let user = null;
  if (token) {
    //
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['x-token'] = token;
    axios.defaults.headers.common['x-refresh-token'] = refreshToken;

    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    const { user: user_token } = jwtDecode(token);
    user = GetUserFromTokenDecoded(user_token);
    localStorage.setItem('viewer_access', user.viewer_access);
  } else {
    delete axios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['x-token'];
    delete axios.defaults.headers.common['x-refresh-token'];
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('viewer_access');
  }
  return user;
};
