import React from 'react';
import PropTypes from 'prop-types';

import { Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import { useMakeStyles } from '../Styles';
import { GetData, ServerErrorsString } from 'helpers';
import SearchAutocomplete from '../SearchAutocomplete';
import { QueryCountries } from 'graphql/System';

const SearchCountriesAtSystem = props => {
  const { selected_country, style } = props;

  const { classes } = useMakeStyles();

  const [name, setCountryName] = React.useState('');
  const [country, setCountry] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;

  React.useEffect(() => {
    if (!loading) {
      return undefined;
    }

    (async () => {
      QueryCountries(name, 0, 25)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, countries } = data.allCountries;
          // console.log({ countries });
          if (ok) {
            if (countries.length === 0) {
              countries.push({ country: 'Empty' });
            }
          } else {
            throw errors;
          }
          setOptions(countries);
        })
        .catch(error => {
          console.log('country-catch-error', error);
          setOptions([]);
          props.handleAddError(ServerErrorsString(error));
        });
    })();
  }, [loading, name]);
  if (selected_country && country && country.id !== selected_country.id) {
    setCountry(selected_country);
  }

  const handeCountryNameChanged = event => {
    const new_country = event.target.value;
    if (options.length) {
      const here = options.filter(x =>
        x.country.toUpperCase().startsWith(new_country.toUpperCase())
      );
      if (!here.length || !new_country.length) {
        setOptions([]);
      }
    }
    setCountryName(new_country);
  };
  const handleAddCountry = country => {
    setCountry(country);
    props.handleAddCountry(country);
  };
  if (props.no_icon) {
    return (
      <SearchAutocomplete
        getOptionLabel={option => {
          if (option) {
            const { country } = option;
            if (country) return country;
          }
          return '';
        }}
        handelSearchNameChanged={handeCountryNameChanged}
        handleAddResultObject={handleAddCountry}
        isOptionEqualToValue={(option, value) => {
          if (value) return option.country === value.country;
          if (option.country === '') return true;
          return false;
        }}
        label="Country"
        loading={loading}
        name={name}
        no_icon
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={country}
        setObject={handleAddCountry}
        setObjectName={setCountryName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    );
  }
  return (
    <Paper className={classes.root} style={style}>
      <SearchIcon className={classes.icon} />
      <SearchAutocomplete
        getOptionLabel={option => {
          if (option) {
            const { country } = option;
            if (country) return country;
          }
          return '';
        }}
        handelSearchNameChanged={handeCountryNameChanged}
        handleAddResultObject={handleAddCountry}
        isOptionEqualToValue={(option, value) => {
          if (value) return option.country === value.country;
          if (option.country === '') return true;
          return false;
        }}
        label="Country"
        loading={loading}
        name={name}
        open={open}
        options={options}
        placeholder={props.placeholder}
        selected={country}
        setObject={handleAddCountry}
        setObjectName={setCountryName}
        setOpen={setOpen}
        setOptions={setOptions}
      />
    </Paper>
  );
};

SearchCountriesAtSystem.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  style: PropTypes.object,
};

SearchCountriesAtSystem.defaultProps = {
  placeholder: 'Search Countries...',
  handleAddCountry: country => console.log('handleAddCountry: ', { country }),
  no_icon: false,
  handleAddError: error => console.log('handleAddError: ', { error }),
};

export default SearchCountriesAtSystem;
