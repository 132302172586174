import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
const genders = { M: 'Male', F: 'Female', O: 'Other' };
class PanelPatientInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes, patient } = this.props;
    let FullName = '';
    let BirthDay = '';
    let Age = '';
    let Gender = '';
    let PatientID = '';
    // console.log({ patient });
    if (patient) {
      const { PatientSex, PatientBirthDate } = patient;
      if (Object.keys(genders).indexOf(PatientSex) !== -1) {
        Gender = genders[PatientSex];
      }
      if (PatientBirthDate) {
        const date = moment(PatientBirthDate, 'YYYY-MM-DD ');
        BirthDay = date.format('MMM Do - YYYY');
        const today = moment();
        const age = today.diff(date, 'years');
        Age = `${age} Years`;
      }
      FullName = patient.FullName;
      PatientID = patient.PatientID;
    }
    return (
      <div className={classes.patientInfo}>
        <div className={classes.patientName}>{FullName}</div>
        <div className={classes.infoItemHeader}>
          <span className={classes.smallLabel}>Birthday</span>
          <div>{BirthDay}</div>
        </div>
        <div className={classes.infoItemHeader}>
          <span className={classes.smallLabel}>Age</span>
          <div>{Age}</div>
        </div>
        <div className={classes.infoItemHeader}>
          <span className={classes.smallLabel}>Gender</span>
          <div>{Gender}</div>
        </div>
        <div className={classes.infoItemHeader}>
          <span className={classes.smallLabel}>PatientID</span>
          <div>{PatientID}</div>
        </div>
      </div>
    );
  }
}

PanelPatientInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default PanelPatientInfo;
