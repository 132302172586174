import { Loader } from '@googlemaps/js-api-loader';
export const GOOGLE_API_KEY = 'AIzaSyCMiQXODbqSAoMCIHBjjLAnVVTf0IMy2Ak';
export const loadGoogleScript = THIS => {
  console.log('loadGoogleScript');
  const loader = new Loader({
    apiKey: GOOGLE_API_KEY,
    version: 'weekly',
    libraries: ['places'],
  });
  loader
    .load()
    .then(() => {
      THIS.handleScriptLoad();
    })
    .catch(e => {
      console.log({ e });
    });
};

export const bern_location = {
  zoom: 6,
  center: { lat: 46.9479739, lng: 7.4474468 }, // default center if no markers are found
};
