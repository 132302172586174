import { volumeLoader, getRenderingEngine, cache } from '@cornerstonejs/core';
import { cleanupCurrentVolume } from './utils_viewer';
import { volumeLoaderScheme } from './utils';
import { setCtTransferFunctionForVolumeActor } from 'views/DicomViewer/components/GridViewers/components/helpers';

export const loadVolume = async (
  imageIds,
  series,
  viewportId,
  renderingEngineId,
  annotationManager,
  default_orientation,
  orientation_letters,
  THIS
) => {
  if (!imageIds || imageIds.length === 0) {
    console.error('No image IDs provided');
    return;
  }
  console.log('loadVolume', series.NumberOfSeriesRelatedInstances);
  // Create a unique volume ID for this series
  const volumeId = `${volumeLoaderScheme}:${series.idSeries}`;

  THIS.setState(
    {
      orientation_letters,
      default_orientation,
      total: imageIds.length,
      isLoading: false,
      series,
      loaded: imageIds,
      position: imageIds.length / 2,
      init: true,
    },
    async () => {
      try {
        let { orientation_letters } = THIS.state;
        // Clean up any existing volume first
        await cleanupCurrentVolume(THIS, annotationManager, cache);

        // Get the rendering engine
        const renderingEngine = getRenderingEngine(renderingEngineId);
        // Get the stack viewport
        const viewport = renderingEngine.getViewport(viewportId);

        // Create and load volume
        const volume = await volumeLoader.createAndCacheVolume(volumeId, {
          imageIds,
        });
        await volume.load();
        const {
          // ImagePlane: { ImageOrientationPatient },
          ImageSize,
        } = series;
        // Force the ImageOrientationPatient to [1, 0, 0, 0, 1, 0]
        if (volume.metadata) {
          console.log({
            ImageOrientationPatient: volume.metadata.ImageOrientationPatient,
          });
          // volume.metadata.ImageOrientationPatient = [1, 0, 0, 0, 1, 0];
        }
        // Set the volume to the viewport
        viewport.setVolumes([
          {
            volumeId,
            callback: setCtTransferFunctionForVolumeActor,
          },
        ]);
        viewport.render();
        // Update state with the new volume information
        THIS.setState({
          aspectRatio: ImageSize.Rows / ImageSize.Columns,
          orientation_letters,
          volume,
          currentVolumeId: volumeId,
        });
      } catch (error) {
        console.error('Error loading volume:', error);
        THIS.setState({
          isLoading: false,
          error:
            error instanceof Error ? error.message : 'Error loading volume',
        });
      }
    }
  );
};
