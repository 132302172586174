import React from 'react';
import PropTypes from 'prop-types';
import { QueryAllCountriesCenters } from 'graphql/Units/utils_centers';
import { GetData, rowsPerPageBig, ServerErrors } from 'helpers';
import { CreateCardHeader, TableGrid } from 'components';
import { headers, headers_show } from './useStyles';
import {
  Card,
  Divider,
  //  Paper
} from '@mui/material';
import LocationCityIcon from '@mui/icons-material/LocationCity';
class TabCenterSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      countries: [],
      selected: [],
      selected_centers: [],
      total: 0,
      page: 0,
      limit: 50,
      centers: [],
      total_cities: 0,
    };
    const { holder } = props;
    if (holder) {
      holder.handleReloadCountries = () => this.queryCountryCenters();
    }
  }
  componentDidMount() {
    this.queryCountryCenters();
  }
  queryCountryCenters = (newPage = null, newLimit = null) => {
    // if (!this.props.isActive) return;
    if (this.state.loading) return;
    let { page, limit } = this.state;
    if (newLimit) {
      page = newPage;
      limit = newLimit;
    }
    this.setState({ loading: true });
    this.props.setState({ loading: true });
    (async () => {
      QueryAllCountriesCenters(page, limit)
        .then(res => {
          const data = GetData(res);
          const { countries, ok, errors, total } = data.allCountriesCenters;
          if (ok) {
            this.setState({
              loading: false,
              countries,
              init: true,
              page,
              limit,
              total,
            });
            this.props.setState({ loading: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false, page, limit, init: true });
          this.props.setState({ loading: false });
          let errors = ServerErrors(error);
          if (errors.length > 10) {
            const total = errors.length;
            errors = errors.splice(0, 10);
            errors.push(`There are ${total - 10} errors...`);
          }
          this.props.handleAddError(errors.join('\n'));
        });
    })();
  };
  handleSelected = selected => {
    let total_cities = 0;
    let centers = [];
    selected.forEach(c => {
      const { cities, country } = c;
      total_cities += cities.length;
      cities.forEach(ci => {
        const { city } = ci;
        centers = centers.concat(
          ci.centers.map(x => ({ ...x, country, city }))
        );
      });
    });
    this.setState({ centers, total_cities, selected });
    this.props.setState({
      selected,
      selected_centers: centers,
      from: 'settings',
    });
  };
  render() {
    const { height, open } = this.props;
    const { countries, selected } = this.state;
    if (open === 'show') {
      const { centers, total_cities } = this.state;
      return (
        <Card>
          <CreateCardHeader
            description={`Information about ${selected.length} countries, ${total_cities} cities and ${centers.length} centers`}
            handleClose={() => {
              this.props.handleTabChange('settings');
              this.setState({ selected: [], selected_centers: [] });
            }}
            icon={<LocationCityIcon style={{ color: 'green' }} />}
            styles={{ paddingTop: 5, paddingBottom: 5 }}
            title="Centers by Countries"
          />
          <Divider />
          <TableGrid
            cell_selection
            checkboxSelection
            dense
            disableSelectionOnClick
            external_selected={this.state.selected_centers}
            grid
            headers={headers_show}
            height={height - 140}
            loading={this.state.loading}
            onPageChange={page => this.setState({ page })}
            onRowsPerPageChange={limit => this.setState({ limit })}
            onSelectedChanged={selected_centers => {
              this.setState({ selected_centers });
              this.props.setState({ selected_centers: centers, from: 'show' });
            }}
            page={this.state.page}
            row_height={33}
            rows={centers}
            rowsPerPage={this.state.limit}
            rowsPerPageOptions={rowsPerPageBig}
            style_table={{ backgroundColor: '#eeeeee' }}
          />
        </Card>
      );
    }
    return (
      <TableGrid
        cell_selection
        checkboxSelection
        dense
        disableSelectionOnClick
        external_selected={this.state.selected}
        grid
        headers={headers}
        height={height - 80}
        idRow="idCountry"
        loading={this.state.loading}
        onPageChange={page => this.setState({ page })}
        onRowsPerPageChange={limit => this.setState({ limit })}
        onSelectedChanged={this.handleSelected}
        page={this.state.page}
        row_height={33}
        rows={countries}
        rowsPerPage={this.state.limit}
        rowsPerPageOptions={rowsPerPageBig}
        style_table={{ backgroundColor: '#eeeeee' }}
      />
    );
  }
}

TabCenterSettings.propTypes = {
  centers: PropTypes.array,
};

export default TabCenterSettings;
