import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { StudiesDatabase } from 'graphql/Study';
import {
  GetData,
  getParams,
  getParamsUrl,
  IsInvalid,
  ServerErrorsString,
} from 'helpers';
import { withStyles } from 'tss-react/mui';

import {
  LoadStudiesSeries,
  StudyGridView,
  StudyListView,
  ViewStudy,
} from './components';
import { withRouter } from 'react-router-dom';
import { CircularProgressWaiting } from 'components';

const useStyles = theme => ({
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
});

class ViewDatabaseStudy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Studies: [],
      total: -1,
      empty: false,
      index_series: -1,
    };
    this.holder = {
      getStudy: null,
      getFilters: null,
    };
    const { holder } = props;
    if (holder) {
      holder.runQueryStudy = query => {
        this.handleRun(query);
      };
      holder.getStudy = idStudy => {
        if (!idStudy) return null;
        const { Studies } = this.state;
        const i = Studies.map(x => x.idStudy).indexOf(idStudy);
        if (i !== -1) {
          return Studies[i];
        }
        if (this.holder.getStudy) {
          return this.holder.getStudy();
        }
        return null;
      };
    }
  }

  componentDidMount() {
    const url = getParamsUrl(this.props);
    if (url === '/image_analysis') {
      this.handleRun();
    }
  }

  componentDidUpdate() {
    const url = getParamsUrl(this.props);
    const { total, empty, loading } = this.state;
    if (url === '/image_analysis' && total === -1 && !empty && !loading) {
      this.handleRun();
    }
  }

  handleRun = (query = {}) => {
    let { description, current_page, current_limit, level, reload } = query;
    console.log('ViewDatabaseStudy - handleRun', {
      current_page,
      current_limit,
    });
    let { page, limit } = this.props;
    const { loading } = this.state;
    if (loading) return;
    if (IsInvalid(current_limit) && current_page === undefined) {
      if (this.state.Studies.length !== 0 || loading) {
        return;
      }
    }
    if (current_limit) {
      page = current_page;
      limit = current_limit;
    }
    if (this.props.holder.setLoading) {
      this.props.holder.setLoading(true);
    }
    const inputs = {
      StudyDescription: description ? description : '',
    };
    const { filters, search_type } = this.props;
    // console.log('ViewDatabaseStudy', { filters });
    if (filters) {
      if (filters.idUser) inputs.idUser = filters.idUser;
      if (filters.SeriesDate) inputs.StudyDate = filters.SeriesDate;
      if (filters.idProject) inputs.idProject = filters.idProject;
    }
    if (search_type) {
      if (search_type.name === 'Patient Name') inputs.PatientName = description;
      if (search_type.name === 'Patient ID') inputs.PatientID = description;
      if (search_type.name === 'AccessionNumber')
        inputs.AccessionNumber = description;
      inputs.StudyDescription = '';
      description = null;
    }
    this.setState({ loading: true, empty: false, total: 0 });
    (async () => {
      StudiesDatabase(inputs, page, limit, level, reload)
        .then(res => {
          const data = GetData(res);
          const { ok, errors, total, studies } = data.StudiesDatabase;
          if (ok) {
            this.setState(
              {
                loading: false,
                total,
                Studies: studies,
                empty: studies.length ? false : true,
              },
              () => {
                LoadStudiesSeries(0, this);
              }
            );
            this.props.holder.setTotal(total);
            if (this.props.holder.setLoading) {
              this.props.holder.setLoading(false);
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          console.log({ error });
          if (this.props.holder.setLoading) {
            this.props.holder.setLoading(false);
          }
          this.setState({ loading: false, total: 0 });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  getIdStudy = () => {
    const params = getParams(this.props);
    const url = getParamsUrl(this.props);
    const { level_1, level_2 } = params;
    const idStudy = parseInt(level_2, 10);
    if (typeof level_1 === 'undefined' || isNaN(idStudy)) {
      if (url !== '/image_analysis') {
        this.props.history.push('/image_analysis');
      }
      return null;
    }
    return idStudy;
  };
  handleStudyDeleted = Study => {
    let { Studies } = this.state;
    const index = Studies.map(x => x.idStudy).indexOf(Study.idStudy);
    if (index !== -1) {
      Studies.splice(index, 1);
      this.setState({ Studies });
      this.props.holder.setTotal(Studies.length);
    }
  };
  handleUpdateSeries = (idStudy, Series) => {
    const { Studies } = this.state;
    const index = Studies.map(x => x.idStudy).indexOf(idStudy);
    // console.log({ idStudy, index, total: Series.length });
    if (index !== -1) {
      Studies[index].Series = Series;
      this.setState({ Studies });
    }
  };
  handleSeriesDeleted = Study => {
    if (IsInvalid(Study)) return;
    const { Studies } = this.state;
    console.log({
      Study,
      Studies,
    });
    let index = Studies.map(x => x.idStudy).indexOf(Study.idStudy);
    if (index === -1) {
      Studies.push(Study);
    }
    const idStudy = this.getIdStudy();
    index = Studies.map(x => x.idStudy).indexOf(idStudy);
    if (index !== -1) {
      Studies[index] = { ...Study };
      const total_series = Studies[index].Series.length;
      if (total_series) {
        this.setState({ Studies });
      } else {
        Studies.splice(index, 1);
        this.props.history.push('/image_analysis');
      }
    }
  };
  render() {
    const { Studies, loading, stop_warning } = this.state;
    const { classes, display_database, height } = this.props;
    const idStudy = this.getIdStudy();
    // console.log({ Studies });
    if (idStudy) {
      let Study = null;
      let total_series = 0;
      if (Studies.length) {
        const index = Studies.map(x => x.idStudy).indexOf(idStudy);
        if (index !== -1) {
          Study = Studies[index];
          console.log({ Study });
          if (Study.Series) {
            total_series = Study.Series.length;
          }
        }
      }
      return (
        <ViewStudy
          display_database={display_database}
          handleAddError={this.props.handleAddError}
          handleAddSuccess={this.props.handleAddSuccess}
          handleSeriesDeleted={this.handleSeriesDeleted}
          handleSetStudy={this.props.handleSetStudy}
          height={height}
          holder={this.holder}
          idStudy={idStudy}
          Study={Study}
          total_series={total_series}
          user={this.props.user}
        />
      );
    }
    // console.log('2', { Studies });

    if (display_database === 'list') {
      return (
        <StudyListView
          handleAddError={this.props.handleAddError}
          height={height}
          index_series={this.state.index_series}
          loading={loading}
          Studies={Studies}
          total={this.state.total}
          user={this.props.user}
        />
      );
    } else {
      if (loading) {
        return <CircularProgressWaiting direction="column" />;
      }
      return (
        <StudyGridView
          classes={classes}
          handleAddError={this.props.handleAddError}
          handleStudyDeleted={this.handleStudyDeleted}
          handleWarning={warning => this.handleAddSuccess(warning, 'warning')}
          height={height}
          index_series={this.state.index_series}
          stop_warning={stop_warning}
          Studies={Studies}
          user={this.props.user}
        />
      );
    }
  }
}

ViewDatabaseStudy.propTypes = {
  classes: PropTypes.object,
  filters: PropTypes.object,
};
ViewDatabaseStudy.defaultProps = {
  filters: null,
};
export default withStyles(withRouter(ViewDatabaseStudy), useStyles);
