import vtkColormaps from '@kitware/vtk.js/Rendering/Core/ColorTransferFunction/ColorMaps';
import { getRenderingEngine } from '@cornerstonejs/core';

export const GetColorMaps = () => {
  const colormaps = vtkColormaps.rgbPresetNames.map(presetName =>
    vtkColormaps.getPresetByName(presetName)
  );
  return colormaps;
};
export const setViewportColormap = (
  renderingEngineId,
  viewportId,
  volumeId,
  colormapName
) => {
  // Get the rendering engine
  const renderingEngine = getRenderingEngine(renderingEngineId);

  // Get the volume viewport
  const viewport = renderingEngine.getViewport(viewportId);
  viewport.setProperties({ colormap: { name: colormapName } }, volumeId);
  viewport.render();
};
const calculateOptimalWindowLevel = image => {
  if (!image) {
    console.log('Invalid Image');
    return { windowWidth: 0, windowCenter: 0 };
  }

  // Get the pixel data from the image
  const pixelData = image.getPixelData();

  // Calculate min and max pixel values
  let min = Infinity;
  let max = -Infinity;

  for (let i = 0; i < pixelData.length; i++) {
    const value = pixelData[i];
    if (value < min) min = value;
    if (value > max) max = value;
  }

  // Calculate window width and level
  const windowWidth = max - min;
  const windowCenter = (max + min) / 2;
  console.log({ min, max });

  return { windowWidth, windowCenter };
};
export const applyOptimalWindowLevel = (viewport, volume) => {
  if (!viewport || !volume) {
    return;
  }
  try {
    const { imageData } = viewport.getImageData();
    const image = imageData.getScalarData();
    if (!image) {
      return;
    }
    // Calculate optimal window level
    const { windowWidth, windowCenter } = calculateOptimalWindowLevel(image);
    if (windowWidth && windowCenter) {
      // Apply the window level to the viewport
      viewport.setProperties({ windowWidth, windowLevel: windowCenter });
      viewport.render();
    }
  } catch (error) {
    console.log({ error });
  }
};
