import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from 'tss-react/mui';

import {
  GetData,
  getParams,
  ServerErrorsString,
  transformTextToLink,
} from 'helpers';
import useStyles from '../Styles';
import { MutationUpdateSummary, MutationUpdateUnit } from 'graphql/Units';
import {
  TabUnitDicomViewer,
  TabUnitGeneral,
  TabUnitLanding,
} from './components';
import { Divider, Paper } from '@mui/material';
import { TabPanel, TabSmall, TabsSmall } from 'components';

class UnitEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      setting_changed: false,
      unit_changed: false,
      unit_modify: {
        idUnit: -1,
        name: '',
        description: '',
        institution: '',
        api: '',
      },
      loading_update: false,
      tabValue: 0,
      tabs: [
        {
          name: 'General',
          TabComponent: TabUnitGeneral,
        },
        {
          name: 'Landing',
          TabComponent: TabUnitLanding,
        },
        {
          name: 'Dicom Viewer',
          TabComponent: TabUnitDicomViewer,
        },
      ],
    };
    this.holder = {
      getSetting: null,
    };
  }

  setUnit = () => {
    const { unit } = this.props;
    const { unit_modify } = this.state;
    if (unit && unit.idUnit !== unit_modify.idUnit) {
      const { idUnit, name, description, institution } = unit;
      this.setState({
        unit_modify: { idUnit, name, description, institution },
      });
    }
  };

  componentDidMount() {
    this.setUnit();
    const { level_2 } = getParams(this.props);
    // console.log({ level_1, level_2 });
    const index = this.state.tabs
      .map(x => transformTextToLink(x.name))
      .indexOf(level_2);
    let tabValue = 0;
    if (index !== -1) tabValue = index;
    this.setState({ tabValue });
  }

  componentDidUpdate() {
    this.setUnit();
  }

  runUpdateSave = () => {
    const { loading } = this.state;
    if (loading) return;
    const { idUnit } = this.props.unit;
    this.setState({ loading: true });
    (async () => {
      let { idUnit: id, ...res_unit } = this.state.unit_modify;
      if (id === -1) {
        res_unit = null;
      }
      const setting = this.holder.getSetting();
      MutationUpdateUnit(idUnit, res_unit, setting)
        .then(res => {
          const data = GetData(res);
          const {
            UpdateUnit: { ok, errors },
          } = data;
          if (ok) {
            this.setState({
              loading: false,
              setting_changed: false,
              unit_changed: false,
            });
            if (setting || res_unit) {
              let new_unit = {
                ...this.props.unit,
                ...setting,
              };
              if (res_unit) {
                new_unit = {
                  ...new_unit,
                  ...res_unit,
                };
              }
              this.props.setUnit(new_unit);
            }
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handelChange = setting_changed => {
    this.setState({ setting_changed });
  };
  handelChangeText = event => {
    let { unit_modify } = this.state;
    unit_modify = {
      ...unit_modify,
      [event.currentTarget.name]: event.currentTarget.value,
    };
    this.setState({
      unit_modify,
      unit_changed: this.IsUnitChanged(unit_modify),
    });
  };
  handleReNameUnit = name => {
    let { unit_modify } = this.state;
    unit_modify = {
      ...unit_modify,
      name,
    };
    this.setState({
      unit_modify,
      unit_changed: this.IsUnitChanged(unit_modify),
    });
  };
  IsUnitChanged = N => {
    if (typeof N === 'undefined') {
      N = this.state.unit_modify;
    }
    const { unit: O } = this.props;
    if (O.name !== N.name) return true;
    if (O.description !== N.description) return true;
    if (O.institution !== N.institution) return true;
    if (O.api !== N.api) return true;
    return false;
  };
  runUpdateSummary = () => {
    const { loading_update } = this.state;
    if (loading_update) return;
    this.setState({ loading_update: true });
    (async () => {
      MutationUpdateSummary()
        .then(res => {
          const data = GetData(res);
          const {
            updateSummary: { ok, errors },
          } = data;
          //
          if (ok) {
            this.setState({ loading_update: false });
          } else {
            throw errors;
          }
        })
        .catch(error => {
          this.setState({ loading_update: false });
          this.props.handleAddError(ServerErrorsString(error));
        });
    })();
  };
  handleTabValue = (event, tabValue) => {
    event.preventDefault();
    tabValue = parseInt(tabValue, 10);
    this.setState({ tabValue });
    const key = transformTextToLink(this.state.tabs[tabValue].name);
    this.props.history.push(`/management/units/main_unit/${key}`);
  };
  render() {
    const { classes, unit, user } = this.props;
    const { tabValue } = this.state;
    if (!unit) return null;
    let admin = false;
    if (typeof user !== 'undefined') {
      admin = user.id === unit.Admin.id;
    }
    const height_tab = '35px';
    return (
      <React.Fragment>
        <Paper square>
          <TabsSmall
            height_tab={height_tab}
            indicatorColor="primary"
            onChange={this.handleTabValue}
            scrollButtons="auto"
            textColor="primary"
            value={tabValue}>
            {this.state.tabs.map((tab, index) => {
              return (
                <TabSmall
                  height_tab={height_tab}
                  key={`unit-edit-tab-${index}`}
                  label={tab.name}
                />
              );
            })}
          </TabsSmall>
          <Divider />
        </Paper>
        <div style={{ width: '100%' }}>
          {this.state.tabs.map((tab, index) => {
            const { TabComponent } = tab;
            return (
              <TabPanel
                className={classes.tab_panel}
                index={index}
                key={`unit-edit-tab-panel-${index}`}
                value={tabValue}>
                <TabComponent
                  admin={admin}
                  classes={classes}
                  handelChange={this.handelChange}
                  handelChangeText={this.handelChangeText}
                  handleAddError={this.props.handleAddError}
                  handleMaximize={this.props.handleMaximize}
                  handleReNameUnit={this.handleReNameUnit}
                  height={this.props.height}
                  holder={this.holder}
                  runUpdateSave={this.runUpdateSave}
                  runUpdateSummary={this.runUpdateSummary}
                  setUnit={this.props.setUnit}
                  state={this.state}
                  unit={unit}
                  user={user}
                />
              </TabPanel>
            );
          })}
        </div>
      </React.Fragment>
    );
  }
}

UnitEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};
UnitEdit.defaultProps = {};

export default withStyles(UnitEdit, useStyles);
