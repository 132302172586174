/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import { List } from '@mui/material';
import { withStyles } from 'tss-react/mui';
import Divider from '@mui/material/Divider';
import { SidebarNavItem } from './components';
import { connect } from 'react-redux';
import { setDicomViewer } from 'redux-store/actions';

const useStyles = theme => ({
  root: {},
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
});

class SidebarNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
    };
  }

  componentDidMount() {
    const {
      pages,
      match: { url },
    } = this.props;
    const { selected } = this.state;
    const url_parts = url.split('/');
    url_parts.shift();
    const index = pages.map(x => x.href).indexOf(`/${url_parts[0]}`);
    if (index === -1) return;
    if (index !== selected) {
      this.setState({ selected: index });
    }
  }
  handleSelected = (index, title) => {
    if (title === 'Dicom Viewer') {
      localStorage.setItem('page', title);
      this.props.setDicomViewer(true);
    } else {
      localStorage.removeItem('page');
      this.props.setDicomViewer(false);
    }
    console.log({ index, title });
    this.setState({ selected: index });
  };
  getSelectedIndex = () => {
    const {
      match: { url },
    } = this.props;
    const url_parts = url.split('/');
    url_parts.shift();
    const { pages } = this.props;
    const index = pages.map(x => x.href).indexOf(`/${url_parts[0]}`);
    return index;
  };

  render() {
    const { classes, pages, className, isDicomViewer } = this.props;
    let { selected } = this.state;
    const index_menu = this.getSelectedIndex();
    return (
      <List
        className={clsx(classes.root, className)}
        key="main-sidebar-nav-list">
        {pages.map((page, index) => {
          if (page.title === 'divider') {
            return <Divider key={`sidebar-item-${index}`} />;
          }
          if (index_menu >= 0 && selected !== index_menu) {
            selected = index_menu;
          }
          return (
            <SidebarNavItem
              handleSelected={this.handleSelected}
              index={index}
              isDicomViewer={isDicomViewer}
              key={`sidebar-item-${index}`}
              minSidebar={this.props.minSidebar}
              openSidebar={this.props.openSidebar}
              page={page}
              selected={selected}
            />
          );
        })}
      </List>
    );
  }
}

SidebarNav.propTypes = {
  classe: PropTypes.object,
};

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

const mapStateToProps = state => {
  const { isDicomViewer } = state.page_globals;
  return {
    isDicomViewer: isDicomViewer === undefined ? false : isDicomViewer,
  };
};

export default connect(mapStateToProps, { setDicomViewer })(
  withStyles(withRouter(SidebarNav), useStyles)
);
