import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextField,
} from '@mui/material';
import { ButtonReload, SearchProjectCentersInputs } from 'components';
import { withRouter } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenIcon from '@mui/icons-material/ZoomOutMap';
import { setDisplayDicomMultiSeries } from 'redux-store/actions';
import { IsUserViewer } from 'helpers';
class ToolBarBrowserProjectSeries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      center: null,
      PatientID: undefined,
      AccessionNumber: undefined,
    };
  }
  componentDidUpdate() {
    const { query } = this.props.state;
    let { PatientID, AccessionNumber } = this.state;
    let keys = {};
    if (query.PatientID !== PatientID) {
      keys.PatientID = query.PatientID;
    }
    if (query.AccessionNumber !== AccessionNumber) {
      keys.AccessionNumber = query.AccessionNumber;
    }
    if (Object.keys(keys).length) {
      this.setState({ ...keys });
    }
  }
  handleAddCenter = center => {
    console.log({ center });
    let { query } = this.props.state;
    let idCenter = null;
    if (center) idCenter = center.id;
    if (idCenter) query = { ...query, idCenter };
    else delete query.idCenter;
    this.props.setState({ query }, () => {
      this.setState({ center });
    });
  };
  handleChanged = event => {
    const value = event.target.value;
    const key = event.target.id;
    let { query } = this.props.state;
    if (value && value !== '') query[key] = value;
    else delete query[key];
    this.props.setState({ query }, () => {
      this.setState({ [key]: value });
    });
  };
  handleViewListSeries = () => {
    const {
      state: { selected },
    } = this.props;
    this.props.setDisplayDicomMultiSeries(selected);
    this.props.history.push('/dicom_viewer');
  };
  render() {
    const {
      project,
      loading,
      state: { selected },
      user,
    } = this.props;
    const InputProps = {
      sx: {
        input: {
          '&::placeholder': {
            fontSize: '13px', // Adjust the font size as needed
            color: 'gray', // Optional: customize the color too
          },
        },
      },
    };
    const InputLabelProps = {
      shrink: true,
    };
    let ComponentOpenDicomViewer = null;
    if (IsUserViewer(user)) {
      ComponentOpenDicomViewer = (
        <Grid item xs={1.3}>
          <IconButton
            disabled={!selected.length}
            onClick={this.handleViewListSeries}
            size="large">
            <Badge
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              badgeContent={selected.length}
              color="error">
              <OpenIcon style={{ color: selected.length ? 'green' : 'gray' }} />
            </Badge>
          </IconButton>
        </Grid>
      );
    }
    return (
      <Paper>
        <Accordion
          expanded={this.state.expanded}
          style={{ margin: 0, minHeight: 0 }}>
          <AccordionSummary
            aria-controls="panel-stuy-times-content"
            expandIcon={
              <ExpandMoreIcon
                onClick={() =>
                  this.setState({ expanded: !this.state.expanded })
                }
              />
            }
            id="panel-stuy-times-header"
            style={{
              display: 'flex',
              margin: 0,
              padding: 0,
              paddingLeft: 10,
              paddingRight: 10,
              height: 55,
              minHeight: 55,
              // '&MuiAccordionSummary-content.Mui-expanded': {
              //   marginBottom: 10,
              // },
            }}>
            <Grid
              container
              direction="row"
              item
              p={1}
              spacing={1}
              sx={{
                maxHeight: 55,
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingTop: 0,
                paddingLeft: 0,
              }}
              xs={12}>
              <Grid
                alignItems="center"
                container
                direction="row"
                item
                justifyContent="flex-start"
                style={{
                  paddingTop: 0,
                }}
                xs={6}>
                {ComponentOpenDicomViewer}
                <Grid item xs={10}>
                  <SearchProjectCentersInputs
                    handleAddCenter={this.handleAddCenter}
                    handleAddError={this.props.handleAddError}
                    idProject={project.id}
                    no_icon
                    placeholder="Leave it empty to query all centers..."
                  />
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  paddingTop: 0,
                }}>
                <TextField
                  id="PatientID"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="PatientName"
                  onChange={this.handleChanged}
                  placeholder="PatientName..."
                  size="small"
                  value={this.state.PatientID}
                  variant="filled"
                />
              </Grid>
              <Grid
                item
                style={{
                  paddingTop: '2px',
                }}>
                <ButtonReload
                  handleReload={() => this.props.loadProjectSeries()}
                  loading={loading}
                  small
                  tooltip="Reload Projects"
                />
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Divider variant="middle" />
            <Grid
              container
              direction="row"
              p={1}
              spacing={1}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'flex-start',
              }}>
              <Grid item>
                <TextField
                  id="StudyDate"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="StudyDate"
                  onChange={this.handleChanged}
                  placeholder="Study date: (0008,0020)..."
                  variant="filled"
                />
              </Grid>
              <Grid
                item
                style={{
                  paddingTop: 0,
                }}>
                <TextField
                  id="AccessionNumber"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="AccessionNumber"
                  onChange={this.handleChanged}
                  placeholder="AccessionNumber..."
                  size="small"
                  value={this.state.AccessionNumber}
                  variant="filled"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="SeriesDescription"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="SeriesDescription"
                  onChange={this.handleChanged}
                  placeholder="Series description (0008,103E)..."
                  variant="filled"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="SeriesNumber"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="SeriesNumber"
                  onChange={this.handleChanged}
                  placeholder="Series Number (0020,0011)..."
                  variant="filled"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="SliceThickness"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="SliceThickness"
                  onChange={this.handleChanged}
                  placeholder="Slice thickness (0018,0050)..."
                  type="number"
                  variant="filled"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="Modality"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="Modality"
                  onChange={this.handleChanged}
                  placeholder="Modality (0008,0060)..."
                  variant="filled"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="ProtocolName"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="ProtocolName"
                  onChange={this.handleChanged}
                  placeholder="Protocol Name (0018,1030)..."
                  variant="filled"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="KVP"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="KVP"
                  onChange={this.handleChanged}
                  placeholder="KVP (0018,0060)..."
                  variant="filled"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="DataCollectionDiameter"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="Collimation/collection diameter"
                  onChange={this.handleChanged}
                  placeholder="DataCollectionDiameter (0018,0090)..."
                  sx={{ minWidth: 260 }}
                  variant="filled"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="SingleCollimationWidth"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="Single Collimation Width"
                  onChange={this.handleChanged}
                  placeholder="SingleCollimationWidth (0018,9306)..."
                  sx={{ minWidth: 260 }}
                  variant="filled"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="ManufacturerModelName"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="ManufacturerModelName"
                  onChange={this.handleChanged}
                  placeholder="ManufacturerModelName (0008,1090)..."
                  sx={{ minWidth: 260 }}
                  variant="filled"
                />
              </Grid>
              <Grid item>
                <TextField
                  id="ContrastBolusAgent"
                  InputLabelProps={InputLabelProps}
                  InputProps={InputProps}
                  label="ContrastBolusAgent"
                  onChange={this.handleChanged}
                  placeholder="ContrastBolusAgent (0018,0010)..."
                  sx={{ minWidth: 260 }}
                  variant="filled"
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Paper>
    );
  }
}

ToolBarBrowserProjectSeries.propTypes = {
  project: PropTypes.object,
};

const mapStateToProps = state => {
  const { user } = state.info_user;
  const { series, study } = state.page_dicom_viewer;
  const { openSidebar, width_sidebar, minSidebar } = state.page_globals;
  const { isDesktop } = state.layout;
  let width = window.innerWidth;
  if (openSidebar && isDesktop) {
    if (minSidebar) width -= 63;
    else width -= width_sidebar;
  }
  return { series, study, width, isDesktop, minSidebar, user };
};

export default connect(mapStateToProps, { setDisplayDicomMultiSeries })(
  withRouter(ToolBarBrowserProjectSeries)
);
